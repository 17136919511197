import { Button, Flex, Spacer } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import ReactDOM from "react-dom";
import { ThemeConstants } from "./ThemeConstants";

interface TaskButtonsProps {
  disabledSubmit?: boolean;
  onBack?: () => void;
  onSave?: () => void;
  onSubmit?: () => void;
  form?: string;
  //other arbitrary props to pass to child Flex component
  [x: string]: any;
}

/**
 * A reusable component version of the "Back"/("Save")/"Submit" buttons for Task Input. The Submit button has a type
 * of "submit" that acts upon the "form" reference passed in.
 *
 * Any extra props are spread onto the Flex container wrapping the buttons.
 *
 * @param onBack the function to call when "Back" is clicked
 * @param onSave the optional function to call when "Save" is clicked.
 * Presence of this param also indicates the "Save" button should be shown.
 * @constructor
 */
export default function TaskButtons({
  disabledSubmit = false,
  onBack,
  onSave,
  form,
  ...rest
}: TaskButtonsProps): JSX.Element {
  return ReactDOM.createPortal(
    <Flex direction={"row"} {...rest} my={"1rem"} height="100%">
      <Spacer />
      <Button color="white" variant={"outline"} onClick={onBack}>
        <Trans>Back</Trans>
      </Button>
      <Spacer minW="1em" maxW="5em" />
      {!!onSave && (
        <>
          <Button color="gray.600" onClick={onSave}>
            <Trans>Save</Trans>
          </Button>
          <Spacer minW="1em" maxW="5em" />
        </>
      )}
      <Button
        colorScheme="orange"
        type="submit"
        form={form}
        disabled={disabledSubmit}>
        <Trans>Submit</Trans>
      </Button>
      <Spacer />
    </Flex>,
    document.getElementById(ThemeConstants.TASK_BUTTONS_CONTAINER_ID) ||
      document.createElement("div"),
  );
}
