import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { get, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import ReactInputMask from "react-input-mask";

export interface FormInputProps extends InputProps {
  fieldId: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  helperText?: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  validationPattern?: RegExp;
  validationPatternMessage?: string;
  inputMask?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  bgColor?: string;
}

export function lengthErrMsgGenerator(minLength?: number, maxLength?: number) {
  if (minLength && !maxLength) {
    return t`Must be ${minLength} characters`;
  }

  if (minLength && maxLength) {
    if (minLength != maxLength) {
      return t`Must be between ${minLength} and ${maxLength} characters`;
    } else {
      return t`Must be exactly ${minLength} characters`;
    }
  }

  if (maxLength) {
    return t`Must be less than ${maxLength} characters`;
  }

  return "";
}

export default function FormInput({
  fieldId,
  label,
  required = true,
  requiredMessage,
  helperText,
  defaultValue,
  placeholder,
  value,
  validationPattern,
  validationPatternMessage,
  inputMask,
  minLength,
  maxLength,
  disabled,
  readOnly,
  bgColor,
  hidden = false,
  ...rest
}: FormInputProps): JSX.Element {
  const { register, formState } = useFormContext();
  const lengthErrMsg = lengthErrMsgGenerator(minLength, maxLength);

  const inputIsAccessible = !hidden && !disabled && !readOnly;
  const validateRequired = inputIsAccessible && required;
  const validatePattern =
    inputIsAccessible && validationPatternMessage && validationPattern;
  const validateMinLength = inputIsAccessible && minLength;
  const validateMaxLength = inputIsAccessible && maxLength;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={get(formState.errors, fieldId)}
      py="0.5rem">
      <FormLabel
        htmlFor={fieldId}
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <Input
        as={(inputMask && ReactInputMask) || undefined}
        mask={disabled ? undefined : inputMask}
        id={fieldId}
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        isReadOnly={readOnly}
        borderColor={"gray.600"}
        _disabled={{ opacity: 1 }}
        {...rest}
        {...register(fieldId, {
          required: {
            value: validateRequired,
            message: requiredMessage ?? `This is required`,
          },
          minLength:
            (validateMinLength && {
              value: minLength,
              message: lengthErrMsg,
            }) ||
            undefined,
          maxLength:
            (validateMaxLength && {
              value: maxLength,
              message: lengthErrMsg,
            }) ||
            undefined,
          pattern:
            (validatePattern && {
              value: validationPattern,
              message: validationPatternMessage,
            }) ||
            undefined,
        })}
      />
      <FormErrorMessage>
        {get(formState.errors, `${fieldId}.message`)}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
