import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import Header from "components/common/basic/Header";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import { useHistory } from "react-router";
import { ResponseCodes } from "tasks/apiConstants";
import { WarningIcon } from "@chakra-ui/icons";
import { useMutation, useQuery } from "react-query";
import {
  ApiDataResponse,
  LoginData,
  putPinVerification,
  sendActiveLogin,
  TokenData,
} from "auth/authApi";
import { useState } from "react";
import { AxiosError } from "axios";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import PinForm from "auth/PinForm";
import { useForm } from "react-hook-form";
import useAuth from "auth/useAuth";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { getTheme } from "applicantTheme/themeApi";
import ApplicantTheme from "applicantTheme/ApplicantTheme";
import useOtesModal from "error/useOtesModal";
import OtesError from "components/common/basic/OtesError";

export default function CandidateEntryUnavailableScreen({
  otesErrorOverride,
}: {
  otesErrorOverride?: OtesError;
}): JSX.Element {
  const [pinCode, setPinCode] = useState<string>("");
  const { triggerOtesModal } = useOtesModal();
  const { token, updateToken, otesError, setOtesError } = useAuth();
  const { handleSubmit } = useForm<never>();
  const { data: theme, isFetching: isFetchingTheme } = useQuery(
    ["theme", token],
    () => (token ? getTheme(token) : ({} as Promise<ApplicantTheme>)),
  );
  const history = useHistory();

  let header;
  let messaging;
  let instructions = (
    <Trans>
      Please contact Orange Tree at{" "}
      <a href="tel:1-888-222-0667">1-888-222-0667</a> for assistance.
    </Trans>
  );

  const { MAINTENANCE } = ResponseCodes.Error.System;
  const { BGC_CANCELLED, ENTRY_EXPIRED, ENTRY_LOCKED, BGC_NOT_FOUND } =
    ResponseCodes.Error.Entry;

  const setFakePinCode = () =>
    setPinCode(Math.floor(Math.random() * 1000000).toString());

  // TODO (JDL:2023-01-19) Consolidate with Login.tsx impl.
  const sendPinMutator = useMutation(
    () => {
      return sendActiveLogin(token);
    },
    {
      onSuccess: (result: ApiDataResponse<LoginData>) => {
        if (!result?.data?.pinCode) {
          return setFakePinCode();
        }

        return setPinCode(result.data.pinCode);
      },
      //set a fake 6 digit pinCode for good measure
      onError: (response: AxiosError) => {
        handleOtesErrorFromAxiosError(response);
        setFakePinCode();
        triggerOtesModal({
          bodyText: t`Unable to send pin, please try again.`,
        });
      },
    },
  );
  const verifyPinMutator = useMutation(
    (pin: string) => putPinVerification(pin, pinCode),
    {
      onSuccess: async (tokenResponse: ApiDataResponse<TokenData>) => {
        setOtesError(undefined);
        updateToken(tokenResponse.data?.value);
      },
      onError: (response: AxiosError) => {
        if (
          ResponseCodes.Error.Action.INVALID_PIN ===
          response?.response?.data?.code
        ) {
          triggerOtesModal({
            bodyText: t`Incorrect or expired PIN, please try again.`,
          });
        } else {
          triggerOtesModal({
            bodyText: t`Unable to verify PIN, please try again.`,
          });
        }
      },
    },
  );

  switch (otesErrorOverride?.otesErrorCode ?? otesError?.otesErrorCode) {
    case BGC_CANCELLED:
      if (theme?.hasActive) {
        header = t`Login`;

        if (pinCode) {
          instructions = (
            <Box pt={3}>
              <PinForm
                description={t`A pin was sent to the phone and/or email on record. This pin will expire in 10 minutes.`}
                onPinSubmit={(pin: string) => verifyPinMutator.mutate(pin)}
                onReSendPin={() => sendPinMutator.mutate()}
              />
            </Box>
          );
        } else {
          messaging = (
            <Trans>
              You require an updated login to access your background screen.
            </Trans>
          );
          instructions = (
            <>
              <form
                onSubmit={handleSubmit(async () => sendPinMutator.mutate())}>
                <Button
                  width={"fit-content"}
                  my={1}
                  mx={"auto"}
                  colorScheme="orange"
                  type="submit">
                  Send me a new login
                </Button>
              </form>
              <Text fontSize={"sm"}>
                <Trans>Sent to the phone and/or email on record.</Trans>
              </Text>
            </>
          );
        }
      } else {
        header = t`Entry Unavailable`;
        messaging = (
          <Trans>
            Your information is no longer available via CandidateConnect.
          </Trans>
        );
        instructions = (
          <Trans>
            Please contact <b>{theme?.clientLabel || "Your Company"}</b> or
            Orange Tree at <a href="tel:1-888-222-0667">1-888-222-0667</a> for
            assistance with your background screen.
          </Trans>
        );
      }

      break;
    case ENTRY_EXPIRED:
      header = t`Entry Expired`;
      messaging = (
        <Trans>
          Your information is no longer available via CandidateConnect.
        </Trans>
      );
      instructions = (
        <Trans>
          Please contact <b>{theme?.clientLabel || "Your Company"}</b> or Orange
          Tree at <a href="tel:1-888-222-0667">1-888-222-0667</a> for assistance
          with your background screen.
        </Trans>
      );
      break;
    case ENTRY_LOCKED:
      header = t`Locked`;
      messaging = <Trans>Too many failed login attempts.</Trans>;
      break;
    case BGC_NOT_FOUND:
      header = t`Error`;
      messaging = (
        <Trans>
          Candidate entry is unavailable because your data could not be looked
          up.
        </Trans>
      );
      break;
    case MAINTENANCE:
      header = t`Maintenance`;
      messaging = (
        <Trans>CandidateConnect is temporarily down for maintenance.</Trans>
      );
      instructions = (
        <Trans>
          We apologize for the inconvenience. Please try again later.
          <br />
          Maintenance events typically last less than 1 hour.
        </Trans>
      );
      break;
    default:
      header = t`Error`;
      messaging = (
        <Trans>Candidate entry is unavailable due to a system error.</Trans>
      );
      instructions = (
        <Trans>
          If you continue to see this message, please contact Orange Tree at{" "}
          <a href="tel:1-888-222-0667">1-888-222-0667</a> for assistance.
        </Trans>
      );
      break;
  }

  conditionalHistoryReplace(history, AppPath.ROOT);

  if (
    isFetchingTheme ||
    sendPinMutator.isLoading ||
    verifyPinMutator.isLoading
  ) {
    return (
      <>
        <Header text="Login" />
        <SpinnerOverlay
          text={
            isFetchingTheme
              ? "One moment please..."
              : sendPinMutator.isLoading
              ? "Sending PIN..."
              : "Verifying PIN..."
          }
        />
      </>
    );
  } else {
    return (
      <>
        <Header text={header} />
        <Container centerContent maxW="container.md">
          {!pinCode && (
            <>
              <Flex p="1rem" direction="row" alignItems={"center"}>
                <WarningIcon w="30px" h="30px" m="0.5rem" color="red.500" />
                <Heading px="0.5rem" as="h3" size="md">
                  Oops...
                </Heading>
              </Flex>
              <Box pb={5}>{messaging}</Box>
            </>
          )}
          <Box textAlign={"center"}>{instructions}</Box>
        </Container>
      </>
    );
  }
}
