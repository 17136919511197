import {
  ApplicationStatus,
  TaskIconType,
  ApplicantEntryTaskId,
  TasksResponse,
} from "tasks/tasksApi";
import Progress from "tasks/Progress";
import TaskList from "tasks/TaskList";

export interface StatusScreenProps {
  tasksData?: TasksResponse;
  onTaskClick: (
    taskId: ApplicantEntryTaskId,
    taskName: string,
    taskIconType: TaskIconType,
  ) => void;
}

// } else if (theme?.applicationStatus === "Cancelled") {
//     return <Heading>Cancelled -- TODO</Heading>;
// } else if (theme?.applicationStatus === "Expired") {
//     return <Heading>Expired -- TODO</Heading>;

export default function StatusScreen({
  tasksData,
  onTaskClick,
}: StatusScreenProps): JSX.Element {
  return (
    <>
      <Progress
        status={tasksData?.applicationStatus || ApplicationStatus.COLLECTING}
        daysRemaining={tasksData?.daysRemaining ?? -1}
      />
      <TaskList
        incompleteTasks={tasksData?.incompleteTasks}
        completeTasks={tasksData?.completeTasks}
        informationTasks={tasksData?.informationTasks}
        onTaskClick={onTaskClick}
      />
    </>
  );
}
