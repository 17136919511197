import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { SocialSecurityNumberFormFields } from "components/tasks/interviews/social-security-number/SocialSecurityNumberTask";
import FormInput from "components/common/form/FormInput";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import { SOCIAL_SECURITY_NUMBER } from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

interface SocialSecurityNumberFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: SocialSecurityNumberFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
}

export type SocialSecurityNumberApiSubForm = InterviewApiSubForm<
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <Box>
    <FormProvider {...methods}>
      <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  </Box>
);

const FormContent = ({ formName, formId }: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Controller
        name={`${formId}.socialSecurityNumber`}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <FormInput
            width="100%"
            maxWidth="140px"
            fieldId={name}
            type={"tel"}
            inputMode={"numeric"}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDownEnforceNumeric}
            label={SOCIAL_SECURITY_NUMBER.LABEL}
            inputMask={SOCIAL_SECURITY_NUMBER.MASK}
            placeholder={SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
            validationPattern={SOCIAL_SECURITY_NUMBER.PATTERN}
            validationPatternMessage={SOCIAL_SECURITY_NUMBER.PATTERN_MESSAGE}
          />
        )}
      />
    </FormControl>
  );
};

export default function SocialSecurityNumberForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: SocialSecurityNumberFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<SocialSecurityNumberFormFields>({ defaultValues });

  const { formName } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    formId,
    formName,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons onBack={goToStatusCheck} form={formId} />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
