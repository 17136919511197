import { DateTime, Interval } from "luxon";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { ApiFormMessages } from "tasks/tasksApi";
import { t } from "@lingui/macro";
import { getSplitFormDate } from "components/common/form/DateInput";
import { EmploymentOrGapFormFields } from "./employment-history/EmploymentOrGapForm";

export interface OptionalApiIdFormFields {
  apiId?: string;
}

export interface TaskComponentProps {
  goToStatusCheck?: () => void;
}

/**
 * All FormComponents need to specify the fields they support.
 * Optionally, if the form will handle formMessages, that type should
 * be specified second.
 */
export interface FormComponentProps<Fields, Messages = ApiFormMessages>
  extends TaskComponentProps {
  formMessages?: Messages;
  onSubmit: SubmitHandler<Fields>;
}

/**
 * Savable Form Components will always need to specify the form's default values
 * fields as type Fields, and optionally the messages types as Messages.
 */
export interface SavableFormComponentProps<Fields, Messages = ApiFormMessages>
  extends FormComponentProps<Fields, Messages> {
  defaultValues: Omit<Fields, "doSave">;
  isSavable: boolean;
}

/**
 * Special Form component props. Does not allow saving.
 */
export interface NonSavableFormComponentProps<
  Fields,
  Messages = ApiFormMessages,
> extends FormComponentProps<Fields, Messages> {
  defaultValues: Omit<Fields, "doSave">;
}

/**
 * A pure function returning a new object tree with split dates.
 * It traverses the object tree looking for "START_DATE" and "END_DATE" nodes
 * to split and add into the object tree as separate start/end year/month/day
 * properties for each node.
 * @param node the root node of the object tree to examine.
 */
export function splitDates(node?: any): any {
  let result;
  if (typeof node === "object") {
    if (Array.isArray(node)) {
      result = node.map((item) => splitDates(item));
    } else {
      result = Object.create(node);
      for (const [key, value] of Object.entries(node)) {
        result[key] = splitDates(value);
      }
    }
    if (node?.START_DATE) {
      //TODO: remove once old date fields are confirmed removed
      result = {
        ...result,
        ...getSplitFormDate(node.START_DATE, [
          "startYear",
          "startMonth",
          "startDay",
        ]),
      };
      const startDate = node.START_DATE;

      result = {
        ...result,
        startDate,
      };
    }
    if (node?.END_DATE) {
      //TODO: remove once old date fields are confirmed removed
      result = {
        ...result,
        ...getSplitFormDate(node.END_DATE, ["endYear", "endMonth", "endDay"]),
      };
      const endDate = node.END_DATE;

      result = {
        ...result,
        endDate,
      };
    }
  } else {
    result = node;
  }
  return result;
}

/**
 * A pure function returning a new object tree with flattened "existingValue" objects.
 * It traverses the given object tree looking for any property X whose value is an object
 * having a key of "existingValue". If found, the "existingValue" string is assigned to X.
 * @param node the root node of the object tree to examine
 */
export function flattenExistingValues(node?: any): any {
  let result;

  if (typeof node === "object") {
    if (node?.existingValue) {
      result = Object.keys(node?.existingValue).length
        ? node?.existingValue
        : undefined;
    } else if (Array.isArray(node)) {
      result = node.map((item) => flattenExistingValues(item));
    } else if (!Object.keys(node).length) {
      result = undefined;
    } else {
      result = Object.create(node);
      for (const [key, value] of Object.entries(node)) {
        result[key] = flattenExistingValues(value);
      }
    }
  } else {
    result = node;
  }
  return result;
}

/**
 * A pure function that traverses the object tree and converts fields
 * with the given string names from string booleans ("true") to booleans.
 * @param node the root node of the object tree to examine
 * @param fieldsToConvert an array string field names to convert
 */
export function boolStrFieldsToBools(
  node: any,
  fieldsToConvert: string[],
): any {
  let result;

  if (typeof node === "object") {
    if (Array.isArray(node)) {
      result = node.map((item) => boolStrFieldsToBools(item, fieldsToConvert));
    } else {
      result = Object.create(node);
      for (const [key, value] of Object.entries(node)) {
        //only convert defined values to booleans -- undefined does not mean false
        if (fieldsToConvert.includes(key) && value !== undefined) {
          result[key] = value === "true";
        } else if (value !== undefined && value !== null) {
          result[key] = boolStrFieldsToBools(value, fieldsToConvert);
        }
      }
    }
  } else {
    result = node;
  }
  return result;
}

/**
 * Returns an array of Intervals representing the qualifying gaps
 * (larger than allowedGapDays) found between the given Intervals.
 * @param intervals an array of Intervals
 * @param allowedGapDays the threshold of allowed days between intervals.
 */
export function getIntervalGaps(
  intervals: Interval[],
  allowedGapDays = 1,
): Interval[] {
  const mergedIntervals = Interval.merge(intervals);

  return mergedIntervals.reduce(
    (results: Interval[], currentInterval, index, srcIntervals) => {
      const previousInterval = srcIntervals[index - 1];
      const previousEnd = previousInterval?.end;
      const currentStart = currentInterval?.start;

      if (previousEnd && currentStart) {
        const gap = currentStart.diff(previousEnd, ["days"]).days;

        if (gap > allowedGapDays) {
          results.push(Interval.fromDateTimes(previousEnd, currentStart));
        }
      }
      return results;
    },
    [],
  );
}

/**
 * A Luxon DateTime js comparator
 * @param dt1 the first DateTime to compare
 * @param dt2 the second DateTime to compare
 */
export function compareDateTimes(dt1: DateTime, dt2: DateTime): number {
  return dt1 < dt2 ? -1 : dt1 > dt2 ? 1 : 0;
}

/**
 * An interval comparator, if start DateTimes are equivalent
 * it then looks at end DateTimes
 * @param i1 the first Interval to compare
 * @param i2 the second Interval to compare
 */
export function compareIntervals(i1: Interval, i2: Interval): number {
  return compareDateTimes(i1.start, i2.start) === 0
    ? compareDateTimes(i1.end, i2.end)
    : compareDateTimes(i1.start, i2.start);
}

export interface HistoryFormEntry {
  START_DATE: string;
  startYear: string;
  startMonth: string;
  startDay: string;
  startDate: string;
  END_DATE: string;
  endYear: string;
  endMonth: string;
  endDay: string;
  endDate: string;
}

export function validateHistory(
  entries: EmploymentOrGapFormFields[],
  maxGapDays: number,
  requiredYearsOfHistory: number,
) {
  const errors: string[] = [];

  const nowDateTime = DateTime.now();
  const requiredMinStartDate = nowDateTime.minus({
    years: requiredYearsOfHistory,
  });

  let hasAtLeastOneEntryWithinRange = false;

  const intervals: Interval[] = [];
  entries.forEach((entry) => {
    const startDateTime = DateTime.fromISO(entry.startDate);

    const isGapEntry = entry.ENTRY_TYPE === "GAP";
    const isNotGapEntry = !isGapEntry;

    if (
      (isNotGapEntry && entry.IS_THIS_YOUR_PRESENT_EMPLOYER === "true") ||
      (isGapEntry && entry.ARE_YOU_CURRENTLY_UNEMPLOYED === "true") ||
      !entry.endDate
    ) {
      hasAtLeastOneEntryWithinRange = true;

      intervals.push(Interval.fromDateTimes(startDateTime, nowDateTime));
    } else {
      const endDateTime = DateTime.fromISO(entry.endDate);

      if (endDateTime < startDateTime) {
        errors.push(
          t`There is an entry with a start date ${startDateTime.toISODate()} that comes after the end date ${endDateTime.toISODate()}.  Please correct this entry.`,
        );
      } else if (endDateTime > nowDateTime) {
        errors.push(
          t`There is an entry with an end date in the future (${endDateTime.toISODate()}).  Please correct this entry.`,
        );
      }
      // Only want to compare intervals within the requirement range.
      // All intervals before the requirement range do not matter as far
      // as the rest of the validation is concerned.
      else if (endDateTime > requiredMinStartDate) {
        // Only counts if it's within the requirement range.
        hasAtLeastOneEntryWithinRange = true;

        intervals.push(Interval.fromDateTimes(startDateTime, endDateTime));
      }
    }
  });

  if (!hasAtLeastOneEntryWithinRange) {
    errors.push(
      t`You must provide at least one gap or employment entry within the required range of ${requiredMinStartDate.toISODate()} to ${nowDateTime.toISODate()}.`,
    );

    // No sense continuing with validation at this point.
    return errors;
  }

  errors.push(
    ...getIntervalGaps(intervals, maxGapDays).map(
      (gap) =>
        t`There are ${Math.floor(
          gap.length("days"),
        )} days between between ${gap.start.toISODate()} and ${gap.end.toISODate()}, which exceeds the max gap of ${maxGapDays}. Please enter or modify a gap entry to explain the gap.`,
    ),
  );

  let minStartDate: DateTime | undefined = undefined;
  let maxEndDate: DateTime | undefined = undefined;

  intervals.forEach((interval) => {
    if (!minStartDate || interval.start < minStartDate) {
      minStartDate = interval.start;
    }

    if (!maxEndDate || interval.end > maxEndDate) {
      maxEndDate = interval.end;
    }
  });

  let minStartDateWithinRange: DateTime | undefined;

  if (minStartDate) {
    minStartDateWithinRange =
      minStartDate > requiredMinStartDate ? minStartDate : requiredMinStartDate;
  }

  let maxEndDateWithinRange: DateTime | undefined;

  if (maxEndDate) {
    maxEndDateWithinRange =
      maxEndDate > requiredMinStartDate ? maxEndDate : requiredMinStartDate;
  } else {
    maxEndDateWithinRange = nowDateTime;
  }

  let providedYearsOfHistory: number;

  if (minStartDateWithinRange && maxEndDateWithinRange) {
    providedYearsOfHistory = Math.abs(
      minStartDateWithinRange.diff(maxEndDateWithinRange).as("years"),
    );
  } else {
    providedYearsOfHistory = 0;
  }

  const gapDaysToNow = Math.abs(maxEndDateWithinRange?.diffNow()?.as("days"));

  if (gapDaysToNow > maxGapDays) {
    errors.push(
      t`There are ${Math.floor(
        gapDaysToNow,
      )} days between ${maxEndDateWithinRange.toISODate()} and today, which exceeds the max gap of ${maxGapDays} days. Please enter or modify a gap entry to explain the gap.`,
    );
  } else {
    const gapYearsToNow = Math.abs(
      maxEndDateWithinRange?.diffNow()?.as("years"),
    );

    // If we have a bookend gap that isn't a gap entry and is within
    // the allowed number of days for a gap without a gap entry,
    // add it to the total provided years of history.
    providedYearsOfHistory += gapYearsToNow;
  }

  if (minStartDateWithinRange) {
    const gapDaysToRequiredMinStartDate = Math.abs(
      minStartDateWithinRange.diff(requiredMinStartDate)?.as("days"),
    );

    if (gapDaysToRequiredMinStartDate > maxGapDays) {
      errors.push(
        t`There are ${Math.floor(
          gapDaysToRequiredMinStartDate,
        )} days between ${requiredMinStartDate.toISODate()} and ${minStartDateWithinRange.toISODate()}, which exceeds the max gap of ${maxGapDays} days. Please enter or modify a gap entry to explain the gap.`,
      );
    } else {
      const gapYearsToRequiredMinStartDate = Math.abs(
        minStartDateWithinRange.diff(requiredMinStartDate)?.as("years"),
      );

      // If we have a bookend gap that isn't a gap entry and is within
      // the allowed number of days for a gap without a gap entry,
      // add it to the total provided years of history.
      providedYearsOfHistory += gapYearsToRequiredMinStartDate;
    }
  }

  if (errors.length) {
    // Do not need to continue to final evaluation if we had errors before now.
    return errors;
  }

  if (requiredYearsOfHistory - providedYearsOfHistory > 0.01) {
    const durations: string[] = [];

    intervals.forEach((interval) => {
      durations.push(
        `${interval.start.toISODate()} to ${interval.end.toISODate()}`,
      );
    });

    let minYearsError: string;

    if (providedYearsOfHistory <= 0) {
      minYearsError = t`None of the provided entries are within the required range of ${requiredMinStartDate.toISODate()} to ${nowDateTime.toISODate()}.`;
    } else {
      const durationStr = durations.join(", ");

      minYearsError = t`You only provided ${providedYearsOfHistory.toFixed(
        2,
      )} of ${requiredYearsOfHistory} required years of history. ${durationStr}.`;
    }
    errors.push(minYearsError);
  }

  return errors;
}

export function genericOnSave(
  methods: any,
  onSubmit?: SubmitHandler<any>,
  isSavable?: boolean,
) {
  return (
    (isSavable &&
      (() => onSubmit?.({ ...methods.getValues(), doSave: true }))) ||
    undefined
  );
}

export function watchAndEvaluateBoolean(
  methods: UseFormReturn<any>,
  fieldId: string,
) {
  if (methods.watch(fieldId) !== undefined) {
    return `${methods.getValues(fieldId)}` === "true";
  } else {
    return false;
  }
}
