import { TaskComponentProps } from "components/tasks/taskHelpers";
import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  ApplicantEntryTaskId,
  SingleFormTaskDef,
} from "tasks/tasksApi";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import taskHeadings from "tasks/taskHeadings";
import { Heading } from "@chakra-ui/react";
import AdmittedOffensesForm, {
  AdmittedOffensesFormFields,
} from "components/tasks/admitted-offenses/AdmittedOffensesForm";
import {
  EMPTY_OFFENSE,
  OffenseFormFields,
} from "components/tasks/admitted-offenses/OffenseForm";
import { SubmitHandler } from "react-hook-form";

interface AdmittedOffenseTaskdefFields {
  OFFENSE: ApiFieldProperties;
  COUNTRY: ApiFieldProperties;
  STATE: ApiFieldProperties;
  COUNTY: ApiFieldProperties;
  DATE: ApiFieldProperties;
}

interface AdmittedOffensesApiFields extends ApiFormFields {
  ADMITTED_OFFENSES: AdmittedOffenseTaskdefFields[];
  CHARGED: ApiFieldProperties;
  CONVICTED: ApiFieldProperties;
  OTHER_INFORMATION: ApiFieldProperties;
}

export interface AdmittedOffensesApiFormMessages extends ApiFormMessages {
  convictionQuestionText: string;
  chargeQuestionText: string;
  admittedOffenseInstructionText: string;
}

export interface AdmittedOffensesApiFormParameters extends ApiFormParameters {
  askForChargedOffense: boolean;
  askForOtherInformation: boolean;
}

interface OffenseApiSaveFields {
  OFFENSE: string;
  COUNTRY: string;
  STATE: string;
  COUNTY: string;
  DATE: string;
}

export default function AdmittedOffensesTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_ADMITTED_OFFENSE;

  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      AdmittedOffensesApiFields,
      AdmittedOffensesApiFormMessages,
      AdmittedOffensesApiFormParameters
    >
  >({
    taskId,
    goToStatusCheck,
  });

  function mapApiOffenseToFormOffense(
    offense: AdmittedOffenseTaskdefFields,
  ): OffenseFormFields {
    return {
      offense: offense?.OFFENSE?.existingValue || "",
      country: offense?.COUNTRY?.existingValue || "",
      state: offense?.STATE?.existingValue || "",
      county: offense?.COUNTY?.existingValue || "",
      offenseDate: offense?.DATE?.existingValue || "",
    };
  }

  function mapFormOffenseToApiOffense(
    { offense, country, state, county, offenseDate }: OffenseFormFields,
    doSave: boolean,
  ): OffenseApiSaveFields {
    return {
      OFFENSE: offense,
      COUNTRY: country,
      STATE: state || "",
      COUNTY: county || "",
      DATE: offenseDate,
    };
  }

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const storeOffenses: SubmitHandler<AdmittedOffensesFormFields> = async ({
    admittedOffenses,
    charged,
    convicted,
    otherInfo,
    doSave = false,
  }: AdmittedOffensesFormFields) => {
    mutateTaskData({
      formFields: {
        ADMITTED_OFFENSES:
          admittedOffenses.map((offense) =>
            mapFormOffenseToApiOffense(offense, doSave),
          ) || [],
        CHARGED: charged ? `${charged}` === "true" : undefined,
        CONVICTED: convicted,
        OTHER_INFORMATION: otherInfo,
      },
      saveOnly: doSave,
    });
  };

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <AdmittedOffensesForm
        defaultValues={{
          admittedOffenses: data?.forms[0]?.formFields?.ADMITTED_OFFENSES?.map(
            (apiOffense) => mapApiOffenseToFormOffense(apiOffense),
          ) || [EMPTY_OFFENSE],
          charged: data?.forms[0]?.formFields?.CHARGED?.existingValue || "",
          convicted: data?.forms[0]?.formFields?.CONVICTED?.existingValue || "",
          otherInfo:
            data?.forms[0]?.formFields?.OTHER_INFORMATION?.existingValue || "",
        }}
        formMessages={data?.forms[0]?.formMessages}
        askForCharged={
          data?.forms[0]?.formParameters?.askForChargedOffense || false
        }
        askForOtherInfo={
          data?.forms[0]?.formParameters?.askForOtherInformation || false
        }
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        onSubmit={(data) => {
          storeOffenses(data);
        }}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
