import { useFormContext, useWatch } from "react-hook-form";
import FormCheckbox from "components/common/form/FormCheckbox";
import { ReactNode } from "react";
import { CheckboxProps } from "@chakra-ui/react";

export interface RenderWhenUncheckedWrapperProps
  extends Omit<CheckboxProps, "defaultValue"> {
  fieldId: string;
  label: string;
  deps?: string[];
  children: ReactNode;
}

export function RenderWhenUncheckedWrapper({
  fieldId,
  label,
  deps,
  children,
}: RenderWhenUncheckedWrapperProps): JSX.Element {
  const { control } = useFormContext();
  const showChildren = !useWatch({ control, name: fieldId });

  return (
    <>
      <FormCheckbox fieldId={fieldId} label={label} deps={deps} />
      {showChildren && children}
    </>
  );
}
