import {
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import ApplicantTheme from "applicantTheme/ApplicantTheme";
import { QuestionIcon } from "@chakra-ui/icons";
import { Trans } from "@lingui/macro";
import { clearAllStorage, setRememberMe } from "auth/authHelpers";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import { Logo } from "components/common/basic/Logo";

interface RememberMeProps {
  theme: ApplicantTheme;
  onStart?: () => void;
}

export interface RememberMeFieldValues {
  rememberMe: boolean;
}

export default function RememberMe({
  theme,
  onStart,
}: RememberMeProps): JSX.Element {
  const { register, handleSubmit } = useForm<RememberMeFieldValues>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit: SubmitHandler<RememberMeFieldValues> = (data) => {
    clearAllStorage();
    setRememberMe(data.rememberMe);
    //if we have an "onStart" function, run it
    onStart && onStart();
  };

  const history = useHistory();

  useEffect(() => {
    conditionalHistoryReplace(history, AppPath.START);
  });

  return (
    <Flex direction="column" bgColor={theme?.background} height={"full"}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="4">
          <ModalHeader>
            <Trans>Remember me on this device</Trans>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Trans>
              Enable on a private device to expedite future logins. Do not
              enable on a public or shared device.
            </Trans>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Center minH={220} padding="5">
        <Image w={160} src={theme?.logo?.medium} />
      </Center>
      {theme?.clientLabel && (
        <Center>
          <Heading color={theme?.color} textAlign="center" as="h1">
            {theme?.clientLabel}
          </Heading>
        </Center>
      )}
      <Center>
        <Heading color={theme?.color} textAlign="center" p="10" as="h1">
          <Trans>Candidate Screening</Trans>
        </Heading>
      </Center>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Center>
          <Checkbox
            color={theme?.color}
            size={"lg"}
            defaultChecked={true}
            {...register("rememberMe")}>
            <Trans>Remember me on this device</Trans>
          </Checkbox>
          <QuestionIcon
            cursor="pointer"
            w="20px"
            h="20px"
            m="1"
            color={theme?.color || "black"}
            onClick={() => onOpen()}
          />
        </Center>
        <Center p={10}>
          <Button
            p="5px 40px"
            color={theme?.startButtonBackgroundTextColor}
            backgroundColor={theme?.startButtonBackgroundColor}
            type="submit"
            _hover={{
              color: theme?.startButtonBackgroundHoverTextColor,
              bg: theme?.startButtonBackgroundHoverColor,
            }}>
            <Trans>Start</Trans>
          </Button>
        </Center>
      </form>
      <Flex direction="column" alignItems="center">
        <Text color={theme?.logoColor}>
          <Trans>Powered by</Trans>
        </Text>
        <Logo
          color={theme?.logoColor?.toLowerCase()}
          acquisitionSource={theme?.acquisitionSource}
        />
      </Flex>
    </Flex>
  );
}
