import { WidgetProps } from "@rjsf/utils";
import { useFormContext } from "react-hook-form";
import FormRadioGroup from "components/common/form/FormRadioGroup";

export default function SpecialFormRadioGroup(props: WidgetProps) {
  const schema = props.schema;
  const uiSchema = props.uiSchema;
  const additionalProperties = uiSchema?.additionalProperties;
  const { control, register, getValues } = useFormContext();

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  register(additionalProperties?.fieldId, {
    onChange: changeHandler,
  });

  //Set defaultValue
  //suppose to be in props.value but is somehow empty
  // TODO (2023-07-11) Rework logic and cleanup
  const defaultValue = getValues().formData
    ? getValues().formData[additionalProperties?.fieldId]
    : "";

  return (
    <>
      <FormRadioGroup
        fieldId={additionalProperties?.fieldId}
        groupLabel={schema.title || ""}
        fontSize="lg"
        fontWeight="bold"
        radios={additionalProperties.radios}
        vertical={true}
        control={control}
        onChange={changeHandler}
        required={additionalProperties.requiredField}
        defaultValue={defaultValue}
      />
    </>
  );
}
