import { TaskComponentProps } from "components/tasks/taskHelpers";
import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  IdVerifyApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import {
  EMPTY_INSTITUTION,
  InstitutionFormFields,
} from "components/tasks/education-history/InstitutionForm";
import EducationHistoryForm, {
  EducationHistoryFormFields,
} from "components/tasks/education-history/EducationHistoryForm";
import { SubmitHandler } from "react-hook-form";
import IdentityVerificationForm from "components/tasks/IdentityVerificationForm";
import { getApiDate } from "components/common/form/DateInput";
import { COUNTRY_UNITED_STATES } from "tasks/apiConstants";

interface EduHistoryItemTaskdefFields {
  EDUCATION_TYPE: ApiFieldProperties;
  DID_YOU_GRADUATE: ApiFieldProperties;
  GRADUATION_DATE: ApiFieldProperties;
  INSTITUTION: ApiFieldProperties;
  COUNTRY: ApiFieldProperties;
  STREET_ADDRESS: ApiFieldProperties;
  CITY: ApiFieldProperties;
  STATE: ApiFieldProperties;
  NAME_WHILE_ATTENDING: ApiFieldProperties;
  DEGREE_TYPE: ApiFieldProperties;
  FIELD_OF_STUDY: ApiFieldProperties;
  SCHOOL_PHONE_NUMBER: ApiFieldProperties;
  START_DATE: ApiFieldProperties;
  END_DATE: ApiFieldProperties;
  GPA: ApiFieldProperties;
  SEAT_NUMBER: ApiFieldProperties;
  SERVICE_ID: ApiFieldProperties;
}

interface EduHistoryApiFormFields extends ApiFormFields {
  NO_EDUCATION_HISTORY: ApiFieldProperties;
  EDUCATION_HISTORY_ITEMS: EduHistoryItemTaskdefFields[];
}

interface EduHistoryApiFormMessages extends ApiFormMessages {
  clientLabel: string;
  clientCustomizedTaskDescription: string;
}

interface EduHistoryApiFormParameters extends IdVerifyApiFormParameters {
  educationTypes?: string[];
  educationTypeToDegreeTypeMappings?: {
    [eduType: string]: string[];
  };
  identityVerificationMode?: boolean;
  allowNoHistorySelection: boolean;
}

export default function EducationHistoryTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_EDUCATION;

  const {
    data,
    isFetching,
    isRefetching,
    refetch: refetchTaskDef,
  } = useGetTaskdef<
    SingleFormTaskDef<
      EduHistoryApiFormFields,
      EduHistoryApiFormMessages,
      EduHistoryApiFormParameters
    >
  >({
    taskId,
    goToStatusCheck,
  });

  function mapApiEduHistItemToFormInstitution(
    apiInst: EduHistoryItemTaskdefFields,
  ): InstitutionFormFields {
    return {
      educationType: apiInst?.EDUCATION_TYPE?.existingValue || "",
      degreeType: apiInst?.DEGREE_TYPE?.existingValue,
      graduated: apiInst?.DID_YOU_GRADUATE?.existingValue || "",
      gradDate: apiInst?.GRADUATION_DATE?.existingValue || "",
      institution: apiInst?.INSTITUTION?.existingValue || "",
      country: apiInst?.COUNTRY?.existingValue || COUNTRY_UNITED_STATES,
      street: apiInst?.STREET_ADDRESS?.existingValue || "",
      city: apiInst?.CITY?.existingValue || "",
      state: apiInst?.STATE?.existingValue || "",
      nameWhileAttending: apiInst?.NAME_WHILE_ATTENDING?.existingValue || "",
      fieldOfStudy: apiInst?.FIELD_OF_STUDY?.existingValue,
      schoolPhone: apiInst?.SCHOOL_PHONE_NUMBER?.existingValue,
      startDate: apiInst?.START_DATE?.existingValue || "",
      endDate: apiInst?.END_DATE?.existingValue || "",
      gpa: apiInst?.GPA?.existingValue,
      seatNumber: apiInst?.SEAT_NUMBER?.existingValue,
      apiId: apiInst?.SERVICE_ID?.existingValue,
    };
  }

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const storeEduHistory: SubmitHandler<EducationHistoryFormFields> = async ({
    noHistory,
    institutions,
    doSave,
  }: EducationHistoryFormFields) =>
    mutateTaskData({
      formFields: {
        NO_EDUCATION_HISTORY: noHistory,
        ...(!noHistory && {
          EDUCATION_HISTORY_ITEMS: institutions.map((inst) => ({
            EDUCATION_TYPE: inst.educationType,
            DEGREE_TYPE: inst.degreeType,
            DID_YOU_GRADUATE: inst.graduated,
            ...(inst.graduated === "true" && {
              GRADUATION_DATE: inst.gradDate || "",
            }),
            INSTITUTION: inst.institution,
            COUNTRY: inst.country,
            STREET_ADDRESS: inst.street,
            CITY: inst.city,
            STATE: inst.state,
            NAME_WHILE_ATTENDING: inst.nameWhileAttending,
            FIELD_OF_STUDY: inst.fieldOfStudy,
            SCHOOL_PHONE_NUMBER: inst.schoolPhone,
            START_DATE: inst.startDate || "",
            END_DATE: inst.endDate || "",
            GPA: inst.gpa,
            SEAT_NUMBER: inst.seatNumber,
            ...(inst.apiId && { SERVICE_ID: inst.apiId }),
          })),
        }),
      },
      saveOnly: doSave,
    });

  if (isFetching || isRefetching || isStoring) {
    return (
      <TaskSpinnerOverlay
        isStoring={isStoring}
        saveOnly={variables?.saveOnly}
      />
    );
  } else if (data?.forms[0]?.formParameters?.identityVerificationMode) {
    return (
      <IdentityVerificationForm
        taskId={taskId}
        clientLabel={data?.forms[0]?.formMessages?.clientLabel}
        goToStatusCheck={goToStatusCheck}
        refetchTaskDef={refetchTaskDef}
        startOrEndOfYear={data?.forms[0]?.formMessages?.startOrEndYear}
      />
    );
  } else {
    const formParameters = {
      allowNoHistorySelection:
        data?.forms?.[0]?.formParameters?.allowNoHistorySelection,
      eduTypes: data?.forms?.[0]?.formParameters?.educationTypes || [],
      eduDegrees:
        data?.forms?.[0]?.formParameters?.educationTypeToDegreeTypeMappings ||
        {},
      isSavable: data?.forms?.[0]?.formParameters?.isSavable || false,
    };

    // TODO (JDL:2023-01-27) Pass formParameters directly to the EducationHistoryForm.
    const { allowNoHistorySelection, eduTypes, eduDegrees, isSavable } =
      formParameters;

    return (
      <>
        <Heading p={1} as="h1">
          {taskHeadings[taskId].long}
        </Heading>
        <Text pb="4">
          {data?.forms?.[0]?.formMessages?.clientCustomizedTaskDescription}
        </Text>
        <EducationHistoryForm
          allowNoHistorySelection={allowNoHistorySelection}
          eduTypes={eduTypes}
          eduDegrees={eduDegrees}
          defaultValues={{
            noHistory:
              data?.forms?.[0]?.formFields?.NO_EDUCATION_HISTORY
                ?.existingValue === "true",
            institutions:
              data?.forms?.[0]?.formFields?.EDUCATION_HISTORY_ITEMS.map(
                (inst) => mapApiEduHistItemToFormInstitution(inst),
              ) || [EMPTY_INSTITUTION],
          }}
          isSavable={isSavable}
          onSubmit={storeEduHistory}
          goToStatusCheck={goToStatusCheck}
        />
      </>
    );
  }
}
