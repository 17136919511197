import { verifyToken } from "auth/authApi";
import {
  clearAllStorage,
  clearToken,
  getStoredOrUrlToken,
  isInvalidJwt,
  storeToken,
} from "auth/authHelpers";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import OtesError from "components/common/basic/OtesError";

interface OtesAuthContextType {
  token: string;
  updateToken: (token: string) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  otesError?: OtesError;
  setOtesError: Dispatch<SetStateAction<OtesError | undefined>>;
  bypassPiiVerify: boolean;
  setBypassPiiVerify: (bypassPiiVerify: boolean) => void;
  bypassOtp: boolean;
  setBypassOtp: (bypassOtp: boolean) => void;
}

const AuthContext = createContext<OtesAuthContextType>(
  {} as OtesAuthContextType,
);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [token, setToken] = useState<string | null>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [otesError, setOtesError] = useState<OtesError | undefined>(undefined);
  const [bypassPiiVerify, setBypassPiiVerify] = useState<boolean>(false);
  const [bypassOtp, setBypassOtp] = useState<boolean>(false);

  const history = useHistory();

  // Handles reading the stored or URL token, verifying it, storing it,
  // and redirecting to clean URL.
  useEffect(() => {
    const latestToken = getStoredOrUrlToken();

    const resetTokenState = () => {
      // Clear the token & local storage, go to Welcome screen.
      clearAllStorage();
      setToken("");

      conditionalHistoryReplace(history, AppPath.WELCOME);
    };

    const persistTokenState = (latestToken: string | null) => {
      if (latestToken !== token) {
        updateToken(latestToken);
      }
    };

    if (isInvalidJwt(latestToken)) {
      // If we don't have a token, reset the state.
      // Don't reset if at Login. Token is not present then.
      if (history.location.pathname !== AppPath.LOGIN) {
        resetTokenState();
      }
      return;
    }

    if (latestToken === token) {
      // If latest is same as current, nothing to verify
      return;
    }

    latestToken &&
      verifyToken(latestToken)
        .then((verifyResponse) => {
          const verificationToken = verifyResponse?.data?.token;

          if (verificationToken) {
            setIsAuthenticated(true);
            persistTokenState(verificationToken);
          } else {
            setIsAuthenticated(false);
            persistTokenState(null);
          }
        })
        .catch((response) => {
          const errorResponse = response?.response;

          const otesErrorIncoming = new OtesError(
            errorResponse?.data?.code,
            errorResponse?.status,
          );

          if (otesErrorIncoming.isSystemOrEntryError) {
            setOtesError(otesErrorIncoming);
          }

          const verificationToken = errorResponse?.data?.data?.token;

          if (verificationToken) {
            // Error or no, we are still in an "authenticated" state if we have a token.
            // We will want to persist that state so that the candidate does not have
            // to reauthenticate just to see an error.
            setIsAuthenticated(true);
            persistTokenState(verificationToken);
          } else {
            resetTokenState();
          }
        });
  }, [history]);

  function updateToken(token: string | null) {
    if (!token) {
      clearToken();
    } else {
      storeToken(token);
    }

    setToken(token);
  }

  return (
    <AuthContext.Provider
      value={{
        token: token || "",
        updateToken,
        isAuthenticated,
        setIsAuthenticated,
        otesError,
        setOtesError,
        bypassPiiVerify,
        setBypassPiiVerify,
        bypassOtp,
        setBypassOtp,
      }}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): OtesAuthContextType {
  return useContext(AuthContext);
}
