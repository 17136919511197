export default {
  brand: {
    white: "#ffffff",
    orange: "#f27f32",
    green: "#bad63b",
    dullOrange: "#61330f",
    gray: "#423f3f",
    dimGray: "#737373",
    red: "#ff0000",
    black: "#000000",
  },
  errorTemplate: {
    queenPink: "#ebccd1",
    cinnabar: "#e53e3e",
    transparentCinnabar: "#e53e3e57",
  },
};
