import { TaskComponentProps } from "../taskHelpers";
import { Heading } from "@chakra-ui/react";
import MeasureOneEmploymentForm, {
  MeasureOneEmployment,
  MeasureOneEmploymentFields,
} from "./MeasureOneEmploymentForm";
import { SubmitHandler } from "react-hook-form";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import {
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  ApplicantEntryTaskId,
  SingleFormTaskDef,
} from "tasks/tasksApi";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import taskHeadings from "tasks/taskHeadings";

interface MeasureOneApiFormFields extends ApiFormFields {
  employments: [MeasureOneEmployment];
}

interface MeasureOneApiFormMessages extends ApiFormMessages {
  formName: string;
  formInstructions: string;
}

interface MeasureOneApiFormParameters extends ApiFormParameters {
  email: string;
  countries: [string];
  employments: [MeasureOneEmployment];
}

const BlankEmployment = {
  employerName: "",
  startDate: "",
  endDate: "",
};

export default function MeasureOneEmploymentTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_MEASURE_ONE_EMPLOYMENT;

  const { data, isFetching, isRefetching } = useGetTaskdef<
    SingleFormTaskDef<
      MeasureOneApiFormFields,
      MeasureOneApiFormMessages,
      MeasureOneApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  const { mutate: mutateTaskData, isLoading: isStoring } = useStoreTaskMutation(
    { taskId, onSuccess: goToStatusCheck },
  );

  const storeMeasureOne: SubmitHandler<MeasureOneEmploymentFields> = async ({
    employments,
  }: MeasureOneEmploymentFields) =>
    mutateTaskData({
      formFields: {
        EMPLOYMENTS: employments.map((employment) => ({
          EMPLOYER_NAME: employment.employerName,
          START_DATE: employment.startDate,
          END_DATE: employment.endDate,
          CREATE_DATA_REQUEST_AND_PUBLIC_TOKEN_RESPONSE:
            employment.measureOneDataResponseToken,
          DATASOURCE_CONNECTED: employment.datasourceConnected,
          ITEMS_CREATED: employment.itemsCreated,
          CREDENTIALS_NOT_OBTAINED: employment.credentialsNotObtained,
          DATASOURCE_NOT_SUPPORTED: employment.datasourceNotSupported,
          EXIT_REQUESTED: employment.exitRequested,
        })),
      },
      saveOnly: false,
    });

  //Use what is currently stored in database, or use data gathered from Employment History
  const hasSavedEmployments =
    data?.forms[0]?.formFields?.employments &&
    data?.forms[0]?.formFields?.employments.length > 0;
  let employments;
  if (hasSavedEmployments) {
    employments = data?.forms[0]?.formFields?.employments;
  } else {
    employments = data?.forms[0]?.formParameters?.employments;
  }

  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <MeasureOneEmploymentForm
        email={data?.forms[0]?.formParameters?.email || ""}
        countries={data?.forms[0]?.formParameters?.countries || [""]}
        defaultValues={{
          employments: employments || [BlankEmployment],
        }}
        onSubmit={storeMeasureOne}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
