import { useMutation, useQuery } from "react-query";
import {
  ApiTaskdef,
  ApplicantEntryTaskId,
  createDataRequestAndPublicToken,
  getSpecialForm,
  getTaskdef,
  getTasks,
  MeasureOneDataRequestToken,
  MeasureOneDataResponseToken,
  postTaskInput,
  PostTaskInputResponse,
  submitAppEntry,
  TaskUid,
} from "tasks/tasksApi";
import useAuth from "auth/useAuth";
import { ApiDataResponse, GenericApiResponse, TokenData } from "auth/authApi";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { AxiosError } from "axios";
import useOtesModal from "error/useOtesModal";
import { t } from "@lingui/macro";
import { ResponseCodes } from "tasks/apiConstants";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { Dispatch, SetStateAction } from "react";

//custom hooks to wrap the react-query tasks retrieval/storage
export function useGetTasks() {
  const { token } = useAuth();
  return useQuery(["tasks", token], () => getTasks(token), {
    onError: handleOtesErrorFromAxiosError,
  });
}

export interface UseGetTaskdefProps extends UseGetTaskProps {
  taskId: ApplicantEntryTaskId | string;
}

export interface UseGetSpecialFormProps extends UseGetTaskProps {
  specialFormId: string;
  includeTaskDef?: boolean;
}

export interface UseGetTaskProps {
  goToStatusCheck?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export interface SpecialFormSchema {
  formId: string;
  formData: JSON;
  uiSchema: UiSchema;
  formSchema: RJSFSchema;
}

export function useGetTaskdef<T extends ApiTaskdef>({
  taskId,
  goToStatusCheck,
  onSuccess,
  onError,
  onSettled,
}: UseGetTaskdefProps) {
  const { token } = useAuth();
  const { triggerOtesModal } = useOtesModal();

  return useQuery(
    [`getTaskDef/${taskId}`, taskId, token],
    () => getTaskdef<T>(token, taskId),
    {
      refetchOnMount: "always",
      onSuccess,
      onError: (response: AxiosError) => {
        handleOtesErrorFromAxiosError(response);

        if (onError) {
          onError();
        } else {
          triggerOtesModal({
            bodyText: t`Error retrieving task information, please try again.  If error continues, contact Orange Tree at 1-888-222-0667 for assistance`,
            onClose: goToStatusCheck,
          });
        }
      },
      onSettled,
    },
  );
}

export function useGetSpecialForm({
  specialFormId,
  includeTaskDef = false,
  goToStatusCheck,
  onSuccess,
  onError,
  onSettled,
}: UseGetSpecialFormProps) {
  const { token } = useAuth();

  return useQuery(
    [`getSpecialForm/${specialFormId}`, specialFormId, token],
    () => getSpecialForm(token, specialFormId, includeTaskDef),
    {
      refetchOnMount: false,
      onSuccess,
      onError: (response: AxiosError) => {
        handleError(response, onError, goToStatusCheck);
      },
      onSettled,
    },
  );
}

function handleError(
  response: any,
  onError?: () => void,
  goToStatusCheck?: () => void,
) {
  const { triggerOtesModal } = useOtesModal();
  handleOtesErrorFromAxiosError(response);
  if (onError) {
    onError();
  } else {
    triggerOtesModal({
      bodyText: t`Error retrieving task information, please try again. If error continues, contact Orange Tree at 1-888-222-0667 for assistance.`,
      onClose: goToStatusCheck,
    });
  }
}

//TODO revise functions props, currently does not pass data to functions
export interface UseTaskMutationProps {
  taskId: ApplicantEntryTaskId | TaskUid;
  onMutate?: () => void;
  onSuccess?: (appEntryComplete?: boolean) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export interface UseIdVerifyTaskMutationProps {
  taskId: ApplicantEntryTaskId | TaskUid;
  onMutate?: () => void;
  onSuccess?: (response: GenericApiResponse) => void;
  onError?: (response: AxiosError) => void;
  onSettled?: () => void;
}

export type IdVerifyMutatorProps = Partial<{
  [taskId in ApplicantEntryTaskId]: { QUESTION_IDENTITY_VERIFY: string };
}>;

export function useIdVerifyTaskMutation({
  taskId,
  onMutate,
  onError,
  onSuccess,
  onSettled,
}: UseIdVerifyTaskMutationProps) {
  const { token } = useAuth();
  return useMutation(
    (payload: IdVerifyMutatorProps) => postTaskInput(token, taskId, payload),
    {
      onSuccess: async (data: GenericApiResponse) => {
        // if an onSuccess function was provided, execute it
        !!onSuccess && onSuccess(data);
      },
      onMutate,
      onSettled,
      onError,
    },
  );
}

export interface StoreTaskMutatorProps {
  APPLICATION_UID?: string;
  TASK_ID?: string;
  saveOnly?: boolean;
  formFields?: { [fieldName: string]: unknown };
}

export function useStoreTaskMutation({
  taskId,
  onMutate,
  onSuccess,
  onSettled,
}: UseTaskMutationProps) {
  const { token, updateToken } = useAuth();
  const { triggerOtesModal } = useOtesModal();

  return useMutation(
    taskId,
    ({
      formFields,
      saveOnly = false,
      APPLICATION_UID,
      TASK_ID,
      // rest serves as a wildcard for interview formUid data
      ...rest
    }: StoreTaskMutatorProps) =>
      postTaskInput(
        token,
        taskId,
        {
          ...(APPLICATION_UID && { APPLICATION_UID }),
          ...(TASK_ID && { TASK_ID }),
          [taskId]: formFields,
          ...rest,
        },
        saveOnly,
      ),
    {
      onSuccess: async (
        data: ApiDataResponse<PostTaskInputResponse> | TokenData,
      ) => {
        //set the token if the response was a TokenData response (rare)
        if ("value" in data) {
          updateToken(data.value);
        } else {
          if (!!onSuccess && data.data?.appEntryComplete) {
            onSuccess(data.data.appEntryComplete);
          }
        }

        //if an onSuccess function was provided, execute it
        !!onSuccess && onSuccess();
      },
      onMutate,
      onSettled,
      onError: (response: AxiosError) => {
        handleOtesErrorFromAxiosError(response);

        const responseCode = response?.response?.data?.code;

        if (ResponseCodes.Error.Action.UNACCESSED_DOCUMENTS === responseCode) {
          triggerOtesModal({
            bodyText: t`All documents must be downloaded before submit, please do so and try again.`,
          });
        } else if (
          ResponseCodes.Error.Action.SIGNATURE_MISSING === responseCode
        ) {
          triggerOtesModal({
            bodyText: t`Signature was unable to be processed, please try again.`,
          });
        } else {
          triggerOtesModal({
            bodyText: t`Error submitting task, please try again.`,
          });
        }
      },
    },
  );
}

export const useSubmitAppEntry = () => {
  const { token } = useAuth();
  return useMutation(() => submitAppEntry(token));
};

export const useCreateDataRequestAndPublicToken = (
  setCurrentMeasureOneDataResponseToken: Dispatch<
    SetStateAction<MeasureOneDataResponseToken>
  >,
) => {
  const { token } = useAuth();
  const { triggerOtesModal } = useOtesModal();

  return useMutation(
    (payload: MeasureOneDataRequestToken) =>
      createDataRequestAndPublicToken(token, payload),
    {
      onSuccess: async (
        response: ApiDataResponse<MeasureOneDataResponseToken>,
      ) => {
        const measureOneDataResponseToken = {
          dataRequestId: response.data.dataRequestId,
          individualId: response.data.individualId,
          publicToken: response.data.publicToken,
        };
        setCurrentMeasureOneDataResponseToken(measureOneDataResponseToken);
      },
      onError: (response: AxiosError) => {
        triggerOtesModal({
          bodyText: t`There is a problem requesting this employment from MeasureOne.`,
        });
      },
    },
  );
};
