import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { Logo } from "components/common/basic/Logo";
import { get, useForm, useWatch } from "react-hook-form";
import CopyrightFooter from "components/common/basic/CopyrightFooter";
import {
  ApiDataResponse,
  LoginData,
  putPinVerification,
  sendColdLogin,
  TokenData,
} from "auth/authApi";
import { t, Trans } from "@lingui/macro";
import { useMutation } from "react-query";
import { useEffect, useState } from "react";
import PinForm from "auth/PinForm";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { useHistory } from "react-router";
import Header from "components/common/basic/Header";
import { tokenHistoryReplace } from "auth/historyHelpers";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import {
  EMAIL_ADDRESS,
  PHONE_NUMBER,
} from "components/common/form/fieldConstants";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { AxiosError } from "axios";
import useOtesModal from "error/useOtesModal";
import { ResponseCodes } from "tasks/apiConstants";
import ReactInputMask from "react-input-mask";
import { onKeyDownEnforceNumeric } from "util/InputUtils";
import useAuth from "./useAuth";

interface LoginFormValues {
  email?: string;
  sms?: string;
}

export default function Login(): JSX.Element {
  const history = useHistory();
  const [pinCode, setPinCode] = useState<string>("");
  const { triggerOtesModal } = useOtesModal();
  const { updateToken, setIsAuthenticated, setBypassOtp } = useAuth();

  const setFakePinCode = () =>
    setPinCode(Math.floor(Math.random() * 1000000).toString());

  // TODO (JDL:2023-01-19) Consolidate with CandidateEntryUnavailableScreen.tsx impl.
  const sendPinMutator = useMutation(
    ({ email, sms }: LoginFormValues) => {
      return sendColdLogin(email ? { email: email } : { sms: sms });
    },
    {
      onSuccess: (result: ApiDataResponse<LoginData>) => {
        if (!result?.data?.pinCode) {
          return setFakePinCode();
        }

        return setPinCode(result.data.pinCode);
      },
      //set a fake 6 digit pinCode for good measure
      onError: (response: AxiosError) => {
        handleOtesErrorFromAxiosError(response);

        setPinCode("");

        let otesModalBodyText;
        if (
          ResponseCodes.Error.Action.VALIDATION_FAILURE ===
          response?.response?.data?.code
        ) {
          otesModalBodyText = t`Unable to send pin, the provided communication is invalid.`;
        } else {
          otesModalBodyText = t`Unable to send pin, please try again.`;
        }

        triggerOtesModal({
          bodyText: otesModalBodyText,
        });
      },
    },
  );
  const verifyPinMutator = useMutation(
    (pin: string) =>
      putPinVerification(
        pin,
        pinCode,
        sendPinMutator.variables?.email ? "email" : "sms",
      ),
    {
      onSuccess: async (tokenResponse: ApiDataResponse<TokenData>) => {
        //Update state values
        updateToken(tokenResponse.data?.value);
        setIsAuthenticated(true);
        setBypassOtp(true);

        tokenHistoryReplace(history, tokenResponse.data?.value);
      },
      onError: (response: AxiosError) => {
        if (
          ResponseCodes.Error.Action.INVALID_PIN ===
          response?.response?.data?.code
        ) {
          triggerOtesModal({
            bodyText: t`Incorrect or expired PIN, please try again.`,
          });
        } else {
          triggerOtesModal({
            bodyText: t`Unable to verify PIN, please try again.`,
          });
        }
      },
    },
  );

  const ContactInfoForm = () => {
    const methods = useForm<LoginFormValues>();
    const {
      register,
      handleSubmit,
      formState,
      formState: { isSubmitted },
      control,
      clearErrors,
      reset,
    } = methods;

    const hasEmailOrPhoneExclusive = ({ email, sms }: LoginFormValues) =>
      (email && !sms) || (sms && !email);

    const emailWatch = useWatch({ control, name: "email" });
    const smsWatch = useWatch({ control, name: "sms" });
    const emailProvided = emailWatch !== "" && emailWatch !== undefined;
    const phoneProvided =
      smsWatch !== "" &&
      smsWatch !== undefined &&
      smsWatch !== "(___) ___-____";

    useEffect(() => {
      if (isSubmitted && !phoneProvided) {
        reset();
      }
      if (!smsWatch?.includes("_")) {
        clearErrors("sms");
      }
    }, [smsWatch]);

    return (
      <>
        <Text>
          <Trans>
            Login using the e-mail or mobile number you provided on your
            application or screening authorization form.
          </Trans>
        </Text>
        <Box>
          <form
            onSubmit={handleSubmit(async ({ email, sms }: LoginFormValues) =>
              sendPinMutator.mutate({ email, sms }),
            )}>
            <FormControl isInvalid={get(formState.errors, "sms")}>
              <FormControl pt="4" pb="2">
                <FormLabel className={phoneProvided ? "" : "requiredField"}>
                  {EMAIL_ADDRESS.LABEL}
                </FormLabel>
                <Input
                  onFocus={() => clearErrors("sms")}
                  type="email"
                  placeholder={phoneProvided ? "" : EMAIL_ADDRESS.PLACEHOLDER}
                  disabled={phoneProvided}
                  {...register("email")}
                />
              </FormControl>

              <Text align="center">
                <strong>
                  <Trans>OR</Trans>
                </strong>
              </Text>

              <FormControl py="2">
                <FormLabel className={emailProvided ? "" : "requiredField"}>
                  <Trans>Mobile Phone Number</Trans>
                </FormLabel>
                <Input
                  disabled={emailProvided}
                  type="tel"
                  onKeyDown={onKeyDownEnforceNumeric}
                  as={ReactInputMask}
                  mask={PHONE_NUMBER.PARENS_MASK}
                  autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
                  placeholder={
                    emailProvided ? "" : PHONE_NUMBER.PARENS_PLACEHOLDER
                  }
                  {...register("sms", {
                    pattern:
                      (!emailProvided && {
                        value: PHONE_NUMBER.PARENS_PATTERN,
                        //DEV-11541 Use of specific message
                        message: t`Please enter a valid mobile phone number.`,
                      }) ||
                      undefined,
                  })}
                />
              </FormControl>
              <FormErrorMessage>
                {get(formState.errors, "sms.message")}
              </FormErrorMessage>
            </FormControl>
            <Center>
              <Button
                my="2"
                p="5px 40px"
                type="submit"
                colorScheme="orange"
                disabled={!emailProvided && !phoneProvided}>
                Submit
              </Button>
            </Center>
          </form>
        </Box>
      </>
    );
  };

  if (sendPinMutator.isLoading || verifyPinMutator.isLoading) {
    return (
      <>
        <Header text="Login" />
        <SpinnerOverlay
          text={
            sendPinMutator.isLoading ? "Sending PIN..." : "Verifying PIN..."
          }
        />
      </>
    );
  } else {
    return (
      <>
        <Header text="Login" />
        <Container
          centerContent
          maxW="container.md"
          overflowY="auto"
          height={`calc(100% - ${ThemeConstants.HEADER_HEIGHT}px)`}>
          <Center pt={5} pb={1}>
            <Logo size="large" useCCLogo />
          </Center>
          <Text textAlign="center" fontSize={"sm"}>
            <Trans>
              <strong>CandidateConnect&trade;</strong> is for those undergoing a
              background screen.
            </Trans>
          </Text>
          <Box p="4">
            <Center>
              <Box>
                {pinCode ? (
                  <PinForm
                    description={t`A pin was sent to ${
                      sendPinMutator.variables?.email ||
                      sendPinMutator.variables?.sms
                    }. This pin will expire in 10 minutes.`}
                    onPinSubmit={(pin: string) => verifyPinMutator.mutate(pin)}
                    onReSendPin={() =>
                      sendPinMutator.mutate({
                        email: sendPinMutator.variables?.email,
                        sms: sendPinMutator.variables?.sms,
                      })
                    }
                  />
                ) : (
                  <ContactInfoForm />
                )}
                <Center>
                  <Text
                    textAlign={"center"}
                    color="orange.600"
                    pt="1rem"
                    fontSize="sm">
                    <Trans>
                      Need Help? Call 1&#8209;888&#8209;222&#8209;0667
                    </Trans>
                  </Text>
                </Center>
              </Box>
            </Center>
          </Box>
          <CopyrightFooter />
        </Container>
      </>
    );
  }
}
