import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  IdVerifyApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import AddressHistoryForm, {
  AddressHistoryFormFields,
} from "components/tasks/address-history/AddressHistoryForm";
import { DatedAddressEntryFormFields } from "components/tasks/address-history/DatedAddressEntryWrapper";
import { SubmitHandler } from "react-hook-form";
import IdentityVerificationForm from "components/tasks/IdentityVerificationForm";
import { DateTime } from "luxon";
import { COUNTRY_UNITED_STATES } from "tasks/apiConstants";

export interface AddressHistoryTaskProps {
  // TODO - move to a new TasksContext
  goToStatusCheck: () => void;
}

export interface AddressApiTaskdefFields {
  COUNTRY: ApiFieldProperties;
  STREET_ADDRESS: ApiFieldProperties;
  CITY: ApiFieldProperties;
  STATE?: ApiFieldProperties;
  POSTAL_CODE: ApiFieldProperties;
  FROM: ApiFieldProperties;
  TO?: ApiFieldProperties;
  INSTANCE_ID?: ApiFieldProperties;
}

// TODO test existing Address INSTANCE_ID - may need to add hidden field to form
export interface AddressApiSaveFields {
  COUNTRY: string;
  STREET_ADDRESS: string;
  CITY: string;
  STATE_TEXT: string;
  STATE_SELECT: string;
  POSTAL_CODE: string;
  FROM: string;
  TO?: string;
  INSTANCE_ID?: string;
}

export interface AddressHistoryApiFields extends ApiFormFields {
  CURRENT_ADDRESS: AddressApiTaskdefFields;
  PREVIOUS_ADDRESSES: AddressApiTaskdefFields[];
}

interface AddressHistoryApiFormMessages extends ApiFormMessages {
  clientLabel: string;
  clientCustomizedTaskDescription: string;
}

interface AddressHistoryApiFormParameters extends IdVerifyApiFormParameters {
  minimumYearsOfAddressHistory?: number;
  maximumAmountOfAddresses?: number;
  maximumGapDays?: number;
  identityVerificationMode?: boolean;
}

export function mapApiAddressToFormAddress(
  apiAddress?: AddressApiTaskdefFields,
): DatedAddressEntryFormFields {
  const fromDateSplit = (apiAddress?.FROM?.existingValue || "").split("-");
  const toDateSplit = (apiAddress?.TO?.existingValue || "").split("-");
  return {
    country: apiAddress?.COUNTRY?.existingValue || COUNTRY_UNITED_STATES,
    street: apiAddress?.STREET_ADDRESS?.existingValue || "",
    city: apiAddress?.CITY?.existingValue || "",
    state: apiAddress?.STATE?.existingValue || "",
    postalCode: apiAddress?.POSTAL_CODE?.existingValue || "",
    startYear: fromDateSplit?.[0] || "",
    startMonth: fromDateSplit?.[1] || "",
    startDay: fromDateSplit?.[2] || "",
    startDate: apiAddress?.FROM?.existingValue || "",
    endYear: toDateSplit?.[0] || "",
    endMonth: toDateSplit?.[1] || "",
    endDay: toDateSplit?.[2] || "",
    endDate: apiAddress?.TO?.existingValue || "",
    apiId: apiAddress?.INSTANCE_ID?.existingValue,
  };
}

export default function AddressHistoryTask({
  goToStatusCheck,
}: AddressHistoryTaskProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY;

  const {
    data,
    isFetching,
    isRefetching,
    refetch: refetchTaskDef,
  } = useGetTaskdef<
    SingleFormTaskDef<
      AddressHistoryApiFields,
      AddressHistoryApiFormMessages,
      AddressHistoryApiFormParameters
    >
  >({
    taskId,
    goToStatusCheck,
  });

  function mapFormAddressToApiAddress(
    formAddress: DatedAddressEntryFormFields,
  ): AddressApiSaveFields {
    return {
      COUNTRY: formAddress.country,
      STREET_ADDRESS: formAddress.street,
      CITY: formAddress.city,
      STATE_TEXT: formAddress.state,
      STATE_SELECT: formAddress.state,
      POSTAL_CODE: formAddress.postalCode || "",
      FROM: formAddress.startDate,
      //only add TO if we have an end date
      ...(!!formAddress?.endDate && {
        TO: formAddress.endDate,
      }),
      ...(!!formAddress?.apiId && {
        INSTANCE_ID: formAddress?.apiId,
      }),
    };
  }

  //create a mutate function for this specific taskId
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const storeAddressHistory: SubmitHandler<AddressHistoryFormFields> = async ({
    currentAddress,
    previousAddresses,
    doSave,
  }: AddressHistoryFormFields) => {
    mutateTaskData({
      formFields: {
        CURRENT_ADDRESS: mapFormAddressToApiAddress(currentAddress),
        PREVIOUS_ADDRESSES: previousAddresses.map((address) =>
          mapFormAddressToApiAddress(address),
        ),
      },
      saveOnly: doSave,
    });
  };

  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : data?.forms[0]?.formParameters?.identityVerificationMode ? (
    <IdentityVerificationForm
      taskId={taskId}
      clientLabel={data?.forms[0]?.formMessages?.clientLabel}
      goToStatusCheck={goToStatusCheck}
      refetchTaskDef={refetchTaskDef}
      startOrEndOfYear={data?.forms[0]?.formMessages?.startOrEndYear}
    />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <Text>
        {data?.forms[0]?.formMessages?.clientCustomizedTaskDescription}
      </Text>
      <AddressHistoryForm
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        maximumGapDays={data?.forms[0]?.formParameters?.maximumGapDays}
        minimumYearsOfAddresses={
          data?.forms[0]?.formParameters?.minimumYearsOfAddressHistory
        }
        maximumAmountOfAddresses={
          data?.forms[0]?.formParameters?.maximumAmountOfAddresses
        }
        goToStatusCheck={goToStatusCheck}
        defaultValues={{
          currentAddress: mapApiAddressToFormAddress(
            data?.forms[0]?.formFields?.CURRENT_ADDRESS,
          ),
          previousAddresses:
            data?.forms[0]?.formFields?.PREVIOUS_ADDRESSES.map((address) =>
              mapApiAddressToFormAddress(address),
            ) || [],
        }}
        onSubmit={storeAddressHistory}
      />
    </>
  );
}
