export default class OtesError {
  private readonly _otesErrorCode?: number;
  private readonly _httpErrorCode: number;

  constructor(otesErrorCode?: number, httpErrorCode?: number) {
    this._httpErrorCode = httpErrorCode || 500;

    if (typeof otesErrorCode === "number") {
      this._otesErrorCode = otesErrorCode;
    }
  }

  get otesErrorCode(): number | undefined {
    return this._otesErrorCode;
  }

  get isSystemOrEntryError(): boolean {
    if (this._otesErrorCode) {
      // -10xx and -11xx series indicate candidate entry is no longer possible..
      return this._otesErrorCode <= -1000 && this._otesErrorCode > -1200;
    }

    return false;
  }

  get hasHttpStatus5xx(): boolean {
    return this._httpErrorCode >= 500;
  }
}
