import { Button, Center, Container, Text } from "@chakra-ui/react";
import { Logo } from "components/common/basic/Logo";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router-dom";
import Header from "../components/common/basic/Header";
import { conditionalHistoryPush } from "./historyHelpers";
import { AppPath } from "../AppPath";

export const WelcomeBack = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Header text="Welcome" />
      <Container centerContent maxW="container.md">
        <Center pt={5} pb={5}>
          <Logo size={"x-large"} useCCLogo />
        </Center>
        <Text p={2}>
          <Trans>
            To protect your personal information, click or tap NEXT to verify
            access to your background screen.
          </Trans>
        </Text>
        <Button
          p="5px 40px"
          mt={10}
          colorScheme="orange"
          onClick={() => conditionalHistoryPush(history, AppPath.LOGIN)}>
          <Trans>Next</Trans>
        </Button>
      </Container>
    </>
  );
};
