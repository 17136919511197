import { Box, CloseButton } from "@chakra-ui/react";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import FormSelect from "components/common/form/FormSelect";
import { RELATIONSHIPS } from "tasks/apiConstants";
import {
  EMAIL_ADDRESS,
  PHONE_NUMBER,
} from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

export interface ReferenceFormFields {
  name: string;
  email: string;
  phone: string;
  relationship: string;
}

interface ReferenceFormProps {
  referenceId: string;
  defaultValues: ReferenceFormFields;
  useRelationshipDropdown?: boolean;
  requireEmail: boolean;
  requirePhone: boolean;
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export const EMPTY_REFERENCE: ReferenceFormFields = {
  name: "",
  email: "",
  phone: "",
  relationship: "",
};

export default function ReferenceForm({
  referenceId,
  defaultValues,
  useRelationshipDropdown = false,
  requireEmail,
  requirePhone,
  showRemoveButton = false,
  onRemoveClick,
}: ReferenceFormProps): JSX.Element {
  return (
    <Box className={"arrayItemForm"}>
      {showRemoveButton && (
        <CloseButton
          className={"closeButton"}
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormInput
        fieldId={`${referenceId}.name`}
        label={t`Name`}
        defaultValue={defaultValues.name}
        maxLength={300}
      />
      {requireEmail && (
        <FormInput
          fieldId={`${referenceId}.email`}
          type={"email"}
          label={EMAIL_ADDRESS.LABEL}
          defaultValue={defaultValues.email}
          placeholder={EMAIL_ADDRESS.PLACEHOLDER}
          validationPattern={EMAIL_ADDRESS.PATTERN}
          validationPatternMessage={EMAIL_ADDRESS.REQUIRED_MESSAGE}
          maxLength={255}
        />
      )}
      {requirePhone && (
        <FormInput
          fieldId={`${referenceId}.phone`}
          label={t`Phone`}
          defaultValue={defaultValues.phone}
          type={"tel"}
          onKeyDown={onKeyDownEnforceNumeric}
          inputMask={PHONE_NUMBER.PARENS_MASK}
          placeholder={PHONE_NUMBER.PARENS_PLACEHOLDER}
          validationPattern={PHONE_NUMBER.PARENS_PATTERN}
          validationPatternMessage={PHONE_NUMBER.PATTERN_MESSAGE}
          autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
        />
      )}
      {useRelationshipDropdown ? (
        <FormSelect
          fieldId={`${referenceId}.relationship`}
          label={t`Relation to Applicant`}
          options={RELATIONSHIPS}
          defaultValue={defaultValues.relationship}
        />
      ) : (
        <FormInput
          fieldId={`${referenceId}.relationship`}
          label={t`Relation to Applicant`}
          defaultValue={defaultValues.relationship}
          maxLength={63}
        />
      )}
    </Box>
  );
}
