import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { EducationDatesFormFields } from "./EducationDatesTask";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import FormCheckbox from "components/common/form/FormCheckbox";
import {
  DatePickerInput,
  validateFormDate,
} from "../../../common/form/DatePickerInput";

interface EducationDatesFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: EducationDatesFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
  displayToDateField?: boolean;
}

export interface EducationDatesSubFormFields extends InterviewFormFields {
  fromDate?: string;
  toDate?: string;
  current?: boolean;
}

export type EducationDatesApiSubForm = InterviewApiSubForm<
  EducationDatesSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
  displayToDateField,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  const methods = useFormContext();
  const [fromDate, toDate] = methods.getValues([
    `${formId}.from`,
    `${formId}.to`,
  ]);

  const validateToDate = () => {
    const current = methods.getValues(`${formId}.current`);
    if (current && current === true) {
      return true;
    }

    const toDatePath = `${formId}.toDate`;
    const toDate = methods.getValues(toDatePath);

    return validateFormDate(toDate);
  };

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <DatePickerInput
        label={t`From`}
        fieldIds={{
          month: `${formId}.fromMonth`,
          day: `${formId}.fromDay`,
          year: `${formId}.fromYear`,
          date: `${formId}.fromDate`,
        }}
      />
      {displayToDateField && (
        <DatePickerInput
          label={t`To`}
          fieldIds={{
            month: `${formId}.toMonth`,
            day: `${formId}.toDay`,
            year: `${formId}.toYear`,
            date: `${formId}.toDate`,
          }}
          validateDate={validateToDate}
        />
      )}
      <FormCheckbox fieldId={`${formId}.current`} label={t`Current`} />
    </FormControl>
  );
};

export default function EducationDatesForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: EducationDatesFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<EducationDatesFormFields>({ defaultValues });

  const { formName, formInstructions } = formMessages;

  const { formId } = formParameters;

  const displayToDateField = !methods.watch(`${formId}.current`);

  const formContentProps = {
    formId,
    formName,
    formInstructions,
    displayToDateField,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
