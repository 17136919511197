export default {
  variants: {
    button: {
      backgroundColor: "brand.orange",
      borderColor: "brand.orange",
      outline: "none",
      color: "brand.white",
      textTransform: "uppercase",
      display: "inline-block",
      marginBottom: 0,
      fontWeight: "bold",
      textAlign: "center",
      verticalAlign: "middle",
      cursor: "pointer",
      backgroundImage: "none",
      border: "1px solid transparent",
      whiteSpace: "nowrap",
      padding: "6px 12px",
      fontSize: "14px",
      lineHeight: 1.42857143,
      borderRadius: "4px",
    },
  },
};
