import { SubmitHandler } from "react-hook-form";
import { FormControl, FormLabel, useTheme, VStack } from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import * as React from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import AddressForm from "components/common/form/AddressForm";

interface AddressDataFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
}

export interface AddressDataSubFormFields extends InterviewFormFields {
  country: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
}

export type AddressDataApiSubForm = InterviewApiSubForm<
  AddressDataSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormContent = ({ formName, formId }: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <AddressForm
        addressId={formId}
        required={{ postalCode: true }}
        fieldIds={{
          country: "country",
          // TODO Consolidate to either "street" or "streetAddress"
          //  rather than flip flopping.
          street: "streetAddress",
          city: "city",
          state: "state",
          postalCode: "postalCode",
        }}
      />
    </FormControl>
  );
};

export default function AddressDataForm({
  formMessages,
  formParameters,
}: AddressDataFormProps): JSX.Element {
  const { formName } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    formId,
    formName,
  };

  return (
    <VStack w="100%" p={1}>
      <FormContent {...formContentProps} />
    </VStack>
  );
}
