import * as H from "history";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { WelcomeBack } from "auth/WelcomeBack";
import Login from "auth/Login";
import useAuth, { AuthProvider } from "auth/useAuth";
import PreChecks from "preChecks/Prechecks";
import Main from "Main";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { de, en, es, fr } from "make-plural/plurals";
import theme from "theme";
import { detect, fromNavigator } from "@lingui/detect-locale";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { ReactNode, useEffect, useState } from "react";
import TermsAndConditions from "components/common/basic/TermsAndConditions";
import {
  getPreChecks,
  handleOtesErrorFromAxiosError,
  isRememberMeSet,
} from "auth/authHelpers";
import { AppPath, AppPathHelper } from "AppPath";
import CandidateEntryUnavailableScreen from "auth/CandidateEntryUnavailableScreen";
import { getSystemStatus } from "auth/authApi";
import OtesError from "components/common/basic/OtesError";
import { OtesModalProvider } from "error/useOtesModal";
import { AxiosError } from "axios";
import useAcquisition, {
  AcquisitionProvider,
} from "applicantTheme/useAcquisition";
import { getTheme } from "applicantTheme/themeApi";
import { useQuery } from "react-query";
import { defaultApplicantTheme } from "applicantTheme/ApplicantTheme";
import Header from "components/common/basic/Header";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function AuthenticationWrapper(): JSX.Element {
  const { token, isAuthenticated, otesError, bypassPiiVerify } = useAuth();

  if (otesError) {
    return <CandidateEntryUnavailableScreen />;
  } else if (!token) {
    return <SpinnerOverlay text={"One moment please..."} />;
  } else if (!isAuthenticated) {
    return <Login />;
  } else {
    const { data, isFetching } = useQuery(
      ["theme", token],
      () => getTheme(token),
      { onError: handleOtesErrorFromAxiosError },
    );

    const theme = data || defaultApplicantTheme;
    const { updateAcquisition } = useAcquisition();
    useEffect(() => {
      updateAcquisition(theme?.acquisitionSource);
    }, [theme]);

    if (isFetching) {
      return (
        <>
          {isRememberMeSet() && <Header />}
          <SpinnerOverlay text={"Reviewing your info..."} />
        </>
      );
    } else {
      const preChecks = getPreChecks(token);

      let processPreChecks: boolean;

      if (preChecks.length === 1 && preChecks.includes("pii")) {
        processPreChecks = !bypassPiiVerify;
      } else {
        processPreChecks = preChecks.length > 0;
      }

      if (processPreChecks) {
        return <PreChecks theme={theme} />;
      } else {
        return <Main />;
      }
    }
  }
}

const defaultLocale = "en-US";
// TODO (JDL:2023-07-06) We need to do a better job of defaulting to English when language is not supported.
const language = defaultLocale; //detect(fromNavigator(), () => defaultLocale) || defaultLocale;

export const languages = {
  "en-US": { plurals: en },
  en: { plurals: en },
  es: { plurals: es },
  de: { plurals: de },
  fr: { plurals: fr },
};
i18n.loadLocaleData(languages);

/**
 * 2022-04-19
 * These next 2 lines are a temporary workaround to remove the spurious warning that i18n is not activated. Should be
 * resolved in a later build of @lingui/react. More history here: https://github.com/lingui/js-lingui/issues/1194
 */
i18n.load(defaultLocale, {});
i18n.activate(defaultLocale);

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string): Promise<void> {
  const { messages } = await import(`./locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

export const App = (): JSX.Element => {
  const [systemHealthy, setSystemHealthy] = useState<boolean>(false);
  const [otesError, setOtesError] = useState<OtesError | undefined>(undefined);

  useEffect(() => {
    dynamicActivate(language);
  }, [language]);

  const history = useHistory();

  useEffect(() => {
    // This check accomplishes two things:
    // 1. It preps the browser with a XSRF set-cookie header.
    // 2. It establishes that the system is minimally responsive, and not under maintenance.
    getSystemStatus()
      .then(() => {
        setSystemHealthy(true);
      })
      .catch((response: AxiosError) => {
        const errorResponse = response?.response;

        setOtesError(
          new OtesError(errorResponse?.data?.code, errorResponse?.status),
        );
      });
  }, [history]);

  let innerContent: ReactNode;

  if (otesError) {
    innerContent = (
      <CandidateEntryUnavailableScreen otesErrorOverride={otesError} />
    );
  } else if (systemHealthy) {
    innerContent = (
      <Switch>
        <Route exact path={AppPath.WELCOME}>
          <WelcomeBack />
        </Route>
        <Route exact path={AppPath.TERMS_CONDITIONS}>
          <TermsAndConditions />
        </Route>
        <AuthProvider>
          <Switch>
            <Route exact path={AppPath.LOGIN}>
              <Login />
            </Route>
            <AcquisitionProvider>
              <Route exact path={AppPathHelper.KNOWN_PATHS}>
                <AuthenticationWrapper />
              </Route>
              <Route
                render={({ location }: { location: H.Location }) =>
                  !AppPathHelper.hasTokenPattern(location) && (
                    <Redirect to="/" />
                  )
                }
              />
            </AcquisitionProvider>
          </Switch>
        </AuthProvider>
      </Switch>
    );
  } else {
    innerContent = <SpinnerOverlay text={"One moment please..."} />;
  }

  return (
    <I18nProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <OtesModalProvider>
            <Router>{innerContent}</Router>
          </OtesModalProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </I18nProvider>
  );
};
