import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, FormLabel, useTheme, VStack } from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import { t } from "@lingui/macro";
import FormSelect from "components/common/form/FormSelect";
import TaskButtons from "components/common/basic/TaskButtons";
import { DriverLicenseFormFields } from "./DriverLicenseTask";
import FormInput from "components/common/form/FormInput";
import { DRIVER_LICENSE_TYPES, STATES_AND_PROVINCES } from "tasks/apiConstants";
import { InterviewSubmitProps } from "../FormInterviewTask";
import {
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import {
  DatePickerInput,
  validateDateOfBirth,
} from "components/common/form/DatePickerInput";

interface DriverLicenseFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues: DriverLicenseFormFields;
  isSavable: boolean;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
}

export default function DriverLicenseForm({
  defaultValues = {
    dateOfBirthDate: "",
  },
  isSavable,
  formMessages,
  formParameters,
  onSubmit,
  goToStatusCheck,
}: DriverLicenseFormProps): JSX.Element {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  const methods: any = useForm<DriverLicenseFormFields>({ defaultValues });
  const formId = formParameters.formId;

  const validateDate = (dateOfBirthString: string) => {
    const driversLicenseType = methods.getValues(
      `${formId}.driversLicenseType`,
    );
    if (
      driversLicenseType &&
      driversLicenseType === "No License, No State ID"
    ) {
      return true;
    }

    return validateDateOfBirth(dateOfBirthString);
  };

  const driversLicenseType = methods.watch(`${formId}.driversLicenseType`);

  const displayDriverLicenseFields =
    driversLicenseType !== "No License, No State ID";

  const driverLicenseForm = "driverLicenseForm";

  return (
    <VStack w="100%" p={1}>
      <FormProvider {...methods}>
        <form id={driverLicenseForm} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormControl as="fieldset">
            <FormLabel
              as="legend"
              fontSize="xl"
              fontWeight="bold"
              color={orange}>
              {formMessages.formName}
            </FormLabel>
            <FormSelect
              fieldId={`${formId}.driversLicenseType`}
              label={t`Driver's License`}
              options={DRIVER_LICENSE_TYPES}
            />
            {displayDriverLicenseFields && (
              <>
                <FormInput
                  label={
                    !driversLicenseType ||
                    driversLicenseType === "Driver's License"
                      ? t`Driver's License Number`
                      : t`State ID Number`
                  }
                  fieldId={`${formId}.driversLicenseNumber`}
                />
                <FormSelect
                  fieldId={`${formId}.driversLicenseState`}
                  label={t`State/Province`}
                  options={STATES_AND_PROVINCES}
                />
                <DatePickerInput
                  label={t`Date Of Birth`}
                  fieldIds={{
                    month: `${formId}.dateOfBirthMonth`,
                    day: `${formId}.dateOfBirthDay`,
                    year: `${formId}.dateOfBirthYear`,
                    date: `${formId}.dateOfBirthDate`,
                  }}
                  {...{
                    validateDate: (value) => validateDate(value),
                  }}
                />
                <FormInput
                  label={t`First Name`}
                  fieldId={`${formId}.firstName`}
                  maxLength={64}
                />
                <FormInput
                  label={t`Middle Name`}
                  fieldId={`${formId}.middleName`}
                  required={false}
                  maxLength={64}
                />
                <FormInput
                  label={t`Last Name`}
                  fieldId={`${formId}.lastName`}
                  maxLength={64}
                />
              </>
            )}
          </FormControl>
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={driverLicenseForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
