import { createContext, ReactNode, useContext, useState } from "react";
import * as React from "react";
import OtesModal, { OtesModalProps } from "components/common/basic/OtesModal";
import { useDisclosure } from "@chakra-ui/react";
import { t } from "@lingui/macro";

interface OtesModalContextType {
  triggerOtesModal: (
    triggerOtesModalParameters: TriggerOtesModalParameters,
  ) => void;
  dismissOtesModal: () => void;
}

const OtesModalContext = createContext<OtesModalContextType>(
  {} as OtesModalContextType,
);

interface TriggerOtesModalParameters {
  headerText?: string;
  bodyText: string;
  buttonText?: string;
  isError?: boolean;
  onClose?: () => void;
}

export function OtesModalProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const otesModal = useDisclosure();

  const [otesModalState, setOtesModalState] = useState<OtesModalProps>({
    isOpen: false,
    onOpen: otesModal.onOpen,
    onClose: otesModal.onClose,
    headerText: "",
    bodyText: "",
    buttonText: t`OK`,
  });

  const triggerOtesModal = ({
    headerText,
    bodyText,
    buttonText,
    isError = true,
    onClose,
  }: TriggerOtesModalParameters) => {
    setOtesModalState((prevModal: OtesModalProps) => ({
      ...prevModal,
      isError: isError,
      onClose: () => {
        onClose?.();
        otesModal.onClose();
      },
      headerText: headerText || (isError ? t`Oops...` : t`Success`),
      bodyText: bodyText,
      buttonText: buttonText || t`OK`,
    }));

    otesModal.onOpen();
  };

  return (
    <OtesModalContext.Provider
      value={{
        triggerOtesModal,
        dismissOtesModal: otesModal.onClose,
      }}>
      <OtesModal
        isError={otesModalState.isError}
        isOpen={otesModal.isOpen}
        onClose={otesModalState.onClose}
        onOpen={otesModalState.onOpen}
        headerText={otesModalState.headerText}
        bodyText={otesModalState.bodyText}
        buttonText={otesModalState.buttonText}
      />
      {children}
    </OtesModalContext.Provider>
  );
}

export default function useOtesModal(): OtesModalContextType {
  return useContext(OtesModalContext);
}
