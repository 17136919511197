import {
  ApplicantEntrySpecialFormTaskId,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { t } from "@lingui/macro";

export interface TaskHeading {
  long: string;
  short?: string;
}

const taskHeadings: Record<
  ApplicantEntryTaskId | ApplicantEntrySpecialFormTaskId,
  TaskHeading
> = {
  [ApplicantEntryTaskId.APP_ENTRY_ACKNOWLEDGEMENT]: { long: t`Authorization` },
  [ApplicantEntryTaskId.APP_ENTRY_ADDITIONAL_QUESTIONS]: {
    long: t`Additional Questions`,
    short: t`Questions`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY]: {
    long: t`Address History`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_ADMITTED_OFFENSE]: {
    long: t`Admitted Offenses`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_AFFIRMATION]: { long: t`Affirmation` },
  [ApplicantEntryTaskId.APP_ENTRY_AKAS]: { long: t`Other Names Used` },
  [ApplicantEntryTaskId.APP_ENTRY_DISCLOSURE]: {
    long: t`Disclosure Regarding Consumer Report`,
    short: t`Disclosure`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_EDUCATION]: {
    long: t`Education History`,
    short: t`Education`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_EMPLOYMENT]: {
    long: t`Employment History`,
    short: t`Employment`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_INVESTIGATIVE_DISCLOSURE]: {
    long: t`Disclosure Regarding Investigative Consumer Report`,
    short: t`Disclosure`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_PERSONAL_INFO]: {
    long: t`Personal Information`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_LICENSE]: {
    long: t`Professional Licenses`,
    short: `Licenses`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_REFERENCE]: {
    long: t`References`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_SPECIFIC_DISCLOSURES]: {
    long: t`Disclosure Information`,
    short: t`Disclosure`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_GA_STATEWIDE]: {
    long: t`Georgia Criminal History Record Search`,
  },
  [ApplicantEntryTaskId.APP_ENTRY_MEASURE_ONE_EMPLOYMENT]: {
    long: t`Job Confirmation: MeasureOne`,
  },

  //Special Form task headings
  [ApplicantEntrySpecialFormTaskId.GA_STATEWIDE]: {
    long: t`Georgia Criminal History Record Search`,
  },
};

export default taskHeadings;
