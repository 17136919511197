import { Trans } from "@lingui/macro";
import { Controller, useFormContext } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import { useRef, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
} from "@chakra-ui/react";

interface FormSignaturePadProps {
  label?: string;
  labelSize?: string;
  labelWeight?: string;
  required?: boolean;
  hidden?: boolean;
  setSignature: (signatureDataUrl: string) => void;
  isSpecialForm?: boolean;
}

interface SignatureFormValues {
  signature: string;
}

export default function FormSignaturePad({
  label,
  labelSize,
  labelWeight,
  required = false,
  hidden = false,
  setSignature,
  isSpecialForm = false,
}: FormSignaturePadProps): JSX.Element {
  const sigPad = useRef<SignaturePad | null>(null);
  const [signaturePadValue, setSignaturePadValue] = useState<string>();

  const resetCanvas = () => {
    sigPad.current?.clear();
    if (isSpecialForm) {
      //Do not resetField or use useForm methods
      //Using such will erase formData in rjsf
      setSignature("");
      setSignaturePadValue("");
    } else {
      resetField("signature");
    }
  };

  const {
    control,
    formState: { errors },
    resetField,
  } = useFormContext<SignatureFormValues>();

  const validateRequired = !hidden && required;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={!!errors?.signature || signaturePadValue === ""}
      width={"auto"}>
      <Heading fontSize={labelSize} fontWeight={labelWeight}>
        {label}
      </Heading>
      <FormLabel
        htmlFor="signature"
        fontSize={"sm"}
        mx={0}
        className={validateRequired ? "requiredField" : "optionalField"}>
        <Trans>Use your mouse or finger to draw your signature</Trans>
      </FormLabel>
      <Controller
        name="signature"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <SignaturePad
            ref={sigPad}
            onEnd={() => {
              //at the end of each stroke update the field value to the PNG string
              field.onChange(() => {
                const signature = sigPad.current
                  ?.getTrimmedCanvas()
                  .toDataURL();
                if (signature) {
                  setSignature(signature);
                  setSignaturePadValue(signature);
                }
              });
            }}
            canvasProps={ThemeConstants.SIGNATURE_CANVAS_PROPS}
          />
        )}
      />
      <FormErrorMessage>
        <Trans>
          Signature is empty or cannot be processed. Please clear the signature
          and try again.
        </Trans>
      </FormErrorMessage>
      <Flex direction={"row"} py={4}>
        <Button
          color="gray.600"
          onClick={() => {
            resetCanvas();
          }}>
          <Trans>Clear</Trans>
        </Button>
      </Flex>
    </FormControl>
  );
}
