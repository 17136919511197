import * as H from "history";
import { AppPath, AppPathHelper } from "../AppPath";

enum HistoryAction {
  REPLACE,
  PUSH,
}

function conditionalHistoryAction(
  historyAction: HistoryAction,
  history: H.History,
  pathname: string,
  ...additionalConditionals: boolean[]
) {
  for (let i = 0; i < additionalConditionals.length; i++) {
    if (!additionalConditionals[i]) {
      return;
    }
  }

  if (!history?.location) {
    return;
  }

  if (
    history.location.pathname === pathname ||
    history.location.pathname.endsWith(pathname)
  ) {
    return;
  }

  switch (historyAction) {
    case HistoryAction.REPLACE:
      history.replace(pathname);
      break;
    case HistoryAction.PUSH:
      history.push(pathname);
      break;
  }
}

export const tokenHistoryReplace = (history: H.History, tokenValue: string) => {
  conditionalHistoryAction(
    HistoryAction.REPLACE,
    history,
    `/${AppPathHelper.TOKEN_PATTERN}${tokenValue}`,
    !!tokenValue,
  );
};

export const conditionalHistoryReplace = (
  history: H.History,
  path: AppPath,
  ...additionalConditionals: boolean[]
) => {
  conditionalHistoryAction(
    HistoryAction.REPLACE,
    history,
    path,
    ...additionalConditionals,
  );
};

export const conditionalHistoryPush = (
  history: H.History,
  path: AppPath,
  ...additionalConditionals: boolean[]
) => {
  conditionalHistoryAction(
    HistoryAction.PUSH,
    history,
    path,
    ...additionalConditionals,
  );
};
