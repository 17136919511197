import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import DateOfBirthForm from "./DateOfBirthForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type DateOfBirthApiForm = {
  formFields: DateOfBirthApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface DateOfBirthFormFields {
  dateOfBirthDate?: string;
  doSave?: boolean;
}

interface DateOfBirthApiFormFields extends InterviewFormFields {
  dateOfBirth?: string;
}

interface DateOfBirthTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: DateOfBirthApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function DateOfBirthTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: DateOfBirthTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    dateOfBirth: form.formFields?.dateOfBirth || "",
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <DateOfBirthForm
      defaultValues={{
        [formParameters.formId]: {
          dateOfBirthDate: formFields.dateOfBirth,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
