import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import AuthorizationDownloadForm from "./AuthorizationDownloadForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type AuthorizationDownloadApiForm = {
  formFields: InterviewFormFields;
  formMessages: InterviewFormMessages;
  formParameters: AuthorizationDownloadApiFormParameters;
};

export interface AuthorizationDownloadFormFields {
  signature?: string;
  doSave?: boolean;
}

export interface AuthorizationDownloadFormInfo {
  authFormUid: string;
  authFormTitle: string;
  hasBeenAccessed: boolean;
}

interface AuthorizationDownloadApiFormParameters
  extends InterviewFormParameters {
  authForms: Array<AuthorizationDownloadFormInfo>;
}

interface AuthorizationDownloadTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: AuthorizationDownloadApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function AuthorizationDownloadTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = false,
  goToStatusCheck,
}: AuthorizationDownloadTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formMessages = {
    formName: form.formMessages?.formName || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    authForms: form.formParameters?.authForms || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <AuthorizationDownloadForm
      defaultValues={{
        [formParameters.formId]: {},
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
