import OffenseForm, {
  EMPTY_OFFENSE,
  OffenseFormFields,
} from "components/tasks/admitted-offenses/OffenseForm";
import { SavableFormComponentProps } from "components/tasks/taskHelpers";
import {
  Controller,
  FormProvider,
  get,
  useFieldArray,
  useForm,
} from "react-hook-form";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import { t, Trans } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";
import { AdmittedOffensesApiFormMessages } from "components/tasks/admitted-offenses/AdmittedOffensesTask";
import FormTextarea from "components/common/form/FormTextarea";
import { DateTime } from "luxon";
import { hasFullDateStringGreaterThanMaxDate } from "components/common/form/DatePickerInput";

export interface AdmittedOffensesFormFields {
  admittedOffenses: OffenseFormFields[];
  charged: string;
  convicted: string;
  otherInfo: string;
  doSave?: boolean;
}

export interface AdmittedOffensesFormProps
  extends SavableFormComponentProps<
    AdmittedOffensesFormFields,
    AdmittedOffensesApiFormMessages
  > {
  askForCharged: boolean;
  askForOtherInfo: boolean;
}

export const maxDate = DateTime.now().plus({ days: 1 }).startOf("day");

export const maxDateErrorMessage = `Offense Date must be before ${maxDate?.toLocaleString()}`;

export default function AdmittedOffensesForm({
  defaultValues,
  askForCharged,
  askForOtherInfo,
  isSavable,
  onSubmit,
  formMessages,
  goToStatusCheck,
}: AdmittedOffensesFormProps): JSX.Element {
  const toast = useToast();

  const methods = useForm<AdmittedOffensesFormFields>({ defaultValues });

  // if necessary, initialize defaultValues with an empty license
  if (!defaultValues.admittedOffenses.length) {
    defaultValues.admittedOffenses = [EMPTY_OFFENSE];
  }

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "admittedOffenses",
  });

  const showOffenses =
    methods.watch("convicted") === "true" ||
    methods.watch("charged") === "true";

  const admittedOffensesForm = "admittedOffensesForm";

  const validateAdmittedOffensesForm = (data: AdmittedOffensesFormFields) => {
    const errors: string[] = [];

    const { admittedOffenses } = data;

    for (const admittedOffense of admittedOffenses) {
      const { offenseDate } = admittedOffense;

      if (hasFullDateStringGreaterThanMaxDate(offenseDate, maxDate)) {
        errors.push(maxDateErrorMessage);
      }
    }

    return errors;
  };

  const onFormSubmit = (data: AdmittedOffensesFormFields) => {
    const validationErrors = validateAdmittedOffensesForm(data);
    validationErrors.forEach((error) =>
      toast({
        position: "top",
        status: "error",
        variant: "subtle",
        description: error,
        isClosable: true,
        duration: 8000,
      }),
    );

    if (!validationErrors.length) {
      onSubmit(data);
    }
  };

  // TODO (JDL:2022-06-23) Use the <FormRadioGroup/> instead of <RadioGroup/>
  return (
    <VStack p={1}>
      <FormProvider {...methods}>
        <form
          id={admittedOffensesForm}
          onSubmit={methods.handleSubmit(onFormSubmit)}>
          <FormControl isInvalid={get(methods.formState.errors, "convicted")}>
            <FormLabel fontSize={"smaller"} pt={2} className="requiredField">
              <Markup content={formMessages?.convictionQuestionText} />
            </FormLabel>
            <Controller
              control={methods.control}
              name="convicted"
              rules={{
                required: { value: true, message: t`This is required` },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <RadioGroup
                  aria-label={formMessages?.convictionQuestionText}
                  name="convicted"
                  pb={1}
                  defaultValue={value}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}>
                  <Radio p={1} size="lg" value={"true"}>
                    Yes
                  </Radio>
                  <Radio p={1} size="lg" value={"false"}>
                    No
                  </Radio>
                  <Radio p={1} size="lg" value={"N/A"}>
                    N/A
                  </Radio>
                </RadioGroup>
              )}
            />
            <FormErrorMessage mt={0}>
              {get(methods.formState.errors, `convicted.message`)}
            </FormErrorMessage>
          </FormControl>
          {askForCharged && (
            <FormControl isInvalid={get(methods.formState.errors, "charged")}>
              <FormLabel fontSize={"smaller"} pt={2} className="requiredField">
                <Markup content={formMessages?.chargeQuestionText} />
              </FormLabel>
              <Controller
                control={methods.control}
                name="charged"
                rules={{
                  required: { value: true, message: t`This is required` },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <RadioGroup
                    aria-label={formMessages?.chargeQuestionText}
                    name="charged"
                    pb={1}
                    defaultValue={value}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}>
                    <Radio p={1} size="lg" value={"true"}>
                      Yes
                    </Radio>
                    <Radio p={1} size="lg" value={"false"}>
                      No
                    </Radio>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage mt={0}>
                {get(methods.formState.errors, `charged.message`)}
              </FormErrorMessage>
            </FormControl>
          )}
          {showOffenses && (
            <>
              {formMessages?.admittedOffenseInstructionText && (
                <FormLabel
                  fontSize={"smaller"}
                  pt={2}
                  className="requiredField">
                  <Markup
                    content={formMessages?.admittedOffenseInstructionText}
                  />
                </FormLabel>
              )}
              {fields.map((offense, index) => (
                <Box key={offense.id}>
                  <OffenseForm
                    offenseId={`admittedOffenses.${index}`}
                    defaultValues={offense}
                    showRemoveButton={fields.length > 1}
                    onRemoveClick={() => remove(index)}
                  />
                </Box>
              ))}
              <Box pt={4}>
                <Button color="gray.600" onClick={() => append(EMPTY_OFFENSE)}>
                  + <Trans>Add Another Offense</Trans>
                </Button>
              </Box>
              {askForOtherInfo && (
                <Box py={4}>
                  <FormTextarea
                    fieldId="otherInfo"
                    label={t`Other Information`}
                    required={false}
                  />
                </Box>
              )}
            </>
          )}
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={
              (isSavable &&
                (() => onSubmit({ ...methods.getValues(), doSave: true }))) ||
              undefined
            }
            form={admittedOffensesForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
