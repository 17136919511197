import {
  genericOnSave,
  SavableFormComponentProps,
} from "components/tasks/taskHelpers";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { FormLabel, VStack } from "@chakra-ui/react";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import TaskButtons from "components/common/basic/TaskButtons";
import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import { GA_STATEWIDE_SEX_TYPES } from "tasks/apiConstants";
import { getGaStatewidePreliminaryAuthForm } from "tasks/tasksApi";
import { t, Trans } from "@lingui/macro";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import FormSignaturePad from "components/common/form/FormSignaturePad";
import useOtesModal from "error/useOtesModal";

export interface GaStatewideCriminalFormFields {
  sex: string;
  race: string;
  employmentGeneral?: boolean;
  mentallyDisabled?: boolean;
  elderly?: boolean;
  children?: boolean;
  employmentPurpose: string;
  document: { signature: string };
  doSave?: boolean;
}

const EmploymentPurposesRadioGroup = (): JSX.Element => {
  const { control } = useFormContext();
  return (
    <FormRadioGroup
      fieldId="employmentPurpose"
      groupLabel="Employment Purposes"
      fontSize="lg"
      fontWeight="bold"
      radios={[
        [t`Employment General`, "employmentGeneral"],
        [t`Working with Mentally Disabled`, "mentallyDisabled"],
        [t`Working with Elderly`, "elderly"],
        [t`Working with Children`, "children"],
      ]}
      vertical={true}
      control={control}
    />
  );
};

export default function GaStatewideCriminalForm({
  defaultValues,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: SavableFormComponentProps<GaStatewideCriminalFormFields>): JSX.Element {
  const gaStatewideCriminalForm = "gaStatewideCriminalForm";

  const methods = useForm<GaStatewideCriminalFormFields>({
    defaultValues,
  });

  const setSignature = (signature: string) => {
    methods.setValue("document.signature", signature);
  };

  const { triggerOtesModal } = useOtesModal();

  return (
    <VStack width="75%" p="1">
      <FormProvider {...methods}>
        <form
          id={gaStatewideCriminalForm}
          onSubmit={methods.handleSubmit(onSubmit)}>
          <FormLabel fontSize="xl" fontWeight="bold">
            <Trans>Open File</Trans>
          </FormLabel>
          <FileDownloadButton
            label={"Download Preliminary Form"}
            getFile={(token: string) =>
              getGaStatewidePreliminaryAuthForm(token)
            }
            onError={() => {
              triggerOtesModal({
                bodyText: t`There was an error downloading the file, please try again.`,
              });
            }}
          />
          <br />
          <FormLabel fontSize="xl" fontWeight="bold">
            <Trans>Required Information</Trans>
          </FormLabel>
          <FormSelect
            fieldId="sex"
            label="Sex"
            options={GA_STATEWIDE_SEX_TYPES}
            defaultValue={defaultValues.sex.trim() ?? ""}
            required={true}
            selectType="Option"
          />
          <FormLabel fontSize="sm" color="gray">
            <Trans>
              By selecting <b>I decline to answer</b> the State of Georgia will
              reject the consent resulting in the criminal search not being
              processed.
            </Trans>
          </FormLabel>
          <FormInput
            minLength={1}
            maxLength={50}
            fieldId="race"
            label="Race"
            required={true}
          />
          <br />
          <EmploymentPurposesRadioGroup />
          <br />
          <FormSignaturePad
            label="Signature"
            labelSize="xl"
            labelWeight="bold"
            required={true}
            setSignature={setSignature}
          />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={gaStatewideCriminalForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
