import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import {
  CANADIAN_PROVINCES,
  COUNTRIES,
  COUNTRY_UNITED_STATES,
  STATES,
} from "tasks/apiConstants";
import { t } from "@lingui/macro";
import { useFormContext, useWatch } from "react-hook-form";
import { getRegionMode } from "components/common/form/formHelpers";
import { OptionalApiIdFormFields } from "components/tasks/taskHelpers";

export interface AddressFormFields extends OptionalApiIdFormFields {
  country: string;
  street: string;
  city: string;
  state: string;
  postalCode?: string;
}

export interface AddressEntryFormProps {
  addressId?: string | number;
  showPostalCode?: boolean;
  required?: {
    country?: boolean;
    street?: boolean;
    city?: boolean;
    state?: boolean;
    postalCode?: boolean;
  };
  // allows parent component to control the names of the individual RHF fields
  fieldIds?: {
    country: string;
    street: string;
    city: string;
    state: string;
    postalCode?: string;
  };
}

export const EMPTY_ADDRESS: AddressFormFields = {
  country: COUNTRY_UNITED_STATES,
  street: "",
  city: "",
  state: "",
  postalCode: "",
};

export default function AddressForm({
  addressId,
  showPostalCode = true,
  required,
  fieldIds = {
    country: "country",
    street: "street",
    city: "city",
    state: "state",
    postalCode: "postalCode",
  },
}: AddressEntryFormProps): JSX.Element {
  const { control, setValue } = useFormContext();
  const getFieldId = (
    field: "country" | "street" | "city" | "state" | "postalCode",
  ) => (addressId ? `${addressId}.${fieldIds[field]}` : `${fieldIds[field]}`);

  const selectedCountry = useWatch({
    control,
    name: getFieldId("country"),
  });

  const regionMode = getRegionMode(selectedCountry ?? COUNTRY_UNITED_STATES);

  return (
    <>
      <FormSelect
        fieldId={getFieldId("country")}
        label={t`Country`}
        placeHolder={t`Select a country`}
        options={COUNTRIES}
        defaultValue={COUNTRY_UNITED_STATES}
        onChange={() => {
          //clear the state value when the country changes
          setValue(addressId ? `${addressId}.state` : "state", "");
        }}
        required={required?.country}
      />
      <FormInput
        fieldId={getFieldId("street")}
        label={t`Street Address`}
        required={required?.street}
        maxLength={100}
      />
      <FormInput
        fieldId={getFieldId("city")}
        label={t`City`}
        required={required?.city}
        maxLength={100}
      />
      {regionMode === "state" || regionMode === "province" ? (
        <FormSelect
          fieldId={getFieldId("state")}
          placeHolder={
            regionMode === "state"
              ? t({ comment: "for US only", message: `Select a state` })
              : t({ comment: "for Canada only", message: `Select a Province` })
          }
          label={regionMode === "state" ? t`State` : t`Province`}
          options={regionMode === "state" ? STATES : CANADIAN_PROVINCES}
          required={required?.state}
        />
      ) : (
        <FormInput
          fieldId={getFieldId("state")}
          label={t`State/Province`}
          required={false}
          maxLength={100}
        />
      )}
      {showPostalCode && (
        <FormInput
          fieldId={getFieldId("postalCode")}
          //a true/false value for required.postalCode override regionMode to determine label
          label={t`Postal Code`}
          minLength={regionMode === "state" ? 5 : 3}
          maxLength={10}
          //a true/false value for required.postalCode overrides the regionMode
          required={
            required?.postalCode !== undefined
              ? required.postalCode
              : regionMode === "state" || regionMode === "province"
          }
        />
      )}
    </>
  );
}
