import { Link, Text } from "@chakra-ui/react";

export default function CopyrightFooter({
  color,
}: {
  color?: string;
}): JSX.Element {
  return (
    <Text color={`${color || "black"}`} textAlign="center" fontSize="xs">
      &copy;&nbsp;
      <Link
        color="orange.600"
        isExternal
        href="https://www.orangetreescreening.com/candidate-support">
        Orange Tree Employment Screening
      </Link>
      &nbsp;- A{" "}
      <Link color="orange.600" isExternal href="https://www.thepbsa.org/">
        PBSA
      </Link>
      &nbsp;accredited national and global provider of comprehensive background
      screening services.
    </Text>
  );
}
