import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import GraduationStatusForm from "./GraduationStatusForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type GraduationStatusApiForm = {
  formFields: GraduationStatusApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface GraduationStatusFormFields {
  didYouGraduate?: string;
  graduationDateDate?: string;
  doSave?: boolean;
}

interface GraduationStatusApiFormFields extends InterviewFormFields {
  didYouGraduate?: boolean;
  graduationDate?: string;
}

interface GraduationStatusTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: GraduationStatusApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function GraduationStatusTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: GraduationStatusTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    didYouGraduate: form.formFields?.didYouGraduate,
    graduationDate: form.formFields?.graduationDate,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <GraduationStatusForm
      defaultValues={{
        [formParameters.formId]: {
          didYouGraduate: formFields.didYouGraduate,
          graduationDateDate: formFields.graduationDate,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
