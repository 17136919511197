import { t } from "@lingui/macro";

export const EMAIL_ADDRESS = {
  LABEL: t`Email Address`,
  PATTERN: /^(.+)@(.+)$/,
  PLACEHOLDER: "john.doe@example.com",
  PATTERN_MESSAGE: t`Must provide a valid email address.`,
  REQUIRED_MESSAGE: t`Must provide an email address.`,
};

export const PHONE_NUMBER = {
  PARENS_PATTERN: /^\s*[(]?[1-9]\d{2}[)]?[-]?\s*\d{3}[-]?\d{4}\s*$/,
  PARENS_PLACEHOLDER: "(###) ###-####",
  PARENS_MASK: "(***) ***-****",

  PATTERN_MESSAGE: t`Must provide a valid phone number.`,
  REQUIRED_MESSAGE: t`Must provide a phone number.`,

  //Chrome on iPhone crashes the page on autofill of this input
  AUTO_COMPLETE: "chrome-off",
};

export const SOCIAL_SECURITY_NUMBER = {
  LABEL: t`Social Security Number`,
  PATTERN: /^\d{3}-\d{2}-\d{4}$/,
  PLACEHOLDER: "###-##-####",
  MASK: "***-**-****",
  PATTERN_MESSAGE: t`Must provide a valid social security number.`,
  REQUIRED_MESSAGE: t`Must provide a social security number.`,
};

export const AIRMAN_CERTIFICATE_NUMBER = {
  LABEL: t`Airman Certificate Number`,
  PATTERN: /^[0-9]*$/,
  PATTERN_MESSAGE: t`Must provide a valid Airman Certificate Number.`,
  REQUIRED_MESSAGE: t`Must provide an Airman Certificate Number.`,

  AUTO_COMPLETE: "chrome-off",
};
