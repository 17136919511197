import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import UploadForm, {
  DocumentTypeProperties,
  UploadFormFields,
} from "components/tasks/interviews/attachment/UploadForm";
import { SubmitHandler } from "react-hook-form";
import { Box, Heading } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { getInterviewDoc } from "tasks/tasksApi";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { Markup } from "interweave";
import useOtesModal from "error/useOtesModal";

interface UploadApiFormMessages extends InterviewFormMessages {
  attachmentQuestion: string;
}

export interface UploadApiFormParameters extends InterviewFormParameters {
  specialFormUid?: string;
  authFormApplicationUid?: string;
  allowNotAvailableSelection: boolean;
  showPersonalInformationWarning: boolean;
  documentTypeSelectionRequired: boolean;
  maximumFileSizeMegabytes: string;
  minimumNumberOfFiles: number;
  supportedMimeTypesForUpload: string[];
  documentTypes: DocumentTypeProperties[];
  candidateDownloadFileExternalGuid?: string;
}

export type AuthorizationDownloadApiSubForm = InterviewApiSubForm<
  InterviewFormFields,
  UploadApiFormMessages,
  UploadApiFormParameters
>;

interface UploadInterviewTaskProps extends TaskComponentProps {
  //For Interviews, TaskIDs are UIDs, hence the string type here
  applicationUid: string;
  taskId: string;
  data: any;
}

export default function UploadInterviewTask({
  applicationUid,
  taskId,
  data,
  goToStatusCheck,
}: UploadInterviewTaskProps): JSX.Element {
  const { triggerOtesModal } = useOtesModal();

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const form: AuthorizationDownloadApiSubForm = data?.forms[0];
  const { formParameters, formMessages } = form;

  const onSubmit: SubmitHandler<UploadFormFields> = async (
    fields: UploadFormFields,
  ) => {
    const { notAvailable, documentType, comment } = fields;

    const formPayload = {
      DOCUMENT: {
        NOT_AVAILABLE: notAvailable,
      },
      ...(documentType && { DOC_TYPE: documentType }),
      ...(comment && { COMMENT: comment }),
    };

    const payload = {
      APPLICATION_UID: applicationUid,
      TASK_ID: taskId,
      [`${formParameters.formId}`]: formPayload,
    };

    mutateTaskData(payload);
  };

  const hasDownload =
    !!formParameters.authFormApplicationUid ||
    !!formParameters.specialFormUid ||
    !!formParameters.candidateDownloadFileExternalGuid;

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading textAlign="center" p={1} as="h1">
        {data?.taskMessages.taskName}
      </Heading>
      <Box py={1}>
        <Markup content={formMessages?.formInstructions} />
      </Box>
      {hasDownload && (
        <FileDownloadButton
          getFile={(token: string) =>
            getInterviewDoc(token, {
              authFormApplicationUid: formParameters.authFormApplicationUid,
              specialFormUid: formParameters.specialFormUid,
              candidateDownloadFileExternalGuid:
                formParameters.candidateDownloadFileExternalGuid,
            })
          }
          label={t`Download`}
          onError={() => {
            triggerOtesModal({
              bodyText: t`There was an error downloading the file, please try again.`,
            });
          }}
        />
      )}
      <UploadForm
        documentTypes={formParameters?.documentTypes || []}
        supportNotAvailable={formParameters?.allowNotAvailableSelection}
        showPersonalInfoWarning={formParameters?.showPersonalInformationWarning}
        docTypeRequired={formParameters?.documentTypeSelectionRequired}
        // ATTACHMENT MAX SIZE
        maxFileSizeMB={32}
        minNumFiles={formParameters?.minimumNumberOfFiles || 1}
        allowedFileTypes={formParameters?.supportedMimeTypesForUpload || []}
        attachmentQuestion={formMessages.attachmentQuestion}
        endpoint={`/api/rest/v1/application/task/${taskId}/upload/ATTACHMENT`}
        onSubmit={onSubmit}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
