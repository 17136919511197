import {
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  ApplicantEntryTaskId,
  getLegalDocByUid,
  SingleFormTaskDef,
  verifyAllDownloaded,
} from "tasks/tasksApi";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import taskHeadings from "tasks/taskHeadings";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Text,
} from "@chakra-ui/react";
import { Plural, t, Trans } from "@lingui/macro";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { Controller, useForm } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import { useRef, useState } from "react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import TaskButtons from "components/common/basic/TaskButtons";
import useAuth from "auth/useAuth";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import useOtesModal from "error/useOtesModal";

interface ApiDocument {
  uid: string;
  title: string;
  hasBeenAccessed: boolean;
}

interface DisclosuresApiFormFields extends ApiFormFields {
  documents: ApiDocument[];
}

interface DisclosuresApiFormParameters extends ApiFormParameters {
  allLegalDocumentsDownloaded: boolean;
}

interface SignatureFormValues {
  signature: string;
}

export default function DisclosuresTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_SPECIFIC_DISCLOSURES;
  const sigPad = useRef<SignaturePad | null>(null);
  const clearCanvas = () => sigPad.current?.clear();

  const { triggerOtesModal } = useOtesModal();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<SignatureFormValues>({ mode: "onBlur" });

  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      DisclosuresApiFormFields,
      ApiFormMessages,
      DisclosuresApiFormParameters
    >
  >({
    taskId,
    goToStatusCheck,
  });

  const {
    mutate,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const { token } = useAuth();

  const submitSignature = ({ signature }: SignatureFormValues) => {
    mutate({ formFields: { DOCUMENT: { SIGNATURE: signature } } });
  };

  const documents = data?.forms[0]?.formFields?.documents;
  const allDocs = new Set<string>();
  const initiallyAccessedDocs = new Set<string>();

  documents?.forEach((document) => {
    allDocs.add(document.uid);

    if (document.hasBeenAccessed) {
      initiallyAccessedDocs.add(document.uid);
    }
  });

  const [accessedDocs, setAccessedDocs] = useState<Set<string>>(
    initiallyAccessedDocs,
  );

  if (
    accessedDocs.size === 0 &&
    initiallyAccessedDocs.size > accessedDocs.size
  ) {
    setAccessedDocs(initiallyAccessedDocs);
  }

  const disclosuresForm = "disclosuresForm";

  if (isFetching || isStoring) {
    return (
      <TaskSpinnerOverlay
        isStoring={isStoring}
        saveOnly={variables?.saveOnly}
      />
    );
  } else {
    return (
      <>
        <Heading p={1} as="h1">
          {taskHeadings[taskId].long}
        </Heading>
        <Text pb="4">
          <Plural
            value={documents?.length}
            one={
              <Trans>
                Please download and review the following disclosure.
              </Trans>
            }
            other={
              <Trans>
                Please download and review the following disclosures.
              </Trans>
            }
          />
        </Text>
        <Box>
          {documents?.map((document) => {
            return (
              <HStack key={document.uid}>
                <FileDownloadButton
                  key={document.uid}
                  getFile={(token: string) =>
                    getLegalDocByUid(document.uid, token)
                  }
                  label={document.title}
                  onError={() => {
                    triggerOtesModal({
                      bodyText: t`There was an error downloading the file, please try again.`,
                    });
                  }}
                  postDownload={() => {
                    setAccessedDocs(
                      (existing: Set<string>) =>
                        new Set<string>([...existing, document.uid]),
                    );

                    verifyAllDownloaded(token, `${taskId}`).then(
                      ({ data: { data: respData } }) => {
                        const { allDocsDownloaded } = respData;
                        if (allDocsDownloaded) {
                          documents.forEach((document) => {
                            setAccessedDocs(
                              (existing: Set<string>) =>
                                new Set<string>([...existing, document.uid]),
                            );
                          });
                        }
                      },
                    );
                  }}
                />
                {accessedDocs.has(document.uid) ? (
                  <>
                    <CheckCircleIcon color={"green"} />
                  </>
                ) : (
                  <>
                    <WarningIcon color="orange.500" />
                  </>
                )}
              </HStack>
            );
          })}
        </Box>
        <Text>
          <Trans>
            By signing below I acknowledge receipt of the additional disclosures
            above.
          </Trans>
        </Text>
        <Box width={"100%"} maxW={"container.sm"}>
          <Heading color="orange" as="h3" size="md" pt="1rem">
            {<Trans>Signature</Trans>}
          </Heading>
          <form id={disclosuresForm} onSubmit={handleSubmit(submitSignature)}>
            <FormControl pt={4} isInvalid={!!errors?.signature} width={"auto"}>
              <FormLabel
                htmlFor="signature"
                fontSize={"sm"}
                mx={0}
                className="requiredField">
                <Trans>Use your mouse or finger to draw your signature</Trans>
              </FormLabel>
              <Controller
                name="signature"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SignaturePad
                    ref={sigPad}
                    onEnd={() =>
                      //at the end of each stroke update the field value to the PNG string
                      field.onChange(
                        sigPad.current?.getTrimmedCanvas().toDataURL(),
                      )
                    }
                    canvasProps={ThemeConstants.SIGNATURE_CANVAS_PROPS}
                  />
                )}
              />
              <FormErrorMessage>
                <Trans>
                  Signature is empty or cannot be processed. Please clear the
                  signature and try again.
                </Trans>
              </FormErrorMessage>
            </FormControl>
            <Flex direction={"row"} py={4}>
              <Button
                color="gray.600"
                onClick={() => {
                  clearCanvas();
                  reset();
                }}>
                <Trans>Clear</Trans>
              </Button>
            </Flex>
            <TaskButtons
              disabledSubmit={allDocs.size > accessedDocs.size}
              onBack={goToStatusCheck}
              form={disclosuresForm}
              mt={"1rem"}
            />
          </form>
        </Box>
      </>
    );
  }
}
