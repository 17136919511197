import { CheckCircleIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export interface OtesModalProps {
  headerText: string;
  bodyText: string;
  buttonText: string;
  isError?: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function OtesModal({
  headerText,
  bodyText,
  buttonText,
  isError = false,
  isOpen,
  onClose,
}: OtesModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mt="90px" mx="5px">
        <Flex p="1px" direction="row" alignItems={"center"} m={"0 auto"}>
          {isError ? (
            <WarningIcon w="auto" height="35px" color="red.500" />
          ) : (
            <CheckCircleIcon w="auto" height="35px" color="green" />
          )}
          <ModalHeader px="0.5rem" as="h3" fontWeight={"bold"}>
            {headerText}
          </ModalHeader>
        </Flex>
        <ModalBody textAlign="left">{bodyText}</ModalBody>
        <ModalFooter textAlign="center">
          <Button colorScheme="orange" onClick={onClose} margin="auto">
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
