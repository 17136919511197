import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from "@chakra-ui/react";
import { Control, Controller, get, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";
import parse from "html-react-parser";

export interface FormRadioGroupProps extends Omit<RadioGroupProps, "children"> {
  fieldId: string;
  groupLabel: string;
  required?: boolean;
  helperText?: string;
  disabled?: boolean;
  radios: string[] | [string, string | number][];
  control?: Control;
  defaultValue?: string;
  hidden?: boolean;
  onChange?: (e: any) => void;
  vertical?: boolean;
}

export default function FormRadioGroup(
  props: FormRadioGroupProps,
): JSX.Element {
  const {
    fieldId,
    groupLabel,
    required = true,
    helperText,
    disabled,
    radios,
    control,
    hidden = false,
    onChange,
    defaultValue,
    ...rest
  } = props;
  const { formState, getValues } = useFormContext();
  const { fontSize, fontWeight, vertical, size = "lg", paddingLeft } = rest;

  const inputIsAccessible = !hidden && !disabled;
  const validateRequired = inputIsAccessible && required;
  return (
    <FormControl
      hidden={hidden}
      isInvalid={get(formState.errors, fieldId)}
      py="0.5rem">
      <FormLabel
        id={`${fieldId}.label`}
        fontSize={fontSize}
        fontWeight={fontWeight}
        className={validateRequired ? "requiredField" : "optionalField"}>
        {parse(groupLabel)}
      </FormLabel>
      <Controller
        control={control}
        name={fieldId}
        rules={{
          required: { value: validateRequired, message: t`This is required` },
        }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <RadioGroup
            aria-label={`${fieldId}.label`}
            name={fieldId}
            isDisabled={disabled}
            defaultValue={
              getValues(fieldId) !== undefined &&
              getValues(fieldId)?.toString() !== ""
                ? getValues(fieldId)?.toString()
                : defaultValue
            }
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}>
            <Stack
              alignItems="normal"
              direction={vertical ? "column" : "row"}
              paddingLeft={paddingLeft}>
              {radios.map((radio, index) => {
                //create separate label & value constants based on typeof radio
                const [label, value] =
                  typeof radio === "string" ? [radio, radio] : radio;

                return (
                  <Radio
                    // verbose key helps in debugging
                    key={`${fieldId}.${value}`}
                    p={1}
                    size={size}
                    value={value}
                    borderColor={"gray.600"}>
                    {label}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>
        {get(formState.errors, `${fieldId}.message`)}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
