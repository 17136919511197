import { Step, Steps } from "chakra-ui-steps";
import { ApplicationStatus } from "tasks/tasksApi";
import { Heading } from "@chakra-ui/react";
import { Plural, t, Trans } from "@lingui/macro";

export interface ProgressProps {
  status: ApplicationStatus;
  daysRemaining: number;
}

export default function Progress({
  status,
  daysRemaining,
}: ProgressProps): JSX.Element {
  const steps = [
    { label: t`Collecting Info` },
    { label: t`Processing` },
    { label: t`Reviewing` },
    { label: t`Complete` },
  ];

  const currentStep = steps.findIndex((step) =>
    step.label.toLowerCase().includes(status.toLowerCase()),
  );

  return (
    <>
      <Heading as="h2" size="lg" p="0.5rem">
        {status === "CANCELLED" ? (
          <Trans>ETA: Cancelled</Trans>
        ) : daysRemaining < 0 ? (
          <Trans>ETA: Pending</Trans>
        ) : (
          <Plural
            value={daysRemaining}
            _0={<Trans>ETA: Complete</Trans>}
            one="ETA: # day"
            other="ETA: # days"
          />
        )}
      </Heading>
      <Steps
        size="sm"
        activeStep={
          currentStep !== steps.length - 1 ? currentStep : steps.length
        }
        p="0.5rem"
        pl={0}
        labelOrientation={"vertical"}
        responsive={false}
        minW={"320px"}>
        {steps.map(({ label }, index) => (
          <Step label={label} key={label} />
        ))}
      </Steps>
    </>
  );
}
