import Header from "components/common/basic/Header";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Link,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RoutedLink, useHistory } from "react-router-dom";
import ApplicantTheme from "applicantTheme/ApplicantTheme";
import { FormProvider, useForm } from "react-hook-form";
import useAuth from "auth/useAuth";
import { t, Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import { Logo } from "components/common/basic/Logo";
import { getAcquisitionClientName } from "applicantTheme/themeHelpers";
import FormCheckbox from "components/common/form/FormCheckbox";
import { useTermsOfServiceMutation } from "auth/authQueries";

interface QuickGuideProps {
  theme: ApplicantTheme;
}

interface QuickGuideFormValues {
  tosAgree: boolean;
  consumerReportOptIn: boolean;
}

type QuickGuidePage =
  | "termsOfService"
  | "updateInfo"
  | "sendDocuments"
  | "checkStatus";

export default function TermsOfServiceAndQuickGuide({
  theme,
}: QuickGuideProps): JSX.Element | null {
  const methods = useForm<QuickGuideFormValues>();
  const { updateToken } = useAuth();
  const [currentPage, setCurrentPage] =
    useState<QuickGuidePage>("termsOfService");
  const providerName = getAcquisitionClientName(theme?.acquisitionSource);
  const history = useHistory();

  useEffect(() => {
    switch (currentPage) {
      default:
      case "termsOfService":
        conditionalHistoryReplace(history, AppPath.TERMS_OF_SERVICE);
        break;
      case "updateInfo":
      case "sendDocuments":
      case "checkStatus":
        conditionalHistoryReplace(history, AppPath.QUICKGUIDE);
        break;
    }
  });

  const setCurrentPageToUpdateInfo = () => {
    setCurrentPage("updateInfo");
  };

  const {
    isLoading,
    mutate,
    data: updatedToken,
  } = useTermsOfServiceMutation(setCurrentPageToUpdateInfo);

  const onFormSubmit = (data: QuickGuideFormValues) => {
    mutate({
      consumerReportOptIn: data.consumerReportOptIn,
    });
  };

  const TermsOfServicePage = () => (
    <Flex direction="column">
      <Center paddingTop="3">
        <Spacer h={92} />
        <Image maxHeight={92} src={theme?.logo?.medium} />
        <Spacer h={92} />
      </Center>
      <Center>
        <Heading p="5" as="h1" fontSize="1.3em">
          <Trans>Welcome, </Trans> {theme?.applicantName}!
        </Heading>
      </Center>
      <FormProvider {...methods}>
        <form id="tosForm" onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Center>
            <Box>
              <Text p="0.5rem">
                <Trans>
                  {`${providerName}`} will be performing your background screen.
                </Trans>
              </Text>
              <Text p="0.5rem">
                <Trans>
                  This site will help us stay in touch and keep you up-to-date.
                </Trans>
              </Text>
              <Text p="0.5rem">
                <Trans>
                  Please take a moment to view the introduction and to sign in.
                </Trans>
              </Text>
              <ul>
                <li>
                  <Link isExternal as={RoutedLink} to="/termsconditions">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    isExternal
                    href="https://www.orangetreescreening.com/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
              <FormCheckbox
                fieldId="tosAgree"
                label=""
                required={true}
                requiredMessage={t`Please check the box to agree to the Terms of Service and Privacy Policy.`}>
                <Trans>
                  I agree to the <b>Terms of Service</b> and{" "}
                  <b>Privacy Policy</b>.
                </Trans>
              </FormCheckbox>
              <FormCheckbox
                fieldId={"consumerReportOptIn"}
                label={t`California, Minnesota or Oklahoma candidates: Check the box to receive a copy of the background report.`}
              />
            </Box>
          </Center>
          <Center p={3}>
            <Button colorScheme="orange" type="submit">
              <Trans>Next</Trans>
            </Button>
          </Center>
        </form>
      </FormProvider>
      <Center>
        <Text>Powered by</Text>
      </Center>
      <Center>
        <Logo color="orange" acquisitionSource={theme?.acquisitionSource} />
      </Center>
    </Flex>
  );

  const UpdateInfoPage = () => (
    <VStack direction="column">
      <Center paddingTop="3">
        <Spacer h={92} />
        <Image maxHeight={92} src="images/userInfo.PNG" />
        <Spacer h={92} />
      </Center>
      <Center>
        <Heading p="3" as="h1" fontSize="1.3em">
          <Trans>Update Information</Trans>
        </Heading>
      </Center>
      <Center>
        <Box>
          <Text p="0.5rem">
            <Trans>Avoid delays in your background screen!</Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>
              This site will help you provide any required information,
              on-the-fly, to keep things moving.
            </Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>
              Click or tap the &ldquo;Next&rdquo; button to learn more!
            </Trans>
          </Text>
        </Box>
      </Center>
      <Center p={3}>
        <Button
          colorScheme="orange"
          onClick={() => setCurrentPage("sendDocuments")}>
          <Trans>Next</Trans>
        </Button>
      </Center>
    </VStack>
  );

  const SendDocumentsPage = () => (
    <VStack direction="column">
      <Center paddingTop="3">
        <Spacer h={92} />
        <Image maxHeight={92} src="images/senddoc.PNG" />
        <Spacer h={92} />
      </Center>
      <Center>
        <Heading p="3" as="h1" fontSize="1.3em">
          <Trans>Send Documents</Trans>
        </Heading>
      </Center>
      <Center>
        <Box>
          <Text p="0.5rem">
            <Trans>
              This site will help you sign a document or capture required
              paperwork.
            </Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>
              Then you can upload directly to complete your background screen.
            </Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>
              Click or tap the &ldquo;Next&rdquo; button to keep going!
            </Trans>
          </Text>
        </Box>
      </Center>
      <Center p={3}>
        <Button
          colorScheme="orange"
          onClick={() => setCurrentPage("checkStatus")}>
          <Trans>Next</Trans>
        </Button>
      </Center>
    </VStack>
  );

  const CheckStatusQGPage = () => (
    <VStack direction="column">
      <Center paddingTop="3">
        <Spacer h={92} />
        <Image maxWidth={92} src="images/status.PNG" />
        <Spacer h={92} />
      </Center>
      <Center>
        <Heading p="3" as="h1" fontSize="1.3em">
          <Trans>Check Status</Trans>
        </Heading>
      </Center>
      <Center>
        <Box>
          <Text p="0.5rem">
            <Trans>
              We will notify you when the background screen is finished or if
              there is anything to do.
            </Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>You can choose how to be notified when you sign in. </Trans>
          </Text>
          <Text p="0.5rem">
            <Trans>
              Click or tap the &ldquo;Next&rdquo; button to sign in!
            </Trans>
          </Text>
        </Box>
      </Center>
      <Center p={3}>
        <Button
          colorScheme="orange"
          onClick={() => {
            updatedToken && updateToken(updatedToken);
          }}>
          <Trans>Next</Trans>
        </Button>
      </Center>
    </VStack>
  );

  let title = "";
  let page;

  if (isLoading) {
    page = <SpinnerOverlay text={"Loading..."} />;
  } else {
    switch (currentPage) {
      default:
      case "termsOfService":
        title = "Welcome";
        page = <TermsOfServicePage />;
        break;
      case "updateInfo":
        title = "Quick Guide";
        page = <UpdateInfoPage />;
        break;
      case "sendDocuments":
        title = "Quick Guide";
        page = <SendDocumentsPage />;
        break;
      case "checkStatus":
        title = "Quick Guide";
        page = <CheckStatusQGPage />;
        break;
    }
  }

  return (
    <>
      {<Header text={title} />}
      {page}
    </>
  );
}
