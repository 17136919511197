export default {
  global: {
    html: {
      height: "100%",
      overflow: "auto",
    },
    form: {
      width: "100%",
    },
    "label.requiredField::after": {
      content: '"*"',
      color: "red",
      fontWeight: "bold",
    },
    ".schemaForm": {
      width: "75%",
    },
    "form.schemaForm div": {
      gridGap: "var(--chakra-space-1)",
    },
    "input, select, checkbox, .chakra-radio__control:not([data-checked]), .chakra-checkbox__control:not([data-checked])":
      {
        bgColor: "white !important",
      },
    ".chakra-radio__control:not([data-checked])": {
      bgColor: "white !important",
    },
    "input[aria-invalid=true], select[aria-invalid=true]": {
      bgColor: "#ffe2e2 !important",
    },
    "input[disabled], select[disabled], input[readonly], select[readonly]": {
      bgColor: "lightgray !important",
      opacity: "1 !important",
    },
    "body, div[id=root]": {
      height: "inherit",
    },
    a: {
      color: "#387ef5 !important",
      wordBreak: "break-word",
    },
    ul: {
      paddingLeft: "30px",
    },
    ".barcode": {
      display: "inline",
      width: "40%",
      maxHeight: "120px",
    },
    ".txt-align-center": {
      textAlign: "center",
    },
    ".arrayItemForm": {
      my: "10px",
      p: "5px 15px 15px 15px",
      position: "relative",
      border: "solid gray 1px",
      borderRadius: "5px",
      boxShadow: "black 0px 5px 6px -7px",
      bgColor: "gray.100",
    },
    ".arrayItemForm .closeButton": {
      position: "absolute",
      right: "4px",
      zIndex: 1,
      bgColor: "gray.300",
    },
    canvas: {
      width: "100%",
    },
    "#google_translate_element": {
      position: "absolute",
      top: 70,
      right: 3,
      zIndex: 1,
      border: "1px solid black",
      backgroundColor: "white",
    },
    "#google_translate_element span": {
      display: "none",
    },
    "#google_translate_element select": {
      margin: 0,
    },
  },
};
