import InstitutionForm, {
  EMPTY_INSTITUTION,
  InstitutionFormFields,
} from "components/tasks/education-history/InstitutionForm";
import {
  genericOnSave,
  SavableFormComponentProps,
} from "components/tasks/taskHelpers";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";
import FormCheckbox from "components/common/form/FormCheckbox";

export interface EducationHistoryFormFields {
  noHistory: boolean;
  institutions: InstitutionFormFields[];
  doSave?: boolean;
}

export interface EducationHistoryFormProps
  extends SavableFormComponentProps<EducationHistoryFormFields> {
  allowNoHistorySelection?: boolean;
  eduTypes: string[];
  eduDegrees: {
    [eduType: string]: string[];
  };
}

export default function EducationHistoryForm({
  defaultValues = {
    noHistory: false,
    institutions: [EMPTY_INSTITUTION],
  },
  allowNoHistorySelection = true,
  eduTypes,
  eduDegrees,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: EducationHistoryFormProps): JSX.Element {
  // if necessary, initialize defaultValues with an empty institution
  if (!defaultValues.institutions.length) {
    defaultValues.institutions = [EMPTY_INSTITUTION];
  }

  const methods = useForm<EducationHistoryFormFields>({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "institutions",
  });

  const showInstitutions = !methods.watch("noHistory");

  const educationHistoryForm = "educationHistoryForm";

  //preset all HighSchool degreeType to 'Diploma' and GED degreeType to 'GED'
  //set degreeType to undefined if not in eduDegrees.Default
  fields.map((apiInstitution, index) => {
    if (apiInstitution.educationType) {
      const eduTypeDegrees =
        eduDegrees[apiInstitution.educationType] || eduDegrees.Default;
      const formInstitution = defaultValues.institutions[index];

      if (formInstitution) {
        if (eduTypeDegrees && eduTypeDegrees.length == 1) {
          formInstitution.degreeType = eduTypeDegrees[0] || "";
        } else if (
          eduTypeDegrees.length > 1 &&
          !eduTypeDegrees.includes(apiInstitution.degreeType || "")
        ) {
          formInstitution.degreeType = undefined;
        }
      }
    }
  });

  return (
    <VStack w="100%" p={1}>
      <FormProvider {...methods}>
        <form
          id={educationHistoryForm}
          onSubmit={methods.handleSubmit(onSubmit)}>
          {allowNoHistorySelection && (
            <Center borderBottom={"1px"} ml="50px" mr="50px">
              <FormCheckbox
                fieldId={"noHistory"}
                label={t`No Education History`}
              />
            </Center>
          )}
          {showInstitutions && (
            <>
              {fields.map((institution, index) => (
                <Box key={institution.id}>
                  <InstitutionForm
                    institutionId={`institutions.${index}`}
                    eduTypes={eduTypes}
                    eduDegrees={eduDegrees}
                    showRemoveButton={fields.length > 1}
                    onRemoveClick={() => remove(index)}
                  />
                </Box>
              ))}
              <Box pt={4}>
                <Button
                  color="gray.600"
                  onClick={() => append(EMPTY_INSTITUTION)}>
                  + <Trans>Add Another Education</Trans>
                </Button>
              </Box>
            </>
          )}
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={educationHistoryForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
