import { NonSavableFormComponentProps } from "components/tasks/taskHelpers";
import { FormProvider, useForm } from "react-hook-form";
import {
  RegistryWidgetsType,
  RJSFSchema,
  SubmitButtonProps,
  UiSchema,
} from "@rjsf/utils";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/chakra-ui";
import validator from "@rjsf/validator-ajv8";
import TaskButtons from "components/common/basic/TaskButtons";
import SpecialFormInput from "components/common/special-form/SpecialFormInput";
import SpecialFormRadioGroup from "components/common/special-form/SpecialFormRadioGroup";
import SpecialFormSelect from "components/common/special-form/SpecialFormSelect";
import SpecialFormSignaturePad from "components/common/special-form/SpecialFormSignaturePad";
import SpecialFormText from "components/common/special-form/SpecialFormText";
import SpecialFormPreliminaryDownloadButton from "components/common/special-form/SpecialFormPreliminaryDownloadButton";
import SpecialFormPreviewDownloadButton from "components/common/special-form/SpecialFormPreviewDownloadButton";
import { useEffect, useState } from "react";

const widgets: RegistryWidgetsType = {
  specialFormInput: SpecialFormInput,
  specialFormRadioGroup: SpecialFormRadioGroup,
  specialFormSelect: SpecialFormSelect,
  signature: SpecialFormSignaturePad,
  specialFormText: SpecialFormText,
  preliminaryFormDownloadButton: SpecialFormPreliminaryDownloadButton,
  postFormDownloadButton: SpecialFormPreviewDownloadButton,
};

export interface SpecialFormFields {
  specialFormId?: string;
  schema: RJSFSchema | any;
  uiSchema: UiSchema | any;
  formData: JSON | any;
}

export default function SpecialFormForm({
  defaultValues,
  onSubmit,
  goToStatusCheck,
}: NonSavableFormComponentProps<SpecialFormFields>): JSX.Element {
  const methods = useForm<SpecialFormFields>({
    defaultValues,
  });

  const { specialFormId, schema, uiSchema, formData } = defaultValues;
  const [persistentFormData, setPersistentFormData] = useState<JSON>();

  const validateAndSubmit = async (data: IChangeEvent) => {
    const { schema, formData, uiSchema } = data;

    //update persistentFormData on submit
    //rjsf clears out form data by default on submit, this is the workaround
    setPersistentFormData(formData);

    //validate using react hook form for UI consistency
    const result = await methods.trigger();

    //Prevent submission if signature is blank value
    const signatureNotBlank = formData.signature && formData.signature !== "";

    if (result === true && signatureNotBlank) {
      onSubmit({
        schema,
        uiSchema,
        formData,
      });
    }
  };

  //Add our standard TaskButtons to RJSF form
  function SubmitButton(props: SubmitButtonProps) {
    return <></>;
  }

  const updateFormData = (event: IChangeEvent) => {
    methods.setValue("formData", event.formData);
  };

  //initialize persistent formData state
  useEffect(() => {
    setPersistentFormData(formData);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Form
          id={specialFormId}
          schema={schema}
          uiSchema={uiSchema}
          formData={persistentFormData}
          validator={validator}
          widgets={widgets}
          templates={{ ButtonTemplates: { SubmitButton } }}
          className={"schemaForm"}
          noValidate={true}
          onChange={updateFormData}
          onSubmit={validateAndSubmit}
        />
        <TaskButtons
          onBack={goToStatusCheck}
          form={defaultValues.specialFormId}
        />
      </FormProvider>
    </>
  );
}
