export const isDevOrQA = () => {
  const hostname = window.location.hostname;
  return (
    hostname === "localhost" ||
    hostname === "candidateconnectui.dev-01.otes-dev.com" ||
    hostname === "candidateconnectui.qa-01.otes-test.com"
  );
};

/**
 * @param devOrQAValue - returned value if in DEV or QA
 * @param prodValue - returned value if in PROD
 */
export const devOrQAProdValue = (devOrQAValue: string, prodValue: string) => {
  return isDevOrQA() ? devOrQAValue : prodValue;
};
