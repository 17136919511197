import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import Logout from "components/common/menu/Logout";
import HelpDeskTicket from "./HelpDeskTicket";
import { Trans } from "@lingui/macro";
import Debug from "components/common/menu/debug/Debug";
import { useEffect, useState } from "react";

interface MenuDrawerProps {
  isOpen: boolean;

  onClose(): void;

  onOpen(): void;
}

export default function MenuDrawer({
  isOpen,
  onOpen,
  onClose,
}: MenuDrawerProps): JSX.Element {
  const [drawerMarginTop, setDrawerMarginTop] = useState<number>(0);
  const hostname = window.location.hostname;
  const showDebug =
    hostname === "localhost" ||
    hostname === "candidateconnectui.dev-01.otes-dev.com" ||
    hostname === "candidateconnectui.qa-01.otes-test.com";

  // This effect detects when the google translate widget has been activated and moves
  // the drawer down 10px so that the exit button is visible.
  useEffect(() => {
    if (document.documentElement.className.match("translated")) {
      setDrawerMarginTop(10);
    } else {
      setDrawerMarginTop(0);
    }

    const observer = new MutationObserver(function (event) {
      if (document.documentElement.className.match("translated")) {
        setDrawerMarginTop(10);
      } else {
        setDrawerMarginTop(0);
      }
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
      childList: false,
      characterData: false,
    });
  }, []);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"sm"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton mt={drawerMarginTop} />
        <DrawerBody mt={drawerMarginTop}>
          <Accordion mt={12} allowToggle>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                <Box flex="1" textAlign="left">
                  Contact Us
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={3} align={"left"}>
                  <Text color="orange.600" fontWeight={"bold"}>
                    Contact Us
                  </Text>
                  <HelpDeskTicket isDispute={false} />
                  <Text>
                    Phone:&nbsp;<strong>1-888-222-0667</strong>
                  </Text>
                  <Text>
                    Email:&nbsp;
                    <strong>
                      <Link
                        href="mailto:candidateconnect@otes.com"
                        color="blue.600">
                        CandidateConnect@otes.com
                      </Link>
                    </strong>
                  </Text>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                <Box flex="1" textAlign="left">
                  Dispute
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={3} align={"left"}>
                  <Text color="orange.600" fontWeight={"bold"}>
                    Start Dispute Process
                  </Text>
                  <Text>
                    You may dispute the contents of your completed background
                    screen if you believe them to be inaccurate.
                  </Text>
                  <HelpDeskTicket isDispute={true} />
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                <Box flex="1" textAlign="left">
                  FAQ
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={3} align={"left"}>
                  <Text color="orange.600" fontWeight={"bold"}>
                    Frequently Asked questions
                  </Text>
                  <Text>
                    <Link
                      color="blue.600"
                      isExternal
                      href="https://www.orangetreescreening.com/candidate-support">
                      Click here&nbsp;
                    </Link>
                    for answers to common questions about your background
                    screen.
                  </Text>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                <Box flex="1" textAlign="left">
                  Log Out
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <VStack spacing={3} mb={3} align={"left"}>
                  <Text color="orange.600" fontWeight={"bold"}>
                    <Trans>Log Out</Trans>
                  </Text>
                  <Text>
                    <Trans>
                      Press the button below to log out and cancel automatic log
                      in.
                    </Trans>
                  </Text>
                </VStack>
                <Logout />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <AccordionIcon />
                <Box flex="1" textAlign="left">
                  About
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <Text textAlign="left" fontWeight={"bold"}>
                  &copy; Orange Tree Employment Screening
                </Text>
                <Text textAlign="left">
                  Read:&nbsp;
                  <Link color="blue.600" isExternal href="/termsconditions">
                    Terms of Service
                  </Link>
                  &nbsp;|&nbsp;
                  <Link
                    color="blue.600"
                    isExternal
                    href="https://www.orangetreescreening.com/privacy-policy">
                    Privacy Policy&nbsp;
                  </Link>
                </Text>
              </AccordionPanel>
            </AccordionItem>
            {showDebug && (
              <AccordionItem>
                <AccordionButton>
                  <AccordionIcon />
                  <Box flex="1" textAlign="left">
                    Debug
                  </Box>
                </AccordionButton>
                <AccordionPanel>
                  <Debug />
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
