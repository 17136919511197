import { TaskComponentProps } from "components/tasks/taskHelpers";
import {
  InterviewFormType,
  InterviewTaskDef,
  SpecialFormInterviewFormType,
} from "components/tasks/interviews/interviewApiTypes";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import MultiFormInterviewTask from "components/tasks/interviews/MultiFormInterviewTask";
import { get, SubmitHandler } from "react-hook-form";
import EmplHistInterviewFormAdapter, {
  mapEmplHistApiToUi,
} from "components/tasks/interviews/employment-history/EmplHistInterviewFormAdapter";
import DrugTestTask, {
  DrugTestApiForm,
} from "components/tasks/interviews/drug-test/DrugTestTask";
import AddrHistInterviewFormAdapter, {
  mapAddrHistApiToUi,
} from "components/tasks/interviews/address-history/AddrHistInterviewFormAdapter";
import EduHistInterviewFormAdapter, {
  mapEduHistApiToUi,
} from "components/tasks/interviews/education-history/EduHistInterviewFormAdapter";
import ConfirmCitizenshipTask, {
  ConfirmCitizenshipApiForm,
  ConfirmCitizenshipFormFields,
} from "components/tasks/interviews/confirm-citizenship/ConfirmCitizenshipTask";
import GraduationStatusTask, {
  GraduationStatusApiForm,
  GraduationStatusFormFields,
} from "components/tasks/interviews/graduation-status/GraduationStatusTask";
import AdditionalReferencesTask, {
  AdditionalReferencesApiForm,
  AdditionalReferencesFormFields,
} from "components/tasks/interviews/additional-references/AdditionalReferencesTask";
import DriverLicenseTask, {
  DriverLicenseApiForm,
  DriverLicenseFormFields,
} from "components/tasks/interviews/driver-license/DriverLicenseTask";
import DropdownSelectionTask, {
  DropdownSelectionApiForm,
} from "components/tasks/interviews/dropdown-selection/DropdownSelectionTask";
import ContactInformationTask, {
  ContactInformationApiForm,
} from "components/tasks/interviews/contact-information/ContactInformationTask";
import AuthorizationDownloadTask, {
  AuthorizationDownloadApiForm,
  AuthorizationDownloadFormFields,
} from "components/tasks/interviews/authorization-download/AuthorizationDownloadTask";
import EducationDatesTask, {
  EducationDatesApiForm,
  EducationDatesFormFields,
} from "components/tasks/interviews/education-dates/EducationDatesTask";
import SocialSecurityNumberTask, {
  SocialSecurityNumberApiForm,
} from "components/tasks/interviews/social-security-number/SocialSecurityNumberTask";
import CreditFreezeTask, {
  CreditFreezeApiForm,
} from "components/tasks/interviews/credit-freeze/CreditFreezeTask";
import DateOfBirthTask, {
  DateOfBirthApiForm,
  DateOfBirthFormFields,
} from "components/tasks/interviews/date-of-birth/DateOfBirthTask";
import SupervisorNameTask, {
  SupervisorNameApiForm,
} from "components/tasks/interviews/supervisor-name/SupervisorNameTask";
import ConfirmNameTask, {
  ConfirmNameApiForm,
  ConfirmNameFormFields,
} from "components/tasks/interviews/confirm-name/ConfirmNameTask";
import ReportTask, {
  ReportApiForm,
} from "components/tasks/interviews/report/ReportTask";
import EmploymentDatesTask, {
  EmploymentDatesApiForm,
  EmploymentDatesFormFields,
} from "components/tasks/interviews/employment-dates/EmploymentDatesTask";
import SurveyTask, {
  SurveyApiForm,
} from "components/tasks/interviews/survey/SurveyTask";
import UploadInterviewTask from "components/tasks/interviews/UploadInterviewTask";
import { UploadFormFields } from "components/tasks/interviews/attachment/UploadForm";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import GaStatewideCriminalFormAdapter, {
  mapGaStatewideCriminalApiToUi,
} from "components/tasks/interviews/criminal/GaStatewideCriminalFormAdapter";
import SpecialFormFormAdapter, {
  SpecialFormApiForm,
} from "components/tasks/interviews/special-form/SpecialFormFormAdapter";

interface InterviewTaskProps extends TaskComponentProps {
  //For Interviews, TaskIDs are UIDs, hence the string type here
  taskId: string;
}

interface InterviewTaskFormProps {
  singleFormInterviewType:
    | InterviewFormType
    | SpecialFormInterviewFormType
    | boolean;
}

export interface InterviewSubmitProps {
  doSave?: boolean;

  /**
   * For FormInterviews we will not know the fields or the types of their values.
   * The field names should always be `${formId}.${fieldName}`. This is
   * necessary to properly construct the payload for the Save/Submit API.
   */
  [formIdDotFieldName: string]: unknown;
}

export default function FormInterviewTask({
  taskId,
  goToStatusCheck,
}: InterviewTaskProps): JSX.Element {
  const { data, isFetching, isRefetching } = useGetTaskdef<InterviewTaskDef>({
    taskId,
    goToStatusCheck,
  });

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const valueIfTrue = (
    condition: boolean | string | undefined,
    value: any,
    defaultValue?: any,
  ): any => {
    return `${condition}` === "true" ? value : defaultValue;
  };

  const valueIfNotTrue = (
    condition: boolean | string | undefined,
    value: any,
    defaultValue?: any,
  ): any => {
    return valueIfTrue(condition, defaultValue, value);
  };

  // For mapping the payload to legacy save task
  const constructFormPayload = (form: any, formFields: any) => {
    const {
      formParameters: { formType },
    } = form;
    switch (formType) {
      case InterviewFormType.CITIZENSHIP_DATA: {
        const {
          citizenOfCountry,
          nationalIdentification,
          nationalIdentificationCountry,
        } = formFields as ConfirmCitizenshipFormFields;

        return {
          ARE_YOU_A_CIT: citizenOfCountry,
          COUNTRY: valueIfTrue(citizenOfCountry, nationalIdentificationCountry),
          CITIZENSHIP_ID: nationalIdentification,
        };
      }
      case InterviewFormType.EDUCATION_DATES: {
        const { fromDate, toDate, current } =
          formFields as EducationDatesFormFields;

        return {
          current,
          from: fromDate,
          to: valueIfNotTrue(current, toDate),
        };
      }
      case InterviewFormType.EMPLOYMENT_DATES: {
        const {
          isThisYourPresentEmployer,
          startDate,
          endDate,
          mayWeContactThisEmployer,
        } = formFields as EmploymentDatesFormFields;

        return {
          isThisYourPresentEmployer: isThisYourPresentEmployer,
          startDate: startDate,
          endDate: valueIfNotTrue(isThisYourPresentEmployer, endDate),
          mayWeContactThisEmployer: valueIfTrue(
            isThisYourPresentEmployer,
            mayWeContactThisEmployer,
          ),
        };
      }
      case InterviewFormType.GRAD_STATUS_DATE: {
        const { didYouGraduate, graduationDateDate } =
          formFields as GraduationStatusFormFields;

        const graduationDate = graduationDateDate;

        return {
          DID_YOU_GRADUATE: didYouGraduate,
          GRADUATION_DATE: valueIfTrue(didYouGraduate, graduationDate),
        };
      }
      case InterviewFormType.ADDITIONAL_REFERENCE: {
        const { noAdditionalReferences, additionalReferences } =
          formFields as AdditionalReferencesFormFields;

        return {
          NO_ADDITIONAL_REFERENCES: noAdditionalReferences,
          ADDITIONAL_REFERENCES: valueIfNotTrue(
            noAdditionalReferences,
            additionalReferences?.map((additionalReference, index) => ({
              RELATION_TO_APPLICANT: additionalReference.relationToApplicant,
              NAME: additionalReference.name,
              EMAIL_ADDRESS: additionalReference.emailAddress,
              PHONE: additionalReference.phone,
            })),
          ),
        };
      }
      case InterviewFormType.DRIVER_LICENSE_INFORMATION: {
        const {
          driversLicenseType,
          driversLicenseNumber,
          driversLicenseState,
          dateOfBirthDate,
          firstName,
          middleName,
          lastName,
        } = formFields as DriverLicenseFormFields;

        const isNoLicenseType =
          driversLicenseType === "No License, No State ID";
        const dateOfBirth = isNoLicenseType ? undefined : dateOfBirthDate;

        return {
          DATE_OF_BIRTH: valueIfNotTrue(isNoLicenseType, dateOfBirth),
          DRIVERS_LICENSE: driversLicenseType,
          DRIVERS_LICENSE_STATE: valueIfNotTrue(
            isNoLicenseType,
            driversLicenseState,
          ),
          DRIVERS_LICENSE_NUMBER: valueIfNotTrue(
            isNoLicenseType,
            driversLicenseNumber,
          ),
          FIRST_NAME: valueIfNotTrue(isNoLicenseType, firstName),
          MIDDLE_NAME: valueIfNotTrue(isNoLicenseType, middleName),
          LAST_NAME: valueIfNotTrue(isNoLicenseType, lastName),
        };
      }
      case InterviewFormType.AUTH_FORM_DOWNLOAD: {
        const { signature } = formFields as AuthorizationDownloadFormFields;

        return {
          document: {
            signature,
          },
        };
      }
      case InterviewFormType.DATE_OF_BIRTH: {
        const { dateOfBirthDate } = formFields as DateOfBirthFormFields;

        return {
          dateOfBirth: dateOfBirthDate,
        };
      }
      case InterviewFormType.CONFIRM_NAME: {
        const { confirmNames } = formFields as ConfirmNameFormFields;
        return {
          confirmNames: confirmNames?.map((confirmName) => {
            const isThisCorrectField = confirmName.isThisCorrect;
            const isThisCorrect = `${isThisCorrectField}` === "true";

            return {
              akaId: confirmName.akaId,
              isThisCorrect: isThisCorrectField,
              firstName: valueIfNotTrue(isThisCorrect, confirmName.firstName),
              middleName: valueIfNotTrue(isThisCorrect, confirmName.middleName),
              lastName: valueIfNotTrue(isThisCorrect, confirmName.lastName),
            };
          }),
        };
      }
      case InterviewFormType.ATTACHMENT: {
        const { notAvailable, documentType, comment } =
          formFields as UploadFormFields;
        return {
          DOCUMENT: notAvailable,
          DOC_TYPE: documentType,
          COMMENT: comment,
        };
      }
      default:
        return {
          ...formFields,
        };
    }
  };

  const onSubmit: SubmitHandler<InterviewSubmitProps> = async ({
    doSave,
    ...formFields
  }: InterviewSubmitProps) => {
    mutateTaskData({
      APPLICATION_UID: data?.taskParameters.applicationUid,
      TASK_ID: data?.taskParameters.taskId,
      //reduce each form into an object of form fields keyed by the formId, spread result
      ...data?.forms.reduce(
        (combinedFormData, currentForm) => {
          const nestedFormFields = get(
            formFields,
            currentForm.formParameters.formId,
          );
          const formPayload = constructFormPayload(
            currentForm,
            nestedFormFields,
          );

          return {
            //first add previously added data, then add current form's data
            ...combinedFormData,
            [currentForm.formParameters.formId]: formPayload,
          };
        },
        {}, //reducer starts with an empty object
      ),
      saveOnly: doSave,
    });
  };

  // when the interview only has 1 form, what type is it?
  const singleFormInterviewType =
    data?.forms.length === 1 && data.forms[0].formParameters.formType;

  const InterviewForm = ({
    singleFormInterviewType,
  }: InterviewTaskFormProps) => {
    const applicationUid = data?.taskParameters?.applicationUid || "";
    const form = data?.forms[0];
    const isSavable = data?.taskParameters.savable;

    //All special form interviews use the same adapter
    const specialFormInterviewTaskType =
      singleFormInterviewType as SpecialFormInterviewFormType;
    if (
      Object.values(SpecialFormInterviewFormType).includes(
        specialFormInterviewTaskType,
      )
    ) {
      return (
        <SpecialFormFormAdapter
          form={form as SpecialFormApiForm}
          taskId={taskId}
          taskType={specialFormInterviewTaskType}
          onSubmit={onSubmit}
          goToStatusCheck={goToStatusCheck}
        />
      );
    }

    switch (singleFormInterviewType) {
      case InterviewFormType.EMPLOYMENT:
        return (
          <EmplHistInterviewFormAdapter
            form={mapEmplHistApiToUi(form)}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.DRUG_TEST_INSTRUCTIONS:
        return (
          <DrugTestTask
            form={form as DrugTestApiForm}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.CITIZENSHIP_DATA:
        return (
          <ConfirmCitizenshipTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as ConfirmCitizenshipApiForm}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.GRAD_STATUS_DATE:
        return (
          <GraduationStatusTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as GraduationStatusApiForm}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.ADDITIONAL_REFERENCE:
        return (
          <AdditionalReferencesTask
            form={form as AdditionalReferencesApiForm}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.DRIVER_LICENSE_INFORMATION:
        return (
          <DriverLicenseTask
            form={form as DriverLicenseApiForm}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.DROPDOWN_SELECTION:
        return (
          <DropdownSelectionTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as DropdownSelectionApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.CONTACT_INFORMATION:
        return (
          <ContactInformationTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as ContactInformationApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.AUTH_FORM_DOWNLOAD:
        return (
          <AuthorizationDownloadTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as AuthorizationDownloadApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.EDUCATION_DATES:
        return (
          <EducationDatesTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as EducationDatesApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.EMPLOYMENT_DATES:
        return (
          <EmploymentDatesTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as EmploymentDatesApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.SOCIAL_SECURITY_NUMBER:
        return (
          <SocialSecurityNumberTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as SocialSecurityNumberApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.CREDIT_FREEZE:
        return (
          <CreditFreezeTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as CreditFreezeApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.DATE_OF_BIRTH:
        return (
          <DateOfBirthTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as DateOfBirthApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.SUPERVISOR_NAME:
        return (
          <SupervisorNameTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as SupervisorNameApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.CONFIRM_NAME:
        return (
          <ConfirmNameTask
            applicationUid={applicationUid}
            taskId={taskId}
            form={form as ConfirmNameApiForm}
            isSavable={isSavable}
            goToStatusCheck={goToStatusCheck}
            onSubmit={onSubmit}
          />
        );
      case InterviewFormType.REPORT:
        return (
          <ReportTask
            form={form as ReportApiForm}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.SURVEY:
        return (
          <SurveyTask
            form={form as SurveyApiForm}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.EDUCATION_HISTORY:
        return (
          <EduHistInterviewFormAdapter
            form={mapEduHistApiToUi(form)}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.ADDRESS_HISTORY:
        return (
          <AddrHistInterviewFormAdapter
            form={mapAddrHistApiToUi(form)}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.ATTACHMENT:
        return (
          <UploadInterviewTask
            applicationUid={applicationUid}
            taskId={taskId}
            data={data}
            goToStatusCheck={goToStatusCheck}
          />
        );
      case InterviewFormType.GA_STATEWIDE_CRIMINAL_SEARCH:
        return (
          <GaStatewideCriminalFormAdapter
            form={mapGaStatewideCriminalApiToUi(form)}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
      default:
        return (
          <MultiFormInterviewTask
            forms={data?.forms || []}
            taskId={data?.taskParameters?.taskId || "task"}
            isSavable={isSavable}
            onSubmit={onSubmit}
            goToStatusCheck={goToStatusCheck}
          />
        );
    }
  };

  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <InterviewForm singleFormInterviewType={singleFormInterviewType} />
  );
}
