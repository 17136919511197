import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import ReferencesForm, {
  ReferencesFormFields,
} from "components/tasks/references/ReferencesForm";
import {
  EMPTY_REFERENCE,
  ReferenceFormFields,
} from "components/tasks/references/ReferenceForm";
import { t } from "@lingui/macro";
import { SubmitHandler } from "react-hook-form";

interface ReferenceTaskdefFields {
  NAME: ApiFieldProperties;
  EMAIL_ADDRESS: ApiFieldProperties;
  PHONE: ApiFieldProperties;
  RELATION_TO_APPLICANT: ApiFieldProperties;
}

interface ReferencesApiFields extends ApiFormFields {
  PROFESSIONAL_REFERENCES: ReferenceTaskdefFields[];
}

interface ReferencesApiFormMessages extends ApiFormMessages {
  clientCustomizedTaskDescription: string;
}

export interface ReferencesApiFormParameters extends ApiFormParameters {
  askForProfessionalReferences: boolean;
  requireReferenceEmail: boolean;
  requireReferencePhone: boolean;
}

export default function ReferencesTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_REFERENCE;

  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      ReferencesApiFields,
      ReferencesApiFormMessages,
      ReferencesApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  function mapApiReferenceToFormReference(
    apiReference: ReferenceTaskdefFields,
  ): ReferenceFormFields {
    return {
      name: apiReference?.NAME?.existingValue || "",
      phone: apiReference?.PHONE?.existingValue || "",
      email: apiReference?.EMAIL_ADDRESS?.existingValue || "",
      relationship: apiReference?.RELATION_TO_APPLICANT?.existingValue || "",
    };
  }

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const storeReferences: SubmitHandler<ReferencesFormFields> = async ({
    references,
    doSave,
  }: ReferencesFormFields) =>
    mutateTaskData({
      formFields: {
        PROFESSIONAL_REFERENCES: references.map((reference) => ({
          NAME: reference.name,
          PHONE: reference.phone,
          EMAIL_ADDRESS: reference.email,
          RELATION_TO_APPLICANT: reference.relationship,
        })),
      },
      saveOnly: doSave,
    });

  let requireEmail =
    data?.forms[0].formParameters?.requireReferenceEmail ?? true;
  let requirePhone =
    data?.forms[0].formParameters?.requireReferencePhone ?? true;
  if (!requireEmail && !requirePhone) {
    requireEmail = true;
    requirePhone = true;
  }

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {data?.forms[0].formParameters?.askForProfessionalReferences
          ? t`Professional References`
          : taskHeadings[taskId].long}
      </Heading>
      <Text>
        {data?.forms[0].formMessages?.clientCustomizedTaskDescription}
      </Text>
      <ReferencesForm
        defaultValues={{
          references: data?.forms[0]?.formFields?.PROFESSIONAL_REFERENCES.map(
            (reference) => mapApiReferenceToFormReference(reference),
          ) || [EMPTY_REFERENCE],
        }}
        useRelationshipDropdown={
          data?.forms[0].formParameters?.askForProfessionalReferences || false
        }
        requireEmail={requireEmail}
        requirePhone={requirePhone}
        isSavable={data?.forms[0].formParameters?.isSavable || false}
        onSubmit={storeReferences}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
