import useAuth from "auth/useAuth";
import {
  ApiDataResponse,
  LoginData,
  postRequiredIdentification,
  postTermsOfService,
  putIdentificationPinVerification,
  RequiredIdentificationRequest,
  sendPIN,
  TokenData,
} from "auth/authApi";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { ResponseCodes } from "tasks/apiConstants";
import { t } from "@lingui/macro";
import useOtesModal from "error/useOtesModal";
import { Dispatch, SetStateAction } from "react";

interface VerifyPinProps {
  pin?: string;
  pinCode: string;
  commType?: "email" | "sms" | undefined;
}

interface LoginProps {
  email?: string;
  sms?: string;
  setPinCode: Dispatch<SetStateAction<string>>;
}

export interface TermsOfServiceProps {
  consumerReportOptIn: boolean;
}

export const useSendPinMutator = ({ email, sms, setPinCode }: LoginProps) => {
  const { triggerOtesModal } = useOtesModal();
  const { token } = useAuth();

  const setFakePinCode = () =>
    setPinCode(Math.floor(Math.random() * 1000000).toString());

  const commType = email ? { email: email } : { sms: sms };
  return useMutation(() => sendPIN(commType, token), {
    onSuccess: (result: ApiDataResponse<LoginData>) => {
      if (!result?.data?.pinCode) {
        return setFakePinCode();
      }
      return setPinCode(result.data.pinCode);
    },
    onError: (response: AxiosError) => {
      handleOtesErrorFromAxiosError(response);

      setPinCode("");

      let otesModalBodyText;
      if (
        ResponseCodes.Error.Action.VALIDATION_FAILURE ===
        response?.response?.data?.code
      ) {
        otesModalBodyText = t`Unable to send pin, the provided communication is invalid.`;
      } else {
        otesModalBodyText = t`Unable to send pin, please try again.`;
      }

      triggerOtesModal({
        bodyText: otesModalBodyText,
      });
    },
  });
};

export const useVerifyPinMutator = ({ pinCode, commType }: VerifyPinProps) => {
  const { triggerOtesModal } = useOtesModal();
  const { token, updateToken, setBypassPiiVerify } = useAuth();

  return useMutation(
    (pin: string) =>
      putIdentificationPinVerification(
        {
          pin,
          pinCode,
          commType,
        },
        token,
      ),
    {
      onSuccess: async (tokenResponse: ApiDataResponse<TokenData>) => {
        //pii check validated
        setBypassPiiVerify(true);
        updateToken(tokenResponse.data?.value);
      },
      onError: (response: AxiosError) => {
        if (
          ResponseCodes.Error.Action.INVALID_PIN ===
          response?.response?.data?.code
        ) {
          triggerOtesModal({
            bodyText: t`Incorrect or expired PIN, please try again.`,
          });
        } else {
          triggerOtesModal({
            bodyText: t`Unable to verify PIN, please try again.`,
          });
        }
      },
    },
  );
};

export function usePostRequiredIdentificationMutation() {
  const { token, updateToken, bypassOtp } = useAuth();
  const { triggerOtesModal } = useOtesModal();

  return useMutation(
    (requiredIdentificationRequest: RequiredIdentificationRequest) =>
      postRequiredIdentification(requiredIdentificationRequest, token),
    {
      onSuccess: (result) => {
        if (result.data.ssnVerified || bypassOtp) {
          updateToken(result.data.token.value);
        }
      },
      onError: (response: AxiosError) => {
        if (
          ResponseCodes.Error.Action.INVALID_PII ===
          response?.response?.data?.code
        ) {
          triggerOtesModal({
            bodyText: t`The personal information entered does not match our data.`,
          });
        } else if (
          ResponseCodes.Error.Entry.ENTRY_LOCKED ===
          response?.response?.data?.code
        ) {
          triggerOtesModal({
            bodyText: t`Candidate entry has been locked due to too many failed attempts.`,
            onClose: () => {
              window.location.reload();
            },
          });
        } else {
          handleOtesErrorFromAxiosError(response);

          triggerOtesModal({
            bodyText: t`Unable to verify your personal information, please try again.`,
          });
        }
      },
    },
  );
}

export const useTermsOfServiceMutation = (
  setCurrentPageToUpdateInfo: () => void,
) => {
  const { token } = useAuth();

  return useMutation(
    (termsOfServiceProps: TermsOfServiceProps) =>
      postTermsOfService(termsOfServiceProps, token),
    {
      onSuccess: () => {
        setCurrentPageToUpdateInfo();
      },
      //3 retries since no user intervention would help
      retry: 3,
    },
  );
};
