import { COUNTRY_UNITED_STATES, COUNTRY_CANADA } from "tasks/apiConstants";

export type RegionMode = "state" | "province" | "freeForm";

export function getRegionMode(country?: string): RegionMode | undefined {
  if (!country) {
    return undefined;
  } else if (country === COUNTRY_UNITED_STATES) {
    return "state";
  } else if (country === COUNTRY_CANADA) {
    return "province";
  } else {
    return "freeForm";
  }
}
