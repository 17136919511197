import { Container, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  TaskIconType,
  ApplicantEntryTaskId,
  TaskRecordsResponse,
} from "tasks/tasksApi";
import { Plural, Trans } from "@lingui/macro";
import TaskListItem from "tasks/TaskListItem";

interface TaskListProps {
  incompleteTasks?: TaskRecordsResponse;
  completeTasks?: TaskRecordsResponse;
  informationTasks?: TaskRecordsResponse | null;
  onTaskClick: (
    task: ApplicantEntryTaskId,
    taskName: string,
    taskIconType: TaskIconType,
  ) => void;
}

export default function TaskList({
  incompleteTasks,
  completeTasks,
  informationTasks,
  onTaskClick,
}: TaskListProps): JSX.Element {
  const completeTaskCount =
    (completeTasks && Object.keys(completeTasks).length) || 0;
  const incompleteTaskCount =
    (incompleteTasks && Object.keys(incompleteTasks).length) || 0;
  const informationTaskCount =
    (informationTasks && Object.keys(informationTasks).length) || 0;

  const hasIncomplete = incompleteTaskCount > 0;
  const hasInformational = informationTaskCount > 0;
  const hasCompleted = completeTaskCount > 0;

  let totalTaskCount = incompleteTaskCount;

  if (hasInformational) {
    for (const taskId in informationTasks) {
      if (informationTasks[taskId].taskName === "Drug Test Instructions") {
        totalTaskCount++;
      }
    }
  }

  return (
    <>
      <Flex p="1rem" direction="row" alignItems={"center"}>
        {totalTaskCount ? (
          <WarningIcon w="30px" h="30px" m="0.5rem" />
        ) : (
          <CheckCircleIcon w="30px" h="30px" m="0.5rem" color="green" />
        )}
        <Heading px="0.5rem" as="h3" size="md">
          <Plural
            value={totalTaskCount}
            one="# task to be completed"
            other="# tasks to be completed"
          />
        </Heading>
      </Flex>
      {!hasIncomplete && !hasInformational && (
        <Container maxW="xl">
          <Text as="i">
            <Trans>
              Nothing is needed at this time. You will receive a message from
              Orange Tree if additional information is needed from you during
              your background screen.
            </Trans>
          </Text>
          {(hasInformational || hasIncomplete || hasCompleted) && (
            <Divider mt="1rem" border="3px" />
          )}
        </Container>
      )}
      {hasIncomplete && (
        <Container maxW="xl">
          {Object.entries(incompleteTasks || {}).map(([taskId, task]) => (
            <TaskListItem
              text={task.taskName}
              iconType={task.taskIconType}
              key={task.taskName}
              completed={false}
              onClick={() =>
                onTaskClick(
                  taskId as ApplicantEntryTaskId,
                  task.taskName,
                  task.taskIconType,
                )
              }
            />
          ))}
          {(hasInformational || hasCompleted) && <Divider border="3px" />}
        </Container>
      )}
      {hasInformational && (
        <Container maxW="xl">
          {Object.entries(informationTasks || {}).map(([taskId, task]) => (
            <TaskListItem
              text={task.taskName}
              iconType={task.taskIconType}
              key={task.taskName}
              completed={false}
              onClick={() =>
                onTaskClick(
                  taskId as ApplicantEntryTaskId,
                  task.taskName,
                  task.taskIconType,
                )
              }
            />
          ))}
          {hasCompleted && <Divider border="3px" />}
        </Container>
      )}
      {hasCompleted && (
        <Container maxW="xl">
          {Object.values(completeTasks || {}).map((task) => (
            <TaskListItem
              text={task.taskName}
              iconType={task.taskIconType}
              completed={true}
              key={task.taskName}
            />
          ))}
        </Container>
      )}
    </>
  );
}
