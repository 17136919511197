import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import ConfirmCitizenshipForm from "./ConfirmCitizenshipForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type ConfirmCitizenshipApiForm = {
  formFields: ConfirmCitizenshipApiFormFields;
  formMessages: ConfirmCitizenshipApiFormMessages;
  formParameters: InterviewFormParameters;
};

export interface ConfirmCitizenshipFormFields {
  citizenOfCountry?: string;
  nationalIdentification?: string;
  nationalIdentificationCountry?: string;
  doSave?: boolean;
}

interface ConfirmCitizenshipApiFormFields extends InterviewFormFields {
  citizenOfCountry: boolean;
  nationalIdentification: string;
  nationalIdentificationCountry: string;
}

interface ConfirmCitizenshipApiFormMessages extends InterviewFormMessages {
  instructionHtml: string;
  countryToConfirm: string;
}

interface ConfirmCitizenshipTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: ConfirmCitizenshipApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function ConfirmCitizenshipTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: ConfirmCitizenshipTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    citizenOfCountry: form.formFields?.citizenOfCountry,
    nationalIdentification: form.formFields?.nationalIdentification,
    nationalIdentificationCountry:
      form.formFields?.nationalIdentificationCountry || "",
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    instructionHtml: form.formMessages?.instructionHtml || "",
    countryToConfirm: form.formMessages?.countryToConfirm || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <ConfirmCitizenshipForm
      defaultValues={{
        [formParameters.formId]: {
          citizenOfCountry: formFields.citizenOfCountry,
          nationalIdentification: formFields.nationalIdentification,
          nationalIdentificationCountry:
            formFields.nationalIdentificationCountry,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
