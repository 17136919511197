import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import EmploymentDatesForm from "./EmploymentDatesForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type EmploymentDatesApiForm = {
  formFields: EmploymentDatesApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface EmploymentDatesFormFields {
  isThisYourPresentEmployer?: string;
  startDate?: string;
  endDate?: string;
  mayWeContactThisEmployer?: string;
  doSave?: boolean;
}

interface EmploymentDatesApiFormFields extends InterviewFormFields {
  isThisYourPresentEmployer?: boolean;
  startDate?: string;
  endDate?: string;
  mayWeContactThisEmployer?: boolean;
}

interface EmploymentDatesTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: EmploymentDatesApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function EmploymentDatesTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: EmploymentDatesTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    isThisYourPresentEmployer: form.formFields?.isThisYourPresentEmployer,
    startDate: form.formFields?.startDate || "",
    endDate: form.formFields?.endDate || "",
    mayWeContactThisEmployer: form.formFields?.mayWeContactThisEmployer,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions:
      form.formMessages?.formInstructions ||
      "Please provide the dates of attendance.",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <EmploymentDatesForm
      defaultValues={{
        [formParameters.formId]: {
          startDate: formFields.startDate,
          endDate: formFields.endDate,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
