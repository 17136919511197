import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import PinField from "react-pin-field";

interface PinFormProps {
  description?: string;
  onPinSubmit: (pin: string) => void;
  onReSendPin: () => void;
}

export default function PinForm({
  description,
  onPinSubmit,
  onReSendPin,
}: PinFormProps): JSX.Element {
  const { handleSubmit, control } = useForm<{ pin: string }>();

  return (
    <Box>
      <Center>
        <Heading pb="0.5em" as="h1">
          <Trans>Verification PIN Sent</Trans>
        </Heading>
      </Center>
      <Text pb="1em">{description}</Text>
      <Trans>
        <Link color="orange.600" onClick={onReSendPin}>
          Click Here
        </Link>{" "}
        to resend the PIN.
      </Trans>
      <form
        onSubmit={handleSubmit(async ({ pin }: { pin: string }) =>
          onPinSubmit(pin),
        )}>
        <Center>
          <FormControl pt="1em" textAlign={"center"}>
            <FormLabel className="requiredField" textAlign={"center"}>
              Enter PIN
            </FormLabel>
            <Controller
              control={control}
              name="pin"
              defaultValue={""}
              render={({ field: { onChange } }) => (
                <Input
                  as={PinField}
                  id={"pin"}
                  name={"pin"}
                  length={6}
                  type={"tel"}
                  inputMode={"numeric"}
                  required={true}
                  validate={/^[0-9]$/}
                  onChange={onChange}
                  borderColor={"gray.600"}
                  style={{
                    width: "43px",
                    height: "43px",
                    margin: "3px",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                />
              )}
            />
          </FormControl>
        </Center>
        <Center pt="4">
          <Button type="submit" colorScheme="orange">
            Submit
          </Button>
        </Center>
      </form>
    </Box>
  );
}
