import { Heading, Text } from "@chakra-ui/react";
import SpecialFormForm, {
  SpecialFormFields,
} from "components/tasks/special-form/SpecialFormForm";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import { SubmitHandler } from "react-hook-form";
import { SpecialFormSchema, useGetSpecialForm } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import {
  InterviewFormFields,
  InterviewFormMessages,
  SpecialFormInterviewFormParameters,
  SpecialFormInterviewFormType,
} from "components/tasks/interviews/interviewApiTypes";

export interface SpecialFormFormAdapterProps {
  form: any;
  taskId: string;
  taskType: SpecialFormInterviewFormType;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  goToStatusCheck?: () => void;
}

export type SpecialFormApiForm = {
  formFields: InterviewFormFields;
  formMessages: InterviewFormMessages;
  formParameters: SpecialFormInterviewFormParameters;
};

export default function SpecialFormFormAdapter({
  form,
  taskId,
  taskType,
  onSubmit,
  goToStatusCheck,
}: SpecialFormFormAdapterProps): JSX.Element {
  const specialFormId = form.formParameters.specialFormId;

  const { data, isFetching, isRefetching } = useGetSpecialForm({
    specialFormId,
    goToStatusCheck,
  });

  const schema = data?.schema as SpecialFormSchema;

  const storeSpecialFormData: SubmitHandler<SpecialFormFields> = async ({
    schema,
    uiSchema,
    formData,
  }: SpecialFormFields) => {
    const schemaFormFields = Object.keys(schema.properties);
    type RequestData = {
      [key: string]: any;
    };
    const submitData: RequestData = {};

    schemaFormFields.forEach((schemaField) => {
      const userDataProperties = uiSchema[schemaField].additionalProperties;
      const userData = formData[schemaField];
      if (userData) {
        const formFieldType = userDataProperties.formFieldType;
        switch (formFieldType) {
          case "radio":
            submitData[userDataProperties.fieldId] = userData;

            //The check below is needed for specialFormGaStatewide
            //because of how its data was setup
            if (taskType == SpecialFormInterviewFormType.GA_STATEWIDE) {
              userDataProperties.formFields.forEach((formField: Array<any>) => {
                submitData[formField[1]] = userData === formField[0];
              });
            }
            break;
          case "compound":
            submitData[userDataProperties.formField] = { SIGNATURE: userData };
            break;
          default:
            submitData[userDataProperties.formField] = userData;
        }
      }
    });

    onSubmit({
      [taskId]: submitData,
    });
  };

  return isFetching || isRefetching ? (
    <TaskSpinnerOverlay />
  ) : (
    <>
      <Heading p={1} as="h1">
        {form.formMessages.formName}
      </Heading>
      <Text pb="4">{form.formMessages.formInstructions}</Text>
      <SpecialFormForm
        defaultValues={{
          specialFormId: schema.formId,
          schema: schema.formSchema,
          uiSchema: schema.uiSchema,
          formData: schema.formData,
        }}
        onSubmit={storeSpecialFormData}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
