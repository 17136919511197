import { Box, Container, Modal, ModalOverlay, Spinner } from "@chakra-ui/react";

export interface SpinnerOverlayProps {
  isShown?: boolean;
  text?: string;
}

export default function SpinnerOverlay({
  isShown = true,
  text,
}: SpinnerOverlayProps): JSX.Element | null {
  return isShown ? (
    <Modal motionPreset={"none"} isOpen={isShown} onClose={() => null}>
      <ModalOverlay color="white" bgColor="rgb(0 0 0 / 60%)">
        <Container
          centerContent
          verticalAlign="middle"
          maxW="container.md"
          h="full"
          p="20">
          <Spinner m="auto" size="xl" label={text || "Loading..."} />
          {text && (
            <Box position="absolute" top="55%">
              {text}
            </Box>
          )}
        </Container>
      </ModalOverlay>
    </Modal>
  ) : null;
}
