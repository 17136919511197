import { WidgetProps } from "@rjsf/utils";
import FormSelect from "components/common/form/FormSelect";

export default function SpecialFormSelect(props: WidgetProps) {
  const schema = props.schema;
  const uiSchema = props.uiSchema;
  const additionalProperties = uiSchema?.additionalProperties;

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  return (
    <>
      <FormSelect
        fieldId={additionalProperties?.fieldId}
        label={schema.title ? schema.title : ""}
        options={additionalProperties.selectValues}
        defaultValue={props.value}
        required={additionalProperties.requiredField}
        onChange={changeHandler}
        selectType="Option"
      />
    </>
  );
}
