import { ApiTaskdef } from "tasks/tasksApi";

export interface InterviewTaskMessages {
  taskName: string;
}

export interface InterviewTaskParameters {
  taskId: string;
  applicationUid: string;
  savable: boolean;
}

export interface InterviewFormFields {
  [fieldName: string]: unknown;
}

export interface InterviewFormMessages {
  formInstructions?: string;
  formName: string;
}

export enum InterviewFormType {
  KEY_VALUE = "keyValue",
  BOOLEAN_STRING = "booleanString",
  DROPDOWN_SELECTION = "dropdownSelection",
  CONFIRM_NAME = "confirmName",
  EMPLOYMENT = "employment",
  EDUCATION_HISTORY = "educationHistory",
  ADDRESS_HISTORY = "addressHistory",
  ADDRESS_DATA = "addressData",
  DRUG_TEST_INSTRUCTIONS = "drugTestInstructions",
  CITIZENSHIP_DATA = "citizenshipData",
  GRAD_STATUS_DATE = "gradStatusDate",
  ADDITIONAL_REFERENCE = "additionalReference",
  DRIVER_LICENSE_INFORMATION = "driverLicenseInformation",
  CONTACT_INFORMATION = "contactInformation",
  REPORT = "report",
  EDUCATION_DATES = "educationDates",
  EMPLOYMENT_DATES = "employmentDates",
  SOCIAL_SECURITY_NUMBER = "socialSecurityNumber",
  CREDIT_FREEZE = "creditFreeze",
  SURVEY = "survey",
  DATE_OF_BIRTH = "dateOfBirth",
  SUPERVISOR_NAME = "supervisorName",
  AUTH_FORM_DOWNLOAD = "authFormDownload",
  ATTACHMENT = "attachment",
  GA_STATEWIDE_CRIMINAL_SEARCH = "gaStatewideCriminalSearch",
}

export enum SpecialFormInterviewFormType {
  GA_STATEWIDE = "specialFormGaStatewide",
}

export interface SpecialFormInterviewFormParameters
  extends InterviewFormParameters {
  specialFormId: string;
}

export interface InterviewFormParameters {
  formId: string;
  // helps determine which properties will be on the form
  formType: InterviewFormType;
  //TODO move to an extended interface
  allowUnavailableSelection?: boolean;
}

/**
 * An interface for each of the "sub-forms" that is retrieved for a
 * "multi-form" interview task definition.
 *
 * If the formFields, formMessages, or formParameters
 * to be retrieved are known, those types can be provided as generics but are otherwise defaulted
 * to the base types that can later be cast to more specific types upon retrieval.
 */
export interface InterviewApiSubForm<
  Fields extends InterviewFormFields = InterviewFormFields,
  Messages extends InterviewFormMessages = InterviewFormMessages,
  Parameters extends InterviewFormParameters = InterviewFormParameters,
> {
  formFields: Fields;
  formMessages: Messages;
  formParameters: Parameters;
}

export interface InterviewTaskDef<
  Form extends InterviewApiSubForm = InterviewApiSubForm,
  TaskMessages extends InterviewTaskMessages = InterviewTaskMessages,
  TaskParameters extends InterviewTaskParameters = InterviewTaskParameters,
> extends ApiTaskdef {
  forms: Form[];
  taskMessages: TaskMessages;
  taskParameters: TaskParameters;
}
