import { SavableFormComponentProps } from "components/tasks/taskHelpers";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import LicenseForm, {
  EMPTY_LICENSE,
  LicenseFormFields,
} from "components/tasks/licenses/LicenseForm";
import { Box, Button, Checkbox, Divider, VStack } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";

export interface LicensesFormFields {
  noProfessionalLicenses: boolean;
  licenses: LicenseFormFields[];
  doSave?: boolean;
}

export interface LicensesFormProps
  extends SavableFormComponentProps<LicensesFormFields> {
  licenseTypeOptions: string[];
}

export default function LicensesForm({
  defaultValues = {
    noProfessionalLicenses: false,
    licenses: [EMPTY_LICENSE],
  },
  licenseTypeOptions,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: LicensesFormProps): JSX.Element {
  const methods = useForm<LicensesFormFields>({ defaultValues });

  // if necessary, initialize defaultValues with an empty license
  if (!defaultValues.licenses.length) {
    defaultValues.licenses = [EMPTY_LICENSE];
  }

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "licenses",
  });

  const showLicenses = !methods.watch("noProfessionalLicenses");

  const licensesForm = "licensesForm";

  return (
    <VStack w="100%" p={1}>
      <FormProvider {...methods}>
        <form id={licensesForm} onSubmit={methods.handleSubmit(onSubmit)}>
          <Checkbox
            size="lg"
            py={4}
            id={"noProfessionalLicenses"}
            defaultChecked={defaultValues.noProfessionalLicenses}
            {...methods.register("noProfessionalLicenses")}>
            {t`No Professional Licenses`}
          </Checkbox>
          {showLicenses && (
            <>
              {fields.map((license, index) => (
                <Box key={license.id}>
                  <LicenseForm
                    licenseId={`licenses.${index}`}
                    defaultValues={license}
                    licenseTypeOptions={licenseTypeOptions}
                    showRemoveButton={fields.length > 1}
                    onRemoveClick={() => remove(index)}
                  />
                </Box>
              ))}
              <Box pt={4}>
                <Button color="gray.600" onClick={() => append(EMPTY_LICENSE)}>
                  + <Trans>Add Another License</Trans>
                </Button>
              </Box>
            </>
          )}
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={
              (isSavable &&
                (() => onSubmit({ ...methods.getValues(), doSave: true }))) ||
              undefined
            }
            form={licensesForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
