import { WidgetProps } from "@rjsf/utils";
import FormSignaturePad from "components/common/form/FormSignaturePad";

export default function SpecialFormSignaturePad(props: WidgetProps) {
  const schema = props.schema;
  const uiSchema = props.uiSchema;
  const additionalProperties = uiSchema?.additionalProperties;

  const setSignature = (signature: string) => {
    props.onChange(signature);
  };

  return (
    <FormSignaturePad
      label={schema.title}
      labelSize={additionalProperties.fontSize}
      labelWeight={additionalProperties.fontWeight}
      required={additionalProperties.required}
      setSignature={setSignature}
      isSpecialForm={true}
    />
  );
}
