import Header from "components/common/basic/Header";
import { ReactNode, useEffect, useState } from "react";
import {
  ApplicantEntrySpecialFormTaskId,
  ApplicantEntryTaskId,
  ApplicationStatus,
  TaskIconType,
} from "tasks/tasksApi";
import { Box, Button, Container, useTheme } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import StatusScreen from "components/status/StatusScreen";
import { useGetTasks, useSubmitAppEntry } from "tasks/taskQueries";
import PersonalInfoTask from "components/tasks/personal-info/PersonalInfoTask";
import SignatureTask from "components/tasks/SignatureTask";
import AddressHistoryTask from "components/tasks/address-history/AddressHistoryTask";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import AkasTask from "components/tasks/akas/AkasTask";
import AdmittedOffensesTask from "components/tasks/admitted-offenses/AdmittedOffensesTask";
import ReferencesTask from "components/tasks/references/ReferencesTask";
import EducationHistoryTask from "components/tasks/education-history/EducationHIstoryTask";
import LicensesTask from "components/tasks/licenses/LicensesTask";
import EmploymentHistoryTask from "components/tasks/employment-history/EmploymentHistoryTask";
import DisclosuresTask from "components/tasks/disclosures/DisclosuresTask";
import FormInterviewTask from "components/tasks/interviews/FormInterviewTask";
import AdditionalQuestionsTask from "components/tasks/additional-questions/AdditionalQuestionsTask";
import GaStatewideCriminalTask from "components/tasks/ga-statewide/GaStatewideCriminalTask";
import taskHeadings from "tasks/taskHeadings";
import { motion } from "framer-motion";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import { useHistory } from "react-router-dom";
import { conditionalHistoryPush } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import SpecialFormTask from "components/tasks/special-form/SpecialFormTask";
import MeasureOneEmploymentTask from "./components/tasks/measureone/MeasureOneEmploymentTask";

export default function Main(): JSX.Element {
  const [currentTask, setCurrentTask] = useState<{
    taskId: ApplicantEntryTaskId | ApplicantEntrySpecialFormTaskId;
    taskName: string;
    taskIconType: TaskIconType;
  }>();
  const [appEntrySubmitted, setAppEntrySubmitted] = useState<boolean>(false);

  const { data, isFetching, refetch } = useGetTasks();
  const submitAppEntry = useSubmitAppEntry();
  const theme = useTheme();

  const statusCheck = (appEntryComplete?: boolean) => {
    setCurrentTask(undefined);
    if (appEntryComplete === true) {
      submitAppEntry.mutate();
      return;
    }
    refetch();
  };

  interface UndefinedTaskProps {
    taskId: any;
  }

  const UndefinedTask = ({ taskId }: UndefinedTaskProps): JSX.Element => {
    return (
      <>
        {taskId}
        <Button colorScheme="orange" onClick={() => setCurrentTask(undefined)}>
          Nothing to see here, yet.
        </Button>
      </>
    );
  };

  const getTaskComponent = ({ taskId }: { taskId: any; taskName: string }) => {
    if (Object.values(ApplicantEntryTaskId).includes(taskId)) {
      switch (taskId) {
        case ApplicantEntryTaskId.APP_ENTRY_ACKNOWLEDGEMENT:
          return (
            <SignatureTask taskId={taskId} goToStatusCheck={statusCheck} />
          );
        case ApplicantEntryTaskId.APP_ENTRY_ADDITIONAL_QUESTIONS:
          return (
            <AdditionalQuestionsTask
              taskId={taskId}
              goToStatusCheck={statusCheck}
            />
          );
        case ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY:
          return <AddressHistoryTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_DISCLOSURE:
          return (
            <SignatureTask taskId={taskId} goToStatusCheck={statusCheck} />
          );
        case ApplicantEntryTaskId.APP_ENTRY_PERSONAL_INFO:
          return <PersonalInfoTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_INVESTIGATIVE_DISCLOSURE:
          return (
            <SignatureTask taskId={taskId} goToStatusCheck={statusCheck} />
          );
        case ApplicantEntryTaskId.APP_ENTRY_AKAS:
          return <AkasTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_ADMITTED_OFFENSE:
          return <AdmittedOffensesTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_REFERENCE:
          return <ReferencesTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_AFFIRMATION:
          return (
            <SignatureTask taskId={taskId} goToStatusCheck={statusCheck} />
          );
        case ApplicantEntryTaskId.APP_ENTRY_EDUCATION:
          return <EducationHistoryTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_LICENSE:
          return <LicensesTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_EMPLOYMENT:
          return <EmploymentHistoryTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_SPECIFIC_DISCLOSURES:
          return <DisclosuresTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_GA_STATEWIDE:
          return <GaStatewideCriminalTask goToStatusCheck={statusCheck} />;
        case ApplicantEntryTaskId.APP_ENTRY_MEASURE_ONE_EMPLOYMENT:
          return <MeasureOneEmploymentTask goToStatusCheck={statusCheck} />;
        default:
          return <UndefinedTask taskId={taskId} />;
      }
    } else if (
      Object.values(ApplicantEntrySpecialFormTaskId).includes(taskId)
    ) {
      switch (taskId) {
        case ApplicantEntrySpecialFormTaskId.GA_STATEWIDE:
          return (
            <SpecialFormTask taskId={taskId} goToStatusCheck={statusCheck} />
          );
        default:
          return <UndefinedTask taskId={taskId} />;
      }
    } else {
      return (
        <FormInterviewTask taskId={taskId} goToStatusCheck={statusCheck} />
      );
    }
  };

  const history = useHistory();

  let headerText;

  history.listen(() => {
    if (!isFetching && history.action === "POP") {
      statusCheck();
    }
  });

  useEffect(() => {
    if (!currentTask) {
      conditionalHistoryPush(history, AppPath.STATUS);
    } else {
      conditionalHistoryPush(history, AppPath.TASK);
    }
  });

  let contentContainer: ReactNode;
  let taskButtonsContainer: ReactNode;

  if (
    (submitAppEntry.isSuccess || submitAppEntry.isError) &&
    !appEntrySubmitted
  ) {
    setAppEntrySubmitted(true);
    refetch();
  } else if (submitAppEntry.isLoading) {
    contentContainer = (
      <SpinnerOverlay text={"Submitting your information..."} />
    );
  } else if (isFetching) {
    contentContainer = (
      <SpinnerOverlay text={!currentTask ? "Retrieving tasks..." : undefined} />
    );
  } else {
    let content: ReactNode;

    if (!currentTask) {
      headerText =
        data?.applicationStatus === ApplicationStatus.COLLECTING
          ? t`Capturing Information`
          : t`Check Status`;

      content = (
        <StatusScreen
          tasksData={data}
          onTaskClick={(
            taskId: ApplicantEntryTaskId,
            taskName: string,
            taskIconType: TaskIconType,
          ) => setCurrentTask({ taskId, taskName, taskIconType })}
        />
      );
    } else {
      const taskHeading = taskHeadings[currentTask.taskId];

      headerText =
        taskHeading?.short ||
        taskHeading?.long ||
        currentTask.taskName ||
        t`Info Entry`;

      const { orange } = theme.colors.brand;

      content = getTaskComponent(currentTask);
      taskButtonsContainer = (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <Box
            id={`${ThemeConstants.TASK_BUTTONS_CONTAINER_ID}`}
            width="100%"
            bottom="0"
            position={"fixed"}
            height={ThemeConstants.TASK_BUTTONS_CONTAINER_HEIGHT}
            bgColor={orange}
          />
        </motion.div>
      );
    }

    let headerAndFooterHeight: number = ThemeConstants.HEADER_HEIGHT;

    if (taskButtonsContainer) {
      headerAndFooterHeight += ThemeConstants.TASK_BUTTONS_CONTAINER_HEIGHT;
    }

    contentContainer = (
      <Box
        overflowY="auto"
        height={`calc(100% - ${headerAndFooterHeight}px)`}
        width="100%">
        <Container centerContent maxW="container.md" p={5}>
          {content}
        </Container>
      </Box>
    );
  }

  return (
    <>
      <Header text={headerText} />
      {contentContainer}
      {taskButtonsContainer}
    </>
  );
}
