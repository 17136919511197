import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { Heading, Text, useTheme } from "@chakra-ui/react";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import { useFormContext, useWatch } from "react-hook-form";
import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import { Trans } from "@lingui/macro";
import parse from "html-react-parser";

export type BoolStrSubFormAnswerFieldType = "dropDown" | "textField" | "none";

export interface BoolStrSubFormFields extends InterviewFormFields {
  answer?: "yes" | "no";
  additionalInformation?: string;
}

export interface BoolStrSubFormMessages extends InterviewFormMessages {
  question: string;
  additionalInformationQuestion: string;
  additionalInformationNoQuestion: string;
}

export interface BoolStrSubFormParameters extends InterviewFormParameters {
  hideAdditionalInformationOnNo: boolean;
  requireAdditionalInformation: boolean;
  additionalInformationFieldType: BoolStrSubFormAnswerFieldType;
  additionalInformationFieldOptions: string[];
}

export type BoolStrApiSubForm = InterviewApiSubForm<
  BoolStrSubFormFields,
  BoolStrSubFormMessages,
  BoolStrSubFormParameters
>;

export default function BoolStrSubForm({
  formMessages: {
    formName,
    formInstructions,
    question,
    additionalInformationQuestion,
    additionalInformationNoQuestion,
  },
  formParameters: {
    formId,
    additionalInformationFieldType,
    additionalInformationFieldOptions,
    hideAdditionalInformationOnNo,
    requireAdditionalInformation,
  },
}: BoolStrApiSubForm): JSX.Element {
  const { control } = useFormContext();
  const isNo = useWatch({ control, name: `${formId}.answer` }) === "no";
  const hideAdditionalWidget = hideAdditionalInformationOnNo && isNo;
  const shownAdditionalQuestion =
    additionalInformationNoQuestion && isNo
      ? additionalInformationNoQuestion
      : additionalInformationQuestion;

  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;

  return (
    <>
      <Heading color={orange} pt={2} as="h2" size="md">
        {formName}
      </Heading>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <FormRadioGroup
        fieldId={`${formId}.answer`}
        groupLabel={question}
        radios={[
          ["Yes", "yes"],
          ["No", "no"],
        ]}
        control={control}
      />
      {additionalInformationFieldType !== "none" &&
        (additionalInformationFieldType === "dropDown" ? (
          <FormSelect
            fieldId={`${formId}.additionalInformation`}
            label={shownAdditionalQuestion}
            options={additionalInformationFieldOptions}
            hidden={hideAdditionalWidget}
            required={requireAdditionalInformation}
          />
        ) : additionalInformationFieldType === "textField" ? (
          <FormInput
            fieldId={`${formId}.additionalInformation`}
            label={shownAdditionalQuestion}
            hidden={hideAdditionalWidget}
            required={requireAdditionalInformation}
          />
        ) : (
          <Text as="em" color="red">
            <Trans>
              There was an error creating this form. Please contact OrangeTree
              Support.
            </Trans>
          </Text>
        ))}
    </>
  );
}
