import axios from "axios";
import { ApiDataResponse, GenericApiResponse } from "auth/authApi";
import { MeasureOneEmployment } from "../components/tasks/measureone/MeasureOneEmploymentForm";

const tasksApiClient = axios.create({
  baseURL: "/api/rest/v1/application",
});

const attachmentApiClient = axios.create({
  baseURL: "api/rest/v1/attachment",
});

const specialFormApiClient = axios.create({
  baseURL: "api/rest/v1/specialform",
});

const measureoneApiClient = axios.create({
  baseURL: "api/rest/v1/measureone",
});

export type TaskIconType =
  | "signature"
  | "action"
  | "download"
  | "upload"
  | "form";

export interface TaskResponse {
  enableSave: boolean;
  taskIconType: TaskIconType;
  taskName: string;
}

const SPECIAL_FORM_PREFIX = "app-entry-special-form-";

export const getSpecialFormId = (taskId: string) => {
  return taskId.substring(SPECIAL_FORM_PREFIX.length);
};

export enum ApplicantEntryTaskId {
  APP_ENTRY_ACKNOWLEDGEMENT = "app-entry-acknowledgement",
  APP_ENTRY_ADDITIONAL_QUESTIONS = "app-entry-additional-questions",
  APP_ENTRY_ADDRESS_HISTORY = "app-entry-address-history",
  APP_ENTRY_ADMITTED_OFFENSE = "app-entry-admitted-offense",
  APP_ENTRY_AFFIRMATION = "app-entry-affirmation",
  APP_ENTRY_AKAS = "app-entry-akas",
  APP_ENTRY_DISCLOSURE = "app-entry-disclosure",
  APP_ENTRY_EDUCATION = "app-entry-education",
  APP_ENTRY_EMPLOYMENT = "app-entry-employment",
  APP_ENTRY_INVESTIGATIVE_DISCLOSURE = "app-entry-investigative-disclosure",
  APP_ENTRY_PERSONAL_INFO = "app-entry-personal-info",
  APP_ENTRY_PROFESSIONAL_LICENSE = "app-entry-professional-license",
  APP_ENTRY_PROFESSIONAL_REFERENCE = "app-entry-professional-reference",
  APP_ENTRY_SPECIFIC_DISCLOSURES = "app-entry-specific-disclosures",
  APP_ENTRY_GA_STATEWIDE = "app-entry-ga-statewide",
  APP_ENTRY_MEASURE_ONE_EMPLOYMENT = "app-entry-measure-one-employment",
}

//special form appEntry
export enum ApplicantEntrySpecialFormTaskId {
  GA_STATEWIDE = "app-entry-special-form-ga-statewide",
}

export type TaskUid = string;

export type TaskRecordsResponse = {
  [taskId: ApplicantEntryTaskId | TaskUid]: TaskResponse;
};

export enum ApplicationStatus {
  COLLECTING = "COLLECTING",
  PROCESSING = "PROCESSING",
  REVIEWING = "REVIEWING",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export interface TasksResponse {
  applicantName: string | null;
  applicationStatus: ApplicationStatus;
  completeTasks?: TaskRecordsResponse;
  daysRemaining: number;
  hasActive: boolean;
  incompleteTasks?: TaskRecordsResponse;
  informationTasks?: TaskRecordsResponse | null;
}

export async function getTasks(token: string) {
  const response = await tasksApiClient.get<TasksResponse>("/tasks", {
    headers: { "x-otes-token": token },
  });
  return response.data;
}

export async function getAppStatus(token: string) {
  const response = await tasksApiClient.get<TasksResponse>("/status", {
    headers: { "x-otes-token": token },
  });
  return response.data;
}

/** API FormField Properties */
export interface ApiFieldProperties {
  existingValue?: string;
  clientCustomizedLabel?: string;
}

/** Base interface, clarified by each extension */
export interface ApiFormFields {
  [name: string]: unknown;
}

/**
 * interface for variable number of unknown property names.
 * For use when property names are unknown but values are never nested within an array.
 * If sometimes nested in an array, use the NestedFormFields interface below.
 */
export interface FlatApiFormFields extends ApiFormFields {
  [name: string]: ApiFieldProperties;
}

/**
 *  interface for variable number of unknown properties, either flat or nested.
 *  Use only when the response from the API varies too much to be captured by a more exact interface.
 */
export interface NestedApiFormFields extends ApiFormFields {
  [name: string]: FlatApiFormFields | FlatApiFormFields[];
}

export interface ApiFormMessages {
  [messageName: string]: string;
}

export interface ApiFormParameters {
  taskId: ApplicantEntryTaskId | string;
  isSavable: boolean;
}

export interface IdVerifyApiFormParameters extends ApiFormParameters {
  identityVerificationMode?: boolean;
}

export interface ApiTaskdef {
  forms: unknown[];
}

/**
 * The majority of task definitions (interviews are the exception)
 * return only a single form with
 * properties of formFields, formMessages, and formParameters. This
 * type makes it possible to define what the members/properties of those
 * objects will be since they should be known before retrieval.
 */
export interface SingleFormTaskDef<
  Fields extends ApiFormFields,
  //default M to ApiFormMessages if no type provided for M
  Messages extends ApiFormMessages = ApiFormMessages,
  //default P to ApiFormParameters if no type provided for P
  Parameters extends ApiFormParameters = ApiFormParameters,
> extends ApiTaskdef {
  forms: {
    formFields?: Fields;
    formMessages?: Messages;
    formParameters?: Parameters;
  }[];
}

export function getTaskdef<T extends ApiTaskdef>(
  token: string,
  taskId: ApplicantEntryTaskId | TaskUid,
) {
  return tasksApiClient
    .get<T>(`/taskdef/${taskId}`, {
      headers: { "x-otes-token": token },
    })
    .then((response) => response.data);
}

// Object with *at least one (e.g. Partial)* ApplicantEntryTaskId as key, with a flat object as value
export type SaveNamedTaskPayload = Partial<{
  [taskId in ApplicantEntryTaskId]: { [fieldName: string]: unknown };
}>;

export interface SaveInterviewTaskPayload {
  APPLICATION_UID?: string;
  TASK_ID?: string;
  [taskId: string]: unknown;
}

export interface VerifyAllDownloadedData {
  allDocsDownloaded: boolean;
}

export interface MeasureOneDataResponseToken {
  dataRequestId: string;
  individualId: string;
  publicToken: string;
}

export interface MeasureOneDataRequestToken {
  itemKey?: number;
  candidateEmailAddress?: string;
  employerName: string;
  isCurrent: boolean;
  countries: [string];
}

export interface PostTaskInputResponse {
  appEntryComplete: boolean;
}

export async function postTaskInput(
  token: string,
  taskId: ApplicantEntryTaskId | string,
  payload: SaveNamedTaskPayload | SaveInterviewTaskPayload,
  saveOnly?: boolean,
) {
  const response = await tasksApiClient.post<ApiDataResponse<any>>(
    `/task/${taskId}` + (saveOnly ? "?saveonly=1" : ""),
    { ...payload },
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function submitAppEntry(token: string) {
  await tasksApiClient.post<ApiDataResponse<any>>(
    `/submitapp`,
    {},
    { headers: { "x-otes-token": token } },
  );
}

export function getLegalDocByUid(uid: string, token: string) {
  return attachmentApiClient.get<Blob>(`/legal/?uid=${uid}`, {
    responseType: "blob",
    headers: {
      "x-otes-token": token,
    },
  });
}

export function getGaStatewidePreliminaryAuthForm(token: string) {
  return attachmentApiClient.get<Blob>(`/gaAuthorizationForm`, {
    responseType: "blob",
    headers: {
      "x-otes-token": token,
    },
  });
}

export function getPreliminarySpecialForm(
  token: string,
  specialFormId: string,
) {
  return attachmentApiClient.get<Blob>(
    `/specialFormPreliminary/${specialFormId}`,
    {
      responseType: "blob",
      headers: {
        "x-otes-token": token,
      },
    },
  );
}

export function generateSpecialForm(
  token: string,
  specialFormId: string,
  payload: any,
) {
  return attachmentApiClient.post<Blob>(
    `/generateSpecialForm/${specialFormId}`,
    { ...payload },
    {
      responseType: "blob",
      headers: {
        "x-otes-token": token,
      },
    },
  );
}

export function getSpecialForm(
  token: string,
  specialFormId: string,
  includeTaskDef = false,
) {
  const responseData = specialFormApiClient
    .get(`/${specialFormId}`, {
      params: {
        includeTaskDef: includeTaskDef,
      },
      headers: {
        "x-otes-token": token,
      },
    })
    .then((response) => response.data);
  return responseData;
}

export function verifyAllDownloaded(token: string, taskId: string) {
  return attachmentApiClient.get<ApiDataResponse<VerifyAllDownloadedData>>(
    `/legal/verifyalldownloaded`,
    {
      params: {
        taskId: taskId,
      },
      headers: {
        "x-otes-token": token,
      },
    },
  );
}

export function getAuthForm(
  token: string,
  authFormApplicationUid: string,
  specialFormUid?: string,
) {
  return attachmentApiClient.get<Blob>("/authorizationForm", {
    responseType: "blob",
    params: {
      applicationUid: authFormApplicationUid,
      uid: specialFormUid,
    },
    headers: {
      "x-otes-token": token,
    },
  });
}

export function getAttachment(
  token: string,
  uid: string,
  isFmcsaDotFile?: boolean,
  formId?: string,
) {
  const path = isFmcsaDotFile ? "/dotFmcsaFile" : "/file";
  const params = {
    uid: uid,
    formId: formId,
  };

  return attachmentApiClient.get<Blob>(path, {
    responseType: "blob",
    params: params,
    headers: {
      "x-otes-token": token,
    },
  });
}

export function getInterviewDoc(
  token: string,
  uids: {
    authFormApplicationUid?: string;
    specialFormUid?: string;
    authFormUid?: string;
    documentUid?: string;
    candidateDownloadFileExternalGuid?: string;
  },
  isFmcsaDotFile?: boolean,
  formId?: string,
) {
  if (uids.authFormApplicationUid) {
    return getAuthForm(
      token,
      uids.authFormApplicationUid,
      uids.specialFormUid,
    ).then();
  } else if (uids.authFormUid) {
    return getAttachment(token, uids.authFormUid, isFmcsaDotFile, formId);
  } else if (uids.specialFormUid) {
    return getAttachment(token, uids.specialFormUid);
  } else if (uids.documentUid) {
    return getAttachment(token, uids.documentUid);
  } else if (uids.candidateDownloadFileExternalGuid) {
    return getAttachment(token, uids.candidateDownloadFileExternalGuid);
  } else {
    throw "cannot retrieve as requested";
  }
}

export async function resetTask(token: string, taskId: string) {
  const response = await tasksApiClient.patch<GenericApiResponse>(
    `/${taskId}/reset`,
    {},
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function resetTasks(token: string) {
  const response = await tasksApiClient.patch<GenericApiResponse>(
    `/reset`,
    {},
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function clearAllSavedTasks(token: string) {
  const response = await tasksApiClient.patch<GenericApiResponse>(
    `/clear`,
    {},
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function clearSavedTask(
  token: string,
  taskId: string,
): Promise<GenericApiResponse> {
  const response = await tasksApiClient.patch<GenericApiResponse>(
    `/${taskId}/clear`,
    {},
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function createDataRequestAndPublicToken(
  token: string,
  payload: MeasureOneDataRequestToken,
) {
  const response = await measureoneApiClient.post<
    ApiDataResponse<MeasureOneDataResponseToken>
  >(
    `/createDataRequestAndPublicToken`,
    { ...payload },
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data;
}

export async function employmentVerified(
  token: string,
  payload: MeasureOneEmployment,
) {
  const response = await measureoneApiClient.post<
    ApiDataResponse<GenericApiResponse>
  >(
    `/employmentVerified`,
    { ...payload },
    {
      headers: { "x-otes-token": token },
    },
  );
}
