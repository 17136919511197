import { FormComponentProps } from "components/tasks/taskHelpers";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import TaskButtons from "components/common/basic/TaskButtons";
import { t, Trans } from "@lingui/macro";
import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import FormCheckbox from "components/common/form/FormCheckbox";
import { FileUpload } from "components/common/file/FileUpload";
import { useState } from "react";
import useOtesModal from "error/useOtesModal";

export interface UploadFormFields {
  notAvailable?: boolean;
  documentType?: string;
  comment?: string;
}

export interface DocumentTypeProperties {
  label: string;
  value: string;
}

export interface UploadFormProps extends FormComponentProps<UploadFormFields> {
  documentTypes: DocumentTypeProperties[];
  supportNotAvailable?: boolean;
  showPersonalInfoWarning?: boolean;
  docTypeRequired?: boolean;
  maxFileSizeMB?: number;
  minNumFiles: number;
  allowedFileTypes: string[];
  attachmentQuestion?: string;
  endpoint: string;
}

// ATTACHMENT MAX SIZE -> maxFileSizeMB
export default function UploadForm({
  documentTypes,
  supportNotAvailable = true,
  showPersonalInfoWarning = false,
  docTypeRequired = true,
  maxFileSizeMB = 32,
  minNumFiles,
  allowedFileTypes,
  attachmentQuestion,
  endpoint,
  onSubmit,
  goToStatusCheck,
}: UploadFormProps): JSX.Element {
  const [isUploadedComplete, setUploadComplete] = useState<boolean>(false);
  const methods = useForm<UploadFormFields>({
    defaultValues: { notAvailable: false },
  });
  const { triggerOtesModal } = useOtesModal();

  const notAvailable = supportNotAvailable && methods.watch("notAvailable");

  const onFormSubmit = (data: UploadFormFields) => {
    if (isUploadedComplete || (supportNotAvailable && notAvailable)) {
      onSubmit(data);
    } else {
      triggerOtesModal({
        bodyText: t`Please upload the requested document(s) before submitting.`,
      });
    }
  };

  const uploadForm = "uploadForm";

  return (
    <VStack width="100%" maxWidth="400px" p={1}>
      {attachmentQuestion && <Text p={2}>{attachmentQuestion}</Text>}
      <FormProvider {...methods}>
        <form id={uploadForm} onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Heading as="h2" size="md" color="orange.600">
            <Trans>Upload</Trans>
          </Heading>
          <Box>
            {supportNotAvailable && (
              <FormCheckbox
                fieldId={"notAvailable"}
                label={t`Not available`}
                required={false}
                deps={["comment"]}
              />
            )}
            {!notAvailable && (
              <>
                {showPersonalInfoWarning && (
                  <Text
                    p={2}
                    mb={4}
                    mt={4}
                    borderRadius={5}
                    border={"1px"}
                    borderColor="orange"
                    fontSize="xs">
                    <Trans>
                      If the documentation you are providing has personal
                      information other than your name, the company name, and a
                      date (ie. social security number, address, date of birth,
                      and/or salary) you may choose to white/black out the
                      information before uploading.
                    </Trans>
                  </Text>
                )}
                <FileUpload
                  accept={allowedFileTypes}
                  minNumFiles={minNumFiles}
                  maxFilesSizeMb={maxFileSizeMB}
                  endpoint={endpoint}
                  onComplete={() => setUploadComplete(true)}
                />
                {docTypeRequired && (
                  <FormSelect
                    fieldId="documentType"
                    label={t`Select the document type to upload`}
                    options={documentTypes.map((type) => type.label)}
                    required={!notAvailable}
                  />
                )}
              </>
            )}
            <FormInput
              fieldId={"comment"}
              label={t`Comment`}
              required={notAvailable}
            />
          </Box>
          <TaskButtons onBack={goToStatusCheck} form={uploadForm} />
        </form>
      </FormProvider>
    </VStack>
  );
}
