import jwtDecode from "jwt-decode";
import OtesError from "components/common/basic/OtesError";
import { AxiosError } from "axios";
import { getSystemStatus } from "auth/authApi";

export interface TokenData {
  APPLICATIONID: string;
  verifications: string[];
  exp: number;
  comm_type: "" | "email" | "otesphone";
}

const emptyTokenData: TokenData = {
  APPLICATIONID: "",
  verifications: [],
  exp: -1,
  comm_type: "",
};

export function getTokenData(token: string): TokenData {
  if (token) {
    try {
      return jwtDecode<TokenData>(token);
    } catch (Error) {
      return emptyTokenData;
    }
  } else {
    return emptyTokenData;
  }
}

export const isValidJwt = (token: null | string): boolean => {
  if (!token) {
    return false;
  }

  const appUid = getTokenData(token)?.APPLICATIONID;
  return !!appUid && appUid !== "";
};

export const isInvalidJwt = (token: null | string): boolean =>
  !isValidJwt(token);

export const getVerifications = (token: string): string[] =>
  getTokenData(token)?.verifications || [];

export const verificationsCount = (token: string): number =>
  getVerifications(token)?.length || 0;

export const getApplicationUid = (token: string): string =>
  getTokenData(token)?.APPLICATIONID || "";

export const hasSameAppUid = (token: string, otherToken: string): boolean =>
  isValidJwt(token) &&
  isValidJwt(otherToken) &&
  getApplicationUid(token) == getApplicationUid(otherToken);

export const hasDifferentAppUid = (
  token: string,
  otherToken: string,
): boolean => !hasSameAppUid(token, otherToken);

export const getPreChecks = (token: string): string[] => {
  const preChecks = [];
  const verifications = getVerifications(token);

  if (!verifications?.length) {
    preChecks.push("quickGuide", "pii", "commPrefs");
  } else {
    if (!verifications?.includes("pii")) {
      preChecks.push("pii");
    }

    if (!verifications?.includes("comm")) {
      preChecks.push("commPrefs");
    }

    if (!verifications?.includes("tos")) {
      preChecks.push("quickGuide");
    }
  }

  return preChecks;
};

export enum StorageKey {
  REMEMBER_ME = "rememberMe",
  APPLICANT_NAME = "applicantName",
  X_OTES_TOKEN = "X_OTES_TOKEN",
}

const getStorage = (): Storage => {
  if (isRememberMeEnabled()) {
    return localStorage;
  } else {
    return sessionStorage;
  }
};

export const clearAllStorage = (): void => {
  localStorage.clear();
  sessionStorage.clear();
};

export const isRememberMeEnabled = (): boolean => {
  return `${localStorage.getItem(StorageKey.REMEMBER_ME)}` === "true";
};

export const isRememberMeSet = (): boolean => {
  return localStorage.getItem(StorageKey.REMEMBER_ME) != undefined;
};

export const setRememberMe = (rememberMe: boolean): void => {
  localStorage.setItem(StorageKey.REMEMBER_ME, `${rememberMe}`);
};

export const getApplicantName = (): string | null => {
  return getStorage().getItem(StorageKey.APPLICANT_NAME);
};

export const setApplicantName = (name: string): void => {
  getStorage().setItem(StorageKey.APPLICANT_NAME, name);
};

export const getStoredToken = (): string | null => {
  return getStorage().getItem(StorageKey.X_OTES_TOKEN);
};

export const storeToken = (token: string): void => {
  getStorage().setItem(StorageKey.X_OTES_TOKEN, token);
};

export const clearToken = (): void => {
  getStorage().removeItem(StorageKey.X_OTES_TOKEN);
};

const getUrlToken = (): string | null => {
  let search = window.location.search;

  // TODO (JDL:2022-04-07) This can be removed once we're past the transition period to CC 2.0
  if (!search) {
    const legacyPath = "#/landing/";
    const hash = window.location.hash;

    if (hash && hash.indexOf(legacyPath) === 0) {
      search = hash.substr(legacyPath.length);
    }
  }

  return new URLSearchParams(search).get("t");
};

const getPreferredToken = (
  rawUrlToken: string,
  rawStoredToken: string,
): null | string => {
  if (
    isValidJwt(rawUrlToken) &&
    isValidJwt(rawStoredToken) &&
    rawUrlToken !== rawStoredToken &&
    (hasDifferentAppUid(rawStoredToken, rawUrlToken) ||
      verificationsCount(rawUrlToken) > verificationsCount(rawStoredToken))
  ) {
    // If:
    // 1. Both url token and stored token are present but not equal.
    // 2. Stored token has less verifications OR application uids are not equal.

    // Clear all storage so that the token, remember me, and applicant name are forgotten.
    clearAllStorage();

    // Prioritize the raw url token
    return rawUrlToken;
  }

  if (isValidJwt(rawStoredToken)) {
    return rawStoredToken;
  }

  if (isValidJwt(rawUrlToken)) {
    return rawUrlToken;
  }

  return null;
};

export const getStoredOrUrlToken = (): null | string => {
  return getPreferredToken(getUrlToken() || "", getStoredToken() || "");
};

export const handleOtesError = (otesError: OtesError): void => {
  // If the error is "system" or "entry", refresh the application to trigger
  // the appropriate error page.
  if (otesError.isSystemOrEntryError) {
    window.location.reload();
  }

  if (otesError.hasHttpStatus5xx) {
    getSystemStatus().catch(() => {
      // If even system status is failing, reload to trigger the system error page.
      window.location.reload();
    });
  }
};

export const handleOtesErrorFromAxiosError = (axiosError: AxiosError) => {
  const otesErrorCode = axiosError?.response?.data?.code;
  const httpErrorCode = axiosError?.response?.status;

  handleOtesError(new OtesError(otesErrorCode, httpErrorCode));
};
