import { memo } from "react";
import { Table, Text } from "@chakra-ui/react";
import TableRowInputReadOnly from "components/common/menu/debug/DebugTableRowInputReadOnly";
import {
  deviceDetect,
  getSelectorsByUserAgent,
  parseUserAgent,
} from "react-device-detect";

const DeviceInformation = () => {
  const userAgent = navigator.userAgent;
  const detectedDevice = deviceDetect(userAgent);
  const deviceSelectors = getSelectorsByUserAgent(userAgent);
  const parsedUserAgent = parseUserAgent(userAgent);

  const deviceInformation = JSON.stringify(
    {
      detectedDevice: detectedDevice,
      deviceSelectors: deviceSelectors,
      parsedUserAgent: parsedUserAgent,
    },
    null,
    3,
  );

  return (
    <>
      <Text align="center" width="100%" borderTop="double" pt={2}>
        Device Information
      </Text>
      <Table width="100%" fontSize="12px">
        {detectedDevice &&
          Object.keys(detectedDevice).map((value) => {
            const label = value;
            const labelValue = detectedDevice[label];

            return (
              <TableRowInputReadOnly
                key={label}
                label={label}
                value={labelValue}
              />
            );
          })}
      </Table>
      <Text align="center" width="100%" fontSize="13px" my={3}>
        Full Device Information
      </Text>
      <textarea
        onFocus={(event: any) => event.target.select()}
        readOnly
        style={{
          overflow: "auto",
          border: "solid black 1px",
          width: "100%",
          height: "100px",
          fontSize: "12px",
          minHeight: "200px",
        }}
        value={deviceInformation}
      />
    </>
  );
};

export default memo(DeviceInformation);
