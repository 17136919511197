import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { get, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";

export interface FormCheckboxProps extends Omit<CheckboxProps, "defaultValue"> {
  fieldId: string;
  formLabel?: string;
  label: string;
  required?: boolean;
  requiredMessage?: string;
  helperText?: string;
  disabled?: boolean;
  deps?: string[];
}

export default function FormCheckbox(props: FormCheckboxProps): JSX.Element {
  const {
    fieldId,
    formLabel,
    label,
    required = false,
    requiredMessage,
    helperText,
    disabled,
    deps,
    children,
    ...rest
  } = props;
  const { register, formState } = useFormContext();

  const validateRequired = required && !disabled;
  const isInvalid = validateRequired && get(formState.errors, fieldId);

  return (
    <FormControl pt={"0.5rem"} isInvalid={isInvalid}>
      {formLabel && <FormLabel>{formLabel}</FormLabel>}
      <Checkbox
        borderColor={"gray.600"}
        isDisabled={disabled}
        id={fieldId}
        {...rest}
        {...register(fieldId, {
          required: {
            value: validateRequired,
            message: requiredMessage || t`This is required`,
          },
          deps,
        })}>
        {label}
        {children}
      </Checkbox>
      <FormErrorMessage>
        {get(formState.errors, `${fieldId}.message`)}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
