import { Center, Text } from "@chakra-ui/react";
import useAuth from "auth/useAuth";
import { getTokenData } from "auth/authHelpers";
import { AppPathHelper } from "AppPath";
import DebuggingActions from "components/common/menu/debug/DebuggingActions";
import TableRowInputReadOnly from "components/common/menu/debug/DebugTableRowInputReadOnly";
import DeviceInformation from "components/common/menu/debug/DeviceInformation";

export default function Debug(): JSX.Element {
  const location = window.location;

  const { token } = useAuth();
  const tokenData = getTokenData(token);
  const localStorageJson = JSON.stringify(localStorage, null, 3);
  const sessionStorageJson = JSON.stringify(sessionStorage, null, 3);
  const tokenDataJson = JSON.stringify(tokenData, null, 3);

  const expirationDate = new Date(
    new Date().getTime() + tokenData.exp,
  ).toISOString();
  const expirationDays = Math.floor(tokenData.exp / 1000 / 60 / 60 / 24);

  const selectTarget = (event: any) => event.target.select();

  return (
    <>
      <Center mt={1}>Useful Info</Center>
      <table style={{ width: "100%", fontSize: "12px" }}>
        <TableRowInputReadOnly
          label="Link"
          value={`${location.protocol}//${location.host}/${AppPathHelper.TOKEN_PATTERN}${token}`}
        />
        <TableRowInputReadOnly label="Token" value={token} />
        <TableRowInputReadOnly label="App" value={tokenData.APPLICATIONID} />
        <TableRowInputReadOnly
          label="Expiry"
          value={`${expirationDate} (${expirationDays} days)`}
        />
        <TableRowInputReadOnly label="Comm Type" value={tokenData.comm_type} />
        <TableRowInputReadOnly
          label="Verifications"
          value={tokenData.verifications}
        />
      </table>
      <DebuggingActions />
      <Text align="center" width="100%" borderTop="double" my={3}>
        JWT / Local Storage / Session Storage
      </Text>
      <Text align="center" width="100%" fontSize="13px">
        JWT
      </Text>
      <textarea
        onFocus={selectTarget}
        readOnly
        style={{
          overflow: "auto",
          border: "solid black 1px",
          width: "100%",
          height: "100px",
          fontSize: "12px",
        }}
        value={`${tokenDataJson}`}
      />
      {localStorageJson !== "{}" && (
        <>
          <Text align="center" width="100%" fontSize="13px">
            Local Storage
          </Text>
          <textarea
            onFocus={selectTarget}
            readOnly
            style={{
              overflow: "auto",
              border: "solid black 1px",
              width: "100%",
              height: "100px",
              fontSize: "12px",
            }}
            value={`${localStorageJson}`}
          />
        </>
      )}
      {sessionStorageJson !== "{}" && (
        <>
          <Text align="center" width="100%" fontSize="13px">
            Session Storage
          </Text>
          <textarea
            onFocus={selectTarget}
            readOnly
            style={{
              overflow: "auto",
              border: "solid black 1px",
              width: "100%",
              height: "100px",
              fontSize: "12px",
            }}
            value={`${sessionStorageJson}`}
          />
        </>
      )}
      <DeviceInformation />
    </>
  );
}
