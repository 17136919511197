/**
 * A custom hook to simplify downloading a file via the API.
 * Inspired by https://www.techprescient.com/react-custom-hook-typescript-to-download-a-file-through-api/
 */

import { AxiosResponse } from "axios";

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
  readonly preDownloading?: () => void;
  readonly postDownloading?: () => void;
  readonly onError?: () => void;
  readonly getFileName?: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName = () => "file_" + Date.now(),
}: DownloadFileProps): DownloadedFileInfo => {
  const download = async () => {
    try {
      !!preDownloading && preDownloading();
      const { headers, data } = await apiDefinition();
      // get the text between quotes after "filename=" in the header
      const fileName = headers["content-disposition"]
        .split("filename=")[1]
        .split('"')[1];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([data]));
      link.download = fileName;
      link.click();
      !!postDownloading && postDownloading();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      !!onError && onError();
    }
  };

  return { download };
};
