import {
  InterviewApiSubForm,
  InterviewFormType,
} from "components/tasks/interviews/interviewApiTypes";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import TaskButtons from "components/common/basic/TaskButtons";
import KeyValueSubForm, {
  KeyValueApiSubForm,
} from "components/tasks/interviews/key-value/KeyValueSubForm";
import { Box, Button, VStack } from "@chakra-ui/react";
import BoolStrSubForm, {
  BoolStrApiSubForm,
} from "components/tasks/interviews/boolean-string-data/BoolStrSubForm";
import DropdownSelectionForm, {
  DropdownSelectionApiSubForm,
} from "components/tasks/interviews/dropdown-selection/DropdownSelectionForm";
import ContactInformationForm, {
  ContactInformationApiSubForm,
} from "components/tasks/interviews/contact-information/ContactInformationForm";
import EducationDatesForm, {
  EducationDatesApiSubForm,
} from "components/tasks/interviews/education-dates/EducationDatesForm";
import SocialSecurityNumberForm, {
  SocialSecurityNumberApiSubForm,
} from "components/tasks/interviews/social-security-number/SocialSecurityNumberForm";
import CreditFreezeForm, {
  CreditFreezeApiSubForm,
} from "components/tasks/interviews/credit-freeze/CreditFreezeForm";
import DateOfBirthForm, {
  DateOfBirthApiSubForm,
} from "components/tasks/interviews/date-of-birth/DateOfBirthForm";
import SupervisorNameForm, {
  SupervisorNameApiSubForm,
} from "components/tasks/interviews/supervisor-name/SupervisorNameForm";
import ConfirmNameForm, {
  ConfirmNameApiSubForm,
} from "components/tasks/interviews/confirm-name/ConfirmNameForm";
import ConfirmCitizenshipForm, {
  ConfirmCitizenshipApiSubForm,
} from "components/tasks/interviews/confirm-citizenship/ConfirmCitizenshipForm";
import GraduationStatusForm, {
  GraduationStatusApiSubForm,
} from "components/tasks/interviews/graduation-status/GraduationStatusForm";
import EmploymentDatesForm, {
  EmploymentDatesApiSubForm,
} from "components/tasks/interviews/employment-dates/EmploymentDatesForm";
import { genericOnSave } from "components/tasks/taskHelpers";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import AddressDataForm, {
  AddressDataApiSubForm,
} from "components/tasks/interviews/address-data/AddressDataForm";

export interface MultiFormInterviewTaskProps {
  forms: InterviewApiSubForm[];
  taskId: string;
  isSavable?: boolean;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  goToStatusCheck?: () => void;
}

export default function MultiFormInterviewTask({
  forms,
  taskId,
  isSavable = false,
  onSubmit,
  goToStatusCheck,
}: MultiFormInterviewTaskProps): JSX.Element {
  const methods = useForm<InterviewSubmitProps>({
    //set the default values so that react-hook-form can properly restore them
    defaultValues: forms.reduce(
      //reduce the provided formData to the same shape we are using to register fields
      (combinedFormData, currentForm) => ({
        //first add previously added data, then add current form's data
        ...combinedFormData,
        [currentForm.formParameters.formId]: currentForm.formFields,
      }),
      {}, //reducer starts with an empty object
    ),
  });

  const interviewForm = `${taskId}-interviewForm`;

  return (
    <VStack w="100%" p={1}>
      <Box>
        <FormProvider {...methods}>
          <form id={interviewForm} onSubmit={methods.handleSubmit(onSubmit)}>
            {forms.map((form, index) => {
              const { formType, formId } = form.formParameters;
              switch (formType) {
                case InterviewFormType.KEY_VALUE:
                  return (
                    <KeyValueSubForm
                      key={`${formId}-keyValue`}
                      {...(form as KeyValueApiSubForm)}
                    />
                  );
                case InterviewFormType.BOOLEAN_STRING:
                  return (
                    <BoolStrSubForm
                      key={`${formId}-booleanString`}
                      {...(form as BoolStrApiSubForm)}
                    />
                  );
                case InterviewFormType.DROPDOWN_SELECTION:
                  return (
                    <DropdownSelectionForm
                      key={`${formId}-dropdownSelection`}
                      hasParentForm={true}
                      {...(form as DropdownSelectionApiSubForm)}
                    />
                  );
                case InterviewFormType.CITIZENSHIP_DATA:
                  return (
                    <ConfirmCitizenshipForm
                      key={`${formId}-citizenshipData`}
                      hasParentForm={true}
                      {...(form as ConfirmCitizenshipApiSubForm)}
                    />
                  );
                case InterviewFormType.GRAD_STATUS_DATE:
                  return (
                    <GraduationStatusForm
                      key={`${formId}-gradStatusDate`}
                      hasParentForm={true}
                      {...(form as GraduationStatusApiSubForm)}
                    />
                  );
                case InterviewFormType.CONTACT_INFORMATION:
                  return (
                    <ContactInformationForm
                      key={`${formId}-contactInformation`}
                      hasParentForm={true}
                      {...(form as ContactInformationApiSubForm)}
                    />
                  );
                case InterviewFormType.EDUCATION_DATES:
                  return (
                    <EducationDatesForm
                      key={`${formId}-educationDates`}
                      hasParentForm={true}
                      {...(form as EducationDatesApiSubForm)}
                    />
                  );
                case InterviewFormType.EMPLOYMENT_DATES:
                  return (
                    <EmploymentDatesForm
                      key={`${formId}-employmentDates`}
                      hasParentForm={true}
                      {...(form as EmploymentDatesApiSubForm)}
                    />
                  );
                case InterviewFormType.SOCIAL_SECURITY_NUMBER:
                  return (
                    <SocialSecurityNumberForm
                      key={`${formId}-socialSecurityNumber`}
                      hasParentForm={true}
                      {...(form as SocialSecurityNumberApiSubForm)}
                    />
                  );
                case InterviewFormType.CREDIT_FREEZE:
                  return (
                    <CreditFreezeForm
                      key={`${formId}-creditFreeze`}
                      hasParentForm={true}
                      {...(form as CreditFreezeApiSubForm)}
                    />
                  );
                case InterviewFormType.DATE_OF_BIRTH:
                  return (
                    <DateOfBirthForm
                      key={`${formId}-dateOfBirth`}
                      hasParentForm={true}
                      {...(form as DateOfBirthApiSubForm)}
                    />
                  );
                case InterviewFormType.SUPERVISOR_NAME:
                  return (
                    <SupervisorNameForm
                      key={`${formId}-supervisorName`}
                      hasParentForm={true}
                      {...(form as SupervisorNameApiSubForm)}
                    />
                  );
                case InterviewFormType.CONFIRM_NAME:
                  return (
                    <ConfirmNameForm
                      key={`${formId}-confirmName`}
                      hasParentForm={true}
                      {...(form as ConfirmNameApiSubForm)}
                    />
                  );
                case InterviewFormType.ADDRESS_DATA:
                  return (
                    <AddressDataForm
                      key={`${formId}-addressData`}
                      {...(form as AddressDataApiSubForm)}
                    />
                  );
                default:
                  return (
                    <Button
                      key={`${formId}-notImplemented`}
                      width={"full"}
                      my={1}
                      colorScheme="orange"
                      onClick={() => goToStatusCheck?.()}>
                      Form type ({formType}) not yet implemented.
                    </Button>
                  );
              }
            })}
            <TaskButtons
              onBack={goToStatusCheck}
              onSave={genericOnSave(methods, onSubmit, isSavable)}
              form={interviewForm}
            />
          </form>
        </FormProvider>
      </Box>
    </VStack>
  );
}
