import { HistoryFormEntry } from "components/tasks/taskHelpers";
import { useFormContext } from "react-hook-form";
import { Box, CloseButton } from "@chakra-ui/react";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import FormInput from "components/common/form/FormInput";
import FormCheckbox from "components/common/form/FormCheckbox";
import AddressForm from "components/common/form/AddressForm";
import { t } from "@lingui/macro";
import FormSelect from "components/common/form/FormSelect";
import { useEffect, useState } from "react";
import { PHONE_NUMBER } from "components/common/form/fieldConstants";
import { COUNTRY_UNITED_STATES } from "tasks/apiConstants";
import {
  onKeyDownEnforceNumeric,
  onKeyDownEnforcePhone,
} from "util/InputUtils";
import {
  DatePickerInput,
  validateFormDate,
} from "components/common/form/DatePickerInput";
import {
  NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD,
  NO_HISTORY_FOR_THIS_PERIOD_FIELD,
} from "./EmploymentHistoryForm";

export interface EmploymentOrGapFormFields extends HistoryFormEntry {
  SERVICE_ID?: string;
  ENTRY_TYPE: string;
  IS_THIS_YOUR_PRESENT_EMPLOYER: string;
  ARE_YOU_CURRENTLY_UNEMPLOYED: string;
  EXPLANATION?: string;
  MAY_WE_CONTACT_THIS_EMPLOYER: string;
  IS_DOT_REGULATED: string;
  REGULATED_TYPE?: string;
  EMPLOYER_TYPE?: { int: string };
  CONTRACTOR?: boolean;
  COMPANY_NAME?: string;
  COMPANY_PHONE?: string;
  COUNTRY?: string;
  STREET_ADDRESS?: string;
  CITY?: string;
  STATE?: string;
  TITLEPOSITION_WHILE_EMPLOYED?: string;
  NAME_WHILE_EMPLOYED?: string;
  EMPLOYEE_NUMBER?: string;
}

interface EmploymentOrGapFormProps {
  entryId: string;
  showDotQuestion?: boolean;
  regulationOptions?: string[];
  employerOptions?: {
    [label: string]: string[];
  };
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export const EMPTY_EMPLOYMENT: EmploymentOrGapFormFields = {
  ENTRY_TYPE: "",
  IS_THIS_YOUR_PRESENT_EMPLOYER: "",
  ARE_YOU_CURRENTLY_UNEMPLOYED: "",
  START_DATE: "",
  startYear: "",
  startMonth: "",
  startDay: "",
  startDate: "",
  endYear: "",
  endMonth: "",
  endDay: "",
  endDate: "",
  END_DATE: "",
  EXPLANATION: "",
  MAY_WE_CONTACT_THIS_EMPLOYER: "",
  IS_DOT_REGULATED: "",
  REGULATED_TYPE: "",
  EMPLOYER_TYPE: undefined,
  CONTRACTOR: false,
  COMPANY_NAME: "",
  COMPANY_PHONE: "",
  COUNTRY: COUNTRY_UNITED_STATES,
  STREET_ADDRESS: "",
  CITY: "",
  STATE: "",
  TITLEPOSITION_WHILE_EMPLOYED: "",
  NAME_WHILE_EMPLOYED: "",
  EMPLOYEE_NUMBER: "",
};

export default function EmploymentOrGapForm({
  entryId,
  showDotQuestion = false,
  regulationOptions = [],
  employerOptions = {},
  showRemoveButton = false,
  onRemoveClick,
}: EmploymentOrGapFormProps): JSX.Element {
  const { watch, control, setValue, getValues } = useFormContext();

  const [usePhoneNumberMask, setUsePhoneNumberMask] = useState<boolean>(true);
  const [showEndDate, setShowEndDate] = useState<boolean>(true);
  const [showRegulatedType, setShowRegulatedType] = useState<boolean>(
    regulationOptions?.length > 0,
  );
  const [showEmployerType, setShowEmployerType] = useState<boolean>(false);

  const isDotRegulatedValue = getValues(`${entryId}.IS_DOT_REGULATED`);
  useEffect(() => {
    //defaults to true for new forms
    if (!isDotRegulatedValue) {
      setValue(`${entryId}.IS_DOT_REGULATED`, "true");
    }
  }, []);

  const isDotRegulated = watch(`${entryId}.IS_DOT_REGULATED`);
  useEffect(() => {
    setShowRegulatedType(
      regulationOptions?.length > 0 && isDotRegulated === "true",
    );
  }, [isDotRegulated]);

  const regulatedType = watch(`${entryId}.REGULATED_TYPE`);
  useEffect(() => {
    setShowEmployerType(!!employerOptions[regulatedType]);
  }, [regulatedType]);

  const entryType = watch(`${entryId}.ENTRY_TYPE`);
  const presentEmployer = watch(`${entryId}.IS_THIS_YOUR_PRESENT_EMPLOYER`);
  const currentlyUnemployed = watch(`${entryId}.ARE_YOU_CURRENTLY_UNEMPLOYED`);

  useEffect(() => {
    setShowEndDate(
      (entryType !== "GAP" && presentEmployer !== "true") ||
        (entryType === "GAP" && currentlyUnemployed !== "true"),
    );
  }, [entryType, presentEmployer, currentlyUnemployed]);

  const country = watch(`${entryId}.COUNTRY`);
  const companyPhone = watch(`${entryId}.COMPANY_PHONE`);

  useEffect(() => {
    setUsePhoneNumberMask(
      country === COUNTRY_UNITED_STATES &&
        companyPhone?.replace(/\D/g, "")?.length < 11,
    );
  }, [country, companyPhone]);

  const validateStartDate = () => {
    const noHistory = getValues(NO_HISTORY_FOR_THIS_PERIOD_FIELD);
    if (noHistory && noHistory === true) {
      return true;
    }

    const startDatePath = `${entryId}.startDate`;
    const startDate = getValues(startDatePath);

    return validateFormDate(startDate);
  };

  const validateEndDate = () => {
    const noHistory = getValues(NO_HISTORY_FOR_THIS_PERIOD_FIELD);
    if (noHistory && noHistory === true) {
      return true;
    }

    const presentEmployer = getValues(
      `${entryId}.IS_THIS_YOUR_PRESENT_EMPLOYER`,
    );
    if (presentEmployer && presentEmployer === "true") {
      return true;
    }

    const entryType = getValues(`${entryId}.ENTRY_TYPE`);
    const currentlyUnemployed = getValues(
      `${entryId}.ARE_YOU_CURRENTLY_UNEMPLOYED`,
    );
    if (
      entryType &&
      entryType === "GAP" &&
      currentlyUnemployed &&
      currentlyUnemployed === "true"
    ) {
      return true;
    }

    const endDatePath = `${entryId}.endDate`;
    const endDate = getValues(endDatePath);

    return validateFormDate(endDate);
  };

  return (
    <Box className="arrayItemForm">
      {showRemoveButton && (
        <CloseButton
          className="closeButton"
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormRadioGroup
        fieldId={`${entryId}.ENTRY_TYPE`}
        groupLabel={t`Entry type`}
        radios={[
          [t`Employment`, "EMP"],
          [t`Gap in Employment`, "GAP"],
        ]}
        control={control}
      />
      <FormRadioGroup
        fieldId={`${entryId}.ARE_YOU_CURRENTLY_UNEMPLOYED`}
        groupLabel={t`Are you currently unemployed?`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        hidden={entryType !== "GAP"}
        control={control}
      />
      <FormRadioGroup
        fieldId={`${entryId}.IS_THIS_YOUR_PRESENT_EMPLOYER`}
        groupLabel={t`Is this your present employer?`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        hidden={entryType === "GAP"}
        control={control}
      />
      <DatePickerInput
        label={t`Start date`}
        parentId={`${entryId}`}
        fieldIds={{
          month: "startMonth",
          day: "startDay",
          year: "startYear",
          date: "startDate",
        }}
        validateDate={validateStartDate}
      />
      {showEndDate && (
        <DatePickerInput
          label={t`End date`}
          parentId={`${entryId}`}
          fieldIds={{
            month: "endMonth",
            day: "endDay",
            year: "endYear",
            date: "endDate",
          }}
          validateDate={validateEndDate}
        />
      )}
      {entryType !== "GAP" ? (
        <>
          {presentEmployer === "true" && (
            <FormRadioGroup
              fieldId={`${entryId}.MAY_WE_CONTACT_THIS_EMPLOYER`}
              groupLabel={t`May we contact this employer?`}
              radios={[
                [t`Yes`, "true"],
                [t`No`, "false"],
              ]}
              control={control}
            />
          )}
          {showDotQuestion && (
            <FormRadioGroup
              fieldId={`${entryId}.IS_DOT_REGULATED`}
              groupLabel={t`Was this employment DOT or FAA regulated?`}
              radios={[
                [t`Yes`, "true"],
                [t`No`, "false"],
              ]}
              control={control}
              defaultValue={isDotRegulatedValue ? isDotRegulatedValue : "true"}
            />
          )}
          {showRegulatedType && showDotQuestion && (
            <FormSelect
              fieldId={`${entryId}.REGULATED_TYPE`}
              label={t`Regulated Type`}
              placeHolder={t`Select a regulated type`}
              options={regulationOptions}
            />
          )}
          {showRegulatedType && showEmployerType && (
            <FormSelect
              fieldId={`${entryId}.EMPLOYER_TYPE`}
              label={t`Employer Type`}
              placeHolder={t`Please select the employer type`}
              options={employerOptions[regulatedType] ?? []}
            />
          )}
          <FormCheckbox
            fieldId={`${entryId}.CONTRACTOR`}
            label={t`Employed as a contract employee or through a staffing agency`}
          />
          <FormInput
            fieldId={`${entryId}.COMPANY_NAME`}
            label={t`Company Name`}
            maxLength={255}
          />
          <AddressForm
            showPostalCode={false}
            addressId={entryId}
            fieldIds={{
              country: "COUNTRY",
              street: "STREET_ADDRESS",
              city: "CITY",
              state: "STATE",
            }}
          />
          <FormInput
            fieldId={`${entryId}.COMPANY_PHONE`}
            label={t`Authorized contact phone number for Employment Verification`}
            type={"tel"}
            onKeyDown={
              usePhoneNumberMask
                ? onKeyDownEnforceNumeric
                : onKeyDownEnforcePhone
            }
            inputMask={
              usePhoneNumberMask ? PHONE_NUMBER.PARENS_MASK : undefined
            }
            placeholder={
              usePhoneNumberMask ? PHONE_NUMBER.PARENS_PLACEHOLDER : undefined
            }
            validationPattern={
              usePhoneNumberMask ? PHONE_NUMBER.PARENS_PATTERN : undefined
            }
            validationPatternMessage={PHONE_NUMBER.PATTERN_MESSAGE}
            autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
          />
          <FormInput
            fieldId={`${entryId}.TITLEPOSITION_WHILE_EMPLOYED`}
            label={t`Title/Position while employed`}
            maxLength={100}
          />
          <FormInput
            fieldId={`${entryId}.NAME_WHILE_EMPLOYED`}
            label={t`Name while employed`}
            maxLength={100}
          />
          <FormInput
            fieldId={`${entryId}.EMPLOYEE_NUMBER`}
            label={t`Employee Number`}
            required={false}
            maxLength={100}
          />
        </>
      ) : (
        <FormInput
          label={t`Explanation`}
          fieldId={`${entryId}.EXPLANATION`}
          maxLength={50}
        />
      )}
    </Box>
  );
}
