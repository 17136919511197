import {
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import PersonalInfoForm, { PersonalInfoFormFields } from "./PersonalInfoForm";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import { SubmitHandler } from "react-hook-form";

interface PersonalInfoApiFormFields extends ApiFormFields {
  driversLicenseNumber?: string;
  driversLicenseState?: string;
}

interface PersonalInfoApiFormMessages extends ApiFormMessages {
  dateOfBirthLabel: string;
  driversLicenseLabel: string;
  nationalIdentificationLabel: string;
}

interface PersonalInfoApiFormParameters extends ApiFormParameters {
  requestDateOfBirth?: boolean;
  requestNationalIdentification: boolean;
  requestDriversLicense: boolean;
  driversLicenseIdentificationId: number;
  ssnOption?: boolean;
  requestAirmanCertificateNumber: boolean;
}

export default function PersonalInfoTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_PERSONAL_INFO;
  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      PersonalInfoApiFormFields,
      PersonalInfoApiFormMessages,
      PersonalInfoApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  //create a mutate function for this specific taskId
  const {
    mutate,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const storeValues: SubmitHandler<PersonalInfoFormFields> = async ({
    dobDate,
    driversLicense,
    dlState,
    fullSsn,
    noSsn,
    airmanCertificateNumber,
  }) => {
    const driversLicenseIdentificationId =
      data?.forms[0]?.formParameters?.driversLicenseIdentificationId;

    const dob = dobDate ? dobDate : undefined;

    //if noSsn checked, fill in SSN with bogus ssn "000-00-0000"
    const bogusSsn = "000-00-0000";
    const ssn = noSsn ? bogusSsn : fullSsn;

    mutate({
      formFields: {
        ...(dob && { DATE_OF_BIRTH: dob }),
        ...(driversLicense && { DRIVERS_LICENSE: driversLicense }),
        ...(driversLicenseIdentificationId && {
          DRIVERS_LICENSE_IDENTIFICATION_ID: driversLicenseIdentificationId,
        }),
        ...(dlState && { DRIVERS_LICENSE_STATE: dlState }),
        ...((fullSsn && { SOCIAL_SECURITY_NUMBER: ssn }) || {
          SOCIAL_SECURITY_NUMBER: ssn,
        }),
        ...(noSsn && { NO_SSN: noSsn }),
        ...(airmanCertificateNumber && {
          AIRMAN_CERTIFICATE_NUMBER: airmanCertificateNumber,
        }),
      },
    });
  };

  const clientCustomizedTaskDescription =
    data?.forms[0]?.formMessages?.clientCustomizedTaskDescription;

  const formFields = {
    driversLicenseNumber:
      data?.forms[0]?.formFields?.driversLicenseNumber || "",
    driversLicenseState: data?.forms[0]?.formFields?.driversLicenseState || "",
  };

  const formMessages = {
    dateOfBirthLabel: data?.forms[0]?.formMessages?.dateOfBirthLabel || "",
    nationalIdentificationLabel:
      data?.forms[0]?.formMessages?.nationalIdentificationLabel || "",
    driversLicenseLabel:
      data?.forms[0]?.formMessages?.driversLicenseLabel || "",
  };

  const formParameters = {
    requestDateOfBirth:
      data?.forms[0]?.formParameters?.requestDateOfBirth || false,
    requestNationalIdentification:
      data?.forms[0]?.formParameters?.requestNationalIdentification || false,
    requestDriversLicense:
      data?.forms[0]?.formParameters?.requestDriversLicense || false,
    driversLicenseIdentificationId:
      data?.forms[0]?.formParameters?.driversLicenseIdentificationId || 0,
    ssnOption: data?.forms[0]?.formParameters?.ssnOption || false,
    requestAirmanCertificateNumber:
      data?.forms[0]?.formParameters?.requestAirmanCertificateNumber || false,
  };

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      {clientCustomizedTaskDescription && (
        <Text pb="4">{clientCustomizedTaskDescription}</Text>
      )}
      <PersonalInfoForm
        defaultValues={{
          fullSsn: "",
          driversLicense: formFields.driversLicenseNumber,
          dlState: formFields.driversLicenseState,
          noSsn: false,
          airmanCertificateNumber: "",
        }}
        formMessages={formMessages}
        formParameters={formParameters}
        onSubmit={storeValues}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
