import axios from "axios";
import { TermsOfServiceProps } from "auth/authQueries";

const client = axios.create({
  baseURL: "/api/rest/v1",
});

// either email or phone, not both
export type LoginRequest =
  | {
      email?: string;
      sms?: never;
    }
  | {
      email?: never;
      sms?: string;
    };

export interface LoginData {
  pinCode: string;
}

/**
 * Send PIN to email or phone, whichever is not blank
 * @param email the email address, if provided
 * @param phone the phone number, if provided
 */
export async function sendPIN(
  commType: LoginRequest,
  token: string,
): Promise<ApiDataResponse<LoginData>> {
  const response = await client.post<ApiDataResponse<LoginData>>(
    "/login/sendPIN",
    {
      email: commType.email || "",
      sms: commType.sms || "",
    },
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

/**
 * Checks the given information against the user's communication method(s)
 * @param email the email address, if provided
 * @param phone the phone number, if provided
 */
export async function sendColdLogin({
  email,
  sms,
}: LoginRequest): Promise<ApiDataResponse<LoginData>> {
  const response = await client.post<ApiDataResponse<LoginData>>(
    "/login/sendColdLogin",
    {
      email: email || "",
      sms: sms || "",
    },
    {},
  );
  return response.data;
}

/**
 * Looks for an active application for the user, and sends a login link for that
 * application to the preferred email or sms on file.
 */
export async function sendActiveLogin(
  token: string,
): Promise<ApiDataResponse<LoginData>> {
  const response = await client.post<ApiDataResponse<LoginData>>(
    "/login/sendActiveLogin",
    {},
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export interface GenericApiResponse {
  code: number;
  success: boolean;
  message: string;
}

export interface ApiDataResponse<T> extends GenericApiResponse {
  data: T;
}

export interface TokenData {
  value: string;
  appId?: string;
  commType?: string;
}

interface VerifyPinProps {
  pin: string;
  pinCode: string;
  commType?: "email" | "sms" | undefined;
}

export async function putIdentificationPinVerification(
  data: VerifyPinProps,
  token: string,
): Promise<ApiDataResponse<TokenData>> {
  const pinResponse = await client.put<ApiDataResponse<TokenData>>(
    "/login/identificationPin",
    { ...data },
    { headers: { "x-otes-token": token } },
  );

  return pinResponse.data;
}

/**
 * Verifies the user's entered PIN
 * @param pin the user-entered PIN
 * @param pinCode the accompanying pinVerificationCode originally returned from sendColdLogin
 */
export async function putPinVerification(
  pin: string,
  pinCode: string,
  commType?: "email" | "sms" | undefined,
): Promise<ApiDataResponse<TokenData>> {
  const pinResponse = await client.put<ApiDataResponse<TokenData>>(
    "/login/getToken",
    {
      pin,
      pinCode,
      commType,
    },
    {},
  );

  return pinResponse.data;
}

export interface SimpleTokenData {
  token: string;
}

/**
 * Calls the get method using the given token as a header on the request. A 200OK response means the token is good.
 * @param token the token to put on the header.
 */
export async function verifyToken(
  token: string,
): Promise<ApiDataResponse<SimpleTokenData>> {
  const response = await client.get<ApiDataResponse<SimpleTokenData>>(
    "/security/verifyToken",
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data;
}

export async function getSystemStatus(): Promise<
  ApiDataResponse<SimpleTokenData>
> {
  const response = await client.get<ApiDataResponse<SimpleTokenData>>(
    "/security/systemStatus",
    {},
  );

  return response.data;
}

/**
 * Unset TOS agreement
 * @param unsetItems the items to be unsetted
 * @param token the token to use on the request
 */
export async function unsetTos(token: string): Promise<string> {
  const response = await client.patch<TokenData>(
    "/security/unsetTos",
    {},
    {
      headers: { "x-otes-token": token },
    },
  );
  return response.data.value;
}

export interface NationalIdentificationConditions {
  isSocialSecurityNumber: boolean;
  numberOfCharactersToVerify: number;
}

//TODO (WKX:2023-08-02) Deprecated: keeping temporarily for compatibility stopgap
export interface RequiredPiiResponse {
  isApplicationLocked: boolean;
  isDateOfBirthRequired: boolean;
  isNationalIdentificationRequired: boolean;
  nationalIdentificationConditions?: NationalIdentificationConditions;
}

//TODO create "interceptor" (?) to add token header for all authenticated requests
//TODO (WKX:2023-08-02) Deprecated: keeping temporarily for compatibility stopgap
export async function getRequiredPii(
  token: string,
): Promise<RequiredPiiResponse> {
  const response = await client.get<RequiredPiiResponse>(
    "/security/requiredpii",
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data;
}

//TODO (WKX:2023-08-02) Deprecated: keeping temporarily for compatibility stopgap
export interface RequiredPiiRequest {
  //date of birth in "YYYY-MM-DD";
  dateOfBirth?: string;
  nationalIdentificationNumber?: string;
}

//TODO (WKX:2023-08-02) Deprecated: keeping temporarily for compatibility stopgap
export async function postRequiredPii(
  data: RequiredPiiRequest,
  token: string,
): Promise<
  ApiDataResponse<{
    token: TokenData;
  }>
> {
  const response = await client.post<
    ApiDataResponse<{
      token: TokenData;
    }>
  >(
    "/security/requiredpii",
    { ...data },

    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export interface CommPrefsResponse {
  emailPref?: CommPref;
  smsPref?: CommPref;
  pinInfo?: {
    pin?: string;
    pinCode: string;
  };
}

/* eslint-disable-next-line */
export interface CommPrefsRequest extends CommPrefsResponse {}

export interface CommPref {
  readOnly: boolean;
  usable: boolean;
  id: number;
  preferred: boolean;
  value: string;
}

export async function unsetCommPrefs(
  token: string,
): Promise<ApiDataResponse<TokenData>> {
  const response = await client.patch<ApiDataResponse<TokenData>>(
    "/application/removePreferences",
    {},
    { headers: { "x-otes-token": token } },
  );

  return response.data;
}

export async function getCommPref(
  commType: string,
  token: string,
): Promise<CommPref> {
  const response = await client.get<CommPref>("/application/preference", {
    params: { commType: commType },
    headers: { "x-otes-token": token },
  });

  return response.data;
}

export async function getCommPrefs(token: string): Promise<CommPrefsResponse> {
  const response = await client.get<CommPrefsResponse>(
    "/application/preferences",
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data;
}

export interface CommPrefsData {
  pinCode: string;
  pinType: string;
  commPrefValue: string;
  token: string;
}

export async function putCommPrefs(
  data: CommPrefsRequest,
  token: string,
): Promise<ApiDataResponse<CommPrefsData>> {
  const response = await client.put<ApiDataResponse<CommPrefsData>>(
    "/application/preferences",
    { ...data },
    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export interface RequiredIdentification {
  isApplicationLocked: boolean;
  isDateOfBirthRequired: boolean;
  isNationalIdentificationRequired: boolean;
  commPrefs: CommPrefsResponse;
  nationalIdentificationConditions?: NationalIdentificationConditions;
}

export interface RequiredIdentificationResponse {
  token: string;
  requiredIdentification: RequiredIdentification;
}

export async function getRequiredIdentification(
  token: string,
): Promise<RequiredIdentificationResponse> {
  const response = await client.get<
    ApiDataResponse<RequiredIdentificationResponse>
  >("/security/requiredIdentification", {
    headers: { "x-otes-token": token },
  });

  return response.data.data;
}

export interface RequiredIdentificationRequest {
  //date of birth in "YYYY-MM-DD";
  dateOfBirth?: string;
  nationalIdentificationNumber?: string;
}

export async function postRequiredIdentification(
  data: RequiredIdentificationRequest,
  token: string,
): Promise<ApiDataResponse<{ token: TokenData; ssnVerified: boolean }>> {
  const response = await client.post<
    ApiDataResponse<{
      token: TokenData;
      ssnVerified: boolean;
    }>
  >(
    "/security/requiredIdentification",
    { ...data },

    { headers: { "x-otes-token": token } },
  );
  return response.data;
}

export async function postTermsOfService(
  data: TermsOfServiceProps,
  token: string,
): Promise<string> {
  const response = await client.post<TokenData>(
    "/security/termsOfService",
    { ...data },
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data.value;
}
