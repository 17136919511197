import { useFormContext } from "react-hook-form";
import { Box, CloseButton } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import {
  CANADIAN_PROVINCES,
  COUNTRIES,
  COUNTRY_UNITED_STATES,
  STATES,
} from "tasks/apiConstants";
import { getRegionMode } from "components/common/form/formHelpers";
import {
  DatePickerInput,
  validateFormDate,
} from "components/common/form/DatePickerInput";

export interface OffenseFormFields {
  offense: string;
  country: string;
  state?: string;
  county?: string;
  offenseDate: string;
}

interface OffenseFormProps {
  offenseId: string;
  defaultValues: OffenseFormFields;
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export const EMPTY_OFFENSE: OffenseFormFields = {
  offense: "",
  country: COUNTRY_UNITED_STATES,
  state: "",
  county: "",
  offenseDate: "",
};

export default function OffenseForm({
  offenseId,
  defaultValues,
  showRemoveButton = false,
  onRemoveClick,
}: OffenseFormProps): JSX.Element {
  const {
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState,
    formState: { isSubmitting, isValidating },
  } = useFormContext();

  const selectedCountry = watch(`${offenseId}.country`);
  const regionMode = getRegionMode(selectedCountry);

  const validateOffenseDate = () => {
    const convicted = getValues("convicted");
    if (!convicted || convicted === "false") {
      return true;
    }

    const offenseDatePath = `${offenseId}.offenseDate`;
    const offenseDate = getValues(offenseDatePath);

    return validateFormDate(offenseDate);
  };

  return (
    <Box className="arrayItemForm">
      {showRemoveButton && (
        <CloseButton
          className="closeButton"
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormInput
        fieldId={`${offenseId}.offense`}
        label={t`Offense`}
        defaultValue={defaultValues.offense}
      />
      <FormSelect
        fieldId={`${offenseId}.country`}
        label={t`Country`}
        placeHolder={t`Select a country`}
        options={COUNTRIES}
        defaultValue={defaultValues.country}
        onChange={() => {
          //clear the state value when the country changes
          setValue(`${offenseId}.state`, "");
        }}
      />
      {regionMode === "state" || regionMode === "province" ? (
        <FormSelect
          fieldId={`${offenseId}.state`}
          placeHolder={
            regionMode === "state" ? t`Select a state` : t`Select a Province`
          }
          label={regionMode === "state" ? t`State` : t`Province`}
          options={regionMode === "state" ? STATES : CANADIAN_PROVINCES}
          defaultValue={defaultValues?.state}
        />
      ) : (
        <FormInput
          fieldId={`${offenseId}.state`}
          label={t`State/Province`}
          defaultValue={defaultValues?.state}
          required={false}
          maxLength={100}
        />
      )}
      <FormInput
        fieldId={`${offenseId}.county`}
        label={t`County`}
        defaultValue={defaultValues?.county}
        required={false}
        maxLength={100}
      />
      <DatePickerInput
        label={t`Offense Date`}
        parentId={offenseId}
        fieldIds={{
          year: "offenseYear",
          month: "offenseMonth",
          day: "offenseDay",
          date: "offenseDate",
        }}
        validateDate={validateOffenseDate}
      />
    </Box>
  );
}
