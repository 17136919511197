export const onKeyDownEnforceNumeric = (event: React.KeyboardEvent) => {
  if (matchesOperationalKey(event)) {
    return true;
  }

  if (!/^[0-9]$/.test(event.key)) {
    event.preventDefault();
    return false;
  }
};

export const onKeyDownEnforcePhone = (event: React.KeyboardEvent) => {
  if (matchesOperationalKey(event)) {
    return true;
  }

  if (!/^[0-9|(|)|\-|\s]$/.test(event.key)) {
    event.preventDefault();
    return false;
  }
};

function matchesOperationalKey(event: React.KeyboardEvent) {
  return (
    "Enter" === event.key ||
    "Delete" === event.key ||
    "Backspace" === event.key ||
    "Home" === event.key ||
    "End" === event.key ||
    "Tab" === event.key ||
    "ArrowLeft" === event.key ||
    "ArrowRight" === event.key ||
    event.ctrlKey ||
    event.altKey ||
    (event.ctrlKey && event.shiftKey)
  );
}
