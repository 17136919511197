import Header from "components/common/basic/Header";
import { t, Trans } from "@lingui/macro";
import PinForm from "auth/PinForm";
import { Box, Center, Text } from "@chakra-ui/react";
import { getCommPref } from "auth/authApi";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { useSendPinMutator, useVerifyPinMutator } from "auth/authQueries";
import { useQuery } from "react-query";
import useAuth from "auth/useAuth";
import { useEffect, useState } from "react";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import Main from "Main";

interface RequiredPiiOTPProps {
  headerValue?: string;
  commType: string;
  pin?: string;
  email?: string;
  sms?: string;
}

export default function RequiredPiiOTP({
  headerValue,
  commType,
  pin,
  email,
  sms,
}: RequiredPiiOTPProps): JSX.Element {
  const { token } = useAuth();
  const [pinCode, setPinCode] = useState<string>("");

  const isCommTypeEmail = () => {
    return commType === "email";
  };

  const isCommTypeSms = () => {
    return commType === "sms";
  };

  const {
    data: pref,
    isFetching: commPrefFetching,
    isSuccess: hasCommPref,
  } = useQuery(["preference", token], () => getCommPref(commType, token), {
    onError: handleOtesErrorFromAxiosError,
  });

  //The communication preference retrieved is determined by the commType
  const emailPrefValue = isCommTypeEmail() ? pref?.value : "";
  const smsPrefValue = isCommTypeSms() ? pref?.value : "";

  //initialize mutators
  const verifyPinMutator = useVerifyPinMutator({
    pinCode: pinCode,
    commType: isCommTypeEmail() ? "email" : "sms",
  });

  const sendPinMutator = useSendPinMutator({
    email: emailPrefValue,
    sms: smsPrefValue,
    setPinCode: setPinCode,
  });

  useEffect(() => {
    //send PIN on page load if haven't already
    if (hasCommPref && !pinCode) {
      sendPinMutator.mutate();
    }
  }, [hasCommPref]);

  if (verifyPinMutator.isSuccess) {
    // token is updated with PII verified
    // return to Main
    return <Main />;
  } else if (
    commPrefFetching ||
    sendPinMutator.isLoading ||
    verifyPinMutator.isLoading
  ) {
    let spinnerText = "Verifying...";
    if (sendPinMutator.isLoading) {
      spinnerText = "Sending PIN...";
    } else if (verifyPinMutator.isLoading) {
      spinnerText = "Verifying PIN...";
    }
    return (
      <>
        <Header text={headerValue} />
        <SpinnerOverlay text={spinnerText} />
      </>
    );
  } else {
    return (
      <>
        <Header text={headerValue} />
        <Box p="4">
          <Center>
            <Box>
              <PinForm
                description={t`A pin was sent to ${
                  isCommTypeEmail() ? email : sms
                }. 
                  This pin will expire in 10 minutes.`}
                onPinSubmit={(pin: string) => verifyPinMutator.mutate(pin)}
                onReSendPin={() => sendPinMutator.mutate()}
              />
              <Center>
                <Text
                  textAlign={"center"}
                  color="orange.600"
                  pt="1rem"
                  fontSize="sm">
                  <Trans>
                    Need Help? Call 1&#8209;888&#8209;222&#8209;0667
                  </Trans>
                </Text>
              </Center>
            </Box>
          </Center>
        </Box>
      </>
    );
  }
}
