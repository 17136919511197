import { extendTheme } from "@chakra-ui/react";

import Colors from "theme/colors";
import Components from "theme/components";
import Styles from "theme/styles";

const overrides = {
  colors: Colors,
  components: Components,
  styles: Styles,
  initialColorMode: "light",
  useSystemColorMode: false,
};

export default extendTheme(overrides);
