import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import {
  EMPTY_LICENSE,
  LicenseFormFields,
} from "components/tasks/licenses/LicenseForm";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import { SubmitHandler } from "react-hook-form";
import LicensesForm, {
  LicensesFormFields,
} from "components/tasks/licenses/LicensesForm";

interface LicenseTaskdefFields {
  LICENSE_TYPE: ApiFieldProperties;
  LICENSE_NUMBER: ApiFieldProperties;
  STATE: ApiFieldProperties;
  ACTIONS_OR_COMPLAINTS: ApiFieldProperties;
}

interface LicensesApiFormFields extends ApiFormFields {
  NO_PROFESSIONAL_LICENSES: ApiFieldProperties;
  PROFESSIONAL_LICENSE_ITEMS: LicenseTaskdefFields[];
}

interface LicensesApiFormMessages extends ApiFormMessages {
  clientCustomizedTaskDescription: string;
}

interface LicensesApiFormParameters extends ApiFormParameters {
  licenseTypeOptions: string[];
}

export default function LicensesTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_PROFESSIONAL_LICENSE;

  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      LicensesApiFormFields,
      LicensesApiFormMessages,
      LicensesApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  function mapApiLicenseToFormLicense(
    apiLicense: LicenseTaskdefFields,
  ): LicenseFormFields {
    return {
      type: apiLicense.LICENSE_TYPE.existingValue || "",
      state: apiLicense.STATE.existingValue || "",
      number: apiLicense.LICENSE_NUMBER.existingValue || "",
      actionsOrComplaints:
        apiLicense.ACTIONS_OR_COMPLAINTS.existingValue || "false",
    };
  }

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const storeLicenses: SubmitHandler<LicensesFormFields> = async ({
    licenses,
    noProfessionalLicenses,
    doSave,
  }: LicensesFormFields) =>
    mutateTaskData({
      formFields: {
        NO_PROFESSIONAL_LICENSES: noProfessionalLicenses,
        ...(!noProfessionalLicenses && {
          PROFESSIONAL_LICENSE_ITEMS: licenses.map((formLicense) => ({
            LICENSE_TYPE: formLicense.type,
            LICENSE_NUMBER: formLicense.number,
            STATE: formLicense.state,
            ACTIONS_OR_COMPLAINTS: formLicense.actionsOrComplaints,
          })),
        }),
      },
      saveOnly: doSave,
    });

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <Text pb="4">
        {data?.forms[0]?.formMessages?.clientCustomizedTaskDescription}
      </Text>
      <LicensesForm
        licenseTypeOptions={
          data?.forms[0]?.formParameters?.licenseTypeOptions || []
        }
        defaultValues={{
          noProfessionalLicenses:
            data?.forms[0]?.formFields?.NO_PROFESSIONAL_LICENSES
              ?.existingValue === "true",
          licenses: data?.forms[0]?.formFields?.PROFESSIONAL_LICENSE_ITEMS.map(
            (license) => mapApiLicenseToFormLicense(license),
          ) || [EMPTY_LICENSE],
        }}
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        onSubmit={storeLicenses}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
