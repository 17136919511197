import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import CreditFreezeForm from "./CreditFreezeForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type CreditFreezeApiForm = {
  formFields: CreditFreezeApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface CreditFreezeFormFields {
  freezeWasLifted?: string;
  comment?: string;
  doSave?: boolean;
}

interface CreditFreezeApiFormFields extends InterviewFormFields {
  freezeWasLifted?: boolean;
  comment?: string;
}

interface CreditFreezeTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: CreditFreezeApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function CreditFreezeTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: CreditFreezeTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    freezeWasLifted: form.formFields?.freezeWasLifted,
    comment: form.formFields?.comment || "",
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <CreditFreezeForm
      defaultValues={{
        [formParameters.formId]: {
          freezeWasLifted: formFields.freezeWasLifted,
          comment: formFields.comment,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
