import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  useTheme,
  VStack,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import {
  AdditionalQuestionsFormFields,
  Question,
} from "./AdditionalQuestionsTask";
import FormInput from "components/common/form/FormInput";
import { t, Trans } from "@lingui/macro";
import { Fragment } from "react";
import FormTextarea from "../../common/form/FormTextarea";

interface AdditionalQuestionsFormParameters {
  taskId: string;
  questions?: Array<Question>;
}

interface AdditionalQuestionsFormProps extends TaskComponentProps {
  formParameters: AdditionalQuestionsFormParameters;
  defaultValues?: AdditionalQuestionsFormFields;
  isSavable?: boolean;
  onSubmit: SubmitHandler<AdditionalQuestionsFormFields>;
}

export default function AdditionalQuestionsForm({
  defaultValues = { answers: [] },
  formParameters,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: AdditionalQuestionsFormProps): JSX.Element {
  const methods = useForm<AdditionalQuestionsFormFields>({ defaultValues });
  const { fields: answers } = useFieldArray({
    control: methods.control,
    name: "answers",
  });

  const { questions } = formParameters;

  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;

  const additionalQuestionsForm = "additionalQuestionsForm";

  return (
    <VStack w="100%" p={1}>
      <FormProvider {...methods}>
        <form
          id={additionalQuestionsForm}
          onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
          <FormControl as="fieldset">
            <FormLabel
              as="legend"
              fontSize="xl"
              fontWeight="bold"
              color={orange}>
              <Trans>Additional Questions</Trans>
            </FormLabel>
            <Text color={dimGray}>
              <Trans>Please answer the questions below.</Trans>
            </Text>
            {questions?.map((question, index) => {
              return (
                <Fragment key={question.id}>
                  <FormControl as="fieldset">
                    <FormTextarea
                      label={`${question.question}`}
                      fieldId={`answers.${index}.answer`}
                    />
                    <FormInput
                      label={`questionId`}
                      hidden={true}
                      fieldId={`answers.${index}.id`}
                      value={`${question.id}`}
                    />
                  </FormControl>
                </Fragment>
              );
            })}
          </FormControl>
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={
              (isSavable &&
                (() => onSubmit({ ...methods.getValues(), doSave: true }))) ||
              undefined
            }
            form={additionalQuestionsForm}
            mt={"1rem"}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
