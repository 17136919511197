import useAuth from "auth/useAuth";
import TermsOfServiceAndQuickGuide from "auth/TermsOfServiceAndQuickGuide";
import RememberMe from "preChecks/RememberMe";
import RequiredPii from "security/RequiredPii";
import ApplicantTheme from "applicantTheme/ApplicantTheme";
import CommPrefs from "preChecks/CommPrefs";
import { motion } from "framer-motion";
import {
  getPreChecks,
  isRememberMeSet,
  setApplicantName,
  storeToken,
} from "auth/authHelpers";
import * as React from "react";
import { useState } from "react";

interface PreChecksProps {
  theme: ApplicantTheme;
}

export default function PreChecks({
  theme,
}: PreChecksProps): JSX.Element | null {
  const { token, bypassPiiVerify } = useAuth();
  const [welcomed, setWelcomed] = useState<boolean>(isRememberMeSet());

  if (welcomed) {
    setApplicantName(theme?.applicantName || "Candidate");
    storeToken(token);
  }

  if (!welcomed) {
    return (
      <>
        <motion.div
          initial={{
            opacity: 0,
            height: "100%",
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}>
          <RememberMe theme={theme} onStart={() => setWelcomed(true)} />
        </motion.div>
      </>
    );
  } else {
    const preChecks = getPreChecks(token);

    if (preChecks?.includes("quickGuide")) {
      return <TermsOfServiceAndQuickGuide theme={theme} />;
    } else if (preChecks?.includes("commPrefs")) {
      return <CommPrefs />;
    } else if (preChecks?.includes("pii") && !bypassPiiVerify) {
      return <RequiredPii />;
    } else {
      return null;
    }
  }
}
