import Header from "components/common/basic/Header";
import {
  Button,
  Center,
  Container,
  Heading,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import useAuth from "auth/useAuth";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  getRequiredIdentification,
  RequiredIdentificationResponse,
} from "auth/authApi";
import { t, Trans } from "@lingui/macro";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import FormRadioGroup, {
  FormRadioGroupProps,
} from "components/common/form/FormRadioGroup";
import { usePostRequiredIdentificationMutation } from "../auth/authQueries";
import RequiredPiiOTP from "./RequiredPiiOTP";
import NationalIdInput from "components/common/form/NationalIdInput";
import {
  DatePickerInput,
  validateDateOfBirth,
} from "components/common/form/DatePickerInput";

export type RequiredIdentificationValues = {
  date: string;
  natlId: string;
  commType: string;
};

export default function RequiredPii(): JSX.Element {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  const { updateToken, token, setBypassPiiVerify, bypassOtp } = useAuth();
  const methods = useForm<RequiredIdentificationValues>();

  const { data, isFetching } = useQuery(
    ["requiredIdentification", token],
    () => getRequiredIdentification(token),
    {
      onSuccess: (data: RequiredIdentificationResponse) => {
        //Nothing to verify, move forward to status page
        if (
          !data.requiredIdentification.isNationalIdentificationRequired &&
          !data.requiredIdentification.isDateOfBirthRequired
        ) {
          setBypassPiiVerify(true);
          updateToken(data.token);
        }
      },
      onError: handleOtesErrorFromAxiosError,
    },
  );

  const {
    mutate: mutateRequiredIdentification,
    isLoading: isStoring,
    isSuccess: verifiedIdentification,
  } = usePostRequiredIdentificationMutation();

  const onSubmit: SubmitHandler<RequiredIdentificationValues> = async ({
    date,
    natlId,
  }) => {
    const dateOfBirth = date ? date : undefined;

    mutateRequiredIdentification({
      dateOfBirth: dateOfBirth,
      nationalIdentificationNumber: natlId,
    });
  };

  const validateDate = (dateOfBirthString: string) => {
    return validateDateOfBirth(dateOfBirthString);
  };

  const history = useHistory();

  useEffect(() => {
    conditionalHistoryReplace(history, AppPath.IDENTIFICATION, !isFetching);
  });

  const emailMasked = data?.requiredIdentification?.commPrefs?.emailPref?.value;
  const smsMasked = data?.requiredIdentification?.commPrefs?.smsPref?.value;

  const radios: FormRadioGroupProps["radios"] = [
    [t`Email ${emailMasked}`, "email"],
    [t`Phone number ending in ${smsMasked}`, "sms"],
  ];

  radios.forEach((radio, index) => {
    if (!emailMasked && radio[1] === "email") {
      radios.splice(index, 1);
    }
    if (!smsMasked && radio[1] === "sms") {
      radios.splice(index, 1);
    }
  });

  //After a commType is selected and requiredIdentification is validated if any.
  //verifiedIdentification = identification verified, and commType should be selected, move forward to One-Time-Pin
  if (verifiedIdentification) {
    return (
      <RequiredPiiOTP
        headerValue={t`Identification`}
        commType={methods.getValues("commType")}
        email={emailMasked}
        sms={smsMasked}
      />
    );
  } else if (isFetching || isStoring) {
    return (
      <>
        <Header />
        <SpinnerOverlay
          text={isStoring ? "Verifying..." : "Reviewing your info..."}
        />
      </>
    );
  } else {
    const dobVerificationRequired =
      data?.requiredIdentification?.isDateOfBirthRequired;
    const ssnVerificationRequired =
      data?.requiredIdentification?.isNationalIdentificationRequired;
    const otpVerificationRequired = !ssnVerificationRequired && !bypassOtp;

    return (
      <>
        <Header text={t`Identification`} />
        <Container centerContent maxW={"container.sm"}>
          <Heading p={4} as="h1">
            <Trans>Personal Data</Trans>
          </Heading>
          <p>
            <Trans>
              To make sure we have the right candidate, please verify the
              following.
            </Trans>
          </p>
          <VStack mt="10px" p={1}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {dobVerificationRequired && (
                  <Center p={1}>
                    <DatePickerInput
                      label={t`Date of Birth`}
                      fieldIds={{
                        month: "month",
                        day: "day",
                        year: "year",
                        date: "date",
                      }}
                      {...{
                        validateDate: (value) => validateDate(value),
                      }}
                    />
                  </Center>
                )}
                {ssnVerificationRequired && (
                  <NationalIdInput
                    isSsn={
                      data?.requiredIdentification
                        ?.nationalIdentificationConditions
                        ?.isSocialSecurityNumber || false
                    }
                    numberOfChars={
                      data?.requiredIdentification
                        ?.nationalIdentificationConditions
                        ?.numberOfCharactersToVerify || -1
                    }
                  />
                )}
                {otpVerificationRequired && (
                  <Center>
                    <FormRadioGroup
                      fieldId="commType"
                      groupLabel="Select a method to receive your verification PIN:"
                      radios={radios}
                      vertical={true}
                      size="md"
                      paddingLeft={10}
                    />
                  </Center>
                )}
                <Center p={5}>
                  <Button
                    p="5px 40px"
                    m="0"
                    borderRadius="5"
                    color="#fff"
                    backgroundColor={orange}
                    type="submit">
                    <Trans>Verify</Trans>
                  </Button>
                </Center>
              </form>
            </FormProvider>
          </VStack>
        </Container>
      </>
    );
  }
}
