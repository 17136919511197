import { t } from "@lingui/macro";
import AddressForm, {
  AddressFormFields,
  EMPTY_ADDRESS,
} from "components/common/form/AddressForm";
import { Box, CloseButton, Input } from "@chakra-ui/react";
import { get, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import {
  DatePickerInput,
  validateFormDate,
} from "components/common/form/DatePickerInput";

export interface DatedAddressEntryFormFields extends AddressFormFields {
  startMonth: string;
  startDay: string;
  startYear: string;
  startDate: string;
  endMonth: string;
  endDay: string;
  endYear: string;
  endDate: string;
}

export interface DatedAddressEntryWrapperProps {
  startDateOnly?: boolean;
  addressId?: string;
  defaults?: DatedAddressEntryFormFields;
  maxDate?: DateTime;
  onRemoveClick?: () => void;
}

export const EMPTY_DATED_ADDRESS: DatedAddressEntryFormFields = {
  ...EMPTY_ADDRESS,
  startMonth: "",
  startDay: "",
  startYear: "",
  startDate: "",
  endMonth: "",
  endDay: "",
  endYear: "",
  endDate: "",
};

export default function DatedAddressEntryWrapper({
  startDateOnly = false,
  addressId,
  defaults,
  maxDate,
  onRemoveClick,
}: DatedAddressEntryWrapperProps): JSX.Element {
  //simplify navigating form data structures when there's an addressId
  const addrIdPath = addressId ? addressId + "." : "";

  const startMonthPath = `${addrIdPath}startMonth`;
  const startDayPath = `${addrIdPath}startDay`;
  const startYearPath = `${addrIdPath}startYear`;
  const startDatePath = `${addrIdPath}startDate`;

  const endMonthPath = `${addrIdPath}endMonth`;
  const endYearPath = `${addrIdPath}endYear`;
  const endDayPath = `${addrIdPath}endDay`;
  const endDatePath = `${addrIdPath}endDate`;

  const {
    getValues,
    setError,
    clearErrors,
    formState,
    formState: { isSubmitting, isValidating },
  } = useFormContext();

  const validateStartDate = () => {
    const startDate = getValues(startDatePath);

    return validateAddressDate(startDate);
  };

  const validateEndDate = () => {
    const endDate = getValues(endDatePath);

    return validateAddressDate(endDate);
  };

  const validateAddressDate = (date: string) => {
    const validOrErrorMessage = validateFormDate(date);
    if (validOrErrorMessage === true) {
      return validateDateOrder();
    }
    return validOrErrorMessage;
  };

  const validateDateOrder = () => {
    const errorType = "dateOrder";
    const errorMessage = t`End date must be after Start date`;

    const [startDate, endDate] = getValues([startDatePath, endDatePath]);

    const isValid = true;
    const startDateTime = startDate && DateTime.fromISO(startDate);
    const endDateTime = endDate && DateTime.fromISO(endDate);

    if (!!startDateTime && !!endDateTime) {
      if (endDateTime <= startDateTime) {
        setError(startDatePath, {
          type: errorType,
          message: errorMessage,
        });
        setError(endDatePath, {
          type: errorType,
          message: errorMessage,
        });

        return errorMessage;
      } else {
        // Only clear errors that are of the specified type.
        // Else we risk clearing other legitimate validation errors such as 'required'.
        const fields: string[] = [];
        if (get(formState.errors, endDatePath)?.type === errorType) {
          fields.push(endDatePath);
        }
        if (fields.length) {
          clearErrors(fields);
        }
      }
    }
    return isValid;
  };

  return (
    <Box className="arrayItemForm">
      {!!onRemoveClick && (
        <CloseButton
          className="closeButton"
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <DatePickerInput
        fieldIds={{
          month: startMonthPath,
          day: startDayPath,
          year: startYearPath,
          date: startDatePath,
        }}
        label={t`Start Date`}
        {...(!startDateOnly && {
          validateDate: () => validateStartDate(),
        })}
      />
      {!startDateOnly && (
        <DatePickerInput
          fieldIds={{
            month: endMonthPath,
            day: endDayPath,
            year: endYearPath,
            date: endDatePath,
          }}
          label={t`End`}
          {...(!startDateOnly && {
            validateDate: () => validateEndDate(),
          })}
        />
      )}
      <AddressForm addressId={addressId} required={{ postalCode: true }} />
    </Box>
  );
}
