import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  IdVerifyApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import {
  boolStrFieldsToBools,
  flattenExistingValues,
  splitDates,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import IdentityVerificationForm from "components/tasks/IdentityVerificationForm";
import { SubmitHandler } from "react-hook-form";
import EmploymentHistoryForm, {
  EmploymentHistoryFormFields,
  NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD,
  NO_HISTORY_FOR_THIS_PERIOD_FIELD,
} from "components/tasks/employment-history/EmploymentHistoryForm";
import taskHeadings from "tasks/taskHeadings";
import { Plural, Trans } from "@lingui/macro";
import { getApiDate } from "components/common/form/DateInput";
import { EMPTY_EMPLOYMENT } from "components/tasks/employment-history/EmploymentOrGapForm";
import { COUNTRY_UNITED_STATES } from "tasks/apiConstants";

export interface EmploymentHistoryEntryTaskdefFields {
  SERVICE_ID: ApiFieldProperties;
  ENTRY_TYPE: ApiFieldProperties;
  ARE_YOU_CURRENTLY_UNEMPLOYED: ApiFieldProperties;
  START_DATE: ApiFieldProperties;
  END_DATE: ApiFieldProperties;
  EXPLANATION: ApiFieldProperties;
  IS_THIS_YOUR_PRESENT_EMPLOYER?: ApiFieldProperties;
  MAY_WE_CONTACT_THIS_EMPLOYER?: ApiFieldProperties;
  IS_DOT_REGULATED?: ApiFieldProperties;
  REGULATED_TYPE?: ApiFieldProperties;
  EMPLOYER_TYPE?: ApiFieldProperties;
  CONTRACTOR?: ApiFieldProperties;
  COMPANY_NAME?: ApiFieldProperties;
  COMPANY_PHONE?: ApiFieldProperties;
  COUNTRY?: ApiFieldProperties;
  STREET_ADDRESS?: ApiFieldProperties;
  CITY?: ApiFieldProperties;
  STATE?: ApiFieldProperties;
  TITLEPOSITION_WHILE_EMPLOYED?: ApiFieldProperties;
  NAME_WHILE_EMPLOYED?: ApiFieldProperties;
  EMPLOYEE_NUMBER?: ApiFieldProperties;
}

export interface EmploymentHistoryApiFields extends ApiFormFields {
  NO_HISTORY_FOR_THIS_PERIOD: ApiFieldProperties;
  NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS: ApiFieldProperties;
  EMPLOYMENT_HISTORY_ITEMS: EmploymentHistoryEntryTaskdefFields[];
}

export interface EmploymentHistoryApiFormMessages extends ApiFormMessages {
  desiredEmploymentDateRangeStart: string;
  clientCustomizedTaskDescription: string;
}

export interface EmploymentHistoryApiFormParameters
  extends IdVerifyApiFormParameters {
  allowNoHistorySelection: boolean;
  minimumNumberOfYearsOfEmploymentHistory: number;
  maximumNumberOfDaysAllowedWithoutGap: number;
  hasDotVerification: boolean;
  hasFmcsaVerification: boolean;
  hasFaaVerification: boolean;
  regulationTypes: string[];
  employerTypes: {
    [label: string]: string[];
  };
  minimumNumberOfEmployments: number;
  maximumNumberOfEmployments: number;
}

export default function EmploymentHistoryTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_EMPLOYMENT;

  const {
    data,
    isFetching,
    isRefetching,
    refetch: refetchTaskDef,
  } = useGetTaskdef<
    SingleFormTaskDef<
      EmploymentHistoryApiFields,
      EmploymentHistoryApiFormMessages,
      EmploymentHistoryApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  function mapApiEmployHistToForm(
    apiData: EmploymentHistoryApiFields,
  ): EmploymentHistoryFormFields {
    return splitDates(
      boolStrFieldsToBools(flattenExistingValues(apiData), [
        "CONTRACTOR",
        NO_HISTORY_FOR_THIS_PERIOD_FIELD,
      ]),
    );
  }

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const hasRegulationTypes =
    data?.forms[0]?.formParameters?.hasFmcsaVerification ||
    data?.forms[0]?.formParameters?.hasFaaVerification;

  const storeEmployHistory: SubmitHandler<EmploymentHistoryFormFields> =
    async ({
      NO_HISTORY_FOR_THIS_PERIOD,
      noAdditionalEmploymentHistoryItems,
      EMPLOYMENT_HISTORY_ITEMS,
      doSave,
    }: EmploymentHistoryFormFields) => {
      mutateTaskData({
        formFields: {
          NO_HISTORY_FOR_THIS_PERIOD: NO_HISTORY_FOR_THIS_PERIOD
            ? "true"
            : "false",
          ...(!NO_HISTORY_FOR_THIS_PERIOD && {
            noAdditionalEmploymentHistoryItems:
              noAdditionalEmploymentHistoryItems,
            EMPLOYMENT_HISTORY_ITEMS: EMPLOYMENT_HISTORY_ITEMS.map((entry) => ({
              ...(!!entry.SERVICE_ID && { SERVICE_ID: entry.SERVICE_ID }),
              ENTRY_TYPE: entry.ENTRY_TYPE,
              START_DATE: entry.startDate,

              ...(((entry.ENTRY_TYPE === "EMP" &&
                entry.IS_THIS_YOUR_PRESENT_EMPLOYER !== "true") ||
                (entry.ENTRY_TYPE === "GAP" &&
                  entry.ARE_YOU_CURRENTLY_UNEMPLOYED !== "true")) && {
                END_DATE: entry.endDate,
              }),
              ...(entry.ENTRY_TYPE === "GAP"
                ? {
                    ...(!!entry.ARE_YOU_CURRENTLY_UNEMPLOYED && {
                      ARE_YOU_CURRENTLY_UNEMPLOYED:
                        entry.ARE_YOU_CURRENTLY_UNEMPLOYED === "true",
                    }),
                    EXPLANATION: entry.EXPLANATION,
                  }
                : {
                    ...(!!entry.IS_THIS_YOUR_PRESENT_EMPLOYER && {
                      IS_THIS_YOUR_PRESENT_EMPLOYER:
                        entry.IS_THIS_YOUR_PRESENT_EMPLOYER,
                    }),
                    ...(!!entry.MAY_WE_CONTACT_THIS_EMPLOYER && {
                      MAY_WE_CONTACT_THIS_EMPLOYER:
                        entry.MAY_WE_CONTACT_THIS_EMPLOYER,
                    }),
                    ...(data?.forms[0]?.formParameters?.hasDotVerification &&
                      !!entry.IS_DOT_REGULATED && {
                        IS_DOT_REGULATED: entry.IS_DOT_REGULATED,
                        ...((hasRegulationTypes && {
                          REGULATED_TYPE: entry.REGULATED_TYPE,
                        }) ||
                          (!hasRegulationTypes &&
                            entry.IS_DOT_REGULATED === "true" && {
                              REGULATED_TYPE: "OTHER",
                            })),
                        ...(!!entry.EMPLOYER_TYPE && {
                          EMPLOYER_TYPE: entry.EMPLOYER_TYPE,
                        }),
                      }),
                    CONTRACTOR: entry.CONTRACTOR ? "true" : "false",
                    COMPANY_NAME: entry.COMPANY_NAME,
                    COMPANY_PHONE: entry.COMPANY_PHONE,
                    COUNTRY: entry.COUNTRY,
                    STREET_ADDRESS: entry.STREET_ADDRESS,
                    CITY: entry.CITY,
                    STATE: entry.STATE,
                    TITLEPOSITION_WHILE_EMPLOYED:
                      entry.TITLEPOSITION_WHILE_EMPLOYED,
                    NAME_WHILE_EMPLOYED: entry.NAME_WHILE_EMPLOYED,
                    EMPLOYEE_NUMBER: entry.EMPLOYEE_NUMBER,
                  }),
            })),
          }),
        },
        saveOnly: doSave,
      });
    };

  const formFields = data?.forms[0]?.formFields;

  const mapApiEmploymentHistory =
    formFields && mapApiEmployHistToForm(formFields);

  const employmentHistory = mapApiEmploymentHistory && {
    ...mapApiEmploymentHistory,
    EMPLOYMENT_HISTORY_ITEMS:
      mapApiEmploymentHistory?.EMPLOYMENT_HISTORY_ITEMS?.map(
        (employmentHistory) => ({
          ...employmentHistory,
          COUNTRY: employmentHistory?.COUNTRY || COUNTRY_UNITED_STATES,
        }),
      ),
  };

  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : data?.forms[0]?.formParameters?.identityVerificationMode ? (
    <IdentityVerificationForm
      taskId={taskId}
      clientLabel={data?.forms[0]?.formMessages?.clientLabel}
      goToStatusCheck={goToStatusCheck}
      refetchTaskDef={refetchTaskDef}
      startOrEndOfYear={data?.forms[0]?.formMessages?.startOrEndYear}
    />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <Text pb="4">
        {data?.forms[0]?.formMessages?.clientCustomizedTaskDescription}
      </Text>
      <EmploymentHistoryForm
        allowNoHistorySelection={
          data?.forms[0]?.formParameters?.allowNoHistorySelection
        }
        reqYearsOfHistory={
          data?.forms[0]?.formParameters
            ?.minimumNumberOfYearsOfEmploymentHistory || 0
        }
        maxGapDays={
          data?.forms[0]?.formParameters
            ?.maximumNumberOfDaysAllowedWithoutGap || 0
        }
        showDotQuestion={data?.forms[0]?.formParameters?.hasDotVerification}
        regulationOptions={
          hasRegulationTypes
            ? data?.forms[0]?.formParameters?.regulationTypes
            : undefined
        }
        employerOptions={data?.forms[0]?.formParameters?.employerTypes}
        minimumEmployments={
          data?.forms[0]?.formParameters?.minimumNumberOfEmployments || 1
        }
        maximumEmployments={
          data?.forms[0]?.formParameters?.maximumNumberOfEmployments || 0
        }
        desiredStartMonth={
          data?.forms[0]?.formMessages?.desiredEmploymentDateRangeStart || ""
        }
        desiredEndMonth={
          data?.forms[0]?.formMessages?.desiredEmploymentDateRangeEnd || ""
        }
        defaultValues={
          employmentHistory || {
            NO_HISTORY_FOR_THIS_PERIOD: false,
            noAdditionalEmploymentHistoryItems: false,
            EMPLOYMENT_HISTORY_ITEMS: [EMPTY_EMPLOYMENT],
          }
        }
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        formMessages={data?.forms[0]?.formMessages}
        onSubmit={storeEmployHistory}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
