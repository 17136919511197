import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  ApplicantEntryTaskId,
  SingleFormTaskDef,
} from "tasks/tasksApi";
import {
  flattenExistingValues,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import GaStatewideCriminalForm, {
  GaStatewideCriminalFormFields,
} from "components/tasks/ga-statewide/GaStatewideCriminalForm";
import { Trans } from "@lingui/macro";
import { Heading, Text } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";

interface GaStatewideCriminalApiFields extends ApiFormFields {
  sex: ApiFieldProperties;
  race: ApiFieldProperties;
  employmentGeneral: ApiFieldProperties;
  mentallyDisabled: ApiFieldProperties;
  elderly: ApiFieldProperties;
  children: ApiFieldProperties;
  employmentPurpose: ApiFieldProperties;
  document: ApiFieldProperties;
}

interface GaStatewideCriminalApiFormMessages extends ApiFormMessages {
  formName: string;
  formInstructions: string;
}

export default function GaStatewideCriminalTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_GA_STATEWIDE;

  const { data, isFetching, isRefetching } = useGetTaskdef<
    SingleFormTaskDef<
      GaStatewideCriminalApiFields,
      GaStatewideCriminalApiFormMessages,
      ApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({ taskId, onSuccess: goToStatusCheck });

  const storeGaStatewideCriminal: SubmitHandler<GaStatewideCriminalFormFields> =
    async ({
      sex,
      race,
      employmentPurpose,
      document,
      doSave,
    }: GaStatewideCriminalFormFields) =>
      mutateTaskData({
        formFields: {
          SEX: sex,
          RACE: race,
          EMPLOYMENT_GENERAL: employmentPurpose === "employmentGeneral",
          MENTALLY_DISABLED: employmentPurpose === "mentallyDisabled",
          ELDERLY: employmentPurpose === "elderly",
          CHILDREN: employmentPurpose === "children",
          DOCUMENT: { SIGNATURE: document.signature },
        },
        saveOnly: doSave,
      });

  function mapApiDataToForm(apiData: any): GaStatewideCriminalFormFields {
    const flattened = flattenExistingValues(apiData);

    const employmentGeneral =
      flattened.forms[0].formFields?.employmentGeneral ?? false;
    const mentallyDisabled =
      flattened.forms[0].formFields?.mentallyDisabled ?? false;
    const elderly = flattened.forms[0].formFields?.elderly ?? false;
    const children = flattened.forms[0].formFields?.children ?? false;

    const getEmploymentPurpose =
      (employmentGeneral && "employmentGeneral") ||
      (mentallyDisabled && "mentallyDisabled") ||
      (elderly && "elderly") ||
      (children && "children") ||
      "";

    return {
      sex: flattened.forms[0].formFields?.sex ?? "",
      race: flattened.forms[0].formFields?.race ?? "",
      employmentPurpose: getEmploymentPurpose,
      document: { signature: "" },
    };
  }

  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {data?.forms[0].formMessages?.formName}
      </Heading>
      <Text pb="4">{data?.forms[0].formMessages?.formInstructions}</Text>
      <GaStatewideCriminalForm
        defaultValues={mapApiDataToForm(data)}
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        onSubmit={storeGaStatewideCriminal}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
