import SpinnerOverlay from "./SpinnerOverlay";
import { t } from "@lingui/macro";

export interface TaskSpinnerOverlayProps {
  isShown?: boolean;
  isStoring?: boolean;
  saveOnly?: boolean;
}

export default function TaskSpinnerOverlay({
  isShown = true,
  isStoring = false,
  saveOnly = false,
}: TaskSpinnerOverlayProps): JSX.Element | null {
  let text: string;

  if (isStoring) {
    text = saveOnly ? t`Saving form...` : t`Submitting form...`;
  } else {
    text = t`Loading form...`;
  }

  return <SpinnerOverlay isShown={isShown} text={text} />;
}
