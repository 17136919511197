import { t } from "@lingui/macro";

// TODO (2024-08-12:JDL) These should be populated from
//  PRoSource DB since that is what they're checked against.
export const STATES_AND_PROVINCES = [
  "Alabama, United States",
  "Alaska, United States",
  "Arizona, United States",
  "Arkansas, United States",
  "California, United States",
  "Colorado, United States",
  "Connecticut, United States",
  "DC, United States",
  "Delaware, United States",
  "Florida, United States",
  "Georgia, United States",
  "Guam, United States",
  "Hawaii, United States",
  "Idaho, United States",
  "Illinois, United States",
  "Indiana, United States",
  "Iowa, United States",
  "Kansas, United States",
  "Kentucky, United States",
  "Louisiana, United States",
  "Maine, United States",
  "Maryland, United States",
  "Massachusetts, United States",
  "Michigan, United States",
  "Minnesota, United States",
  "Mississippi, United States",
  "Missouri, United States",
  "Montana, United States",
  "Nebraska, United States",
  "Nevada, United States",
  "New Hampshire, United States",
  "New Jersey, United States",
  "New Mexico, United States",
  "New York, United States",
  "North Carolina, United States",
  "North Dakota, United States",
  "Ohio, United States",
  "Oklahoma, United States",
  "Oregon, United States",
  "Pennsylvania, United States",
  "Puerto Rico, United States",
  "Rhode Island, United States",
  "South Carolina, United States",
  "South Dakota, United States",
  "Tennessee, United States",
  "Texas, United States",
  "Utah, United States",
  "Vermont, United States",
  "Virgin Islands (U.S.), United States",
  "Virginia, United States",
  "Washington, United States",
  "West Virginia, United States",
  "Wisconsin, United States",
  "Wyoming, United States",
  "Alberta, Canada",
  "British Columbia, Canada",
  "Manitoba, Canada",
  "New Brunswick, Canada",
  "Newfoundland and Labrador, Canada",
  "Northwest Territories, Canada",
  "Nova Scotia, Canada",
  "Nunavut, Canada",
  "Ontario, Canada",
  "Prince Edward Island, Canada",
  "Quebec, Canada",
  "Saskatchewan, Canada",
  "Yukon, Canada",
];

// TODO (2024-08-12:JDL) These should be populated from
//  PRoSource DB since that is what they're checked against.
export const STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "DC",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands (U.S.)",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

// TODO (2024-08-12:JDL) These should be populated from
//  PRoSource DB since that is what they're checked against.
export const CANADIAN_PROVINCES = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export const COUNTRY_UNITED_STATES = "United States";
export const COUNTRY_CANADA = "Canada";

// TODO (2024-08-12:JDL) These should be populated from
//  PRoSource DB since that is what they're checked against.
export const COUNTRIES = [
  COUNTRY_UNITED_STATES,
  COUNTRY_CANADA,
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France, Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latin America",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Island",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Republic of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State (Holy See)",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zaire",
  "Zambia",
  "Zimbabwe",
];

export const RELATIONSHIPS = [
  t`Former Employer`,
  t`Current Employer`,
  t`Colleague`,
  t`Teacher`,
  t`Supervisor`,
  t`Client`,
];

export const DRIVER_LICENSE_TYPES = [
  t`Driver's License`,
  t`State ID Only`,
  t`No License, No State ID`,
];

export const ResponseCodes = {
  Success: {
    Action: { ANSWER_CORRECT: 1215 },
  },
  Error: {
    System: {
      /* -10xx series - system status */
      MAINTENANCE: -1000,
      OUTAGE: -1001,
    },
    Entry: {
      /* -11xx series - candidate entry and background check status */
      ENTRY_LOCKED: -1100,
      ENTRY_EXPIRED: -1101,
      BGC_CANCELLED: -1102,
      BGC_NOT_FOUND: -1103,
      TOKEN_INVALID: -1104,
    },
    Action: {
      /* -12xx series - action status */
      VALIDATION_FAILURE: -1201,
      INVALID_PIN: -1202,
      UNACCESSED_DOCUMENTS: -1203,
      SIGNATURE_MISSING: -1204,
      TASK_NOT_FOUND: -1205,
      ANSWER_INCORRECT: -1206,
      ANSWER_INCORRECT_NO_RETRIES: -1207,
      INVALID_PII: -1208,
      SMS_NOT_AVAILABLE: -1209,
    },
  },
};

export const GA_STATEWIDE_SEX_TYPES = [
  { label: t`Male`, value: "Male" },
  { label: t`Female`, value: "Female" },
  { label: t`I decline to answer`, value: "I decline to answer" },
];
