import EmploymentOrGapForm, {
  EmploymentOrGapFormFields,
  EMPTY_EMPLOYMENT,
} from "components/tasks/employment-history/EmploymentOrGapForm";
import {
  SavableFormComponentProps,
  validateHistory,
} from "components/tasks/taskHelpers";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { Box, Button, Center, Text, useToast, VStack } from "@chakra-ui/react";
import FormCheckbox from "components/common/form/FormCheckbox";
import { Plural, t, Trans } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";
import { useState } from "react";

export const NO_HISTORY_FOR_THIS_PERIOD_FIELD = "NO_HISTORY_FOR_THIS_PERIOD";
export const NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD =
  "noAdditionalEmploymentHistoryItems";
export const EMPLOYMENT_HISTORY_ITEMS_FIELD = "EMPLOYMENT_HISTORY_ITEMS";

export interface EmploymentHistoryFormFields {
  NO_HISTORY_FOR_THIS_PERIOD: boolean;
  noAdditionalEmploymentHistoryItems: boolean;
  EMPLOYMENT_HISTORY_ITEMS: EmploymentOrGapFormFields[];
  doSave?: boolean;
}

export interface EmploymentHistoryFormProps
  extends SavableFormComponentProps<EmploymentHistoryFormFields> {
  reqYearsOfHistory: number;
  maxGapDays: number;
  showDotQuestion?: boolean;
  regulationOptions?: string[];
  employerOptions?: {
    [label: string]: string[];
  };
  allowNoHistorySelection?: boolean;
  minimumEmployments: number;
  maximumEmployments: number;
  desiredStartMonth: string;
  desiredEndMonth: string;
}

export default function EmploymentHistoryForm({
  defaultValues = {
    NO_HISTORY_FOR_THIS_PERIOD: false,
    noAdditionalEmploymentHistoryItems: false,
    EMPLOYMENT_HISTORY_ITEMS: [EMPTY_EMPLOYMENT],
  },
  reqYearsOfHistory,
  maxGapDays,
  showDotQuestion,
  regulationOptions,
  employerOptions,
  allowNoHistorySelection = true,
  minimumEmployments,
  maximumEmployments,
  desiredStartMonth,
  desiredEndMonth,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: EmploymentHistoryFormProps): JSX.Element {
  const [allowSkipRequiredMinimum, setAllowSkipRequiredMinimum] =
    useState<boolean>(
      defaultValues.noAdditionalEmploymentHistoryItems != undefined,
    );

  // if necessary, initialize defaultValues with an empty employment entry
  if (!defaultValues?.EMPLOYMENT_HISTORY_ITEMS?.length) {
    defaultValues.EMPLOYMENT_HISTORY_ITEMS = [EMPTY_EMPLOYMENT];
  }

  const methods = useForm<EmploymentHistoryFormFields>({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: EMPLOYMENT_HISTORY_ITEMS_FIELD,
  });
  const [currentNumberOfEmployments, setCurrentNumberOfEmployments] =
    useState<number>(fields.length > 1 ? fields.length : 1);

  const showEntries = !useWatch({
    control: methods.control,
    name: NO_HISTORY_FOR_THIS_PERIOD_FIELD,
  });

  const noAdditionalEmploymentHistoryItems = useWatch({
    control: methods.control,
    name: NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD,
  });

  const toast = useToast();

  const onFormSubmit = (data: EmploymentHistoryFormFields) => {
    toast.closeAll();

    let validationErrors: string[] = [];
    if (showEntries && !noAdditionalEmploymentHistoryItems) {
      let contactableEmployments = 0;
      data.EMPLOYMENT_HISTORY_ITEMS.forEach((entry) => {
        if (
          (entry.IS_THIS_YOUR_PRESENT_EMPLOYER === "true" &&
            entry.MAY_WE_CONTACT_THIS_EMPLOYER === "true") ||
          entry.IS_THIS_YOUR_PRESENT_EMPLOYER === "false"
        ) {
          contactableEmployments += 1;
        }
      });

      //Don't validate history if provided with max contactable employments
      const metMaxContactableEmployments =
        contactableEmployments == maximumEmployments &&
        maximumEmployments >= minimumEmployments;

      //Required Years Minimum of History Validation
      if (requireYearsOfHistory && !metMaxContactableEmployments) {
        validationErrors = validateHistory(
          data.EMPLOYMENT_HISTORY_ITEMS,
          maxGapDays,
          reqYearsOfHistory,
        );
      }

      //Contactable Employments Validation
      if (contactableEmployments < minimumEmployments) {
        validationErrors.push(
          t`There are ${contactableEmployments} contactable employments out of the minimum required ${minimumEmployments}. 
        Please add more previous employments or select a present employer as contactable.`,
        );
      }
      if (
        requireMaximumEmployments &&
        contactableEmployments > maximumEmployments
      ) {
        validationErrors.push(
          t`There are ${contactableEmployments} contactable employments out of the maximum requested ${maximumEmployments}. 
        Please remove previous employments or select a present employer as non-contactable.`,
        );
      }

      //Provide instructions to checkbox to bypass all validation
      let lastValidationError = validationErrors[validationErrors.length - 1];
      lastValidationError =
        lastValidationError +
        " Or check box to indicate no additional employment information to provide.";
      validationErrors[validationErrors.length - 1] = lastValidationError;
    }

    validationErrors.forEach((error) =>
      toast({
        position: "top",
        status: "error",
        variant: "subtle",
        description: error,
        isClosable: true,
        duration: null,
      }),
    );
    if (!validationErrors.length) {
      setAllowSkipRequiredMinimum(false);
      onSubmit(data);
    } else {
      setAllowSkipRequiredMinimum(true);
    }
  };

  //# of Employments
  const requireMinimumEmployments = minimumEmployments > 1;
  const requireMaximumEmployments =
    maximumEmployments && maximumEmployments > minimumEmployments;

  //Minimum Years of Employment History
  const minimumNumberOfYearsOfEmploymentHistory = reqYearsOfHistory
    ? reqYearsOfHistory
    : 0;
  const requireYearsOfHistory = minimumNumberOfYearsOfEmploymentHistory > 0;

  let noAdditionalEmploymentsText = "";
  if (requireYearsOfHistory && requireMinimumEmployments) {
    noAdditionalEmploymentsText = t`Check this box if you cannot meet the required employment entries or required history period.`;
  } else if (requireYearsOfHistory && !requireMinimumEmployments) {
    noAdditionalEmploymentsText = t`Check this box if you cannot meet the required history period.`;
  } else if (!requireYearsOfHistory && requireMinimumEmployments) {
    noAdditionalEmploymentsText = t`Check this box if you cannot meet the required employment entries.`;
  }

  const employmentHistoryForm = "employmentHistoryForm";

  return (
    <VStack w="100%" p={1}>
      <Text pb="4">
        {maximumEmployments <= minimumEmployments &&
          maximumEmployments == 1 && (
            <Trans>Provide your most recent/current employment</Trans>
          )}
        {maximumEmployments <= minimumEmployments && maximumEmployments != 1 && (
          <Plural
            value={minimumEmployments}
            one={<Trans>Provide your most recent/current employments</Trans>}
            other={
              <Trans>
                Provide your most recent <strong>#</strong> employments
              </Trans>
            }
          />
        )}
        {(requireMaximumEmployments && (
          <>
            <Plural
              value={minimumEmployments}
              one={
                <Trans>
                  Provide at least <strong>#</strong> employment
                </Trans>
              }
              other={
                <Trans>
                  Provide at least <strong>#</strong> employments
                </Trans>
              }
            />
            <Trans>
              , and up to <strong>{maximumEmployments}</strong> employments
            </Trans>
          </>
        )) || <></>}
        {requireYearsOfHistory && (
          <>
            {" "}
            <Trans>
              for the period: <strong>{desiredStartMonth}</strong> to{" "}
              <strong>{desiredEndMonth}</strong>
            </Trans>
          </>
        )}
        {(!(
          maximumEmployments <= minimumEmployments && maximumEmployments == 1
        ) && (
          <>
            <Trans>, starting with your most recent/current employer.</Trans>{" "}
          </>
        )) || <>{". "}</>}

        {requireYearsOfHistory && (
          <>
            <Trans>
              Include a Gap in Employment if there is more than{" "}
              <Plural value={maxGapDays} one={t`# day`} other={t`# days`} />{" "}
              between employments.
            </Trans>{" "}
            <Trans>
              If your employment history does not go back{" "}
              <Plural
                value={minimumNumberOfYearsOfEmploymentHistory}
                one={t`# year`}
                other={t`# years`}
              />
              , please input a Gap in Employment to account for the time(s) not
              employed.
            </Trans>{" "}
            <Trans>
              You will have to account for the entire{" "}
              <Plural
                value={minimumNumberOfYearsOfEmploymentHistory}
                one={t`# year`}
                other={t`# years`}
              />{" "}
              in order to submit your response.
            </Trans>{" "}
          </>
        )}
      </Text>

      <FormProvider {...methods}>
        <form
          id={employmentHistoryForm}
          onSubmit={methods.handleSubmit(onFormSubmit)}>
          {allowNoHistorySelection && (
            <Center borderBottom={"1px"} ml="50px" mr="50px">
              <FormCheckbox
                fieldId={NO_HISTORY_FOR_THIS_PERIOD_FIELD}
                label={t`No history for this period`}
                defaultChecked={defaultValues.NO_HISTORY_FOR_THIS_PERIOD}
              />
            </Center>
          )}
          {showEntries && (
            <>
              {allowSkipRequiredMinimum && (
                <Box mt="10px">
                  <Box ml="10px" mr="10px" p={0}>
                    <FormCheckbox
                      fieldId={NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD}
                      formLabel={noAdditionalEmploymentsText}
                      label={t`I do not have additional employment information to provide.`}
                      defaultChecked={
                        defaultValues.noAdditionalEmploymentHistoryItems
                      }
                    />
                  </Box>
                </Box>
              )}
              {fields.map((entry, index) => (
                <Box key={entry.id}>
                  <EmploymentOrGapForm
                    entryId={`${EMPLOYMENT_HISTORY_ITEMS_FIELD}.${index}`}
                    showDotQuestion={showDotQuestion}
                    regulationOptions={regulationOptions}
                    employerOptions={employerOptions}
                    showRemoveButton={fields.length > 1}
                    onRemoveClick={() => remove(index)}
                  />
                </Box>
              ))}
              <Box pt={4}>
                <Button
                  color="gray.600"
                  onClick={() => append(EMPTY_EMPLOYMENT)}>
                  + <Trans>Add Another Employment or Gap</Trans>
                </Button>
              </Box>
            </>
          )}
          <TaskButtons
            onBack={() => {
              toast.closeAll();
              goToStatusCheck?.();
            }}
            onSave={
              (isSavable &&
                (() => {
                  toast.closeAll();
                  return onSubmit({ ...methods.getValues(), doSave: true });
                })) ||
              undefined
            }
            form={employmentHistoryForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
