import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { get, useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { lengthErrMsgGenerator } from "./FormInput";

export interface FormTextareaProps extends TextareaProps {
  fieldId: string;
  label: string;
  required?: boolean;
  helperText?: string;
  validationPattern?: RegExp;
  validationPatternMessage?: string;
  minLength?: number;
  maxLength?: number;
  disabled?: boolean;
}

export default function FormTextarea({
  fieldId,
  label,
  required = true,
  helperText,
  defaultValue,
  validationPattern,
  validationPatternMessage,
  minLength,
  maxLength,
  disabled,
  hidden = false,
  ...rest
}: FormTextareaProps): JSX.Element {
  const { register, formState } = useFormContext();
  const lengthErrMsg = lengthErrMsgGenerator(minLength, maxLength);

  const inputIsAccessible = !hidden && !disabled;
  const validateRequired = inputIsAccessible && required;
  const validatePattern =
    inputIsAccessible && validationPattern && validationPatternMessage;
  const validateMinLength = inputIsAccessible && minLength;
  const validateMaxLength = inputIsAccessible && maxLength;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={get(formState.errors, fieldId)}
      py="0.5rem">
      <FormLabel
        htmlFor={fieldId}
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <Textarea
        id={fieldId}
        defaultValue={defaultValue}
        disabled={disabled}
        borderColor={"gray.600"}
        {...rest}
        {...register(fieldId, {
          required: { value: validateRequired, message: t`This is required` },
          minLength:
            (validateMinLength && {
              value: minLength,
              message: lengthErrMsg,
            }) ||
            undefined,
          maxLength:
            (validateMaxLength && {
              value: maxLength,
              message: lengthErrMsg,
            }) ||
            undefined,
          pattern:
            (validatePattern && {
              value: validationPattern,
              message: validationPatternMessage,
            }) ||
            undefined,
        })}
      />
      <FormErrorMessage>
        {get(formState.errors, `${fieldId}.message`)}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
