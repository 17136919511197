import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Heading,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Plural, t, Trans } from "@lingui/macro";
import DatedAddressEntryWrapper, {
  DatedAddressEntryFormFields,
  EMPTY_DATED_ADDRESS,
} from "components/tasks/address-history/DatedAddressEntryWrapper";
import TaskButtons from "components/common/basic/TaskButtons";
import { DateTime, Interval } from "luxon";
import {
  compareIntervals,
  genericOnSave,
  getIntervalGaps,
} from "components/tasks/taskHelpers";
import { useState } from "react";

// the interface for the fields AddressHistory
export interface AddressHistoryFormFields {
  currentAddress: DatedAddressEntryFormFields;
  previousAddresses: DatedAddressEntryFormFields[];
  //to determine if the data is submitted or just saved
  doSave?: boolean;
}

export interface AddressHistoryFormProps {
  defaultValues: {
    currentAddress: DatedAddressEntryFormFields;
    previousAddresses: DatedAddressEntryFormFields[];
  };
  isSavable: boolean;
  maximumGapDays?: number;
  minimumYearsOfAddresses?: number;
  maximumAmountOfAddresses?: number;
  showPreviousAddresses?: boolean;
  //TODO move goToStatusCheck to a new TasksContext
  goToStatusCheck?: () => void;
  onSubmit: SubmitHandler<AddressHistoryFormFields>;
}

export default function AddressHistoryForm({
  defaultValues = {
    currentAddress: EMPTY_DATED_ADDRESS,
    previousAddresses: [],
  },
  goToStatusCheck,
  isSavable,
  maximumGapDays = 10,
  minimumYearsOfAddresses = 0,
  maximumAmountOfAddresses = 0,
  showPreviousAddresses = true,
  onSubmit,
}: AddressHistoryFormProps): JSX.Element {
  const methods = useForm<AddressHistoryFormFields>({
    defaultValues,
  });

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "previousAddresses",
  });
  //fields.length + 1 because have to add the Current Address too
  const [currentAmountOfAddresses, setCurrentAmountOfAddresses] =
    useState<number>(fields.length >= 1 ? fields.length + 1 : 1);

  const toast = useToast();

  const maxDate = DateTime.now().plus({ days: 1 }).startOf("day");

  function validateHistory(data: AddressHistoryFormFields) {
    //subtract -0.03 to deal with rounding issues
    const requiredYearsOfHistory = minimumYearsOfAddresses
      ? minimumYearsOfAddresses - 0.03
      : 0;
    const errors: string[] = [];

    const intervals: Interval[] = [];
    intervals.push(
      Interval.fromDateTimes(
        DateTime.fromISO(data.currentAddress.startDate),
        new Date(),
      ),
    );

    if (minimumYearsOfAddresses > 0) {
      data.previousAddresses.forEach((address) =>
        intervals.push(
          Interval.fromDateTimes(
            DateTime.fromISO(address.startDate),
            DateTime.fromISO(address.endDate),
          ),
        ),
      );
    }

    errors.push(
      ...getIntervalGaps(intervals, maximumGapDays).map(
        (gap) =>
          t`There is a gap in your address history between ${gap.start.toLocaleString(
            DateTime.DATE_SHORT,
          )} and ${gap.end.toLocaleString(DateTime.DATE_SHORT)}`,
      ),
    );

    const minFormDate = intervals.sort(compareIntervals)[0].start;
    const providedYearsOfHistory = Math.abs(minFormDate?.diffNow().as("years"));

    if (requiredYearsOfHistory - providedYearsOfHistory > 0.01) {
      const minYearsError = t`You only provided ${providedYearsOfHistory.toFixed(
        2,
      )} of ${minimumYearsOfAddresses} required years of address history (${minFormDate.toLocaleString(
        DateTime.DATE_SHORT,
      )})`;
      errors.push(minYearsError);
    }

    const {
      currentAddress: { startDay, startMonth, startYear },
    } = data;

    const dateTime =
      startDay &&
      startMonth &&
      startYear &&
      DateTime.fromObject({
        day: Number(startDay),
        month: Number(startMonth),
        year: Number(startYear),
      }).endOf("day");
    if (dateTime) {
      if (maxDate < dateTime) {
        errors.push(t`Start date must be before ${maxDate?.toLocaleString()}`);
      }
    }
    return errors;
  }

  const onFormSubmit = (data: AddressHistoryFormFields) => {
    toast.closeAll();

    let validationErrors: string[] = [];
    if (requireMinimumYearsOfAddresses) {
      validationErrors = validateHistory(data);
    }

    validationErrors.forEach((error) =>
      toast({
        position: "top",
        status: "error",
        variant: "subtle",
        description: error,
        isClosable: true,
        duration: 8000,
      }),
    );
    if (!validationErrors.length) {
      onSubmit(data);
    }
  };

  //TODO (WKX:2023-11-13) Similar functionality to EmploymentHistoryForm.tsx. Could consolidate?
  function addAddress() {
    append(EMPTY_DATED_ADDRESS);
    setCurrentAmountOfAddresses(currentAmountOfAddresses + 1);
  }
  function removeAddress(addressIndex: number) {
    remove(addressIndex);
    setCurrentAmountOfAddresses(currentAmountOfAddresses - 1);
  }

  const reachedMaxAddresses =
    currentAmountOfAddresses >= maximumAmountOfAddresses &&
    maximumAmountOfAddresses != 0;
  const addAddressButtonText = reachedMaxAddresses
    ? `Reached Max Requested Addresses (${maximumAmountOfAddresses})`
    : "Add A Previous Address";

  const requireMinimumYearsOfAddresses = minimumYearsOfAddresses > 0;
  const requireMaximumAmountOfAddresses = maximumAmountOfAddresses > 0;

  const addressHistoryForm = "addressHistoryForm";

  return (
    <VStack w="100%" p={1}>
      <Text pb={"4"}>
        {(requireMaximumAmountOfAddresses && (
          <Plural
            value={maximumAmountOfAddresses}
            one={<Trans>Provide your most recent residential address</Trans>}
            other={
              <Trans>
                Provide your most recent <strong>#</strong> residential
                addresses
              </Trans>
            }
          />
        )) || <Trans>Provide your most recent address(es)</Trans>}
        {(requireMinimumYearsOfAddresses && (
          <>
            {" "}
            <Plural
              value={minimumYearsOfAddresses}
              one={<Trans>for the past year.</Trans>}
              other={
                <Trans>
                  for the past <strong>#</strong> years.
                </Trans>
              }
            />
          </>
        )) || <>{"."}</>}
      </Text>
      <FormProvider {...methods}>
        <form
          id={addressHistoryForm}
          onSubmit={methods.handleSubmit(onFormSubmit)}>
          <Heading pb={1} pt={2} as="h2" size="md">
            <Trans>Current Address</Trans>
          </Heading>
          <DatedAddressEntryWrapper
            addressId={"currentAddress"}
            startDateOnly
            maxDate={maxDate}
          />
          {showPreviousAddresses &&
            (maximumAmountOfAddresses > 1 || maximumAmountOfAddresses == 0) && (
              <>
                <Heading pb={3} pt={2} as="h2" size="md">
                  <Trans>Previous Addresses</Trans>
                </Heading>
                {fields.map((field, index) => (
                  <Box key={field.id + "box"} pb={4}>
                    <DatedAddressEntryWrapper
                      key={field.id}
                      addressId={`previousAddresses.${index}`}
                      onRemoveClick={() => removeAddress(index)}
                    />
                  </Box>
                ))}
                <Button
                  color="gray.600"
                  disabled={reachedMaxAddresses}
                  onClick={addAddress}>
                  + <Trans>{addAddressButtonText}</Trans>
                </Button>
              </>
            )}
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={addressHistoryForm}
          />
        </form>
      </FormProvider>
    </VStack>
  );
}
