import {
  InterviewApiSubForm,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import { Heading, Text } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { flattenExistingValues } from "components/tasks/taskHelpers";
import {
  DatedAddressEntryFormFields,
  EMPTY_DATED_ADDRESS,
} from "components/tasks/address-history/DatedAddressEntryWrapper";
import AddressHistoryForm from "components/tasks/address-history/AddressHistoryForm";
import { AddressApiSaveFields } from "components/tasks/address-history/AddressHistoryTask";

export interface AddrHistInterviewFormFields extends InterviewSubmitProps {
  /**
   * Note - the History Interviews deviate from the Interview Single/Sub form paradigm since they
   * re-use the task definition layout of the History Tasks. History Interview forms are Singleton forms
   * since there will only ever be one form on the History Interview task definitions. For this reason
   * they also will not conform to the "formId.fieldName" field naming convention outlined in
   * InterviewSubmitProps
   *
   * Additionally, as of April 2022 the History Interviews still utilize an object with a
   * property of "existingValue" to represent saved/stored values with which to populate
   * the form. As this is set to be removed with later improvements, that api data layout
   * is not captured by the History Interview api form Types/Interfaces.
   */
  currentAddress: DatedAddressEntryFormFields;
  previousAddresses: DatedAddressEntryFormFields[];
}

export interface AddrHistSubFormParameters extends InterviewFormParameters {
  askForAddressHistory: boolean;
  minimumYearsOfAddressHistory: number;
  maximumAmountOfAddresses: number;
  maximumGapDays: number;
}

export interface AddrHistSubFormMessages extends InterviewFormMessages {
  clientCustomizedTaskDescription: string;
}

export type AddrHistApiSubForm = InterviewApiSubForm<
  AddrHistInterviewFormFields,
  AddrHistSubFormMessages,
  AddrHistSubFormParameters
>;

export interface AddrHistInterviewFormProps {
  form: AddrHistApiSubForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
  goToStatusCheck?: () => void;
}

/**
 * A function that maps/coalesces the api data into the format
 * required by the UI form.
 * @param apiData the AddresstHistoryInterview task definition
 */
export function mapAddrHistApiToUi(
  apiData: any, //"any" used here until API removes "existingValue" object
): AddrHistApiSubForm {
  const flattened = flattenExistingValues(apiData);

  const mapFlatAddrToFormAddr = (flatAddr: any) => ({
    country: flatAddr.COUNTRY,
    street: flatAddr.STREET_ADDRESS,
    city: flatAddr.CITY,
    state: flatAddr.STATE,
    postalCode: flatAddr.POSTAL_CODE,
    startDate: flatAddr.FROM,
    endDate: flatAddr.TO,
    apiId: flatAddr.INSTANCE_ID,
  });

  return {
    formFields: {
      currentAddress: mapFlatAddrToFormAddr(
        flattened?.formFields?.CURRENT_ADDRESS,
      ) as DatedAddressEntryFormFields,
      previousAddresses: flattened.formFields.PREVIOUS_ADDRESSES?.map(
        (flatAddr: any) => mapFlatAddrToFormAddr(flatAddr) || [],
      ),
    },
    formMessages: flattened.formMessages,
    formParameters: flattened.formParameters,
  };
}

export default function AddrHistInterviewFormAdapter({
  form: {
    formFields: { currentAddress = EMPTY_DATED_ADDRESS, previousAddresses },
    formMessages: { clientCustomizedTaskDescription },
    formParameters: {
      formId,
      askForAddressHistory,
      minimumYearsOfAddressHistory,
      maximumAmountOfAddresses,
      maximumGapDays,
    },
  },
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: AddrHistInterviewFormProps): JSX.Element {
  function mapFormToApi({
    currentAddress,
    previousAddresses,
    doSave,
  }: AddrHistInterviewFormFields) {
    const mapFormAddrToApiAddr = (
      addr: DatedAddressEntryFormFields,
    ): AddressApiSaveFields => ({
      COUNTRY: addr.country,
      STREET_ADDRESS: addr.street,
      CITY: addr.city,
      STATE_TEXT: addr.state,
      STATE_SELECT: addr.state,
      POSTAL_CODE: addr?.postalCode || "",
      FROM: addr.startDate,
      TO: addr.endDate,
      ...(addr.apiId && { INSTANCE_ID: addr.apiId }),
    });

    onSubmit({
      //nest the fields within the formId as required by storeInterviewData
      [formId]: {
        CURRENT_ADDRESS: mapFormAddrToApiAddr(currentAddress),
        PREVIOUS_ADDRESSES: {
          ADDRESS_HISTORY_ITEMS: previousAddresses.map((prevAddr) =>
            mapFormAddrToApiAddr(prevAddr),
          ),
        },
      },
      doSave,
    });
  }

  return (
    <>
      <Heading p={1} as="h1">
        {t`Address History`}
      </Heading>
      <Text pb="4">{clientCustomizedTaskDescription}</Text>
      <AddressHistoryForm
        defaultValues={{ currentAddress, previousAddresses }}
        maximumGapDays={maximumGapDays}
        minimumYearsOfAddresses={minimumYearsOfAddressHistory}
        maximumAmountOfAddresses={maximumAmountOfAddresses}
        showPreviousAddresses={askForAddressHistory}
        isSavable={isSavable}
        goToStatusCheck={goToStatusCheck}
        onSubmit={mapFormToApi}
      />
    </>
  );
}
