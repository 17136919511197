import { ApiFormFields } from "tasks/tasksApi";
import {
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import DrugTestForm from "./DrugTestForm";

export type DrugTestApiForm = {
  formFields?: ApiFormFields;
  formMessages: DrugTestApiFormMessages;
  formParameters: DrugTestApiFormParameters;
};

interface DrugTestTaskProps extends TaskComponentProps {
  form: DrugTestApiForm;
}

interface DrugTestApiFormMessages extends InterviewFormMessages {
  instructionHtml: string;
}

interface DrugTestApiFormParameters extends InterviewFormParameters {
  authFormUid: string;
}

export default function DrugTestTask({
  form,
  goToStatusCheck,
}: DrugTestTaskProps): JSX.Element {
  const formMessages = {
    formName: form.formMessages?.formName || "",
    instructionHtml: form.formMessages?.instructionHtml || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    authFormUid: form.formParameters?.authFormUid || "",
  };

  return (
    <DrugTestForm
      formMessages={formMessages}
      formParameters={formParameters}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
