import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import { Plural, t, Trans } from "@lingui/macro";
import parse from "html-react-parser";
import TaskButtons from "components/common/basic/TaskButtons";
import {
  AdditionalReferencesFormFields,
  emptyAdditionalReference,
  Reference,
} from "components/tasks/interviews/additional-references/AdditionalReferencesTask";
import FormInput from "components/common/form/FormInput";
import FormCheckbox from "components/common/form/FormCheckbox";
import { RELATIONSHIPS } from "tasks/apiConstants";
import FormSelect from "components/common/form/FormSelect";
import { Fragment, useEffect, useState } from "react";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import { PHONE_NUMBER } from "components/common/form/fieldConstants";
import { EMAIL_ADDRESS } from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";
import {
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";

interface AdditionalReferencesFormParameters extends InterviewFormParameters {
  askForProfessionalReferences?: boolean;
  numberOfRequiredAdditionalReferences?: number;
  requireReferenceEmail: boolean;
  requireReferencePhone: boolean;
}

interface AdditionalReferencesFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: AdditionalReferencesFormParameters;
  defaultValues: AdditionalReferencesFormFields;
  isSavable: boolean;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  validateUniqueAdditionalReferences: (
    additionalReferences: Reference[],
  ) => boolean;
}

export default function AdditionalReferencesForm({
  defaultValues = {
    existingReferences: [],
    noAdditionalReferences: undefined,
    additionalReferences: [],
  },
  isSavable,
  formMessages,
  formParameters,
  onSubmit,
  validateUniqueAdditionalReferences,
  goToStatusCheck,
}: AdditionalReferencesFormProps): JSX.Element {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  const methods: any = useForm<AdditionalReferencesFormFields>({
    defaultValues,
  });
  const formId = formParameters.formId;

  const { fields: existingReferences } = useFieldArray({
    control: methods.control,
    name: `${formId}.existingReferences`,
  });

  const { fields: additionalReferences, append } = useFieldArray({
    control: methods.control,
    name: `${formId}.additionalReferences`,
  });

  const [uniqueAdditionalReferencesError, setUniqueAdditionalReferencesError] =
    useState("");

  const formInstructions = formMessages.formInstructions;

  const displayAdditionalReferencesField = !methods.watch(
    `${formId}.noAdditionalReferences`,
  );

  const hasNumberOfRequiredAdditionalReferences =
    additionalReferences.length ===
    formParameters.numberOfRequiredAdditionalReferences;

  const displayAdditionalReferencesAddButton =
    displayAdditionalReferencesField &&
    !hasNumberOfRequiredAdditionalReferences;

  useEffect(() => {
    const { isSubmitting, isValidating } = methods.formState;
    const noAdditionalReferences = methods.getValues(
      `${formId}.noAdditionalReferences`,
    );
    if (!noAdditionalReferences) {
      const additionalReferences = methods.getValues(
        `${formId}.additionalReferences`,
      );
      if (isSubmitting || isValidating) {
        const hasUniqueAdditionalReferences =
          validateUniqueAdditionalReferences(additionalReferences || []);

        if (!hasUniqueAdditionalReferences) {
          setUniqueAdditionalReferencesError(
            "Additional references are not unique.",
          );
        } else {
          setUniqueAdditionalReferencesError("");
        }
      }
    }
  }, [methods.formState.isSubmitting, methods.formState.isValidating]);

  useEffect(() => {
    if (!displayAdditionalReferencesField) {
      setUniqueAdditionalReferencesError("");
    }
  }, [displayAdditionalReferencesField]);

  let requireEmail = formParameters.requireReferenceEmail;
  let requirePhone = formParameters.requireReferencePhone;
  if (!requireEmail && !requirePhone) {
    requireEmail = true;
    requirePhone = true;
  }

  const referencesForm = "referencesForm";

  return (
    <VStack w="100%" p={1}>
      <Box>
        <FormProvider {...methods}>
          <form id={referencesForm} onSubmit={methods.handleSubmit(onSubmit)}>
            <FormControl
              as="fieldset"
              isInvalid={!!uniqueAdditionalReferencesError}>
              <Box>{formInstructions && parse(formInstructions)}</Box>
              {existingReferences.map((existingReference, index) => (
                <Fragment key={existingReference.id}>
                  <Box className="arrayItemForm" bgColor="gray.100">
                    <FormControl as="fieldset">
                      <FormInput
                        label={t`Name`}
                        fieldId={`${formId}.existingReferences.${index}.name`}
                        disabled
                        required={false}
                      />
                      {requireEmail && (
                        <FormInput
                          label={t`Email Address`}
                          fieldId={`${formId}.existingReferences.${index}.emailAddress`}
                          type={"email"}
                          disabled
                          required={false}
                          validationPattern={EMAIL_ADDRESS.PATTERN}
                          validationPatternMessage={
                            EMAIL_ADDRESS.PATTERN_MESSAGE
                          }
                        />
                      )}
                      {requirePhone && (
                        <FormInput
                          label={t`Phone`}
                          fieldId={`${formId}.existingReferences.${index}.phone`}
                          disabled
                          required={false}
                        />
                      )}
                      <FormInput
                        label={t`Relation To Applicant`}
                        fieldId={`${formId}.existingReferences.${index}.relationToApplicant`}
                        disabled
                        required={false}
                      />
                    </FormControl>
                  </Box>
                </Fragment>
              ))}
              <Heading pb={1} pt={2} as="h2" size="md">
                <Plural
                  one={`Please Provide ${formParameters.numberOfRequiredAdditionalReferences} Additional Reference`}
                  other={`Please Provide ${formParameters.numberOfRequiredAdditionalReferences} Additional References`}
                  value={formParameters.numberOfRequiredAdditionalReferences}
                />
              </Heading>
              <FormCheckbox
                fieldId={`${formId}.noAdditionalReferences`}
                label={t`No additional references`}
                defaultChecked={defaultValues.noAdditionalReferences}
              />
              {uniqueAdditionalReferencesError && (
                <Box
                  mt={4}
                  borderColor={"#ebccd1"}
                  border={"1px solid #e53e3e87"}>
                  <Heading
                    as="h3"
                    size="sm"
                    fontWeight="medium"
                    color={"#e53e3e"}
                    backgroundColor={"#e53e3e57"}
                    padding={"10px 15px"}
                    mb={1}>
                    Errors
                  </Heading>
                  <FormErrorMessage padding={"10px 15px"}>
                    {uniqueAdditionalReferencesError}
                  </FormErrorMessage>
                </Box>
              )}
              {displayAdditionalReferencesField &&
                additionalReferences.map((additionalReference, index) => (
                  <Fragment key={additionalReference.id}>
                    <Box className="arrayItemForm">
                      <FormControl as="fieldset">
                        <FormInput
                          label={t`Name`}
                          fieldId={`${formId}.additionalReferences.${index}.name`}
                        />
                        {requireEmail && (
                          <FormInput
                            label={t`Email Address`}
                            fieldId={`${formId}.additionalReferences.${index}.emailAddress`}
                            type={"email"}
                            validationPattern={EMAIL_ADDRESS.PATTERN}
                            validationPatternMessage={
                              EMAIL_ADDRESS.PATTERN_MESSAGE
                            }
                          />
                        )}
                        {requirePhone && (
                          <FormInput
                            label={t`Phone`}
                            fieldId={`${formId}.additionalReferences.${index}.phone`}
                            type={"tel"}
                            onKeyDown={onKeyDownEnforceNumeric}
                            inputMask={PHONE_NUMBER.PARENS_MASK}
                            placeholder={PHONE_NUMBER.PARENS_PLACEHOLDER}
                            validationPattern={PHONE_NUMBER.PARENS_PATTERN}
                            validationPatternMessage={
                              PHONE_NUMBER.PATTERN_MESSAGE
                            }
                            autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
                          />
                        )}
                        {formParameters.askForProfessionalReferences ? (
                          <FormSelect
                            label={t`Relation To Applicant`}
                            fieldId={`${formId}.additionalReferences.${index}.relationToApplicant`}
                            options={RELATIONSHIPS}
                          />
                        ) : (
                          <FormInput
                            label={t`Relation To Applicant`}
                            fieldId={`${formId}.additionalReferences.${index}.relationToApplicant`}
                          />
                        )}
                      </FormControl>
                    </Box>
                  </Fragment>
                ))}
              {displayAdditionalReferencesAddButton && (
                <Box pt={4} textAlign={"end"}>
                  <Button
                    color="gray.600"
                    onClick={() => {
                      append(emptyAdditionalReference);
                    }}>
                    + <Trans>Add Another Reference</Trans>
                  </Button>
                </Box>
              )}
              <TaskButtons
                onBack={goToStatusCheck}
                onSave={genericOnSave(methods, onSubmit, isSavable)}
                form={referencesForm}
              />
            </FormControl>
          </form>
        </FormProvider>
      </Box>
    </VStack>
  );
}
