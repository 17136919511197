import {
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { CommPrefsRequest } from "auth/authApi";
import ReactInputMask from "react-input-mask";
import {
  EMAIL_ADDRESS,
  PHONE_NUMBER,
} from "components/common/form/fieldConstants";
import { useEffect, useState } from "react";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

interface CommPrefsFormProps extends CommPrefsRequest {
  onSubmit: SubmitHandler<CommPrefsRequest>;
}

export default function CommPrefsForm({
  emailPref,
  smsPref,
  onSubmit,
}: CommPrefsFormProps): JSX.Element {
  const [emailPreferredRequired, setEmailPreferredRequired] =
    useState<boolean>(true);
  const [smsPreferredRequired, setSmsPreferredRequired] =
    useState<boolean>(true);
  const { register, formState, handleSubmit, getValues, control, clearErrors } =
    useForm<CommPrefsRequest>({
      mode: "onSubmit",
      defaultValues: { emailPref, smsPref },
    });

  const emailIsPreferred = useWatch({ control, name: "emailPref.preferred" });
  const smsIsPreferred = useWatch({ control, name: "smsPref.preferred" });

  useEffect(() => {
    if (emailIsPreferred || smsIsPreferred) {
      clearErrors("emailPref.preferred");
      clearErrors("smsPref.preferred");
    }
    setSmsPreferredRequired(
      (emailIsPreferred && smsIsPreferred) || !emailIsPreferred,
    );
    setEmailPreferredRequired(
      (emailIsPreferred && smsIsPreferred) || !smsIsPreferred,
    );
  }, [emailIsPreferred, smsIsPreferred]);

  const smsPreferredIsInvalid = !!formState.errors.smsPref?.preferred;
  const smsValueIsInvalid = !!formState.errors.smsPref?.value;
  const smsPrefIsReadOnly = smsPref?.readOnly;

  const emailPreferredIsInvalid = !!formState.errors.emailPref?.preferred;
  const emailValueIsInvalid = !!formState.errors.emailPref?.value;
  const emailPrefIsReadOnly = emailPref?.readOnly;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={emailPreferredIsInvalid}>
        <FormLabel
          htmlFor="email"
          className={
            emailPreferredRequired ? "requiredField" : "optionalField"
          }>
          <Checkbox
            id="emailPref.preferred"
            required={emailPreferredRequired}
            {...register("emailPref.preferred", {
              //validates that at least one of the checkboxes is checked
              validate: (value) => {
                return value || getValues("smsPref.preferred");
              },
            })}>
            {t`Email Address`}
          </Checkbox>
        </FormLabel>
        <FormErrorMessage mt="-10px">
          <Trans>
            Must select at least one preferred communication preference.
          </Trans>
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={emailValueIsInvalid}>
        <Input
          id="emailPref.value"
          type={"email"}
          readOnly={emailPrefIsReadOnly}
          bgColor={emailPrefIsReadOnly ? "lightgray" : ""}
          {...register("emailPref.value", {
            required: {
              value: emailIsPreferred,
              message: EMAIL_ADDRESS.REQUIRED_MESSAGE,
            },
            // if field is readonly, we shouldn't validate because modifying
            // the input is impossible.
            pattern: !emailPrefIsReadOnly
              ? {
                  value: EMAIL_ADDRESS.PATTERN,
                  message: EMAIL_ADDRESS.PATTERN_MESSAGE,
                }
              : undefined,
          })}
        />
        <FormErrorMessage>
          {formState?.errors?.emailPref?.value?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={smsPreferredIsInvalid} pt="1rem">
        <FormLabel
          htmlFor="phone"
          className={smsPreferredRequired ? "requiredField" : "optionalField"}>
          <Checkbox
            id="smsPref.preferred"
            {...register("smsPref.preferred", {
              //validates that at least one of the checkboxes is checked
              validate: (value) => {
                return value || getValues("emailPref.preferred");
              },
            })}>
            {t`Mobile Phone`}
          </Checkbox>
        </FormLabel>
        <FormErrorMessage mt="-10px">
          <Trans>
            Must select at least one preferred communication preference.
          </Trans>
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={smsValueIsInvalid}>
        <Input
          as={ReactInputMask}
          id="smsPref.value"
          readOnly={smsPrefIsReadOnly}
          type={"tel"}
          onKeyDown={onKeyDownEnforceNumeric}
          mask={PHONE_NUMBER.PARENS_MASK}
          autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
          bgColor={smsPrefIsReadOnly ? "lightgray" : ""}
          {...register("smsPref.value", {
            required: {
              value: smsIsPreferred,
              message: PHONE_NUMBER.REQUIRED_MESSAGE,
            },
            // if field is readonly, we shouldn't validate because modifying
            // the input is impossible.
            pattern: !smsPrefIsReadOnly
              ? {
                  value: PHONE_NUMBER.PARENS_PATTERN,
                  message: PHONE_NUMBER.PATTERN_MESSAGE,
                }
              : undefined,
          })}
        />
        <FormErrorMessage>
          {formState.errors.smsPref?.value?.message}
        </FormErrorMessage>
        <FormHelperText>
          <Trans>
            By selecting preferred on this number, you are agreeing to receive
            text notifications regarding your background screen. Messages will
            occur while your background screen process is active. Text STOP to
            cancel. Msg&Data rates may apply.
          </Trans>
        </FormHelperText>
        <FormHelperText fontWeight="bold">
          {smsPref?.usable != undefined && !smsPref?.usable ? (
            t`This phone is not able to receive text messages. If you wish to
        receive text messages regarding your background screen, please
        provide an appropriate number.`
          ) : (
            <Trans>
              Be sure this number is capable of receiving text messages if you
              mark it preferred. <br />
              Only United States based phone numbers are supported.
            </Trans>
          )}
        </FormHelperText>
      </FormControl>
      <Center>
        <Button m="2rem" colorScheme="orange" type="submit">
          <Trans>Submit</Trans>
        </Button>
      </Center>
    </form>
  );
}
