import { Button, Flex, Spacer } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import ReactDOM from "react-dom";
import { ThemeConstants } from "./ThemeConstants";

interface OkButtonProps {
  onClick?: () => void;
  //other arbitrary props to pass to child Flex component
  [x: string]: any;
}

export default function OkButton({
  onClick,
  ...rest
}: OkButtonProps): JSX.Element {
  return ReactDOM.createPortal(
    <Flex direction={"row"} {...rest} my={"1rem"} height="100%">
      <Spacer />
      <Button color="gray.600" onClick={onClick}>
        <Trans>OK</Trans>
      </Button>
      <Spacer />
    </Flex>,
    document.getElementById(ThemeConstants.TASK_BUTTONS_CONTAINER_ID) ||
      document.createElement("div"),
  );
}
