import TaskButtons from "components/common/basic/TaskButtons";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { Markup } from "interweave";
import { Trans } from "@lingui/macro";

import SignaturePad from "react-signature-canvas";
import { useRef } from "react";
import {
  ApiFormMessages,
  FlatApiFormFields,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import taskHeadings from "tasks/taskHeadings";
import useAuth from "auth/useAuth";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import { Controller, useForm } from "react-hook-form";
import { ThemeConstants } from "../common/basic/ThemeConstants";

interface SignatureTaskProps {
  taskId: ApplicantEntryTaskId;
  heading?: string;
  description?: string;
  goToStatusCheck: () => void;
}

interface SignatureTaskApiFormMessages extends ApiFormMessages {
  clientCustomizedSignatureDescription: string;
}

interface SignatureFormValues {
  signature: string;
}

export default function SignatureTask({
  taskId,
  description,
  heading,
  goToStatusCheck,
}: SignatureTaskProps): JSX.Element {
  const sigPad = useRef<SignaturePad | null>(null);
  const clearCanvas = () => sigPad.current?.clear();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<SignatureFormValues>({ mode: "onBlur" });

  const { isFetching, data } = useGetTaskdef<
    SingleFormTaskDef<FlatApiFormFields, SignatureTaskApiFormMessages>
  >({ taskId, goToStatusCheck });

  const {
    isLoading: isStoring,
    mutate,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const submitSignature = ({ signature }: SignatureFormValues) => {
    mutate({ formFields: { DOCUMENT: { SIGNATURE: signature } } });
  };

  const signatureTaskForm = taskId + "-form";

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <Box width={"inherit"}>
      <Heading textAlign="center" p={1} as="h2">
        {taskHeadings[taskId].long}
      </Heading>
      <VStack p={1} width={"inherit"}>
        <Box fontSize={"smaller"} pt={2} width="inherit">
          <Markup
            content={
              data?.forms[0]?.formMessages
                ?.clientCustomizedSignatureDescription || description
            }
          />
        </Box>
        <Container centerContent maxW={"container.sm"}>
          <VStack alignItems={"start"} width={"inherit"}>
            <Heading color="orange" as="h3" size="md" pt="1rem">
              {heading || <Trans>Signature</Trans>}
            </Heading>
            <form
              id={signatureTaskForm}
              onSubmit={handleSubmit(submitSignature)}>
              <FormControl isInvalid={!!errors?.signature} width={"auto"}>
                <FormLabel
                  htmlFor="signature"
                  fontSize={"sm"}
                  mx={0}
                  className="requiredField">
                  <Trans>Use your mouse or finger to draw your signature</Trans>
                </FormLabel>
                <Controller
                  name="signature"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SignaturePad
                      ref={sigPad}
                      onEnd={() =>
                        //at the end of each stroke update the field value to the PNG string
                        field.onChange(
                          sigPad.current?.getTrimmedCanvas().toDataURL(),
                        )
                      }
                      canvasProps={ThemeConstants.SIGNATURE_CANVAS_PROPS}
                    />
                  )}
                />
                <FormErrorMessage>
                  <Trans>
                    Signature is empty or cannot be processed. Please clear the
                    signature and try again.
                  </Trans>
                </FormErrorMessage>
              </FormControl>

              <Flex direction={"row"} py={4}>
                <Button
                  color="gray.600"
                  onClick={() => {
                    clearCanvas();
                    reset();
                  }}>
                  <Trans>Clear</Trans>
                </Button>
              </Flex>
              <TaskButtons onBack={goToStatusCheck} form={signatureTaskForm} />
            </form>
          </VStack>
        </Container>
      </VStack>
    </Box>
  );
}
