import { Box, CloseButton } from "@chakra-ui/react";
import FormSelect from "components/common/form/FormSelect";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import { STATES_AND_PROVINCES } from "tasks/apiConstants";

export interface LicenseFormFields {
  type: string;
  number: string;
  state: string;
  actionsOrComplaints: string;
}

interface LicenseFormProps {
  licenseId: string;
  defaultValues: LicenseFormFields;
  licenseTypeOptions: string[];
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export const EMPTY_LICENSE: LicenseFormFields = {
  type: "",
  number: "",
  state: "",
  actionsOrComplaints: "",
};

export default function LicenseForm({
  licenseId,
  defaultValues,
  licenseTypeOptions,
  showRemoveButton = false,
  onRemoveClick,
}: LicenseFormProps): JSX.Element {
  return (
    <Box className="arrayItemForm">
      {showRemoveButton && (
        <CloseButton
          className="closeButton"
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormSelect
        fieldId={`${licenseId}.type`}
        label={t`License Type`}
        placeHolder={t`Select a license type`}
        options={licenseTypeOptions}
        defaultValue={defaultValues?.type}
      />
      <FormInput
        fieldId={`${licenseId}.number`}
        label={t`License Number`}
        defaultValue={defaultValues?.number}
        maxLength={64}
      />
      <FormSelect
        fieldId={`${licenseId}.state`}
        label={t`State/Province`}
        placeHolder={t`Select a state/province`}
        options={STATES_AND_PROVINCES}
        defaultValue={defaultValues?.state}
      />
    </Box>
  );
}
