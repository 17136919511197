import { FormComponentProps } from "components/tasks/taskHelpers";
import { FormProvider, useForm } from "react-hook-form";
import { Text, VStack } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";
import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import { STATES_AND_PROVINCES } from "tasks/apiConstants";
import {
  SOCIAL_SECURITY_NUMBER,
  AIRMAN_CERTIFICATE_NUMBER,
} from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";
import FormCheckbox from "components/common/form/FormCheckbox";
import { Box } from "@chakra-ui/react";
import {
  DatePickerInput,
  validateDateOfBirth,
} from "components/common/form/DatePickerInput";

export interface PersonalInfoFormFields {
  dobDate?: string;
  fullSsn?: string;
  driversLicense?: string;
  dlState?: string;
  noSsn?: boolean;
  airmanCertificateNumber?: string;
}

interface PersonalInfoFormMessages {
  dateOfBirthLabel?: string;
  driversLicenseLabel?: string;
  nationalIdentificationLabel?: string;
}

interface PersonalInfoFormParameters {
  requestDateOfBirth?: boolean;
  requestNationalIdentification?: boolean;
  requestDriversLicense?: boolean;
  driversLicenseIdentificationId: number;
  ssnOption?: boolean;
  requestAirmanCertificateNumber?: boolean;
}

export interface PersonalInfoFormProps
  extends FormComponentProps<PersonalInfoFormFields, PersonalInfoFormMessages> {
  defaultValues: PersonalInfoFormFields;
  formMessages: PersonalInfoFormMessages;
  formParameters: PersonalInfoFormParameters;
}

export default function PersonalInfoForm({
  defaultValues = {
    dobDate: "",
    fullSsn: "",
    driversLicense: "",
    dlState: "",
    noSsn: false,
    airmanCertificateNumber: "",
  },
  formMessages,
  formParameters,
  onSubmit,
  goToStatusCheck,
}: PersonalInfoFormProps): JSX.Element {
  const methods = useForm<PersonalInfoFormFields>({ defaultValues });

  const personalInfoForm = "personalInfoForm";
  const showSsn = !methods.watch("noSsn");

  const validateDate = (dateOfBirthString: string) => {
    return validateDateOfBirth(dateOfBirthString);
  };

  return (
    <>
      <VStack maxWidth="300px" p={1}>
        <FormProvider {...methods}>
          <form id={personalInfoForm} onSubmit={methods.handleSubmit(onSubmit)}>
            {formParameters.requestDateOfBirth && (
              <DatePickerInput
                label={t`Date of Birth`}
                fieldIds={{
                  year: "dobYear",
                  month: "dobMonth",
                  day: "dobDay",
                  date: "dobDate",
                }}
                helperText={formMessages.dateOfBirthLabel}
                {...{
                  validateDate: (value) => validateDate(value),
                }}
              />
            )}
            <Box maxW="210px">
              {formParameters.requestNationalIdentification && showSsn && (
                <FormInput
                  fieldId="fullSsn"
                  type="tel"
                  inputMode="numeric"
                  helperText={formMessages.nationalIdentificationLabel}
                  onKeyDown={onKeyDownEnforceNumeric}
                  label={SOCIAL_SECURITY_NUMBER.LABEL}
                  inputMask={SOCIAL_SECURITY_NUMBER.MASK}
                  placeholder={SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
                  validationPattern={SOCIAL_SECURITY_NUMBER.PATTERN}
                  validationPatternMessage={
                    SOCIAL_SECURITY_NUMBER.PATTERN_MESSAGE
                  }
                  defaultValue={methods.getValues("fullSsn")}
                />
              )}
              {formParameters.ssnOption && (
                <FormCheckbox
                  fieldId="noSsn"
                  label={t`By checking this box, I certify that the US Government has not issued a Social Security Number to me.`}
                  size="sm"
                  alignItems="baseline"
                />
              )}
            </Box>
            {formParameters.requestDriversLicense && (
              <>
                <FormInput
                  fieldId="driversLicense"
                  label={t`Driver's License`}
                  helperText={formMessages.driversLicenseLabel}
                  maxLength={64}
                />
                <FormSelect
                  fieldId="dlState"
                  label={t`Driver's License State/Province`}
                  options={STATES_AND_PROVINCES}
                />
              </>
            )}
            {formParameters.requestAirmanCertificateNumber && (
              <>
                <FormInput
                  type="tel"
                  fieldId="airmanCertificateNumber"
                  label={AIRMAN_CERTIFICATE_NUMBER.LABEL}
                  onKeyDown={onKeyDownEnforceNumeric}
                  maxLength={64}
                  autoComplete={AIRMAN_CERTIFICATE_NUMBER.AUTO_COMPLETE}
                  requiredMessage={""}
                  validationPattern={AIRMAN_CERTIFICATE_NUMBER.PATTERN}
                  validationPatternMessage={""}
                />
                <Text
                  fontSize="sm"
                  fontWeight="bold">{t`Please only enter numbers. Do not enter alpha characters.`}</Text>
                <Text
                  color="red"
                  fontSize="sm">{t`**If you do not have an Airman Certificate number, please enter 0.`}</Text>
              </>
            )}
            <TaskButtons
              onBack={goToStatusCheck}
              form={personalInfoForm}
              mt="1rem"
            />
          </form>
        </FormProvider>
      </VStack>
    </>
  );
}
