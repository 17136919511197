import { Flex, Spacer, Text } from "@chakra-ui/react";
import {
  CheckCircleIcon,
  DownloadIcon,
  EditIcon,
  TriangleUpIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { TaskIconType } from "tasks/tasksApi";

interface TaskListItemProps {
  text: string;
  iconType: TaskIconType;
  completed: boolean;
  onClick?: () => void;
}

const iconTypeMap = {
  signature: <EditIcon color="#f27f32" w="20px" h="20px" />,
  action: <WarningIcon color="orange" w="20px" h="20px" />,
  download: <DownloadIcon color="orange" w="20px" h="20px" />,
  upload: (
    <DownloadIcon color="orange" w="20px" h="20px" transform="rotate(180deg)" />
  ),
  form: <></>,
};

export default function TaskListItem({
  text,
  iconType,
  completed = false,
  onClick,
}: TaskListItemProps): JSX.Element {
  return (
    <Flex
      my="1rem"
      cursor={completed ? undefined : "pointer"}
      alignItems="center"
      onClick={completed ? undefined : onClick}
    >
      <Flex
        alignItems="center"
        px="0.5rem"
        w="full"
        bgColor={completed ? "lightgray" : undefined}
        border={completed ? "1px solid gray" : "1px solid orange"}
        borderLeft={completed ? "" : "10px solid orange"}
        borderRadius="5px"
      >
        <Text p="0.25rem">{text}</Text>
        <Spacer />
        {!completed && iconTypeMap[iconType]}
      </Flex>
      {completed ? (
        <CheckCircleIcon w="20px" h="20px" m="0.5rem" color="green" />
      ) : (
        <TriangleUpIcon m="0.5rem" color="orange" transform="rotate(90deg)" />
      )}
    </Flex>
  );
}
