import { NonSavableFormComponentProps } from "components/tasks/taskHelpers";
import { FormProvider, useForm } from "react-hook-form";
import TaskButtons from "components/common/basic/TaskButtons";
import { Box, Container, HStack, Text, useTheme } from "@chakra-ui/react";
import MeasureOneEmploymentItem from "./MeasureOneEmploymentItem";
import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import parse from "html-react-parser";
import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  useCreateDataRequestAndPublicToken,
  useStoreTaskMutation,
} from "tasks/taskQueries";
import {
  ApplicantEntryTaskId,
  MeasureOneDataResponseToken,
} from "tasks/tasksApi";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { devOrQAProdValue } from "util/EnvironmentUtils";

export interface MeasureOneEmployment {
  employerName: string;
  startDate: string;
  endDate?: string;
  measureOneDataResponseToken?: MeasureOneDataResponseToken;

  //Below are events for the MeasureOne Widget(2023-09-22)
  //https://docs.measureone.com/#tag/Individuals/paths/~1v3~1individuals~1new/post

  //Triggered when the User submits the credentials successfully on the Widget.
  //In case of MFA, this event will be triggered after the MFA is submitted successfully
  datasourceConnected?: boolean;

  //Triggered when one or more items are manually uploaded by the Individual
  itemsCreated?: boolean;

  //Triggered when the chooses to exit MeasureOne Link after being an attempt to reset their password
  credentialsNotObtained?: boolean;

  //Triggered when the selected datasource by Individual is not supported by MeasureOne
  datasourceNotSupported?: boolean;

  //Triggered when the user chooses to exit the MeasureOne Link experience.
  exitRequested?: boolean;
}

export interface MeasureOneEmploymentFormProps
  extends NonSavableFormComponentProps<MeasureOneEmploymentFields> {
  email: string;
  countries: [string];
}

export interface MeasureOneEmploymentFields {
  employments: [MeasureOneEmployment];
}

export type MeasureOneDataRequest = {
  itemKey: number;
  employerName: string;
  isCurrent: boolean;
};

export type MeasureOneDataResponseTokens = {
  itemKey: number;
  measureOneDataResponseToken: MeasureOneDataResponseToken;
};

export default function MeasureOneEmploymentForm({
  defaultValues,
  email,
  countries,
  onSubmit,
  goToStatusCheck,
}: MeasureOneEmploymentFormProps): JSX.Element {
  const measureOneEmploymentForm = "measureOneEmployment";
  const [loadedMeasureOneScript, setLoadedMeasureOneScript] =
    useState<boolean>(false);
  const [widgetVisible, setWidgetVisible] = useState<boolean>(false);
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const [selectedEmploymentKey, setSelectedEmploymentKey] =
    useState<number>(-1);
  const [
    currentMeasureOneDataResponseToken,
    setCurrentMeasureOneDataResponseToken,
  ] = useState<MeasureOneDataResponseToken>(Object);
  const [submitDisabled, disableSubmit] = useState<boolean>(false);
  const [widgetFullscreenToggled, setWidgetFullscreen] =
    useState<boolean>(false);

  const theme = useTheme();
  const { dimGray } = theme.colors.brand;

  const methods = useForm<MeasureOneEmploymentFields>({
    defaultValues,
  });

  const { employments } = defaultValues;

  const { mutate: mutateTaskData, isLoading: isStoring } = useStoreTaskMutation(
    { taskId: ApplicantEntryTaskId.APP_ENTRY_MEASURE_ONE_EMPLOYMENT },
  );

  const {
    data: dataRequestAndPublicToken,
    mutateAsync: createDataRequestAndPublicToken,
    isLoading: creatingDataRequestAndPublicToken,
    isSuccess: createdDataRequestAndPublicToken,
  } = useCreateDataRequestAndPublicToken(setCurrentMeasureOneDataResponseToken);

  function enterWidgetFullscreen() {
    //*on mobile set to fullscreen
    //MeasureOne script automatically expands widget to screen size when below 480px width threshold.
    if (window.innerWidth <= 480) {
      setWidgetFullscreen(true);
      document.documentElement.requestFullscreen();
      document.getElementById("taskButtonsContainer")!.style!.display = "none";
      document.getElementById("m1box")!.style!.top = "0px";
      document.getElementById("m1box")!.style!.position = "fixed";
    }
  }

  function exitWidgetFullscreen() {
    if (widgetFullscreenToggled) {
      setWidgetFullscreen(false);
      if (document.fullscreenElement != null) {
        document.exitFullscreen();
      }
      document.getElementById("taskButtonsContainer")!.style!.display = "block";
      document.getElementById("m1box")!.style!.top = "revert";
      document.getElementById("m1box")!.style!.position = "revert";
    }
  }

  //A MeasureOneEmploymentItem was clicked, retrieve MeasureOneDataResponseToken and initiate MeasureOne widget
  function getMeasureOneDataRequest(
    measureOneDataRequest: MeasureOneDataRequest,
  ) {
    const { itemKey, employerName, isCurrent } = measureOneDataRequest;

    setSelectedEmploymentKey(itemKey);
    setConfigLoaded(false);
    disableSubmit(true);
    setWidgetVisible(true);
    enterWidgetFullscreen();

    createDataRequestAndPublicToken({
      itemKey: itemKey,
      candidateEmailAddress: email,
      employerName: employerName,
      isCurrent: isCurrent,
      countries: countries,
    });
  }

  function closeWidget() {
    methods.setValue("employments", employments);

    //Allow showing of MeasureOne "Thank You" message before closing widget
    setTimeout(() => {
      setWidgetVisible(false);
      setSelectedEmploymentKey(-1);
      disableSubmit(false);
      exitWidgetFullscreen();
    }, 2000);
  }

  function saveTask() {
    mutateTaskData({
      formFields: {
        EMPLOYMENTS: employments.map((employment) => ({
          EMPLOYER_NAME: employment.employerName,
          START_DATE: employment.startDate,
          END_DATE: employment.endDate,
          CREATE_DATA_REQUEST_AND_PUBLIC_TOKEN_RESPONSE:
            employment.measureOneDataResponseToken,
          DATASOURCE_CONNECTED: employment.datasourceConnected,
          ITEMS_CREATED: employment.itemsCreated,
          CREDENTIALS_NOT_OBTAINED: employment.credentialsNotObtained,
          DATASOURCE_NOT_SUPPORTED: employment.datasourceNotSupported,
          EXIT_REQUESTED: employment.exitRequested,
        })),
      },
      saveOnly: true,
    });
  }

  //load MeasureOne script
  //TODO (WKX:2023-09-25) Use a React libary to import scripts
  const scriptSrc = devOrQAProdValue(
    "https://api-stg.measureone.com/v3/js/m1-link-2021042000.js",
    "https://api.measureone.com/v3/js/m1-link-2021042000.js",
  );
  useEffect(() => {
    if (
      !loadedMeasureOneScript &&
      !document.getElementById("measureOneScriptElement")
    ) {
      const script = document.createElement("script");
      script.id = "measureOneScriptElement";
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);
      setLoadedMeasureOneScript(true);
    }
  }, []);

  const configHostName = devOrQAProdValue(
    "api-stg.measureone.com",
    "api.measureone.com",
  );
  //TODO (WKX:2023-09-25) Should convert this into its own component and use Typescript
  function setMeasureOneConfig(itemKey: number) {
    setSelectedEmploymentKey(itemKey);
    setWidgetVisible(true);
    setConfigLoaded(true);
    employments[selectedEmploymentKey].measureOneDataResponseToken =
      currentMeasureOneDataResponseToken;
    methods.setValue("employments", employments);

    const config = {
      access_key: currentMeasureOneDataResponseToken.publicToken,
      host_name: configHostName,
      datarequest_id: currentMeasureOneDataResponseToken.dataRequestId,
      branding: {
        styles: {
          primary_dark: "#186793",
          primary_light: "#2e9ccb",
          secondary_color: "#ffffff",
          min_height: "1000px",
        },
      },
      options: {
        display_profile: false,
      },
    };

    const m1_widget = document.querySelector("m1-link");
    m1_widget?.setAttribute("config", JSON.stringify(config));

    m1_widget?.addEventListener("datasourceConnected", (event) => {
      employments[selectedEmploymentKey].datasourceConnected = true;
      saveTask();
      closeWidget();
    });
    m1_widget?.addEventListener("itemsCreated", (event) => {
      employments[selectedEmploymentKey].itemsCreated = true;
      saveTask();
      closeWidget();
    });
    m1_widget?.addEventListener("credentialsNotObtained", (event) => {
      employments[selectedEmploymentKey].credentialsNotObtained = true;
      closeWidget();
    });
    m1_widget?.addEventListener("exitRequested", (event) => {
      employments[selectedEmploymentKey].exitRequested = true;
      saveTask();
      closeWidget();
    });
  }

  return (
    <>
      <FormProvider {...methods}>
        <form
          id={measureOneEmploymentForm}
          onSubmit={methods.handleSubmit(onSubmit)}>
          {isStoring && <SpinnerOverlay text="Updating..." />}
          <Text>
            <Trans>
              Speed up the background check by accessing your payroll accounts
              or uploading documentation through <b>MeasureOne</b>. This process
              allows you to securely send us your employment dates and title
              without us needing to contact the employer.
            </Trans>
          </Text>
          <br />
          <Text>
            <Trans>
              Click the employer names below to find out if you can access this
              instant process.
            </Trans>
          </Text>
          <Container centerContent>
            <br />
            {employments.map((employment, key) => {
              const notCompleted =
                !employment.datasourceConnected && !employment.itemsCreated;

              if (notCompleted) {
                const disabled =
                  selectedEmploymentKey != key && selectedEmploymentKey != -1;
                const employmentIsSelected = selectedEmploymentKey == key;

                return (
                  <MeasureOneEmploymentItem
                    key={key}
                    itemKey={key}
                    employerName={employment.employerName}
                    startDate={employment.startDate}
                    endDate={employment.endDate}
                    disabled={disabled}
                    completed={employment.datasourceConnected}
                    getMeasureOneDataRequest={getMeasureOneDataRequest}
                    gettingMeasureOneDataRequest={
                      creatingDataRequestAndPublicToken && employmentIsSelected
                    }
                    gotMeasureOneDataRequest={
                      createdDataRequestAndPublicToken && employmentIsSelected
                    }
                    setMeasureOneConfig={setMeasureOneConfig}
                    configLoaded={configLoaded && employmentIsSelected}
                  />
                );
              } else {
                return (
                  <HStack>
                    <MeasureOneEmploymentItem
                      itemKey={key}
                      employerName={employment.employerName}
                      startDate={employment.startDate}
                      endDate={employment.endDate}
                      disabled={true}
                      completed={employment.datasourceConnected}
                      getMeasureOneDataRequest={() => true}
                      setMeasureOneConfig={() => true}
                    />
                    <CheckCircleIcon w="auto" height="35px" color="green" />
                  </HStack>
                );
              }
            })}
            {!employments.length && (
              <Text>
                <i>There are no employments to confirm.</i>
              </Text>
            )}
            <Box border={widgetVisible ? "1px" : "0px"} id="m1box">
              {widgetVisible && parse(`<m1-link></m1-link>`)}
            </Box>
          </Container>
          <br />
          <Text color={dimGray} fontSize="sm">
            <Trans>
              To skip the{" "}
              <i>
                <b>Job Confirmation: MeasureOne</b>
              </i>{" "}
              process, you may Submit without completing the steps above. This
              will extend the time it takes to complete the background check.
            </Trans>
          </Text>
          <TaskButtons
            onBack={goToStatusCheck}
            disabledSubmit={submitDisabled}
            form={measureOneEmploymentForm}
          />
        </form>
      </FormProvider>
    </>
  );
}
