import { WidgetProps } from "@rjsf/utils";
import { useFormContext } from "react-hook-form";
import FormInput from "components/common/form/FormInput";

export default function SpecialFormInput(props: WidgetProps) {
  const schema = props.schema;
  const uiSchema = props.uiSchema;
  const additionalProperties = uiSchema?.additionalProperties;
  const { register } = useFormContext();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value);
  };

  register(additionalProperties?.fieldId, {
    onChange: changeHandler,
  });

  return (
    <>
      <FormInput
        minLength={schema.minLength}
        maxLength={schema.maxLength}
        fieldId={additionalProperties?.fieldId}
        label={schema.title ? schema.title : ""}
        required={additionalProperties?.requiredField}
        defaultValue={props.value}
        onChange={changeHandler}
      />
    </>
  );
}
