import {
  InterviewFormParameters,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import SurveyForm from "./SurveyForm";

export type SurveyApiForm = {
  formMessages: InterviewFormMessages;
  formParameters: SurveyApiFormParameters;
};

interface SurveyApiFormParameters extends InterviewFormParameters {
  surveyUrl?: string;
}

interface SurveyTaskProps extends TaskComponentProps {
  form: SurveyApiForm;
}

export default function SurveyTask({
  form,
  goToStatusCheck,
}: SurveyTaskProps): JSX.Element {
  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    surveyUrl: form.formParameters?.surveyUrl || "",
  };

  return (
    <SurveyForm
      formMessages={formMessages}
      formParameters={formParameters}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
