import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import DropdownSelectionForm from "./DropdownSelectionForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type DropdownSelectionApiForm = {
  formFields: DropdownSelectionApiFormFields;
  formMessages: DropdownSelectionApiFormMessages;
  formParameters: DropdownSelectionApiFormParameters;
};

export interface DropdownSelectionFormFields {
  dropdownSelection?: string;
  otherValue?: string;
  doSave?: boolean;
}

interface DropdownSelectionApiFormFields extends InterviewFormFields {
  dropdownSelection?: string;
  otherValue?: string;
}

interface DropdownSelectionApiFormMessages extends InterviewFormMessages {
  dropdownLabel: string;
  otherLabel: string;
}

interface DropdownSelectionApiFormParameters extends InterviewFormParameters {
  dropdownOptions?: Array<string>;
}

interface DropdownSelectionTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: DropdownSelectionApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function DropdownSelectionTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: DropdownSelectionTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    dropdownSelection: form.formFields?.dropdownSelection || "",
    otherValue: form.formFields?.otherValue || "",
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
    dropdownLabel: form.formMessages?.dropdownLabel || "",
    otherLabel: form.formMessages?.otherLabel || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    dropdownOptions: form.formParameters?.dropdownOptions || [],
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <DropdownSelectionForm
      defaultValues={{
        [formParameters.formId]: {
          dropdownSelection: formFields.dropdownSelection,
          otherValue: formFields.otherValue,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
