import Header from "components/common/basic/Header";
import { Container, Heading, Text, VStack } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { useMutation, useQuery } from "react-query";
import { CommPrefsRequest, getCommPrefs, putCommPrefs } from "auth/authApi";
import useAuth from "auth/useAuth";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { useEffect, useState } from "react";
import PinForm from "auth/PinForm";
import CommPrefsForm from "preChecks/CommPrefsForm";
import { useHistory } from "react-router";
import { conditionalHistoryReplace } from "auth/historyHelpers";
import { AppPath } from "AppPath";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import useOtesModal from "error/useOtesModal";
import { AxiosError } from "axios";
import { ResponseCodes } from "tasks/apiConstants";

export interface PinSecret {
  code: string;
  type: string | "sms" | "email";
  commPrefValue?: string;
}

export default function CommPrefs(): JSX.Element {
  const { token, updateToken, setBypassOtp } = useAuth();
  const [pinSecret, setPinSecret] = useState<PinSecret>();
  const { triggerOtesModal } = useOtesModal();

  const { data: prefs, isFetching } = useQuery(
    ["preferences", token],
    () => getCommPrefs(token),
    { onError: handleOtesErrorFromAxiosError },
  );

  const onUpdateCommPrefsError = (response: AxiosError) => {
    handleOtesErrorFromAxiosError(response);
    const responseCode = response?.response?.data?.code;
    const hasPinSecretCode = pinSecret?.code;

    if (hasPinSecretCode) {
      if (ResponseCodes.Error.Action.INVALID_PIN === responseCode) {
        triggerOtesModal({
          bodyText: t`Incorrect or expired PIN, please try again.`,
        });
      } else {
        triggerOtesModal({
          bodyText: t`Unable to verify PIN, please try again.`,
        });
      }
    } else {
      if (ResponseCodes.Error.Action.SMS_NOT_AVAILABLE === responseCode) {
        triggerOtesModal({
          bodyText: t`Mobile Phone cannot be preferred because it is not a valid United States phone number.`,
        });
      } else {
        triggerOtesModal({
          bodyText: t`Unable to store preferences, please try again.`,
        });
      }
    }
  };

  const updateCommPrefsMutation = useMutation(
    (payload: CommPrefsRequest) => putCommPrefs(payload, token),
    {
      onSuccess: (response) => {
        if (response?.data.pinType && response?.data.pinCode) {
          setPinSecret({
            code: response.data.pinCode,
            type: response.data.pinType,
            commPrefValue: response.data.commPrefValue,
          });
        } else {
          setBypassOtp(true);
          updateToken(response?.data.token);
        }
      },
      onError: onUpdateCommPrefsError,
    },
  );

  const onCommPrefsSubmit = (payload: CommPrefsRequest) => {
    updateCommPrefsMutation.mutate(payload);
  };

  const onCommPrefsPinSubmit = (pin: string) => {
    const { pinInfo, ...payloadNoPinInfo } =
      updateCommPrefsMutation.variables || {};

    updateCommPrefsMutation.mutate({
      pinInfo: { pin, pinCode: pinSecret?.code || "" },
      ...payloadNoPinInfo,
    });
  };

  const onCommPrefsReSendPin = () => {
    const { pinInfo, ...payloadNoPinInfo } =
      updateCommPrefsMutation.variables || {};

    updateCommPrefsMutation.mutate({
      ...payloadNoPinInfo,
    });
  };

  const history = useHistory();

  useEffect(() => {
    conditionalHistoryReplace(history, AppPath.NOTIFICATIONS, !isFetching);
  });

  const headerText = isFetching ? "" : "Notifications";

  let formContent;

  if (isFetching || updateCommPrefsMutation.isLoading) {
    let spinnerText: string;

    if (!isFetching) {
      if (pinSecret?.code) {
        spinnerText = "One moment please...";
      } else {
        spinnerText = "Updating preferences...";
      }
    } else {
      spinnerText = "Loading preferences...";
    }

    formContent = <SpinnerOverlay text={spinnerText} />;
  } else if (pinSecret?.code) {
    formContent = (
      <>
        <Container centerContent maxW="container.sm">
          <PinForm
            description={t`A pin was sent to ${pinSecret?.commPrefValue} to verify your access to the device before we update it to preferred. This pin will expire in 10 minutes.`}
            onPinSubmit={onCommPrefsPinSubmit}
            onReSendPin={onCommPrefsReSendPin}
          />
        </Container>
      </>
    );
  } else {
    formContent = (
      <>
        <Container centerContent maxW="container.sm">
          <VStack p={1}>
            <Heading p={1} as="h1" alignItems="center" size="xl">
              <Trans>Communication Preferences</Trans>
            </Heading>
            <Text py={2}>
              <Trans>
                Please provide at least one method by which we can contact you
                regarding your background screen.
              </Trans>
            </Text>
            <CommPrefsForm
              onSubmit={onCommPrefsSubmit}
              emailPref={prefs?.emailPref}
              smsPref={prefs?.smsPref}
            />
          </VStack>
        </Container>
      </>
    );
  }

  return (
    <>
      <Header text={headerText} />
      {formContent}
    </>
  );
}
