import {
  Box,
  Heading,
  HStack,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";

export default function TermsAndConditions(): JSX.Element {
  const bottomBorder = "10px";
  const listLeftPadding = "25px";

  return (
    <>
      <HStack alignContent={"left"} p={4}>
        <Box w={"lg"}>&nbsp;</Box>
        <Box>
          <VStack alignContent={"center"} p={4}>
            <Heading>
              <Text>Terms of Use</Text>
            </Heading>
            <Text>
              <strong>Last modified: December 29, 2016</strong>
            </Text>
          </VStack>
          <Text>
            <strong>Acceptance of the Terms of Use:</strong> Welcome to the
            website of Orange Tree Employment Screening, LLC
            (&quot;Company&quot;, &quot;we&quot; or &quot;us&quot;). The
            following terms and conditions, together with any documents they
            expressly incorporate by reference (collectively, these &quot;Terms
            of Use&quot;), govern your access to and use of this site, including
            any content, functionality and services provided on or through the
            website or its related domains and subdomains (the
            &quot;Website&quot;), whether as a guest or registered user;
            provided however that the terms of a separate agreement entered into
            by and between you and the Company shall govern to the exclusion of
            these Terms of Use where applicable.
          </Text>
          <Text pb={bottomBorder}>
            Please read the Terms of Use carefully before you start to use the
            Website. By using the Website, you accept and agree to be bound and
            abide by these Terms of Use and our{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            , incorporated herein by reference. If you do not want to agree to
            these Terms of Use or the{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            , you must not access or use the Website.
          </Text>
          <Text>
            <strong>Changes to the Terms of Use:</strong> We may revise and
            update these Terms of Use from time to time in our sole discretion.
            All changes are effective immediately when we post them, and apply
            to all access to and use of the Website thereafter.
          </Text>
          <Text pb={bottomBorder}>
            Your continued use of the Website following the posting of revised
            Terms of Use means that you accept and agree to the changes. You are
            expected to check this page each time you access this Website so you
            are aware of any changes, as they are binding on you.
          </Text>
          <Text>
            <strong>Electronic Signature and Delivery:</strong> You consent and
            agree that your use of a key pad, mouse or other device to select an
            item, button, icon or similar act/action while using any electronic
            service Company offers; or in accessing or making any transactions
            regarding any agreement, acknowledgment, consent, terms, disclosures
            or conditions constitutes your signature, acceptance and agreement
            as if actually signed by you in writing. Further, you agree that no
            certification authority or other third party verification is
            necessary to the validity of your electronic signature; and that the
            lack of such certification or third party verification will not in
            any way affect the enforceability of your signature or any resulting
            contract between you and Company.
          </Text>
          <Text>
            By using this Website you are demonstrating that you agree to, and
            you have the ability to, view and save information and Documents
            through electronic means.
          </Text>
          <Text>
            You agree to receive electronic delivery of:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>the Privacy Notice</ListItem>
              <ListItem>
                Information presented to you as part of an online transaction
                such as interviews, disclosures, forms, notices, authorizations,
                and other information
              </ListItem>
              <ListItem>Your consumer report where applicable</ListItem>
              <ListItem>
                Documents reflecting our client’s decisions regarding your
                consumer report, including adverse action notices
              </ListItem>
              <ListItem>
                Information and Documents presented to you related to a dispute
                or reinvestigation process
              </ListItem>
            </UnorderedList>
          </Text>
          <Text>
            You agree to receive text messages from Company at any telephone
            number you have provided to us or that we have otherwise obtained.
          </Text>
          <Text>
            You may update your contact information for preferences regarding
            electronic delivery of information or Documents by accessing and
            updating your profile.
          </Text>
          <Text>
            If you choose to receive documents and information electronically,
            it is your responsibility to periodically logon to the Website and
            check the delivery of information and new Documents. Open and read
            your Documents. YOU REPRESENT THAT YOU HAVE THE EQUIPMENT TO RECEIVE
            INFORMATION AND DOCUMENTS ONLINE.
          </Text>
          <Text pb={bottomBorder}>
            We will deliver electronic information or Documents by posting them
            on this Website, by electronic mail or by other reasonable methods
            of electronic delivery. We may send a notice by text or to your
            email when information is needed or an electronic Document is
            posted, depending on your preferences and applicable law. If
            applicable law or system limitations prevent Company from delivering
            certain Documents, Company will deliver them as allowed by law.
          </Text>
          <Text>
            <strong>Accessing the Website and Account Security:</strong> We
            reserve the right to withdraw or amend this Website, and any service
            or material we provide on the Website, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of the Website is unavailable at any time or for any period.
            From time to time, we may restrict access to some parts of the
            Website, or the entire Website, to users, including registered
            users. You are responsible for:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                Making all arrangements necessary for you to have access to the
                Website.
              </ListItem>
              <ListItem>
                Ensuring that all persons who access the Website through your
                internet connection are aware of these Terms of Use and comply
                with them.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text>
            To access the Website or some of the resources it offers, you may be
            asked to provide certain registration details or other information.
          </Text>
          <Text>
            It is a condition of your use of the Website that all the
            information you provide on the Website is correct, current and
            complete. You agree that all information you provide to register
            with this Website or otherwise, including but not limited to through
            the use of any interactive features on the Website, including any
            User Contribution, is governed by our{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            , and you consent to all actions we take with respect to your
            information consistent with our{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            .
          </Text>
          <Text>
            If you choose, or are provided with, a user name, password, pin or
            any other piece of information as part of our security procedures,
            you must treat such information as confidential, and you must not
            disclose it to any other person or entity. You also acknowledge that
            your account is personal to you and agree not to provide any other
            person with access to this Website or portions of it using your user
            name, password, pin, or other security information. You agree to
            notify us immediately of any unauthorized access to or use of your
            user name or password or any other breach of security. You also
            agree to ensure that you exit from your account at the end of each
            session. You should use particular caution when accessing your
            account from a public or shared computer so that others are not able
            to view or record your password, pin, or other personal information.
          </Text>
          <Text pb={bottomBorder}>
            We have the right to disable any user name, password, pin, or other
            identifier, whether chosen by you or provided by us, at any time in
            our sole discretion for any or no reason, including if, in our
            opinion, you have violated any provision of these Terms of Use.
          </Text>
          <Text>
            <strong>Intellectual Property Rights:</strong> The Website and its
            entire contents, features and functionality (including but not
            limited to all information, software, text, displays, images, video
            and audio, and the design, selection and arrangement thereof), are
            owned by the Company, its licensors or other providers of such
            material and are protected by United States and international
            copyright, trademark, patent, trade secret and other intellectual
            property or proprietary rights laws. The Company name, marks, logo
            and all related names, logos, product and service names, designs and
            slogans are trademarks of the Company or its affiliates or
            licensors. You must not use such marks without the prior written
            permission of the Company. All other names, logos, product and
            service names, designs and slogans on this Website are the
            trademarks of their respective owners.
          </Text>
          <Text>
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store
            or transmit any of the material on our Website, except as follows:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                Your computer may temporarily store copies of such materials in
                RAM incidental to your accessing and viewing those materials.
              </ListItem>
              <ListItem>
                You may store files that are automatically cached by your Web
                browser for display enhancement purposes.
              </ListItem>
              <ListItem>
                You may print or download one copy of a reasonable number of
                pages of the Website for your own personal, non-commercial use
                or limited business purpose of exploring a potential transaction
                or business relationship with the Company, and not for further
                reproduction, publication or distribution.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text>
            You must not:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                Modify copies of any materials from this site.
              </ListItem>
              <ListItem>
                Delete or alter any copyright, trademark or other proprietary
                rights notices from copies of materials from this site.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text>
            You must not access or use for any commercial purposes use, other
            than the limited business purpose of exploring a potential
            transaction or business relationship with the Company, any part of
            the Website or any services or materials available through the
            Website. If you wish to make any use of material on the Website
            other than that set out in this section, please address your request
            to: compliance@otes.com.
          </Text>
          <Text pb={bottomBorder}>
            If you print, copy, modify, download or otherwise use or provide any
            other person with access to any part of the Website in breach of the
            Terms of Use, your right to use the Website will cease immediately
            and you must, at our option, return or destroy any copies of the
            materials you have made. No right, title or interest in or to the
            Website or any content on the site is transferred to you, and all
            rights not expressly granted are reserved by the Company. Any use of
            the Website not expressly permitted by these Terms of Use is a
            breach of these Terms of Use and may violate copyright, trademark
            and other laws.
          </Text>
          <Text>
            <strong>User Contributions:</strong> The Website may contain
            personal web pages or profiles and other interactive features
            (collectively, &quot;Interactive Services&quot;) that allow users to
            post, submit, publish, display or transmit to the Company or its
            client(s) (hereinafter, &quot;post&quot;) content or materials
            (collectively, &quot;User Contributions&quot;) on or through the
            Website.
          </Text>
          <Text>
            All User Contributions must comply with the Content Standards set
            out in these Terms of Use.
          </Text>
          <Text>
            Any User Contribution you post to the site will be considered
            non-confidential in as much as it may be shared with the Orange Tree
            client that requested a Consumer Report about you. By providing any
            User Contribution on the Website, you grant us and our affiliates
            and service providers, and each of their and our respective
            licensees, successors and assigns the rights to publish or display,
            any such material, for the limited purpose of preparing a Consumer
            Report about you.
          </Text>
          <Text>
            You represent and warrant that:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                All of your User Contributions do and will comply with these
                Terms of Use.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text>
            You understand and acknowledge that you are responsible for any User
            Contributions you submit or contribute, and you, not the Company,
            have full responsibility for such content, including its legality,
            reliability, accuracy and appropriateness.
          </Text>
          <Text pb={bottomBorder}>
            We are not responsible, or liable to any third party, for the
            content or accuracy of any User Contributions posted by you or any
            other user of the site.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Prohibited Uses:</strong> You may use the Website only for
            lawful purposes and in accordance with these Terms of Use. You agree
            not to use the Website:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                In any way that violates any applicable federal, state, local or
                international law or regulation (including, without limitation,
                any laws regarding the export of data or software to and from
                the US or other countries).
              </ListItem>
              <ListItem>
                For the purpose of exploiting, harming or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content,asking for personally identifiable information or
                otherwise.
              </ListItem>
              <ListItem>
                To send, knowingly receive, upload, download, use or re-use any
                material which does not its entirety comply with all applicable
                federal, state, local and international laws and regulations or,
                without limiting the foregoing, does any of the following
                (“Content Standards”):
                <UnorderedList pl={listLeftPadding}>
                  <ListItem>
                    Contain any material which is defamatory, obscene, indecent,
                    abusive, offensive, harassing, violent, hateful,
                    inflammatory or otherwise objectionable.
                  </ListItem>
                  <ListItem>
                    Promote sexually explicit or pornographic material,
                    violence, or discrimination based on race, sex, religion,
                    nationality, disability, sexual orientation or age.
                  </ListItem>
                  <ListItem>
                    Infringe any patent, trademark, trade secret, copyright or
                    other intellectual property or other rights of any other
                    person.
                  </ListItem>
                  <ListItem>
                    Violate the legal rights (including the rights of publicity
                    and privacy) of others or contain any material that could
                    give rise to any civil or criminal liability under
                    applicable laws or regulations or that otherwise may be in
                    conflict with these Terms of Use and our{" "}
                    <Link
                      isExternal
                      href="https://www.orangetreescreening.com/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    (https://www.orangetreescreening.com/privacy-policy).
                  </ListItem>
                  <ListItem>Be likely to deceive any person.</ListItem>
                  <ListItem>
                    Promote any illegal activity, or advocate, promote or assist
                    any unlawful act.
                  </ListItem>
                  <ListItem>
                    Cause annoyance, inconvenience or needless anxiety or be
                    likely to upset, embarrass, alarm or annoy any other person.
                  </ListItem>
                  <ListItem>
                    Give the impression that they emanate from or are endorsed
                    by us or any other person or entity, if this is not the
                    case.
                  </ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                To transmit, or procure the sending of, any advertising or
                promotional material without our prior written consent,
                including any &quot;junk mail&quot;, &quot;chain letter&quot; or
                &quot;spam&quot; or any other similar solicitation.
              </ListItem>
              <ListItem>
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user or any other person or entity (including,
                without limitation, by using e-mail addresses or screen names
                associated with any of the foregoing).
              </ListItem>
              <ListItem>
                To engage in any other conduct that restricts or inhibits
                anyone&apos;s use or enjoyment of the Website, or which, as
                determined by us, may harm the Company or users of the Website
                or expose them to liability.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text pb={bottomBorder}>
            Additionally, you agree not to:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                Use the Website in any manner that could disable, overburden,
                damage, or impair the site or interfere with any other
                party&apos;s use of the Website, including their ability to
                engage in real time activities through the Website.
              </ListItem>
              <ListItem>
                Use any robot, spider or other automatic device, process or
                means to access the Website for any purpose, including
                monitoring or copying any of the material on the Website.
              </ListItem>
              <ListItem>
                Use any manual process to monitor or copy any of the material on
                the Website or for any other unauthorized purpose without our
                prior written consent.
              </ListItem>
              <ListItem>
                Introduce any viruses, trojan horses, worms, logic bombs or
                other material which is malicious or technologically harmful.
              </ListItem>
              <ListItem>
                Attempt to gain unauthorized access to, interfere with, damage
                or disrupt any parts of the Website, the server on which the
                Website is stored, or any server, computer or database connected
                to the Website.
              </ListItem>
              <ListItem>
                Attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack.
              </ListItem>
              <ListItem>
                Otherwise attempt to interfere with the proper working of the
                Website.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text pb={bottomBorder}>
            <strong>Monitoring and Enforcement; Termination:</strong> We have
            the right to:
            <UnorderedList pl={listLeftPadding}>
              <ListItem>
                Remove or refuse to post any User Contributions for any or no
                reason in our sole discretion.
              </ListItem>
              <ListItem>
                Take any action with respect to any User Contribution that we
                deem necessary or appropriate in our sole discretion, including
                if we believe that such User Contribution violates the Terms of
                Use, including the Content Standards, infringes any intellectual
                property right or other right of any person or entity, threatens
                the personal safety of users of the Website or the public or
                could create liability for the Company.
              </ListItem>
              <ListItem>
                Take appropriate legal action, including without limitation,
                referral to law enforcement, for any illegal or unauthorized use
                of the Website.
              </ListItem>
              <ListItem>
                Terminate or suspend your access to all or part of the Website
                for any or no reason, including without limitation, any
                violation of these Terms of Use.
              </ListItem>
            </UnorderedList>
          </Text>
          <Text pb={bottomBorder}>
            Without limiting the foregoing, we have the right to fully cooperate
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            posting any materials on or through the Website.{" "}
            <strong>
              YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES,
              LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
              ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT
              OF ITS NVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE
              OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
              AUTHORITIES.
            </strong>
          </Text>
          <Text pb={bottomBorder}>
            <strong>Changes to the Website:</strong> We may update the content
            on this Website from time to time, but its content is not
            necessarily complete or up-to-date. Any of the material on the
            Website may be out of date at any given time, and we are under no
            obligation to update such material.
          </Text>
          <Text pb={bottomBorder}>
            <strong>
              Information About You and Your Visits to the Website:
            </strong>{" "}
            All information we collect on this Website is subject to our{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            . By using the Website, you consent to all actions taken by us with
            respect to your information in compliance with the{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            .
          </Text>
          <Text pb={bottomBorder}>
            <strong>Linking to the Website and Social Media Features:</strong>{" "}
            You may link to our homepage, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it, but you must not establish a link in such a way as to suggest
            any form of association, approval or endorsement on our part without
            our express written consent.
          </Text>
          <Text pb={bottomBorder}>
            You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to cease. We reserve the right to withdraw
            linking permission without notice.
          </Text>
          <Text pb={bottomBorder}>
            We may disable all or any social media features and any links at any
            time without notice in our discretion.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Links from the Website:</strong> If the Website contains
            links to other sites and resources provided by third parties, these
            links are provided for your convenience only. This includes links
            contained in advertisements, including banner advertisements and
            sponsored links. We have no control over the contents of those sites
            or resources, and accept no responsibility for them or for any loss
            or damage that may arise from your use of them. If you decide to
            access any of the third party websites linked to this Website, you
            do so entirely at your own risk and subject to the terms and
            conditions of use for such websites.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Geographic Restrictions:</strong> The owner of the Website
            is based in the state of Minnesota in the United States. Access to
            the Website is prohibited by any person(s) or organization(s)
            designated by the United States government as a foreign terrorist
            pursuant to section 219 of the Immigration and Nationality Act or
            otherwise in violation of any US export control restrictions. If you
            access the Website from outside the United States, you do so on your
            own initiative and are responsible for compliance with local laws.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Disclaimer of Warranties:</strong> You understand that we
            cannot and do not guarantee or warrant that files available for
            downloading from the internet or the Website will be free of viruses
            or other destructive code. You are responsible for implementing
            sufficient procedures and checkpoints to satisfy your particular
            requirements for anti-virus protection and accuracy of data input
            and output, and for maintaining a means external to our site for any
            reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS
            OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES
            OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
            COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
            MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
            POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
          </Text>
          <Text pb={bottomBorder}>
            YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
            CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
            PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS,
            WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
            NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
            ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
            WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
            ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
            WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </Text>
          <Text>
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT,
            ACCURACY AND FITNESS FOR PARTICULAR PURPOSE.
          </Text>
          <Text pb={bottomBorder}>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW. WHERE WARRANTIES MAY BE
            LIMITED BUT NOT EXCLUDED, IN NO EVENT SHALL THE COMPANY&apos;S
            AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT,
            WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED THE LOWEST AMOUNT
            ALLOWABLE UNDER THE APPLICABLE LAW, OR $100.00 IF SUCH AMOUNT IS NOT
            PROVIDED FOR BY SUCH LAW. THE FOREGOING LIMITATIONS SHALL APPLY EVEN
            IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL
            PURPOSE.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Limitation on Liability:</strong> IN NO EVENT WILL THE
            COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS,
            EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF
            ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
            WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED
            TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY
            SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER
            WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
            PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
            TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN
            IF FORESEEABLE.
          </Text>
          <Text pb={bottomBorder}>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Indemnification:</strong> You agree to defend, indemnify and
            hold harmless the Company, its affiliates, licensors and service
            providers, and its and their respective officers, directors,
            employees, contractors, agents, licensors, suppliers, successors and
            assigns from and against any claims, liabilities, damages,
            judgments, awards, losses, costs, expenses or fees (including
            reasonable attorneys&apos; fees) arising out of or relating to your
            violation of these Terms of Use or your use of the Website,
            including, but not limited to, your User Contributions, any use of
            the Website&apos;s content, services and products other than as
            expressly authorized in these Terms of Use or your use of any
            information obtained from the Website.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Governing Law and Jurisdiction:</strong> All matters
            relating to the Website and these Terms of Use and any dispute or
            claim arising therefrom or related thereto (in each case, including
            non-contractual disputes or claims), shall be governed by and
            construed in accordance with the internal laws of the State of
            Minnesota without giving effect to any choice or conflict of law
            provision or rule (whether of the State of Minnesota or any other
            jurisdiction).
          </Text>
          <Text pb={bottomBorder}>
            <strong>Limitation on Time to File Claims:</strong> ANY CAUSE OF
            ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE
            TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
            AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION
            OR CLAIM IS PERMANENTLY BARRED.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Waiver and Severability:</strong> No waiver of by the
            Company of any term or condition set forth in these Terms of Use
            shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of the Company to assert a right or provision under these
            Terms of Use shall not constitute a waiver of such right or
            provision.
          </Text>
          <Text pb={bottomBorder}>
            If any provision of these Terms of Use is held by a court or other
            tribunal of competent jurisdiction to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of
            the Terms of Use will continue in full force and effect.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Entire Agreement:</strong> The Terms of Use, our{" "}
            <Link
              isExternal
              href="https://www.orangetreescreening.com/privacy-policy">
              Privacy Policy
            </Link>
            , and Copyright Policy (as applicable) constitute the sole and
            entire agreement between you and Company with respect to the Website
            and supersede all prior and contemporaneous oral understandings,
            agreements, representations and warranties with respect to the
            Website. For the avoidance of doubt, the terms of a separate
            subscription agreement entered into by and between you and the
            Company shall govern to the exclusion of these Terms of Use where
            applicable.
          </Text>
          <Text pb={bottomBorder}>
            <strong>Your Comments and Concerns:</strong> This website is
            operated by Orange Tree Employment Screening, LLC.
          </Text>
          <Text>
            All feedback, comments, requests for technical support and other
            communications relating to the Website should be directed to:{" "}
            <Link href="mailto:compliance@otes.com">compliance@otes.com.</Link>
          </Text>
          <Text>Thank you for visiting the Website.</Text>
        </Box>
        <Box w={"lg"}>&nbsp;</Box>
      </HStack>
    </>
  );
}
