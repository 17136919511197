import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import AdditionalQuestionsForm from "./AdditionalQuestionsForm";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import {
  ApiFormFields,
  ApiFormMessages,
  ApiFormParameters,
  SingleFormTaskDef,
} from "tasks/tasksApi";

export type AdditionalQuestionsApiForm = {
  formFields: AdditionalQuestionsApiFormFields;
  formMessages: ApiFormMessages;
  formParameters: AdditionalQuestionsApiFormParameters;
};

export interface AdditionalQuestionsFormFields {
  answers?: Array<Answer>;
  doSave?: boolean;
}

export interface Question {
  id: number;
  question?: string;
}

interface Answer {
  id: number;
  answer?: string;
}

interface AdditionalQuestionsApiFormFields extends ApiFormFields {
  answers?: Array<Answer>;
}

interface AdditionalQuestionsApiFormParameters extends ApiFormParameters {
  questions: Array<Question>;
}

interface AdditionalQuestionsTaskProps extends TaskComponentProps {
  taskId: string;
  isSavable?: boolean;
}

export default function AdditionalQuestionsTask({
  taskId,
  isSavable = true,
  goToStatusCheck,
}: AdditionalQuestionsTaskProps): JSX.Element {
  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<
      AdditionalQuestionsApiFormFields,
      ApiFormMessages,
      AdditionalQuestionsApiFormParameters
    >
  >({ taskId, goToStatusCheck });

  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const storeAdditionalQuestions: SubmitHandler<AdditionalQuestionsFormFields> =
    async ({ doSave, answers }) => {
      mutateTaskData({
        formFields: {
          answers,
        },
        saveOnly: doSave,
      });
    };

  const formFields = {
    answers: data?.forms[0]?.formFields?.answers || [],
  };

  const formParameters = {
    taskId: data?.forms[0]?.formParameters?.taskId || "",
    questions: data?.forms[0]?.formParameters?.questions || [],
  };

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <AdditionalQuestionsForm
      defaultValues={{
        answers: formFields.answers,
      }}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={storeAdditionalQuestions}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
