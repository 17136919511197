import { Button, Center, Spinner } from "@chakra-ui/react";
import { MeasureOneDataRequest } from "./MeasureOneEmploymentForm";
import { ReactNode } from "react";

interface MeasureOneEmploymentProps {
  itemKey: number;
  employerName: string;
  startDate: string;
  endDate?: string;
  disabled: boolean;
  completed?: boolean;
  getMeasureOneDataRequest: (
    measureOneDataRequest: MeasureOneDataRequest,
  ) => void;
  gettingMeasureOneDataRequest?: boolean;
  gotMeasureOneDataRequest?: boolean;
  setMeasureOneConfig: (itemKey: number) => void;
  configLoaded?: boolean;
}

export default function MeasureOneEmploymentItem({
  itemKey,
  employerName,
  startDate,
  endDate,
  disabled,
  completed,
  getMeasureOneDataRequest,
  gettingMeasureOneDataRequest,
  gotMeasureOneDataRequest,
  setMeasureOneConfig,
  configLoaded,
}: MeasureOneEmploymentProps): JSX.Element {
  let employmentButton: ReactNode;
  const employmentItemText = `${employerName} (${startDate} to ${
    endDate || "CURRENT"
  })`;

  if (gettingMeasureOneDataRequest) {
    employmentButton = (
      <Button colorScheme="orange" whiteSpace="normal">
        <Spinner m="auto" size="md" float="left" />
        {employmentItemText}
      </Button>
    );
  } else if (gotMeasureOneDataRequest && !configLoaded) {
    setMeasureOneConfig(itemKey);

    employmentButton = (
      <Button colorScheme="orange" whiteSpace="normal">
        <Spinner m="auto" size="md" float="left" />
        {employmentItemText}
      </Button>
    );
  } else if (configLoaded) {
    employmentButton = (
      <Button
        colorScheme="orange"
        border="4px"
        borderColor="black"
        whiteSpace="normal">
        {employmentItemText}
      </Button>
    );
  } else {
    const onClickFunction = () => {
      if (disabled || completed) {
        return () => true;
      } else {
        return () =>
          getMeasureOneDataRequest({
            itemKey: itemKey,
            employerName: employerName,
            isCurrent: !!endDate,
          });
      }
    };

    employmentButton = (
      <Button
        colorScheme="orange"
        onClick={onClickFunction()}
        disabled={disabled || completed}
        whiteSpace="normal">
        {employmentItemText}
      </Button>
    );
  }

  return <Center p={2}>{employmentButton}</Center>;
}
