import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import AdditionalReferencesForm from "./AdditionalReferencesForm";
import hash from "object-hash";

export type AdditionalReferencesApiForm = {
  formFields: AdditionalReferencesApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: AdditionalReferencesApiFormParameters;
};

export interface AdditionalReferencesFormFields {
  existingReferences?: Array<Reference>;
  noAdditionalReferences?: boolean;
  additionalReferences?: Array<Reference>;
  doSave?: boolean;
}

export interface Reference {
  relationToApplicant: string;
  name: string;
  emailAddress: string;
  phone: string;
}

interface AdditionalReferencesApiFormFields extends InterviewFormFields {
  existingReferences: Array<Reference>;
  noAdditionalReferences: boolean;
  additionalReferences: Array<Reference>;
}

interface AdditionalReferencesApiFormParameters
  extends InterviewFormParameters {
  askForProfessionalReferences: boolean;
  numberOfRequiredAdditionalReferences: number;
  requireReferenceEmail: boolean;
  requireReferencePhone: boolean;
}

interface AdditionalReferencesTaskProps extends TaskComponentProps {
  form: AdditionalReferencesApiForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export const emptyAdditionalReference = {
  name: "",
  emailAddress: "",
  phone: "",
  relationToApplicant: "",
};

export default function AdditionalReferencesTask({
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: AdditionalReferencesTaskProps): JSX.Element {
  const formFields = {
    existingReferences: form.formFields?.existingReferences,
    noAdditionalReferences: form.formFields?.noAdditionalReferences,
    additionalReferences: form.formFields?.additionalReferences,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    askForProfessionalReferences:
      form.formParameters?.askForProfessionalReferences,
    numberOfRequiredAdditionalReferences:
      form.formParameters?.numberOfRequiredAdditionalReferences,
    requireReferenceEmail: form.formParameters?.requireReferenceEmail,
    requireReferencePhone: form.formParameters?.requireReferencePhone,
  };

  const validateUniqueAdditionalReferences = (
    additionalReferences: Reference[] = [],
  ) => {
    const additionalReferencesHashes =
      additionalReferences?.map((additionalReference, index) =>
        hash(additionalReference),
      ) || [];
    return (
      [...new Set(additionalReferencesHashes)].length ===
      additionalReferencesHashes.length
    );
  };

  const additionalReferencesDefaultValue =
    formFields.additionalReferences || [];
  if (!additionalReferencesDefaultValue.length) {
    for (
      let i = 0;
      i < formParameters.numberOfRequiredAdditionalReferences;
      i++
    ) {
      additionalReferencesDefaultValue.push(emptyAdditionalReference);
    }
  }

  return (
    <AdditionalReferencesForm
      defaultValues={{
        [formParameters.formId]: {
          existingReferences: formFields.existingReferences,
          noAdditionalReferences:
            formFields.noAdditionalReferences !== undefined
              ? formFields.noAdditionalReferences
              : undefined,
          additionalReferences: additionalReferencesDefaultValue,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      validateUniqueAdditionalReferences={validateUniqueAdditionalReferences}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
