import {
  InterviewApiSubForm,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import {
  EmploymentOrGapFormFields,
  EMPTY_EMPLOYMENT,
} from "components/tasks/employment-history/EmploymentOrGapForm";
import { SubmitHandler } from "react-hook-form";
import EmploymentHistoryForm, {
  NO_ADDITIONAL_EMPLOYMENT_HISTORY_ITEMS_FIELD,
  NO_HISTORY_FOR_THIS_PERIOD_FIELD,
} from "components/tasks/employment-history/EmploymentHistoryForm";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import { Heading, Text } from "@chakra-ui/react";
import { Plural, t, Trans } from "@lingui/macro";
import {
  boolStrFieldsToBools,
  flattenExistingValues,
  splitDates,
} from "components/tasks/taskHelpers";

export interface EmplHistInterviewFormFields extends InterviewSubmitProps {
  /**
   * Note - the History Interviews deviate from the Interview Singleton/Sub form paradigm since they
   * re-use the task definition layout of the History Tasks. History Interview forms are Singleton forms
   * since there will only ever be one form on the History Interview task definitions. For this reason
   * they also will not conform to the "formId.fieldName" field naming convention outlined in
   * InterviewSubmitProps
   *
   * Additionally, as of April 2022 the History Interviews still utilize an object with a
   * property of "existingValue" to represent saved/stored values with which to populate
   * the form. As this is set to be removed with later improvements, that api data layout
   * is not captured by the History Interview api form Types/Interfaces.
   */
  NO_HISTORY_FOR_THIS_PERIOD: boolean;
  noAdditionalEmploymentHistoryItems: boolean;
  EMPLOYMENT_HISTORY_ITEMS: EmploymentOrGapFormFields[];
}

export interface EmplHistSubFormMessages extends InterviewFormMessages {
  clientCustomizedTaskDescription: string;
  desiredEmploymentDateRangeStart: string;
  desiredEmploymentDateRangeEnd: string;
}

export interface EmplHistSubFormParameters extends InterviewFormParameters {
  allowNoHistorySelection: boolean;
  minimumNumberOfYearsOfEmploymentHistory: number;
  maximumNumberOfDaysAllowedWithoutGap: number;
  hasDotVerification: boolean;
  hasFmcsaVerification: boolean;
  hasFaaVerification: boolean;
  regulationTypes: string[];
  employerTypes: {
    [label: string]: string[];
  };
  minimumNumberOfEmployments: number;
  maximumNumberOfEmployments: number;
}

export type EmplHistApiSubForm = InterviewApiSubForm<
  EmplHistInterviewFormFields,
  EmplHistSubFormMessages,
  EmplHistSubFormParameters
>;

export interface EmplHistInterviewFormProps {
  form: EmplHistApiSubForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
  goToStatusCheck?: () => void;
}

/**
 * A function that maps/coalesces the api data into the format
 * required by the UI form.
 * @param apiData the EmploymentHistoryInterview task definition
 */
export function mapEmplHistApiToUi(
  apiData: any, //"any" used here until API removes "existingValue" object
): EmplHistApiSubForm {
  return splitDates(
    boolStrFieldsToBools(flattenExistingValues(apiData), [
      "CONTRACTOR",
      NO_HISTORY_FOR_THIS_PERIOD_FIELD,
    ]),
  );
}

export default function EmplHistInterviewFormAdapter({
  form: {
    formFields: {
      NO_HISTORY_FOR_THIS_PERIOD = false,
      noAdditionalEmploymentHistoryItems,
      EMPLOYMENT_HISTORY_ITEMS = [EMPTY_EMPLOYMENT],
    },
    formMessages: {
      clientCustomizedTaskDescription,
      desiredEmploymentDateRangeStart,
      desiredEmploymentDateRangeEnd,
    },
    formParameters: {
      allowNoHistorySelection,
      formId,
      hasDotVerification,
      hasFmcsaVerification,
      hasFaaVerification,
      maximumNumberOfDaysAllowedWithoutGap,
      minimumNumberOfYearsOfEmploymentHistory,
      regulationTypes,
      employerTypes,
      minimumNumberOfEmployments,
      maximumNumberOfEmployments,
    },
  },
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: EmplHistInterviewFormProps): JSX.Element {
  function mapFormToApi(fields: InterviewSubmitProps) {
    const {
      NO_HISTORY_FOR_THIS_PERIOD,
      noAdditionalEmploymentHistoryItems,
      EMPLOYMENT_HISTORY_ITEMS,
      doSave,
    } = boolStrFieldsToBools(fields, [
      "IS_THIS_YOUR_PRESENT_EMPLOYER",
      "ARE_YOU_CURRENTLY_UNEMPLOYED",
      "MAY_WE_CONTACT_THIS_EMPLOYER",
      "IS_DOT_REGULATED",
    ]);

    const hasRegulationTypes = hasFmcsaVerification || hasFaaVerification;

    onSubmit({
      //nest the fields within the formId as required by storeInterviewData
      [formId]: {
        // Send all fields when saving, but filter to appropriate fields if submitting (doSave = false)
        ...(doSave
          ? {
              NO_HISTORY_FOR_THIS_PERIOD,
              noAdditionalEmploymentHistoryItems,
              EMPLOYMENT_HISTORY_ITEMS: EMPLOYMENT_HISTORY_ITEMS.map(
                (entry: any) => ({
                  ...entry,
                  START_DATE: entry.startDate,
                  END_DATE: entry.endDate,
                }),
              ),
              doSave,
            }
          : {
              NO_HISTORY_FOR_THIS_PERIOD,
              ...(!NO_HISTORY_FOR_THIS_PERIOD && {
                noAdditionalEmploymentHistoryItems,
                EMPLOYMENT_HISTORY_ITEMS: EMPLOYMENT_HISTORY_ITEMS.map(
                  (entry: any) => ({
                    ...(!!entry.SERVICE_ID && { SERVICE_ID: entry.SERVICE_ID }),
                    ENTRY_TYPE: entry.ENTRY_TYPE,
                    START_DATE: entry.startDate,
                    ...(((entry.ENTRY_TYPE === "EMP" &&
                      !entry.IS_THIS_YOUR_PRESENT_EMPLOYER) ||
                      (entry.ENTRY_TYPE === "GAP" &&
                        !entry.ARE_YOU_CURRENTLY_UNEMPLOYED)) && {
                      END_DATE: entry.endDate,
                    }),
                    ...(entry.ENTRY_TYPE === "GAP"
                      ? {
                          ...(!!entry.ARE_YOU_CURRENTLY_UNEMPLOYED && {
                            ARE_YOU_CURRENTLY_UNEMPLOYED:
                              entry.ARE_YOU_CURRENTLY_UNEMPLOYED,
                          }),
                          EXPLANATION: entry.EXPLANATION,
                        }
                      : {
                          ...(!!entry.IS_THIS_YOUR_PRESENT_EMPLOYER && {
                            IS_THIS_YOUR_PRESENT_EMPLOYER:
                              entry.IS_THIS_YOUR_PRESENT_EMPLOYER,
                          }),
                          ...(!!entry.MAY_WE_CONTACT_THIS_EMPLOYER && {
                            MAY_WE_CONTACT_THIS_EMPLOYER:
                              entry.MAY_WE_CONTACT_THIS_EMPLOYER,
                          }),
                          ...(hasDotVerification &&
                            !!entry.IS_DOT_REGULATED && {
                              IS_DOT_REGULATED: entry.IS_DOT_REGULATED,
                              ...(hasRegulationTypes && {
                                REGULATED_TYPE: entry.REGULATED_TYPE,
                              }),
                              ...(!!entry.EMPLOYER_TYPE && {
                                EMPLOYER_TYPE: entry.EMPLOYER_TYPE,
                              }),
                            }),
                          CONTRACTOR: entry.CONTRACTOR,
                          COMPANY_NAME: entry.COMPANY_NAME,
                          COMPANY_PHONE: entry.COMPANY_PHONE,
                          COUNTRY: entry.COUNTRY,
                          STREET_ADDRESS: entry.STREET_ADDRESS,
                          CITY: entry.CITY,
                          STATE: entry.STATE,
                          TITLEPOSITION_WHILE_EMPLOYED:
                            entry.TITLEPOSITION_WHILE_EMPLOYED,
                          NAME_WHILE_EMPLOYED: entry.NAME_WHILE_EMPLOYED,
                          EMPLOYEE_NUMBER: entry.EMPLOYEE_NUMBER,
                        }),
                  }),
                ),
              }),
            }),
      },
      doSave,
    });
  }

  return (
    <>
      <Heading p={1} as="h1">
        {t`Employment History`}
      </Heading>
      <Text pb="4">{clientCustomizedTaskDescription}</Text>
      <EmploymentHistoryForm
        reqYearsOfHistory={minimumNumberOfYearsOfEmploymentHistory}
        maxGapDays={maximumNumberOfDaysAllowedWithoutGap}
        allowNoHistorySelection={allowNoHistorySelection}
        showDotQuestion={hasDotVerification}
        regulationOptions={regulationTypes}
        employerOptions={employerTypes}
        minimumEmployments={minimumNumberOfEmployments}
        maximumEmployments={maximumNumberOfEmployments}
        desiredStartMonth={desiredEmploymentDateRangeStart}
        desiredEndMonth={desiredEmploymentDateRangeEnd}
        defaultValues={{
          NO_HISTORY_FOR_THIS_PERIOD,
          noAdditionalEmploymentHistoryItems,
          EMPLOYMENT_HISTORY_ITEMS,
        }}
        isSavable={isSavable}
        onSubmit={mapFormToApi}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
