import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import DriverLicenseForm from "./DriverLicenseForm";

export type DriverLicenseApiForm = {
  formFields: DriverLicenseApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface DriverLicenseFormFields {
  driversLicenseType?: string;
  driversLicenseNumber?: string;
  driversLicenseState?: string;
  dateOfBirthDate?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  doSave?: boolean;
}

interface DriverLicenseApiFormFields extends InterviewFormFields {
  driversLicenseType?: string;
  driversLicenseNumber?: string;
  driversLicenseState?: string;
  dateOfBirth?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

interface DriverLicenseTaskProps extends TaskComponentProps {
  form: DriverLicenseApiForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function DriverLicenseTask({
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: DriverLicenseTaskProps): JSX.Element {
  const formFields = {
    driversLicenseType: form.formFields?.driversLicenseType,
    driversLicenseNumber: form.formFields?.driversLicenseNumber,
    driversLicenseState: form.formFields?.driversLicenseState,
    dateOfBirth: form.formFields?.dateOfBirth,
    firstName: form.formFields?.firstName,
    middleName: form.formFields?.middleName,
    lastName: form.formFields?.lastName,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return (
    <DriverLicenseForm
      defaultValues={{
        [formParameters.formId]: {
          driversLicenseType: formFields.driversLicenseType,
          driversLicenseNumber: formFields.driversLicenseNumber,
          driversLicenseState: formFields.driversLicenseState,
          dateOfBirthDate: formFields.dateOfBirth,
          firstName: formFields.firstName,
          middleName: formFields.middleName,
          lastName: formFields.lastName,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
