import { Image } from "@chakra-ui/react";

type IconProps = {
  color?: string;
  acquisitionSource?: string;
};

export const HeaderLogo = ({
  color = "orange",
  acquisitionSource = "OrangeTree",
}: IconProps): JSX.Element => {
  let logoColor: string;
  let logoName: string;

  switch (color.toLowerCase()) {
    case "black":
      logoColor = color;
      break;
    case "white":
      logoColor = color;
      break;
    default:
      logoColor = "orange";
      break;
  }

  switch (acquisitionSource) {
    default:
      logoName = "otes-logo-header";
      break;
  }

  const iconSrc = `images/logos/${logoName}-${logoColor}.svg`;
  return <Image ml={-2} py={1} h={16} w={16} src={iconSrc} alt="Logo" />;
};
