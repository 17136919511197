import { Box, Center, VStack } from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { getInterviewDoc } from "tasks/tasksApi";
import useOtesModal from "error/useOtesModal";
import OkButton from "components/common/basic/OkButton";
import {
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";

interface DrugTestFormMessages extends InterviewFormMessages {
  instructionHtml?: string;
}

interface DrugTestFormParameters extends InterviewFormParameters {
  authFormUid?: string;
}

interface DrugTestFormProps extends TaskComponentProps {
  formMessages: DrugTestFormMessages;
  formParameters: DrugTestFormParameters;
}

export default function DrugTestForm({
  formMessages,
  formParameters,
  goToStatusCheck,
}: DrugTestFormProps): JSX.Element {
  const { triggerOtesModal } = useOtesModal();

  const instructionHtml = formMessages.instructionHtml;

  const isQuestDiagnostics = formParameters.authFormUid;

  return (
    <>
      <VStack w="100%" p={1}>
        <Box>
          {instructionHtml && parse(instructionHtml)}
          {isQuestDiagnostics && (
            <Center>
              <FileDownloadButton
                getFile={(token: string) =>
                  getInterviewDoc(token, {
                    authFormUid: formParameters.authFormUid,
                  })
                }
                label={t`Download Quest Authorization Form`}
                onError={() => {
                  triggerOtesModal({
                    bodyText: t`There was an error downloading the file, please try again.`,
                  });
                }}
              />
            </Center>
          )}
        </Box>
        <OkButton onClick={goToStatusCheck} />
      </VStack>
    </>
  );
}
