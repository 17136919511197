import {
  InterviewFormParameters,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import ReportForm from "./ReportForm";

export type ReportApiForm = {
  formMessages: InterviewFormMessages;
  formParameters: ReportApiFormParameters;
};

interface ReportApiFormParameters extends InterviewFormParameters {
  documentUid?: string;
}

interface ReportTaskProps extends TaskComponentProps {
  form: ReportApiForm;
}

export default function ReportTask({
  form,
  goToStatusCheck,
}: ReportTaskProps): JSX.Element {
  const formMessages = {
    formName: form.formMessages?.formName || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
    documentUid: form.formParameters?.documentUid || "",
  };

  return (
    <ReportForm
      formMessages={formMessages}
      formParameters={formParameters}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
