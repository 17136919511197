import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { EmploymentDatesFormFields } from "./EmploymentDatesTask";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import {
  DatePickerInput,
  validateFormDate,
} from "components/common/form/DatePickerInput";

interface EmploymentDatesFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: EmploymentDatesFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
  displayEndDateField?: boolean;
  displayContactEmployerField?: boolean;
}

export interface EmploymentDatesSubFormFields extends InterviewFormFields {
  startDate?: string;
  endDate?: string;
}

export type EmploymentDatesApiSubForm = InterviewApiSubForm<
  EmploymentDatesSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <Box>
    <FormProvider {...methods}>
      <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  </Box>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
  displayEndDateField,
  displayContactEmployerField,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  const methods = useFormContext();
  const [startDate, endDate] = methods.getValues([
    `${formId}.startDate`,
    `${formId}.endDate`,
  ]);

  const validateEndDate = () => {
    const presentEmployer = methods.getValues(
      `${formId}.isThisYourPresentEmployer`,
    );
    if (presentEmployer && presentEmployer === "true") {
      return true;
    }

    const endDatePath = `${formId}.endDate`;
    const endDate = methods.getValues(endDatePath);

    return validateFormDate(endDate);
  };

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <FormRadioGroup
        fieldId={`${formId}.isThisYourPresentEmployer`}
        groupLabel={t`Is This Your Present Employer?`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        control={methods.control}
      />
      <DatePickerInput
        label={t`Start Date`}
        fieldIds={{
          month: `${formId}.startMonth`,
          day: `${formId}.startDay`,
          year: `${formId}.startYear`,
          date: `${formId}.startDate`,
        }}
      />
      {displayEndDateField && (
        <DatePickerInput
          label={t`End Date`}
          fieldIds={{
            month: `${formId}.endMonth`,
            day: `${formId}.endDay`,
            year: `${formId}.endYear`,
            date: `${formId}.endDate`,
          }}
          validateDate={validateEndDate}
        />
      )}
      {displayContactEmployerField && (
        <FormRadioGroup
          fieldId={`${formId}.mayWeContactThisEmployer`}
          groupLabel={t`May We Contact This Employer?`}
          radios={[
            [t`Yes`, "true"],
            [t`No`, "false"],
          ]}
          control={methods.control}
        />
      )}
    </FormControl>
  );
};

export default function EmploymentDatesForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: EmploymentDatesFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<EmploymentDatesFormFields>({ defaultValues });

  const { formName, formInstructions } = formMessages;

  const { formId } = formParameters;

  const isThisYourPresentEmployer = methods.watch(
    `${formId}.isThisYourPresentEmployer`,
  );

  const displayEndDateField = isThisYourPresentEmployer?.toString() !== "true";

  const displayContactEmployerField =
    isThisYourPresentEmployer?.toString() === "true";

  const formContentProps = {
    formId,
    formName,
    formInstructions,
    displayEndDateField,
    displayContactEmployerField,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
