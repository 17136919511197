import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  EMPTY_INSTITUTION,
  InstitutionFormFields,
} from "components/tasks/education-history/InstitutionForm";
import {
  InterviewApiSubForm,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { flattenExistingValues } from "components/tasks/taskHelpers";
import EducationHistoryForm from "components/tasks/education-history/EducationHistoryForm";
import { Heading, Text } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";

export interface EduHistInterviewFormFields extends InterviewSubmitProps {
  /**
   * Note - the History Interviews deviate from the Interview Singleton/Sub form paradigm since they
   * re-use the task definition layout of the History Tasks. History Interview forms are Singleton forms
   * since there will only ever be one form on the History Interview task definitions. For this reason
   * they also will not conform to the "formId.fieldName" field naming convention outlined in
   * InterviewSubmitProps
   *
   * Additionally, as of April 2022 the History Interviews still utilize an object with a
   * property of "existingValue" to represent saved/stored values with which to populate
   * the form. As this is set to be removed with later improvements, that api data layout
   * is not captured by the History Interview api form Types/Interfaces.
   */
  noHistory: boolean;
  institutions: InstitutionFormFields[];
}

export interface EduHistSubFormMessages extends InterviewFormMessages {
  clientCustomizedTaskDescription: string;
}

export interface EduHistSubFormParameters extends InterviewFormParameters {
  educationTypes: string[];
  educationTypeToDegreeTypeMappings: { [eduType: string]: string[] };
  allowNoHistorySelection: boolean;
}

export type EduHistApiSubForm = InterviewApiSubForm<
  EduHistInterviewFormFields,
  EduHistSubFormMessages,
  EduHistSubFormParameters
>;

export interface EduHistInterviewFormProps {
  form: EduHistApiSubForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
  goToStatusCheck?: () => void;
}

export function mapEduHistApiToUi(
  apiData: any, //"any" used here until API removes "existingValue" object
): EduHistApiSubForm {
  const flattened = flattenExistingValues(apiData);

  const mapFlatInstToFormInst = (flatInst: any) => ({
    educationType: flatInst.EDUCATION_TYPE,
    degreeType: flatInst.DEGREE_TYPE,
    graduated: flatInst.DID_YOU_GRADUATE,
    gradDate: flatInst.GRADUATION_DATE,
    institution: flatInst.INSTITUTION,
    country: flatInst.COUNTRY,
    street: flatInst.STREET_ADDRESS,
    city: flatInst.CITY,
    state: flatInst.STATE,
    nameWhileAttending: flatInst.NAME_WHILE_ATTENDING,
    fieldOfStudy: flatInst.FIELD_OF_STUDY,
    schoolPhone: flatInst.SCHOOL_PHONE_NUMBER,
    startDate: flatInst.START_DATE,
    endDate: flatInst.END_DATE,
    gpa: flatInst.GPA,
    seatNumber: flatInst.SEAT_NUMBER,
    apiId: flatInst.SERVICE_ID,
  });

  return {
    formFields: {
      noHistory: flattened.formFields.NO_EDUCATION_HISTORY === "true",
      institutions: flattened.formFields.EDUCATION_HISTORY_ITEMS?.map(
        (flatInst: any) => mapFlatInstToFormInst(flatInst),
      ) || [EMPTY_INSTITUTION],
    },
    formMessages: flattened.formMessages,
    formParameters: flattened.formParameters,
  };
}

export default function EduHistInterviewFormAdapter({
  form: {
    formFields: { noHistory = false, institutions = [EMPTY_INSTITUTION] },
    formMessages: { clientCustomizedTaskDescription },
    formParameters: {
      formId,
      educationTypes,
      educationTypeToDegreeTypeMappings,
      allowNoHistorySelection = false,
    },
  },
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: EduHistInterviewFormProps): JSX.Element {
  const mapFormToApi = ({
    noHistory,
    institutions,
    doSave,
  }: EduHistInterviewFormFields) =>
    onSubmit({
      //nest the fields within the formId as required by storeInterviewData
      [formId]: {
        ...(allowNoHistorySelection && { NO_EDUCATION_HISTORY: noHistory }),
        EDUCATION_HISTORY_ITEMS: institutions.map((inst) => ({
          EDUCATION_TYPE: inst.educationType,
          DEGREE_TYPE: inst.degreeType,
          DID_YOU_GRADUATE: inst.graduated,
          ...(inst.graduated === "true" && {
            GRADUATION_DATE: inst.gradDate,
          }),
          INSTITUTION: inst.institution,
          COUNTRY: inst.country,
          STREET_ADDRESS: inst.street,
          CITY: inst.city,
          STATE: inst.state,
          NAME_WHILE_ATTENDING: inst.nameWhileAttending,
          FIELD_OF_STUDY: inst.fieldOfStudy,
          SCHOOL_PHONE_NUMBER: inst.schoolPhone,
          START_DATE: inst.startDate,
          END_DATE: inst.endDate,
          GPA: inst.gpa,
          SEAT_NUMBER: inst.seatNumber,
          ...(inst.apiId && { SERVICE_ID: inst.apiId }),
        })),
      },
      doSave,
    });

  return (
    <>
      <Heading p={1} as="h1">
        <Trans>Education History</Trans>
      </Heading>
      <Text pb="4">{clientCustomizedTaskDescription}</Text>
      <EducationHistoryForm
        allowNoHistorySelection={allowNoHistorySelection}
        eduTypes={educationTypes}
        eduDegrees={educationTypeToDegreeTypeMappings}
        defaultValues={{ noHistory, institutions }}
        isSavable={isSavable}
        onSubmit={mapFormToApi}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
