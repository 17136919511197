import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
  watchAndEvaluateBoolean,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { ConfirmName, ConfirmNameFormFields } from "./ConfirmNameTask";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import hash from "object-hash";

interface ConfirmNameFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: ConfirmNameFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  validateUniqueConfirmNames: () => boolean;
  setHasUniqueConfirmNames: (hasUniqueConfirmNames: boolean) => void;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
  hasUniqueConfirmNames?: boolean;
}

// FIXME (JDL:2022-06-28) These fields aren't right.
export interface ConfirmNameSubFormFields extends InterviewFormFields {
  phone?: string;
  phoneUnavailable?: boolean;
  email?: string;
  emailUnavailable?: boolean;
}

export type ConfirmNameApiSubForm = InterviewApiSubForm<
  ConfirmNameSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  validateUniqueConfirmNames,
  setHasUniqueConfirmNames,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form
      id={formId}
      onSubmit={
        onSubmit &&
        methods.handleSubmit(() =>
          validateUniqueConfirmNames()
            ? onSubmit({ ...methods.getValues() })
            : setHasUniqueConfirmNames(false),
        )
      }>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
  hasUniqueConfirmNames,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  const { queenPink, cinnabar, transparentCinnabar } =
    theme.colors.errorTemplate;
  const methods = useFormContext();

  const { fields: confirmNames } = useFieldArray({
    control: methods.control,
    name: `${formId}.confirmNames`,
  });

  const {
    formState: { isSubmitting, isValidating },
  } = methods;

  const uniqueConfirmNamesError = "Confirm names are not unique.";

  return (
    <FormControl
      as="fieldset"
      isInvalid={
        hasUniqueConfirmNames === undefined ? false : !hasUniqueConfirmNames
      }>
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      {hasUniqueConfirmNames === undefined
        ? false
        : !hasUniqueConfirmNames && (
            <Box>
              <Heading
                as="h3"
                size="md"
                fontWeight="medium"
                color={cinnabar}
                backgroundColor={transparentCinnabar}
                padding={"10px 15px"}
                mb={4}>
                Errors
              </Heading>
              <FormErrorMessage padding={"10px 15px"}>
                {uniqueConfirmNamesError}
              </FormErrorMessage>
            </Box>
          )}
      {confirmNames.map((confirmName, index) => {
        const isThisCorrectFieldId = `${formId}.confirmNames.${index}.isThisCorrect`;
        const isThisCorrect = watchAndEvaluateBoolean(
          methods,
          isThisCorrectFieldId,
        );

        useEffect(() => {
          const firstName = methods.getValues(
            `${formId}.confirmNames.${index}.firstName`,
          );
          const lastName = methods.getValues(
            `${formId}.confirmNames.${index}.lastName`,
          );
          if (isThisCorrect) {
            methods.clearErrors(`${formId}.confirmNames.${index}.firstName`);
            methods.resetField(`${formId}.confirmNames.${index}.firstName`);
            methods.resetField(`${formId}.confirmNames.${index}.middleName`);
            methods.clearErrors(`${formId}.confirmNames.${index}.lastName`);
            methods.resetField(`${formId}.confirmNames.${index}.lastName`);
          } else {
            if (isSubmitting || isValidating) {
              if (!firstName) {
                methods.setError(`${formId}.confirmNames.${index}.firstName`, {
                  type: "required",
                  message: t`This is required`,
                });
              }
              if (!lastName) {
                methods.setError(`${formId}.confirmNames.${index}.lastName`, {
                  type: "required",
                  message: t`This is required`,
                });
              }
            }
          }
        }, [isThisCorrect, isSubmitting, isValidating]);

        return (
          <Fragment key={confirmName.id}>
            <Box className="arrayItemForm" bgColor="gray.100">
              <FormControl as="fieldset">
                <FormInput
                  label={t`First Name`}
                  fieldId={`${formId}.confirmNames.${index}.firstName`}
                  disabled={isThisCorrect}
                  maxLength={64}
                />
                <FormInput
                  required={false}
                  label={t`Middle Name`}
                  fieldId={`${formId}.confirmNames.${index}.middleName`}
                  disabled={isThisCorrect}
                  maxLength={64}
                />
                <FormInput
                  label={t`Last Name`}
                  fieldId={`${formId}.confirmNames.${index}.lastName`}
                  disabled={isThisCorrect}
                  maxLength={64}
                />
                <FormRadioGroup
                  fieldId={isThisCorrectFieldId}
                  groupLabel={t`Is this information correct?`}
                  radios={[
                    [t`Yes`, "true"],
                    [t`No`, "false"],
                  ]}
                  control={methods.control}
                />
              </FormControl>
            </Box>
          </Fragment>
        );
      })}
    </FormControl>
  );
};

export default function ConfirmNameForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: ConfirmNameFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<ConfirmNameFormFields>({ defaultValues });

  const { formName, formInstructions } = formMessages;

  const { formId } = formParameters;

  const {
    formState: { isSubmitting, isValidating },
  } = methods;

  const [hasUniqueConfirmNames, setHasUniqueConfirmNames] = useState<
    boolean | undefined
  >(undefined);

  const validateUniqueConfirmNames = () => {
    const confirmNames: ConfirmName[] = methods.getValues(formId).confirmNames;
    const confirmNameHashes =
      confirmNames?.map((confirmName) =>
        hash(confirmName, {
          excludeKeys: (key) => key === "akaId" || key === "isThisCorrect",
        }),
      ) || [];
    const hasUniqueConfirmNames =
      [...new Set(confirmNameHashes)].length === confirmNames.length;
    return hasUniqueConfirmNames;
  };

  useEffect(() => {
    if (isSubmitting || isValidating) {
      setHasUniqueConfirmNames(validateUniqueConfirmNames());
    }
  }, [isSubmitting, isValidating]);

  const formContentProps = {
    formId,
    formName,
    formInstructions,
    hasUniqueConfirmNames,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
    validateUniqueConfirmNames,
    setHasUniqueConfirmNames,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
