import { memo } from "react";

interface TableRowInputReadOnlyProp {
  label: string;
  value: string | string[];
}

const TableRowInputReadOnly = ({ label, value }: TableRowInputReadOnlyProp) => (
  <tr>
    <td>{label}</td>
    <td>
      <input
        onFocus={(event: any) => event.target.select()}
        style={{
          border: "solid black 1px",
          width: "100%",
        }}
        type="text"
        value={value}
        readOnly
      />
    </td>
  </tr>
);

export default memo(TableRowInputReadOnly);
