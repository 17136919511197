import { Image } from "@chakra-ui/react";

type LogoProps = {
  size?: "small" | "medium" | "large" | "x-large";
  color?: string;
  acquisitionSource?: string;
  useCCLogo?: boolean;
};

export const Logo = ({
  size = "medium",
  color = "orange",
  acquisitionSource = "OrangeTree",
  useCCLogo = false,
}: LogoProps): JSX.Element => {
  let height: number;
  let logoColor: string;
  let logoName: string;

  switch (size) {
    case "small":
      height = 8;
      break;
    case "medium":
      height = 12;
      break;
    case "large":
      height = 24;
      break;
    case "x-large":
      height = 132;
      break;
  }

  switch (color.toLowerCase()) {
    case "black":
      logoColor = color;
      break;
    case "white":
      logoColor = color;
      break;
    default:
      logoColor = "orange";
      break;
  }

  switch (acquisitionSource) {
    default:
      logoName = "otes-logo";
      break;
  }

  if (useCCLogo) {
    logoName = "cc-logo";
  }

  const logoSrc = `images/logos/${logoName}-${logoColor}.svg`;
  return <Image h={height} src={logoSrc} alt="Logo" />;
};
