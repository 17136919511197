import { WidgetProps } from "@rjsf/utils";
import useOtesModal from "error/useOtesModal";
import { FormLabel } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { generateSpecialForm } from "tasks/tasksApi";
import { useFormContext } from "react-hook-form";

export default function SpecialFormPreviewDownloadButton(props: WidgetProps) {
  const { triggerOtesModal } = useOtesModal();
  const { getValues } = useFormContext();
  const specialFormId = props?.uiSchema?.additionalProperties.specialFormId;

  function getSpecialFormPreview(token: string) {
    const { formData } = getValues();
    return generateSpecialForm(token, specialFormId, formData);
  }

  return (
    <>
      <FormLabel fontSize="xl" fontWeight="bold">
        <Trans>Open File</Trans>
      </FormLabel>
      <FileDownloadButton
        label={"Download / Preview  Form"}
        getFile={(token: string) => getSpecialFormPreview(token)}
        onError={() => {
          triggerOtesModal({
            bodyText: t`There was an error downloading the file, please try again.`,
          });
        }}
      />
      <br />
    </>
  );
}
