import * as H from "history";

export enum AppPath {
  IDENTIFICATION = "/identification",
  LOGIN = "/login",
  NOTIFICATIONS = "/notifications",
  QUICKGUIDE = "/quickguide",
  ROOT = "/",
  START = "/start",
  STATUS = "/status",
  TASK = "/task",
  TERMS_CONDITIONS = "/termsconditions",
  TERMS_OF_SERVICE = "/tos",
  WELCOME = "/welcome",
}

export const AppPathHelper: {
  KNOWN_PATHS: string[];
  TOKEN_PATTERN: string;
  hasTokenPattern: (location: H.Location) => boolean;
} = {
  KNOWN_PATHS: Object.values(AppPath),
  TOKEN_PATTERN: "?t=",
  hasTokenPattern: (location: H.Location) =>
    location.search?.includes(AppPathHelper.TOKEN_PATTERN) ||
    location.hash?.includes(AppPathHelper.TOKEN_PATTERN),
};
