import FormInput from "components/common/form/FormInput";
import FormSelect from "components/common/form/FormSelect";
import { Box, Heading, useTheme } from "@chakra-ui/react";
import { RenderWhenUncheckedWrapper } from "components/common/form/RenderWhenUncheckedWrapper";
import { t } from "@lingui/macro";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import FormTextarea from "components/common/form/FormTextarea";

export type KeyValueSubFormAnswerFieldType =
  | "textField"
  | "textArea"
  | "dropDown";

export interface KeyValueSubFormFields extends InterviewFormFields {
  answer: string;
  unavailable?: boolean;
}

export interface KeyValueSubFormMessages extends InterviewFormMessages {
  question: string;
}

export interface KeyValueSubFormParameters extends InterviewFormParameters {
  maximumAnswerLength?: number;
  answerFieldType: KeyValueSubFormAnswerFieldType;
  answerFieldOptions?: string[];
}

export type KeyValueApiSubForm = InterviewApiSubForm<
  KeyValueSubFormFields,
  KeyValueSubFormMessages,
  KeyValueSubFormParameters
>;

export default function KeyValueSubForm({
  formMessages: { formName, question },
  formParameters: {
    formId,
    maximumAnswerLength,
    answerFieldType,
    answerFieldOptions = [],
    allowUnavailableSelection,
  },
}: KeyValueApiSubForm): JSX.Element {
  const getFieldComponent = () => {
    switch (answerFieldType) {
      case "textField":
        return (
          <FormInput
            fieldId={`${formId}.answer`}
            label={question}
            maxLength={maximumAnswerLength}
          />
        );
      case "textArea":
        return (
          <FormTextarea
            fieldId={`${formId}.answer`}
            width={"100%"}
            label={question}
            maxLength={maximumAnswerLength}
          />
        );
      case "dropDown":
        return (
          <FormSelect
            fieldId={`${formId}.answer`}
            label={question}
            options={answerFieldOptions}
          />
        );
    }
  };

  const theme = useTheme();
  const { orange } = theme.colors.brand;

  return (
    <>
      <Heading color={orange} pt={2} as="h2" size="md">
        {formName}
      </Heading>
      <Box width="100%">
        {allowUnavailableSelection ? (
          <RenderWhenUncheckedWrapper
            fieldId={`${formId}.unavailable`}
            label={t`Unavailable`}>
            {getFieldComponent()}
          </RenderWhenUncheckedWrapper>
        ) : (
          getFieldComponent()
        )}
      </Box>
    </>
  );
}
