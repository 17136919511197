import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import ConfirmNameForm from "./ConfirmNameForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type ConfirmNameApiForm = {
  formFields: ConfirmNameApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface ConfirmNameFormFields {
  confirmNames?: Array<ConfirmName>;
  doSave?: boolean;
}

export interface ConfirmName {
  akaId: number;
  isThisCorrect?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

interface ConfirmNameApiFormFields extends InterviewFormFields {
  confirmNames?: Array<ConfirmName>;
}

interface ConfirmNameTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: ConfirmNameApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function ConfirmNameTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: ConfirmNameTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    confirmNames: form.formFields?.confirmNames || [],
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <ConfirmNameForm
      defaultValues={{
        [formParameters.formId]: {
          confirmNames: formFields.confirmNames.map((confirmName) => ({
            ...confirmName,
            isThisCorrect: confirmName.isThisCorrect?.toString(),
          })),
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
