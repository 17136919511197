import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import ContactInformationForm from "./ContactInformationForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type ContactInformationApiForm = {
  formFields: ContactInformationApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface ContactInformationFormFields {
  phone?: string;
  phoneUnavailable?: boolean;
  email?: string;
  emailUnavailable?: boolean;
  doSave?: boolean;
}

interface ContactInformationApiFormFields extends InterviewFormFields {
  phone?: string;
  phoneUnavailable?: boolean;
  email?: string;
  emailUnavailable?: boolean;
}

interface ContactInformationTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: ContactInformationApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function ContactInformationTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: ContactInformationTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    phone: form.formFields?.phone || "",
    phoneUnavailable: form.formFields?.phoneUnavailable,
    email: form.formFields?.email || "",
    emailUnavailable: form.formFields?.emailUnavailable,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions: form.formMessages?.formInstructions || "",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <ContactInformationForm
      defaultValues={{
        [formParameters.formId]: {
          phone: formFields.phone,
          phoneUnavailable: formFields.phoneUnavailable,
          email: formFields.email,
          emailUnavailable: formFields.emailUnavailable,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
