import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewApiSubForm,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { Heading, Text } from "@chakra-ui/react";
import { flattenExistingValues } from "components/tasks/taskHelpers";
import GaStatewideCriminalForm from "components/tasks/ga-statewide/GaStatewideCriminalForm";

export interface GaStatewideCriminalInterviewFormFields
  extends InterviewSubmitProps {
  sex: string;
  race: string;
  employmentGeneral?: boolean;
  mentallyDisabled?: boolean;
  elderly?: boolean;
  children?: boolean;
  employmentPurpose: string;
  document: { signature: string };
}

export type GaStatewideCriminalSubForm = InterviewApiSubForm<
  GaStatewideCriminalInterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

export interface GaStatewideCriminalInterviewFormProps {
  form: GaStatewideCriminalSubForm;
  onSubmit: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
  goToStatusCheck?: () => void;
}

export function mapGaStatewideCriminalApiToUi(
  apiData: any,
): GaStatewideCriminalSubForm {
  const flattened = flattenExistingValues(apiData);

  return {
    formFields: {
      sex: flattened.formFields?.sex ?? "",
      race: flattened.formFields?.race ?? "",
      employmentGeneral: flattened.formFields?.employmentGeneral ?? false,
      mentallyDisabled: flattened.formFields?.mentallyDisabled ?? false,
      elderly: flattened.formFields?.elderly ?? false,
      children: flattened.formFields?.children ?? false,
      employmentPurpose: "",
      document: { signature: flattened.formFields?.siblings ?? "" },
    },
    formMessages: flattened.formMessages,
    formParameters: flattened.formParameters,
  };
}

export default function GaStatewideCriminalFormAdapter({
  form: {
    formFields: {
      sex,
      race,
      employmentGeneral,
      mentallyDisabled,
      elderly,
      children,
      document,
    },
    formMessages: { formName, formInstructions },
    formParameters: { formId },
  },
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: GaStatewideCriminalInterviewFormProps): JSX.Element {
  const mapFormToApi = ({
    sex,
    race,
    employmentPurpose,
    document,
    doSave,
  }: GaStatewideCriminalInterviewFormFields) =>
    onSubmit({
      [formId]: {
        SEX: sex,
        RACE: race,
        EMPLOYMENT_GENERAL: employmentPurpose === "employmentGeneral",
        MENTALLY_DISABLED: employmentPurpose === " mentallyDisabled",
        ELDERLY: employmentPurpose === "elderly",
        CHILDREN: employmentPurpose === "children",
        DOCUMENT: { SIGNATURE: document.signature },
      },
      doSave,
    });

  const getEmploymentPurpose =
    (employmentGeneral && "employmentGeneral") ||
    (mentallyDisabled && "mentallyDisabled") ||
    (elderly && "elderly") ||
    (children && "children") ||
    "";

  return (
    <>
      <Heading p={1} as="h1">
        <Trans>{formName}</Trans>
      </Heading>
      <Text pb="4">{formInstructions}</Text>
      <GaStatewideCriminalForm
        defaultValues={{
          sex: sex,
          race: race,
          employmentPurpose: getEmploymentPurpose,
          document: document,
        }}
        isSavable={isSavable}
        onSubmit={mapFormToApi}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
