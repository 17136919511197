import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { get, useFormContext } from "react-hook-form";
import { t } from "@lingui/macro";

interface Option {
  label: string;
  value: string;
}

interface FormSelectProps {
  fieldId: string;
  label: string;
  required?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  helperText?: string;
  options: string[] | [string, string][] | Option[];
  defaultValue?: string;
  onChange?: (e: any) => void;
  hidden?: boolean;
  selectType?: "string" | "keyPair" | "Option";
}

export default function FormSelect({
  fieldId,
  label,
  required = true,
  placeHolder = t`Select a value`,
  disabled,
  helperText,
  options,
  defaultValue,
  onChange,
  hidden = false,
  selectType = "string",
}: FormSelectProps): JSX.Element {
  const { register, formState } = useFormContext();

  const inputIsAccessible = !hidden && !disabled;
  const validateRequired = inputIsAccessible && required;

  return (
    <FormControl
      hidden={hidden}
      isInvalid={get(formState.errors, fieldId)}
      py="0.5rem">
      <FormLabel
        htmlFor={fieldId}
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <Select
        placeholder={placeHolder}
        id={fieldId}
        defaultValue={defaultValue}
        disabled={disabled}
        borderColor={"gray.600"}
        {...register(fieldId, {
          required: { value: validateRequired, message: t`This is required` },
          onChange: (event) => {
            !!onChange && onChange(event);
          },
        })}>
        {selectType === "string" &&
          options.map((value, index) => {
            return (
              <option key={index} value={value as string}>
                {value}
              </option>
            );
          })}
        {selectType === "keyPair" &&
          Object.entries(options).map(([key, option]) => {
            return (
              <option key={key} value={option[0]}>
                {option[1]}
              </option>
            );
          })}
        {selectType === "Option" &&
          options.map((value, index) => {
            return (
              <option key={index} value={(value as Option).value}>
                {(value as Option).label}
              </option>
            );
          })}
      </Select>

      <FormErrorMessage>
        {get(formState.errors, `${fieldId}.message`)}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
