import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { GraduationStatusFormFields } from "./GraduationStatusTask";
import { t } from "@lingui/macro";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import {
  DatePickerInput,
  validateFormDate,
} from "components/common/form/DatePickerInput";

interface GraduationStatusFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: GraduationStatusFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
  displayGraduationDateField?: boolean;
}

export interface GraduationStatusSubFormFields extends InterviewFormFields {
  didYouGraduate?: boolean;
  graduationDate?: string;
}

export type GraduationStatusApiSubForm = InterviewApiSubForm<
  GraduationStatusSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <Box>
    <FormProvider {...methods}>
      <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  </Box>
);

const FormContent = ({
  formName,
  formId,
  displayGraduationDateField,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  const methods = useFormContext();

  const validateGradDate = (gradutionDateString: string) => {
    const graduated = methods.getValues(`${formId}.didYouGraduate`);
    if (!graduated || graduated === "false") {
      return true;
    }

    return validateFormDate(gradutionDateString);
  };

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <FormRadioGroup
        fieldId={`${formId}.didYouGraduate`}
        groupLabel={t`Did You Graduate?`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        control={methods.control}
      />
      {displayGraduationDateField && (
        <DatePickerInput
          label={t`Graduation Date`}
          fieldIds={{
            month: `${formId}.graduationDateMonth`,
            day: `${formId}.graduationDateDay`,
            year: `${formId}.graduationDateYear`,
            date: `${formId}.graduationDateDate`,
          }}
          validateDate={validateGradDate}
        />
      )}
    </FormControl>
  );
};

export default function GraduationStatusForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: GraduationStatusFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<GraduationStatusFormFields>({ defaultValues });

  const { formName } = formMessages;

  const { formId } = formParameters;

  const didYouGraduate = methods.watch(`${formId}.didYouGraduate`);

  const displayGraduationDateField =
    didYouGraduate !== undefined && didYouGraduate.toString() === "true";

  const formContentProps = {
    formId,
    formName,
    defaultValues,
    displayGraduationDateField,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
