import taskHeadings from "tasks/taskHeadings";
import { Heading } from "@chakra-ui/react";
import { ApplicantEntryTaskId } from "tasks/tasksApi";
import { t } from "@lingui/macro";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "components/common/form/FormInput";
import TaskButtons from "components/common/basic/TaskButtons";
import { useIdVerifyTaskMutation } from "tasks/taskQueries";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { GenericApiResponse } from "auth/authApi";
import { ResponseCodes } from "tasks/apiConstants";
import { Markup } from "interweave";
import useOtesModal from "error/useOtesModal";
import { AxiosError } from "axios";
import { handleOtesErrorFromAxiosError } from "../../auth/authHelpers";

export interface IdentityVerificationFormProps {
  taskId: ApplicantEntryTaskId;
  clientLabel?: string;
  startOrEndOfYear?: string;
  goToStatusCheck?: () => void;
  refetchTaskDef: () => void;
}

export interface IdentityVerificationFormFields {
  answer: string;
}

const ID_VERIFY_DESC_TEXT: Partial<{
  [taskId in ApplicantEntryTaskId]: (
    clientLabel?: string,
    startOrEndYear?: string,
  ) => string;
}> = {
  [ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY]: (
    clientLabel,
    startOrEndYear,
  ) =>
    startOrEndYear
      ? t`Our records indicate you provided address information to
        <b>${clientLabel}</b>. If you would like
        to load that information into this form, please enter the
        street/house number of one of the addresses you provided for the year 
        <b>${startOrEndYear}</b>.`
      : t`Our records indicate you provided address information to
        <b>${clientLabel}</b>. If you would like
        to load that information into this form, please enter the
        street/house number of one of the addresses you provided.`,

  [ApplicantEntryTaskId.APP_ENTRY_EMPLOYMENT]: (clientLabel, startOrEndYear) =>
    startOrEndYear
      ? t`Our records indicate you provided employment information to <b>${clientLabel}</b>. 
        If you would like to load that information into this form, please enter the 
        name of one of the employments you provided for the year <b>${startOrEndYear}</b>.`
      : t`Our records indicate you provided employment information to <b>${clientLabel}</b>. 
        If you would like to load that information into this form, please enter the 
        name of one of the employments you provided.`,

  [ApplicantEntryTaskId.APP_ENTRY_EDUCATION]: (clientLabel, startOrEndYear) =>
    startOrEndYear
      ? t`Our records indicate you provided employment information to ${clientLabel}. 
        If you would like to load that information into this form, please enter the 
        name of an education you provided for the year <b>${startOrEndYear}</b>.`
      : t`Our records indicate you provided employment information to <b>${clientLabel}</b>. 
        If you would like to load that information into this form, please enter the 
        name of an education you provided.`,
};

const ID_VERIFY_INPUT_LABELS: Partial<{
  [taskId in ApplicantEntryTaskId]: string;
}> = {
  [ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY]: t`House/Street Number`,
  [ApplicantEntryTaskId.APP_ENTRY_EDUCATION]: t`School Name`,
  [ApplicantEntryTaskId.APP_ENTRY_EMPLOYMENT]: t`Company Name`,
};

export default function IdentityVerificationForm({
  taskId,
  clientLabel,
  startOrEndOfYear,
  goToStatusCheck,
  refetchTaskDef,
}: IdentityVerificationFormProps): JSX.Element {
  const methods = useForm<IdentityVerificationFormFields>();
  const { triggerOtesModal } = useOtesModal();

  const descTextGenerator = ID_VERIFY_DESC_TEXT[taskId];
  const labelText = ID_VERIFY_INPUT_LABELS[taskId];
  const isNumeric = ApplicantEntryTaskId.APP_ENTRY_ADDRESS_HISTORY === taskId;

  const { mutate: mutateIdVerify, isLoading: isVerifying } =
    useIdVerifyTaskMutation({
      taskId,
      onSuccess: (response: GenericApiResponse) => {
        if (ResponseCodes.Success.Action.ANSWER_CORRECT === response.code) {
          triggerOtesModal({
            isError: false,
            onClose: refetchTaskDef,
            bodyText: t`Your answer was correct, taking you to the form.`,
          });
        } else {
          triggerOtesModal({
            bodyText: t`Unable to verify your personal information, please try again.`,
          });
        }
      },
      onError: (response: AxiosError) => {
        handleOtesErrorFromAxiosError(response);

        switch (response?.response?.data?.code) {
          case ResponseCodes.Error.Action.ANSWER_INCORRECT:
            triggerOtesModal({
              bodyText: t`Your answer was incorrect, you have one try left.`,
            });
            break;

          case ResponseCodes.Error.Action.ANSWER_INCORRECT_NO_RETRIES:
            triggerOtesModal({
              onClose: refetchTaskDef,
              bodyText: t`Your answer was incorrect, taking you to an empty form.`,
            });
            break;

          default:
            triggerOtesModal({
              bodyText: t`Unable to verify your personal information, please try again.`,
            });
            break;
        }
      },
    });

  const storeIdVerify: SubmitHandler<IdentityVerificationFormFields> = async ({
    answer,
  }: IdentityVerificationFormFields) => {
    mutateIdVerify({ [taskId]: { QUESTION_IDENTITY_VERIFY: answer } });
  };

  const identityVerificationForm = "identityVerificationForm";

  return (
    <>
      <SpinnerOverlay isShown={isVerifying} text={t`Verifying answer...`} />
      <Heading p={2} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <Markup
        content={
          descTextGenerator && descTextGenerator(clientLabel, startOrEndOfYear)
        }
      />
      <FormProvider {...methods}>
        <form
          id={identityVerificationForm}
          onSubmit={methods.handleSubmit(storeIdVerify)}>
          <FormInput
            fieldId="answer"
            label={labelText || "Value"}
            type={isNumeric ? "tel" : "text"}
            inputMode={isNumeric ? "numeric" : "text"}
          />
          <TaskButtons
            onBack={goToStatusCheck}
            form={identityVerificationForm}
          />
        </form>
      </FormProvider>
    </>
  );
}
