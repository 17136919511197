import axios from "axios";
import ApplicantTheme from "applicantTheme/ApplicantTheme";

const themeApiClient = axios.create({
  baseURL: "/api/rest/v1",
});

/**
 * Retrieves the theme for the applicant indicated by the given token.
 * @param token the JWT token that belong to a specific applicant
 */
export async function getTheme(token: string): Promise<ApplicantTheme> {
  const response = await themeApiClient.get<ApplicantTheme>(
    "/application/theme",
    {
      headers: { "x-otes-token": token },
    },
  );

  return response.data;
}
