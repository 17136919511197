import {
  Controller,
  FormProvider,
  get,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import {
  AuthorizationDownloadFormFields,
  AuthorizationDownloadFormInfo,
} from "./AuthorizationDownloadTask";
import { Plural, t, Trans } from "@lingui/macro";
import { ReactNode, useRef, useState } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import { getInterviewDoc, verifyAllDownloaded } from "tasks/tasksApi";
import SignatureCanvas from "react-signature-canvas";
import useAuth from "auth/useAuth";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import useOtesModal from "error/useOtesModal";

interface AuthorizationDownloadFormParameters extends InterviewFormParameters {
  authForms: Array<AuthorizationDownloadFormInfo>;
}

interface AuthorizationDownloadFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: AuthorizationDownloadFormParameters;
  defaultValues?: AuthorizationDownloadFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  methods: any;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
  authForms: Array<AuthorizationDownloadFormInfo>;
  accessedDocs: Set<string>;
  setAccessedDocs: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export interface AuthorizationDownloadSubFormFields
  extends InterviewFormFields {
  signature?: string;
}

export interface AuthorizationDownloadSubFormParameters
  extends InterviewFormParameters {
  authForms: Array<AuthorizationDownloadFormInfo>;
}

export type AuthorizationDownloadApiSubForm = InterviewApiSubForm<
  AuthorizationDownloadSubFormFields,
  InterviewFormMessages,
  AuthorizationDownloadSubFormParameters
>;

const FormWrapper = ({
  formId,
  methods,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formId,
  authForms,
  accessedDocs,
  setAccessedDocs,
}: FormContentProps) => {
  const { token } = useAuth();
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  const { triggerOtesModal } = useOtesModal();
  const signCanvas = useRef<SignatureCanvas | null>(null);
  const clearCanvas = () => {
    signCanvas.current?.clear();
  };

  const methods = useFormContext();
  const {
    formState: { errors, isSubmitted },
  } = methods;

  return (
    <>
      <FormControl as="fieldset" isInvalid={get(errors, `${formId}.signature`)}>
        <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
          {formName}
        </FormLabel>
        <Text color={dimGray}>
          <Plural
            value={authForms.length}
            one={
              <Trans>
                Please download and review the following authorization form.
              </Trans>
            }
            other={
              <Trans>
                Please download and review the following authorization forms.
              </Trans>
            }
          />
        </Text>
        {authForms.map((formInfo) => (
          <Box key={formInfo.authFormUid} m={2}>
            <Box key={formInfo.authFormUid}>
              <FileDownloadButton
                getFile={(token: string) =>
                  getInterviewDoc(
                    token,
                    {
                      authFormUid: formInfo.authFormUid,
                    },
                    true,
                    formId,
                  )
                }
                postDownload={() => {
                  setAccessedDocs(
                    (existing: Set<string>) =>
                      new Set<string>([...existing, formInfo.authFormUid]),
                  );

                  verifyAllDownloaded(token, `${formId}`).then(
                    ({ data: { data: respData } }) => {
                      const { allDocsDownloaded } = respData;
                      if (allDocsDownloaded) {
                        authForms.forEach((authForm) => {
                          setAccessedDocs(
                            (existing: Set<string>) =>
                              new Set<string>([
                                ...existing,
                                authForm.authFormUid,
                              ]),
                          );
                        });
                      }
                    },
                  );
                }}
                label={formInfo.authFormTitle}
                onError={() => {
                  triggerOtesModal({
                    bodyText: t`There was an error downloading the file, please try again.`,
                  });
                }}
              />
              {accessedDocs.has(formInfo.authFormUid) ? (
                <>
                  <CheckCircleIcon color={"green"} />
                </>
              ) : (
                <>
                  <WarningIcon color="orange.500" />
                </>
              )}
            </Box>
          </Box>
        ))}
        <FormLabel
          as="legend"
          fontSize="xl"
          fontWeight="bold"
          color={orange}
          className="requiredField">
          <Trans>Signature</Trans>
        </FormLabel>
        <Text color={dimGray}>
          <Trans>
            By signing below I acknowledge receipt of the additional
            authorization forms above.
          </Trans>
        </Text>
        <Flex alignItems="end" mt="10px">
          {/* TODO (JDL:2022-07-29) Signature duplication should be componentized.*/}
          <Controller
            name={`${formId}.signature`}
            rules={{ required: t`This is required` }}
            render={({ field: { onChange } }) => (
              <SignatureCanvas
                ref={signCanvas}
                onEnd={() => {
                  //at the end of each stroke update the field value to the PNG string
                  onChange(signCanvas.current?.getTrimmedCanvas().toDataURL());
                }}
                canvasProps={ThemeConstants.SIGNATURE_CANVAS_PROPS}
              />
            )}
          />
        </Flex>
        <FormLabel
          fontWeight="normal"
          fontSize="smaller"
          className={"requiredField"}>
          <Trans>Use your mouse or finger to draw your signature</Trans>
        </FormLabel>
        <Box mt="15px">
          <Button
            color="gray.600"
            onClick={() => {
              clearCanvas();
              methods.setValue(`${formId}.signature`, "");
              if (isSubmitted) {
                methods.setError(`${formId}.signature`, {
                  type: "required",
                  message: t`This is required`,
                });
              }
            }}>
            <Trans>Clear</Trans>
          </Button>
        </Box>
        <FormErrorMessage>
          <Trans>
            Signature is empty or cannot be processed. Please clear the
            signature and try again.
          </Trans>
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

export default function AuthorizationDownloadForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  onSubmit,
  goToStatusCheck,
}: AuthorizationDownloadFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<AuthorizationDownloadFormFields>({ defaultValues });

  const { formName } = formMessages;

  const { formId, authForms } = formParameters;

  const allDocs = new Set<string>();
  const initiallyAccessedDocs = new Set<string>();

  authForms.forEach((authForm) => {
    allDocs.add(authForm.authFormUid);

    if (authForm.hasBeenAccessed) {
      initiallyAccessedDocs.add(authForm.authFormUid);
    }
  });

  const [accessedDocs, setAccessedDocs] = useState<Set<string>>(
    initiallyAccessedDocs,
  );

  const formContentProps = {
    formId,
    formName,
    authForms,
    accessedDocs,
    setAccessedDocs,
  };

  const formWrapperProps = {
    formId,
    methods,
    onSubmit,
  };

  return (
    <VStack p={1}>
      {
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            disabledSubmit={allDocs.size > accessedDocs.size}
            onBack={goToStatusCheck}
            form={formId}
            mt={"1rem"}
          />
        </FormWrapper>
      }
    </VStack>
  );
}
