import { FormLabel, useTheme, VStack } from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import {
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { t } from "@lingui/macro";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { getInterviewDoc } from "tasks/tasksApi";
import OkButton from "../../../common/basic/OkButton";
import useOtesModal from "error/useOtesModal";

interface ReportFormParameters extends InterviewFormParameters {
  documentUid?: string;
}

interface ReportFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: ReportFormParameters;
}

export default function ReportForm({
  formMessages,
  formParameters,
  goToStatusCheck,
}: ReportFormProps): JSX.Element {
  const { formName } = formMessages;
  const { documentUid } = formParameters;
  const { triggerOtesModal } = useOtesModal();
  const theme = useTheme();
  const { orange } = theme.colors.brand;

  return (
    <>
      <VStack w="100%" p={1}>
        <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
          {formName}
        </FormLabel>
        <FileDownloadButton
          getFile={(token: string) =>
            getInterviewDoc(token, {
              documentUid,
            })
          }
          label={t`Download`}
          onError={() => {
            triggerOtesModal({
              bodyText: t`There was an error downloading the file, please try again.`,
            });
          }}
        />
        <OkButton onClick={goToStatusCheck} />
      </VStack>
    </>
  );
}
