import { Button } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import useAuth from "auth/useAuth";
import { clearAllStorage } from "auth/authHelpers";

export default function Logout(): JSX.Element {
  const { updateToken } = useAuth();

  return (
    <Button
      colorScheme="orange"
      onClick={() => {
        clearAllStorage();
        updateToken("");
        window.location.href = "/";
      }}>
      <Trans>LOG OUT</Trans>
    </Button>
  );
}
