import Button from "theme/components/button";
import Divider from "theme/components/divider";
import Link from "theme/components/link";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

export default {
  Button: Button,
  Steps: Steps,
  Divider: Divider,
  Link,
};
