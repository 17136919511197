import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { SupervisorNameFormFields } from "./SupervisorNameTask";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";

interface SupervisorNameFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: SupervisorNameFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
}

export interface SupervisorNameSubFormFields extends InterviewFormFields {
  supervisorsName?: string;
  supervisorsTitle?: string;
}

export type SupervisorNameApiSubForm = InterviewApiSubForm<
  SupervisorNameSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({ formName, formId }: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Box width="100%">
        <FormInput
          label={t`Supervisor's Name`}
          fieldId={`${formId}.supervisorsName`}
        />
        <FormInput
          label={t`Supervisor's Title`}
          fieldId={`${formId}.supervisorsTitle`}
        />
      </Box>
    </FormControl>
  );
};

export default function SupervisorNameForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: SupervisorNameFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<SupervisorNameFormFields>({ defaultValues });

  const { formName } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    formId,
    formName,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" maxWidth="400px" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
