import {
  Flex,
  Heading,
  IconButton,
  Spacer,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import MenuDrawer from "components/common/menu/MenuDrawer";
import { ThemeConstants } from "components/common/basic/ThemeConstants";
import useAuth from "auth/useAuth";
import { HeaderLogo } from "components/common/basic/HeaderLogo";
import useAcquisition from "applicantTheme/useAcquisition";

interface HeaderProps {
  text?: string;
}

export default function Header({ text }: HeaderProps): JSX.Element {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  // charka-ui hook to handle the menu display state
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useAuth();
  const { acquisition } = useAcquisition();

  return (
    <>
      {isAuthenticated && (
        <MenuDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      )}
      <Flex
        p="0.5rem"
        bgColor={orange}
        color="#fff"
        width={"full"}
        height={ThemeConstants.HEADER_HEIGHT}
        alignItems={"center"}>
        {isAuthenticated && (
          <HeaderLogo color="white" acquisitionSource={acquisition} />
        )}
        <Spacer minW="1" />
        <Heading textAlign="center" size={"md"} isTruncated>
          {text?.toUpperCase()}
        </Heading>
        <Spacer minW="1" />
        {isAuthenticated && (
          <IconButton
            visibility={isAuthenticated ? "visible" : "hidden"}
            size="lg"
            color="#fff"
            bgColor={orange}
            icon={<HamburgerIcon w="50px" h="50px" />}
            aria-label="menu drawer"
            onClick={onOpen}
          />
        )}
      </Flex>
    </>
  );
}
