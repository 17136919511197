import { WidgetProps } from "@rjsf/utils";
import { FormLabel, Text } from "@chakra-ui/react";
import parse from "html-react-parser";

export default function SpecialFormText(props: WidgetProps) {
  const uiSchema = props.uiSchema;
  const additionalProperties = uiSchema?.additionalProperties;

  return (
    <>
      <FormLabel
        fontSize={additionalProperties?.fontSize}
        fontWeight={additionalProperties?.fontWeight}
        color={additionalProperties?.color}>
        {additionalProperties.message && parse(additionalProperties.message)}
      </FormLabel>
    </>
  );
}
