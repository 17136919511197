import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Controller, get, useFormContext } from "react-hook-form";
import PinField from "react-pin-field";

interface NationalIdInputProps {
  required?: boolean;
  isSsn: boolean;
  numberOfChars: number;
  helperText?: string;
}

export default function NationalIdInput({
  required = true,
  isSsn,
  numberOfChars,
  helperText,
}: NationalIdInputProps): JSX.Element {
  const { formState, control } = useFormContext();

  return (
    <FormControl isInvalid={get(formState.errors, "natlId")}>
      <FormLabel htmlFor="natlId" className="requiredField">
        {isSsn ? t`Last Four Digits of SSN` : t`National Identification Number`}
      </FormLabel>
      <Controller
        control={control}
        name="natlId"
        defaultValue={""}
        rules={{
          required: {
            value: required,
            message: t`This is required`,
          },
        }}
        render={({ field: { onChange } }) => (
          <Input
            as={PinField}
            id={"natlId"}
            name={"natlId"}
            type={"tel"}
            required={true}
            inputMode={"numeric"}
            length={numberOfChars}
            validate={/^[0-9]$/}
            onChange={onChange}
            borderColor={"gray.600"}
            style={{
              width: "45px",
              height: "45px",
              margin: "3px",
              fontSize: "16px",
              textAlign: "center",
            }}
          />
        )}
      />
      <FormErrorMessage>
        {formState.errors.natlId && formState.errors.natlId.message}
      </FormErrorMessage>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
