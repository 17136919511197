import { SavableFormComponentProps } from "components/tasks/taskHelpers";
import ReferenceForm, {
  EMPTY_REFERENCE,
  ReferenceFormFields,
} from "components/tasks/references/ReferenceForm";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Box, Button, Divider } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import TaskButtons from "components/common/basic/TaskButtons";

export interface ReferencesFormFields {
  references: ReferenceFormFields[];
  doSave?: boolean;
}

export interface ReferencesFormProps
  extends SavableFormComponentProps<ReferencesFormFields> {
  useRelationshipDropdown: boolean;
  requireEmail: boolean;
  requirePhone: boolean;
}

export default function ReferencesForm({
  defaultValues,
  useRelationshipDropdown,
  requireEmail,
  requirePhone,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: ReferencesFormProps): JSX.Element {
  const methods = useForm<ReferencesFormFields>({ defaultValues });

  // if necessary, initialize defaultValues with an empty reference
  if (!defaultValues.references.length) {
    defaultValues.references = [EMPTY_REFERENCE];
  }

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "references",
  });

  const referencesForm = "referencesForm";

  return (
    <FormProvider {...methods}>
      <form
        id={referencesForm}
        onSubmit={methods.handleSubmit((data: ReferencesFormFields) =>
          onSubmit(data),
        )}>
        {fields.map((reference, index) => (
          <Box key={reference.id}>
            <ReferenceForm
              referenceId={`references.${index}`}
              defaultValues={reference}
              useRelationshipDropdown={useRelationshipDropdown}
              requireEmail={requireEmail}
              requirePhone={requirePhone}
              showRemoveButton={fields.length > 1}
              onRemoveClick={() => remove(index)}
            />
          </Box>
        ))}
        <Box pt={4}>
          <Button color="gray.600" onClick={() => append(EMPTY_REFERENCE)}>
            + <Trans>Add Another Reference</Trans>
          </Button>
        </Box>
        <TaskButtons
          onBack={goToStatusCheck}
          onSave={
            (isSavable &&
              (() => onSubmit({ ...methods.getValues(), doSave: true }))) ||
            undefined
          }
          form={referencesForm}
        />
      </form>
    </FormProvider>
  );
}
