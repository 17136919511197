import Uppy from "@uppy/core";
import { Dashboard, useUppy } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import XHRUpload from "@uppy/xhr-upload";
import useAuth from "auth/useAuth";
import Webcam from "@uppy/webcam";
import useOtesModal from "error/useOtesModal";
import { t } from "@lingui/macro";

type FileUploadProps = {
  accept?: string[];
  maxFilesSizeMb?: number;
  minNumFiles: number;
  endpoint: string;
  onComplete: () => void;
};

export const FileUpload = ({
  accept,
  maxFilesSizeMb,
  minNumFiles,
  endpoint,
  onComplete,
}: FileUploadProps): any => {
  const { token } = useAuth();
  const { triggerOtesModal } = useOtesModal();

  const uppy = useUppy(() => {
    const getCookie = (cookieKey: string): string => {
      const cookie = document.cookie.match(
        "(^|;)\\s*" + cookieKey + "\\s*=\\s*([^;]+)",
      );
      return cookie?.pop?.() || "";
    };

    return new Uppy({
      restrictions: {
        minNumberOfFiles: minNumFiles,
        maxNumberOfFiles: minNumFiles > 1 ? 10 : 1,
        allowedFileTypes: accept,
        maxFileSize: (maxFilesSizeMb || 0) * 1000000,
      },
    })
      .use(XHRUpload, {
        endpoint,
        timeout: 90 * 1000,
        headers: {
          "x-otes-token": token,
          "x-xsrf-token": getCookie("XSRF-TOKEN"),
        },
      })
      .use(Webcam, {
        modes: ["picture"],
        facingMode: "environment",
      });
  });

  uppy.on("complete", (result) => {
    if (!result?.failed.length) {
      onComplete();
    } else {
      triggerOtesModal({
        bodyText: t`There was an error on upload, please try again.`,
      });
    }
  });

  uppy.on("error", (err) => {
    triggerOtesModal({
      bodyText:
        err?.message || t`There was an error on upload, please try again.`,
    });
  });

  return (
    <Dashboard
      uppy={uppy}
      plugins={["Webcam"]}
      width="100%"
      height="auto"
      doneButtonHandler={undefined}
    />
  );
};
