import { createContext, ReactNode, useContext, useState } from "react";

interface AcquisitionContextType {
  acquisition: string;
  updateAcquisition: (acquisition: string) => void;
}

const AcquisitionContext = createContext<AcquisitionContextType>(
  {} as AcquisitionContextType,
);

export function AcquisitionProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [acquisition, setAcquisition] = useState<string | null>("");

  function updateAcquisition(acquisition: string | null) {
    setAcquisition(acquisition);
  }

  return (
    <AcquisitionContext.Provider
      value={{
        acquisition: acquisition || "acquisition",
        updateAcquisition,
      }}>
      {children}
    </AcquisitionContext.Provider>
  );
}

export default function useAcquisition(): AcquisitionContextType {
  return useContext(AcquisitionContext);
}
