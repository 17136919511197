import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FormControl, FormLabel, useTheme, VStack } from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { ConfirmCitizenshipFormFields } from "components/tasks/interviews/confirm-citizenship/ConfirmCitizenshipTask";
import { t } from "@lingui/macro";
import { ReactNode, useEffect } from "react";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import FormRadioGroup from "components/common/form/FormRadioGroup";
import { COUNTRIES, COUNTRY_UNITED_STATES } from "tasks/apiConstants";
import FormSelect from "components/common/form/FormSelect";
import FormInput from "components/common/form/FormInput";
import { SOCIAL_SECURITY_NUMBER } from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

interface ConfirmCitizenshipFormMessages extends InterviewFormMessages {
  instructionHtml: string;
  countryToConfirm: string;
}

interface ConfirmCitizenshipFormProps extends TaskComponentProps {
  formMessages: ConfirmCitizenshipFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: ConfirmCitizenshipFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formId?: string;
  countryToConfirm?: string;
}

export interface ConfirmCitizenshipSubFormFields extends InterviewFormFields {
  didYouGraduate?: boolean;
  graduationDate?: string;
}

export interface ConfirmCitizenshipSubFormMessages
  extends InterviewFormMessages {
  instructionHtml: string;
  countryToConfirm: string;
}

export type ConfirmCitizenshipApiSubForm = InterviewApiSubForm<
  ConfirmCitizenshipSubFormFields,
  ConfirmCitizenshipSubFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formId,
  countryToConfirm,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;
  const methods = useFormContext();

  const citizenOfCountry = methods
    .watch(`${formId}.citizenOfCountry`)
    ?.toString();

  const nationalIdentificationCountry = methods.watch(
    `${formId}.nationalIdentificationCountry`,
  );

  const displayCountryField = citizenOfCountry === "false";

  const confirmingSSN =
    (displayCountryField &&
      nationalIdentificationCountry === COUNTRY_UNITED_STATES) ||
    (!displayCountryField && countryToConfirm === COUNTRY_UNITED_STATES);

  useEffect(() => {
    const { isDirty } = methods.formState;

    if (isDirty) {
      methods.setValue(`${formId}.nationalIdentification`, "");
    }

    methods.clearErrors();
  }, [confirmingSSN]);

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <FormRadioGroup
        fieldId={`${formId}.citizenOfCountry`}
        groupLabel={t`Are you a citizen of ${countryToConfirm}?`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        control={methods.control}
      />
      {displayCountryField && (
        <FormSelect
          fieldId={`${formId}.nationalIdentificationCountry`}
          label={t`Country`}
          placeHolder={t`Select a country`}
          options={COUNTRIES.filter((country) => country !== countryToConfirm)}
        />
      )}
      {citizenOfCountry && (
        <FormInput
          label={`${
            confirmingSSN ? SOCIAL_SECURITY_NUMBER.LABEL : "National ID"
          }`}
          fieldId={`${formId}.nationalIdentification`}
          type={confirmingSSN ? "tel" : "text"}
          inputMode={confirmingSSN ? "numeric" : "text"}
          onKeyDown={confirmingSSN ? onKeyDownEnforceNumeric : undefined}
          inputMask={confirmingSSN ? SOCIAL_SECURITY_NUMBER.MASK : undefined}
          placeholder={
            confirmingSSN ? SOCIAL_SECURITY_NUMBER.PLACEHOLDER : undefined
          }
          validationPattern={
            confirmingSSN ? SOCIAL_SECURITY_NUMBER.PATTERN : /.*/
          }
          validationPatternMessage={SOCIAL_SECURITY_NUMBER.PATTERN_MESSAGE}
        />
      )}
    </FormControl>
  );
};

export default function ConfirmCitizenshipForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: ConfirmCitizenshipFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<ConfirmCitizenshipFormFields>({ defaultValues });

  const { formName, countryToConfirm } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    formId,
    formName,
    countryToConfirm,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" maxWidth="400px" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
