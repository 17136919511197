import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { get, useFormContext, useWatch, Validate } from "react-hook-form";
import { DateTime } from "luxon";
import { t } from "@lingui/macro";
import { useEffect } from "react";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

const now = DateTime.now();

export const ONE_HUNDRED_YEARS_AGO = now.minus({ years: 100 }).year;
export const TEN_YEARS_AGO = now.minus({ years: 10 }).year;
export const { day: THIS_DAY, month: THIS_MONTH, year: THIS_YEAR } = now;
export function yearsAgo(yearsAgo: number) {
  return now.minus({ years: yearsAgo }).year;
}

export interface DateInputProps {
  parentId?: string;
  fieldIds?: { month?: string; day?: string; year?: string };
  label: string;
  //default value in YYYY-MM-DD format
  defaultMonth?: string;
  defaultDay?: string;
  defaultYear?: string;
  placeholderMonth?: string;
  placeholderDay?: string;
  placeholderYear?: string;
  defaultPlaceholders?: boolean;
  required?: boolean;
  helperText?: string;
  requiredMinDate?: boolean;
  minYear?: number;
  maxYear?: number;
  validateMonth?: Validate<any> | Record<string, Validate<any>>;
  validateDay?: Validate<any> | Record<string, Validate<any>>;
  validateYear?: Validate<any> | Record<string, Validate<any>>;
  hidden?: boolean;
}

export function toIsoDate(year?: string, month?: string, day?: string) {
  if (!year || !month || !day) {
    return "";
  }

  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

//handles zero-padding dates when they are being submitted, but not when saving
export function getApiDate(
  year: string,
  month: string,
  day: string,
  isSaveMode?: boolean,
) {
  return isSaveMode ? `${year}-${month}-${day}` : toIsoDate(year, month, day);
}

export function getSplitFormDate(
  apiDate?: string,
  componentNames = ["year", "month", "day"],
  delimiter = "-",
): { [key: string]: string } {
  //Only split string fields
  const splitDate =
    typeof apiDate === "string" ? apiDate.split(delimiter) : ["", "", ""];
  return componentNames.reduce(
    (result: { [key: string]: string }, dateComp, index) => {
      result[dateComp] = splitDate[index];
      return result;
    },
    {},
  );
}

export function hasDateGreaterThanMaxDate(
  month: string,
  day: string,
  year: string,
  maxDate: DateTime,
) {
  let dateTime;

  if (month && day && year) {
    dateTime = DateTime.fromObject({
      month: Number(month),
      day: Number(day),
      year: Number(year),
    }).endOf("day");
  }

  return dateTime && maxDate && dateTime > maxDate;
}

export const isCompleteDate = (month: any, day: any, year: any) => {
  return !!(
    month &&
    !isNaN(month) &&
    day &&
    !isNaN(day) &&
    year &&
    !isNaN(year)
  );
};

export const validStartEndDate = (
  startDate: {
    month: number;
    day: number;
    year: number;
  },
  endDate: {
    month: number;
    day: number;
    year: number;
  },
) => {
  const startDateTime = DateTime.fromObject({
    year: startDate.year,
    month: startDate.month,
    day: startDate.day,
  });

  const endDateTime = DateTime.fromObject({
    year: endDate.year,
    month: endDate.month,
    day: endDate.day,
  });

  return startDateTime < endDateTime;
};

export default function DateInput({
  parentId,
  fieldIds = { month: "month", day: "day", year: "year" },
  label,
  defaultMonth,
  defaultDay,
  defaultYear,
  placeholderMonth,
  placeholderDay,
  placeholderYear,
  defaultPlaceholders,
  required = true,
  helperText,
  requiredMinDate = false,
  minYear = ONE_HUNDRED_YEARS_AGO,
  maxYear = THIS_YEAR,
  validateMonth,
  validateDay,
  validateYear,
  hidden = false,
}: // deps = [],
DateInputProps): JSX.Element {
  const { register, formState, control, setFocus } = useFormContext();

  const inputIsAccessible = !hidden;
  const validateRequired = inputIsAccessible && required;
  const validateRequiredMinDate = inputIsAccessible && requiredMinDate;
  const validateMinYear = inputIsAccessible && minYear;
  const validateMaxYear = inputIsAccessible && maxYear;

  const parentPath = parentId ? parentId + "." : "";
  const pathIds = {
    month: parentPath + fieldIds.month,
    day: parentPath + fieldIds.day,
    year: parentPath + fieldIds.year,
  };

  const [month, day, year] = useWatch({
    control,
    name: [pathIds.month, pathIds.day, pathIds.year],
  });

  const maxDay = DateTime.local(Number(year), Number(month)).daysInMonth;
  useEffect(() => {
    if (
      (month?.length === 2 && !year && day?.length === 0) ||
      (month?.length === 2 && year?.length === 4 && day?.length === 0)
    ) {
      setFocus(pathIds.day);
    } else if (
      (month?.length === 2 && day?.length === 2 && !year) ||
      (month?.length === 0 && day?.length === 2 && !year)
    ) {
      setFocus(pathIds.year);
    } else if (month?.length === 0 && day?.length === 2 && year?.length === 4) {
      setFocus(pathIds.month);
    }
  }, [month, day, year]);

  const monthValueErrMsg = t`Please enter a month between 1 and 12`;
  const monthLengthErrMsg = t`1-2 digits required for month.`;
  const dayValueErrMsg = t`Please enter a day between 1 and ${maxDay}`;
  const dayLengthErrMsg = t`1-2 digits required for day`;
  const monthRegExp = new RegExp("^0[1-9]$|^[1-9]$|^1[0-2]$");
  const dayRegExp = new RegExp("^(0?[0-9]|[1-3][0-9])$");
  const yearRegExp = new RegExp("[0-9]{4}$");

  let yearValueErrMsg: string;

  if (minYear && maxYear) {
    yearValueErrMsg = t`Please enter a year in the range of ${minYear}-${maxYear}`;
  } else if (minYear) {
    yearValueErrMsg = t`Please enter a year no earlier than ${minYear}`;
  } else if (maxYear) {
    yearValueErrMsg = t`Please enter a year no later than ${maxYear}`;
  } else {
    // Will never be seen, but set here anyways to satisfy lint.
    yearValueErrMsg = "";
  }

  const yearLengthErrMsg = t`4 digits required for year.`;
  const digitsOnlyErrMsg = t`Please enter numbers only`;

  // const validateDepsOnChange = () => {
  //   //trigger validation on any touched dependency fields
  //   trigger(deps?.filter((dep) => get(formState.touchedFields, dep)));
  // };

  const hasInvalidMinDate =
    validateRequiredMinDate && get(formState.errors, `${parentPath}.minDate`);

  const enforceMaxLengthOf = (maxLength: number) => {
    return (event: React.KeyboardEvent) => {
      if (
        "Enter" === event.key ||
        "Delete" === event.key ||
        "Backspace" === event.key ||
        "Home" === event.key ||
        "End" === event.key ||
        "Tab" === event.key ||
        "ArrowLeft" === event.key ||
        "ArrowRight" === event.key
      ) {
        return true;
      }

      const currentTarget: HTMLInputElement =
        event.currentTarget as HTMLInputElement;

      if (currentTarget.value.length >= maxLength) {
        currentTarget.value = currentTarget.value.substr(0, maxLength);
      }
    };
  };

  if (defaultPlaceholders) {
    placeholderMonth = "MM";
    placeholderDay = "DD";
    placeholderYear = "YYYY";
  }

  const monthPadding = placeholderMonth ? "10px" : "15px";
  const dayPadding = placeholderDay ? "10px" : "15px";

  return (
    <Box hidden={hidden} py="0.5rem" width={"fit-content"}>
      <FormLabel
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <FormControl
        isInvalid={
          hasInvalidMinDate ||
          get(formState.errors, pathIds.month) ||
          get(formState.errors, pathIds.day) ||
          get(formState.errors, pathIds.year)
        }>
        <VStack alignItems="flexStart">
          <HStack spacing="1rem" width="max-content">
            <FormControl
              isInvalid={
                get(formState.errors, pathIds.month) ||
                (requiredMinDate &&
                  get(formState.errors, `${parentPath}.minDate`))
              }>
              <FormLabel fontSize="xs" htmlFor={pathIds.month}>
                Month
              </FormLabel>
              <Input
                id={pathIds.month}
                defaultValue={defaultMonth}
                placeholder={placeholderMonth}
                _placeholder={{
                  ml: "-10px",
                }}
                p={monthPadding}
                type={"tel"}
                inputMode={"numeric"}
                width="3.2em"
                borderColor={"gray.600"}
                onKeyDown={onKeyDownEnforceNumeric}
                onKeyUp={enforceMaxLengthOf(2)}
                {...register(pathIds.month, {
                  required: {
                    value: validateRequired,
                    message: t`This is required`,
                  },
                  min:
                    (inputIsAccessible && {
                      value: 1,
                      message: monthValueErrMsg,
                    }) ||
                    undefined,
                  max:
                    (inputIsAccessible && {
                      value: 12,
                      message: monthValueErrMsg,
                    }) ||
                    undefined,
                  minLength:
                    (inputIsAccessible && {
                      value: 1,
                      message: monthLengthErrMsg,
                    }) ||
                    undefined,
                  maxLength:
                    (inputIsAccessible && {
                      value: 2,
                      message: monthLengthErrMsg,
                    }) ||
                    undefined,
                  pattern:
                    (inputIsAccessible && {
                      value: monthRegExp,
                      message: digitsOnlyErrMsg,
                    }) ||
                    undefined,
                  validate: (inputIsAccessible && validateMonth) || undefined,
                  // ...(!!deps.length && { onChange: validateDepsOnChange }),
                })}
              />
            </FormControl>
            <FormControl
              isInvalid={
                get(formState.errors, pathIds.day) || hasInvalidMinDate
              }>
              <FormLabel fontSize="xs" htmlFor={pathIds.day}>
                Day
              </FormLabel>
              <Input
                id={pathIds.day}
                defaultValue={defaultDay}
                placeholder={placeholderDay}
                _placeholder={{
                  ml: "-10px",
                }}
                p={dayPadding}
                type={"tel"}
                inputMode={"numeric"}
                width="3.2em"
                borderColor={"gray.600"}
                onKeyDown={onKeyDownEnforceNumeric}
                onKeyUp={enforceMaxLengthOf(2)}
                {...register(pathIds.day, {
                  required: {
                    value: validateRequired,
                    message: t`This is required`,
                  },
                  min:
                    (inputIsAccessible && {
                      value: 1,
                      message: dayValueErrMsg,
                    }) ||
                    undefined,
                  max:
                    (inputIsAccessible && {
                      value: maxDay,
                      message: dayValueErrMsg,
                    }) ||
                    undefined,
                  minLength:
                    (inputIsAccessible && {
                      value: 1,
                      message: dayLengthErrMsg,
                    }) ||
                    undefined,
                  maxLength:
                    (inputIsAccessible && {
                      value: 2,
                      message: dayLengthErrMsg,
                    }) ||
                    undefined,
                  pattern:
                    (inputIsAccessible && {
                      value: dayRegExp,
                      message: digitsOnlyErrMsg,
                    }) ||
                    undefined,
                  validate: (inputIsAccessible && validateDay) || undefined,
                  // ...(!!validate?.day && { validate: validate.day }),
                  // ...(!!deps.length && { onChange: validateDepsOnChange }),
                })}
              />
            </FormControl>
            <FormControl
              isInvalid={
                get(formState.errors, pathIds.year) || hasInvalidMinDate
              }>
              <FormLabel fontSize="xs" htmlFor={pathIds.year}>
                Year
              </FormLabel>
              <Input
                id={pathIds.year}
                defaultValue={defaultYear}
                placeholder={placeholderYear}
                width="4.5em"
                type={"tel"}
                inputMode={"numeric"}
                borderColor={"gray.600"}
                onKeyDown={onKeyDownEnforceNumeric}
                onKeyUp={enforceMaxLengthOf(4)}
                {...register(pathIds.year, {
                  required: {
                    value: validateRequired,
                    message: t`This is required`,
                  },
                  min:
                    (validateMinYear && {
                      value: minYear,
                      message: yearValueErrMsg,
                    }) ||
                    undefined,
                  max:
                    (validateMaxYear && {
                      value: maxYear,
                      message: yearValueErrMsg,
                    }) ||
                    undefined,
                  minLength:
                    (inputIsAccessible && {
                      value: 4,
                      message: yearLengthErrMsg,
                    }) ||
                    undefined,
                  maxLength:
                    (inputIsAccessible && {
                      value: 4,
                      message: yearLengthErrMsg,
                    }) ||
                    undefined,
                  pattern:
                    (inputIsAccessible && {
                      value: yearRegExp,
                      message: digitsOnlyErrMsg,
                    }) ||
                    undefined,
                  validate: (inputIsAccessible && validateYear) || undefined,
                  // ...(!!validate?.year && { validate: validate.year }),
                  // ...(!!deps.length && { onChange: validateDepsOnChange }),
                })}
              />
            </FormControl>
          </HStack>
          <FormErrorMessage>
            {get(formState.errors, `${pathIds.month}.message`) ||
              get(formState.errors, `${pathIds.day}.message`) ||
              get(formState.errors, `${pathIds.year}.message`) ||
              (hasInvalidMinDate && "End Date must be after Start Date")}
          </FormErrorMessage>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </VStack>
      </FormControl>
    </Box>
  );
}
