interface LogoResource {
  large: string;
  medium: string;
  small: string;
}

export default interface ApplicantTheme {
  applicantName: string;
  applicationStatus: string;
  background: string;
  clientLabel?: string;
  color: string;
  hasActive: boolean;
  logo: LogoResource;
  logoColor: string;
  startButtonBackgroundColor: string;
  startButtonBackgroundHoverColor: string;
  startButtonBackgroundHoverTextColor: string;
  startButtonBackgroundTextColor: string;
  acquisitionSource: string;
}

export const defaultApplicantTheme: ApplicantTheme = {
  applicantName: "",
  applicationStatus: "",
  background: "#FFFFFF",
  color: "#000000",
  hasActive: false,
  logo: {
    large: "",
    medium: "",
    small: "",
  },
  logoColor: "Orange",
  startButtonBackgroundColor: "#00000",
  startButtonBackgroundHoverColor: "#FFFFFF",
  startButtonBackgroundHoverTextColor: "#000000",
  startButtonBackgroundTextColor: "#FFFFFF",
  acquisitionSource: "OrangeTree",
};
