import {
  Button,
  Textarea,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import useAuth from "../../../auth/useAuth";
import { Trans } from "@lingui/macro";
import axios from "axios";
import { useState } from "react";

const client = axios.create({
  baseURL: "/api/rest/v1",
});

export interface HelpDeskTicketFormFields {
  subject: string;
  body: string;
  dispute: boolean;
}

export interface HelpDeskTicketFormProps {
  isDispute: boolean;
}

export interface HelpDeskTicketResponseProps {
  ticket_id: string;
}

export default function HelpDeskTicket({
  isDispute,
}: HelpDeskTicketFormProps): JSX.Element {
  const [responseText, setResponseText] = useState<string>();
  const { register, handleSubmit, formState, setValue } =
    useForm<HelpDeskTicketFormFields>({
      mode: "onSubmit",
    });

  const { token } = useAuth();

  const submitHelpDeskTicket = async (data: HelpDeskTicketFormFields) => {
    setResponseText("");

    const response = await client
      .post<HelpDeskTicketResponseProps>(
        "/deskpro/ticket",
        { ...data },
        {
          headers: { "x-otes-token": token },
        },
      )
      .then((response) => {
        setResponseText(
          "Your message has been submitted successfully. Your ticket number is " +
            response.data.ticket_id,
        );
        setValue("subject", "");
        setValue("body", "");
      })
      .catch(() => {
        setResponseText("Sorry, the message was not sent. Try again later..");
      });
  };

  return (
    <form onSubmit={handleSubmit(submitHelpDeskTicket)}>
      <FormControl isInvalid={!!formState.errors.subject}>
        <FormLabel htmlFor="subject" className="requiredField">
          Subject
        </FormLabel>
        <Input
          id="subject"
          {...register("subject", {
            required: "Please enter a subject",
          })}
        />
        <FormErrorMessage>
          {formState?.errors?.subject?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.body}>
        <FormLabel htmlFor="body" className="requiredField">
          Comments
        </FormLabel>
        <Textarea
          id="body"
          {...register("body", {
            required: "Please enter a description of the issue.",
          })}
        />
        <FormErrorMessage>{formState?.errors?.body?.message}</FormErrorMessage>

        <FormHelperText>
          <Text>{responseText}</Text>
        </FormHelperText>
      </FormControl>

      <Button colorScheme="orange" type={"submit"}>
        <Trans>SUBMIT</Trans>
      </Button>
    </form>
  );
}
