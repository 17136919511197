import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { get, useFormContext, Validate } from "react-hook-form";
import { useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactInputMask from "react-input-mask";
import { CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import { isSafari } from "react-device-detect";

const now = DateTime.now();
export const TEN_YEARS_AGO_DATE = now.minus({ years: 10 });
export const MIN_DATE_FULL = now.minus({ years: 100 }).startOf("day");
export const MAX_DATE_FULL = now.startOf("day");

export function hasFullDateStringGreaterThanMaxDate(
  fullDateString: string,
  maxDate: DateTime,
) {
  let dateTime;

  if (fullDateString) {
    dateTime = DateTime.fromISO(fullDateString).endOf("day");
  }

  return dateTime && maxDate && dateTime > maxDate;
}

export function isStartDateBeforeEndDate(
  startDateString: string,
  endDateString: string,
) {
  let startDateTime;
  let endDateTime;

  if (startDateString) {
    startDateTime = DateTime.fromISO(startDateString);
  }

  if (endDateString) {
    endDateTime = DateTime.fromISO(endDateString);
  }

  return startDateTime && endDateTime && startDateTime < endDateTime;
}

export function validateFormDate(formDate: string) {
  if (!formDate) {
    return "This is required";
  }

  const formDateTime = formDate && DateTime.fromISO(formDate);
  if (!formDateTime || !formDateTime.isValid) {
    return "Invalid date";
  }

  return true;
}

export function validateDateOfBirth(dateOfBirthString: string) {
  const validOrErrorMessage = validateFormDate(dateOfBirthString);

  if (DateTime.fromISO(dateOfBirthString) > TEN_YEARS_AGO_DATE) {
    return "Please enter a year no later than " + TEN_YEARS_AGO_DATE.year;
  }
  return validOrErrorMessage;
}

interface DatePickerComponentProps {
  customInput?: React.ReactNode | undefined;
}

export interface DatePickerInputProps {
  parentId?: string;
  fieldIds?: { month?: string; day?: string; year?: string; date?: string };
  label: string;
  required?: boolean;
  helperText?: string;
  requiredMinDate?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  validateDate?: Validate<any> | Record<string, Validate<any>>;
  hidden?: boolean;
}

export function DatePickerInput({
  parentId,
  fieldIds = { month: "month", day: "day", year: "year" },
  label,
  required = true,
  helperText,
  requiredMinDate = false,
  minDate = MIN_DATE_FULL,
  maxDate = MAX_DATE_FULL,
  validateDate,
  hidden = false,
}: DatePickerInputProps) {
  const { register, formState, getValues, setValue, clearErrors } =
    useFormContext();

  const inputIsAccessible = !hidden;
  const validateRequired = inputIsAccessible && required;
  const validateRequiredByFunction =
    (validateRequired && validateDate !== undefined) || !validateRequired;
  const validateRequiredMinDate = inputIsAccessible && requiredMinDate;

  const parentPath = parentId ? parentId + "." : "";
  const fieldParts = fieldIds?.month?.split(".");
  let addressPath = "";
  if (fieldParts && fieldParts.length > 1) {
    addressPath = addressPath + fieldIds?.month?.split(".")[0];
  }
  if (fieldParts && fieldParts.length === 3) {
    addressPath = addressPath + "." + fieldParts[1];
  }
  const dateLabelParts = fieldIds?.date?.split(".");
  let dateLabel = "";
  if (dateLabelParts && dateLabelParts.length === 1) {
    dateLabel = dateLabelParts[0];
  }
  if (dateLabelParts && dateLabelParts.length === 2) {
    dateLabel = "." + dateLabelParts[1];
  }
  if (dateLabelParts && dateLabelParts.length === 3) {
    dateLabel = "." + dateLabelParts[2];
  }

  const dateId = parentPath + addressPath + dateLabel;

  const savedDate = getValues(dateId);
  // fromIso() returns a DateTime object regardless of what was passed in
  // use isValid to avoid calling methods on invalid DateTime objects
  const savedDateTime = DateTime.fromISO(savedDate);
  const [dateValue, setDateValue] = useState<Date | null>(
    savedDateTime.isValid ? savedDateTime.toJSDate() : null,
  );

  // Only valid saved dates will be displayed. Incomplete dates will be empty.
  useEffect(() => {
    setValue(dateId, savedDateTime.isValid ? savedDateTime.toISODate() : null);
  }, [dateValue]);

  // Invalid dates or null values will not show date preview
  const [formattedDatePreview, setFormattedDatePreview] = useState<
    string | null
  >(
    savedDateTime.isValid
      ? savedDateTime.toLocaleString(DateTime.DATE_FULL)
      : null,
  );

  const hasInvalidMinDate =
    validateRequiredMinDate && get(formState.errors, `${parentPath}.minDate`);

  // The DatePicker component onChange in combination with :
  //  - customInput > Input > ReactInputMask
  // does not trigger on Safari browsers.
  // This is a workaround that wil trigger the onChange when "Enter" is entered.
  function handleSafariOnKeyDown(e: any) {
    if (e.key === "Enter" && isSafari) {
      const dateValue = e.target.value;

      const dateTimeValue: DateTime = DateTime.fromFormat(
        dateValue,
        "MM / dd / yyyy",
      );
      if (dateTimeValue.isValid) {
        const isDateAfterMinDate = dateTimeValue >= minDate;
        const isDateBeforeMaxDate = dateTimeValue <= maxDate;

        if (isDateAfterMinDate && isDateBeforeMaxDate) {
          handleDateChange(dateTimeValue.toJSDate());
        }
      }
    }
  }

  const handleOnKeyDown = (e: any) => {
    handleSafariOnKeyDown(e);
  };

  // This only triggers when the input has a full date
  const handleDateChange = (date: Date) => {
    // Set the date state value
    setDateValue(date);

    // Set the formatted date preview text
    setFormattedDatePreview(
      DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL),
    );

    // Set the form's date value
    setValue(dateId, DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"));
    clearErrors(dateId);
  };

  // Return DateTime object as a string in "MM / dd / yyyy" format
  const getFormattedDateString = (date: Date) => {
    const dateTime = DateTime.fromJSDate(date);
    return dateTime.isValid ? dateTime.toFormat("MM / dd / yyyy") : null;
  };

  const DatePickerComponent = ({ customInput }: DatePickerComponentProps) => {
    return (
      <DatePicker
        selected={dateValue}
        onChange={handleDateChange}
        onKeyDown={handleOnKeyDown}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        minDate={minDate.toJSDate()}
        maxDate={maxDate.toJSDate()}
        placeholderText="MM/DD/YYYY"
        customInput={customInput}
      />
    );
  };

  const DatePickerIcon = ({ ...props }) => {
    return (
      <InputRightElement
        zIndex={0}
        pointerEvents={dateValue ? "all" : "none"}
        {...props}>
        {dateValue ? (
          <CloseIcon
            onClick={() => {
              setDateValue(null);
              setFormattedDatePreview("");
              setValue(dateId, null);
            }}
            cursor="pointer"
          />
        ) : (
          <CalendarIcon />
        )}
      </InputRightElement>
    );
  };

  return (
    <Box hidden={hidden} py="0.5rem" width={"fit-content"}>
      <FormLabel
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <FormControl
        isInvalid={hasInvalidMinDate || get(formState.errors, dateId)}>
        <VStack alignItems="flexStart">
          {isSafari ? (
            <InputGroup size="md">
              <DatePickerComponent
                customInput={
                  (
                    <Button
                      id={dateId}
                      color={"black"}
                      background={"white"}
                      border={"1px"}
                      borderColor={"gray.600"}
                      fontStyle={"normal"}
                      textTransform={"none"}
                      fontSize={"md"}
                      fontWeight={"light"}
                      fontFamily={"sans-serif"}
                      _hover={{ bg: "" }}
                      width="125%"
                      {...register(dateId, {
                        required: {
                          value: !validateRequiredByFunction,
                          message: t`This is required`,
                        },
                        validate:
                          (inputIsAccessible && validateDate) || undefined,
                      })}>
                      {dateValue
                        ? getFormattedDateString(dateValue)
                        : "Click to select date"}
                    </Button>
                  ) as React.ReactNode
                }
              />
              <DatePickerIcon right="unset" />
            </InputGroup>
          ) : (
            <InputGroup size="md">
              <DatePickerComponent
                customInput={
                  <Input
                    id={dateId}
                    as={ReactInputMask}
                    borderColor={"gray.600"}
                    mask="99 / 99 / 9999"
                    {...register(dateId, {
                      required: {
                        value: !validateRequiredByFunction,
                        message: t`This is required`,
                      },
                      validate:
                        (inputIsAccessible && validateDate) || undefined,
                    })}
                  />
                }
              />
              <DatePickerIcon width="4.5rem" />
            </InputGroup>
          )}
          <FormErrorMessage>
            {get(formState.errors, `${dateId}.message`) ||
              (hasInvalidMinDate && "End Date must be after Start Date")}
          </FormErrorMessage>
          {formattedDatePreview && (
            <FormHelperText>{formattedDatePreview}</FormHelperText>
          )}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </VStack>
      </FormControl>
    </Box>
  );
}
