import {
  Flex,
  FormLabel,
  Link,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import {
  InterviewApiSubForm,
  InterviewFormParameters,
  InterviewFormFields,
  InterviewFormMessages,
} from "components/tasks/interviews/interviewApiTypes";
import parse from "html-react-parser";
import OkButton from "../../../common/basic/OkButton";

interface SurveyFormParameters extends InterviewFormParameters {
  surveyUrl?: string;
}

interface SurveyFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: SurveyFormParameters;
}

export type SurveyApiSubForm = InterviewApiSubForm<
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

export default function SurveyForm({
  formMessages,
  formParameters,
  goToStatusCheck,
}: SurveyFormProps): JSX.Element {
  const { formName, formInstructions } = formMessages;

  const { surveyUrl } = formParameters;

  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;

  return (
    <VStack w="100%" p={1}>
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <Flex width={"full"} alignItems={"start"}>
        <Link isExternal href={surveyUrl} mt={3}>
          Take Survey
        </Link>
      </Flex>
      <OkButton onClick={goToStatusCheck} />
    </VStack>
  );
}
