import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { DropdownSelectionFormFields } from "./DropdownSelectionTask";
import FormSelect from "components/common/form/FormSelect";
import FormInput from "components/common/form/FormInput";
import { ReactNode, useEffect } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";

interface DropdownSelectionFormMessages extends InterviewFormMessages {
  dropdownLabel?: string;
  otherLabel?: string;
}

interface DropdownSelectionFormParameters extends InterviewFormParameters {
  dropdownOptions?: Array<string>;
}

interface DropdownSelectionFormProps extends TaskComponentProps {
  formMessages: DropdownSelectionFormMessages;
  formParameters: DropdownSelectionFormParameters;
  defaultValues?: DropdownSelectionFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
  dropdownLabel?: string;
  dropdownOptions?: Array<string>;
  displaySpecifyOtherField?: boolean;
  otherLabel?: string;
}

export interface DropdownSelectionSubFormFields extends InterviewFormFields {
  dropdownSelection?: string;
  otherValue?: string;
}

export interface DropdownSelectionSubFormMessages
  extends InterviewFormMessages {
  dropdownLabel: string;
  otherLabel: string;
}

export interface DropdownSelectionSubFormParameters
  extends InterviewFormParameters {
  dropdownOptions?: Array<string>;
}

export type DropdownSelectionApiSubForm = InterviewApiSubForm<
  DropdownSelectionSubFormFields,
  DropdownSelectionSubFormMessages,
  DropdownSelectionSubFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
  dropdownLabel,
  dropdownOptions,
  displaySpecifyOtherField,
  otherLabel,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions}</Text>
      <Box width="100%">
        <FormSelect
          fieldId={`${formId}.dropdownSelection`}
          label={dropdownLabel || ""}
          options={dropdownOptions || []}
        />
        {displaySpecifyOtherField && (
          <FormInput
            label={otherLabel || ""}
            fieldId={`${formId}.otherValue`}
          />
        )}
      </Box>
    </FormControl>
  );
};

export default function DropdownSelectionForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: DropdownSelectionFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<DropdownSelectionFormFields>({ defaultValues });

  const { formName, formInstructions, dropdownLabel, otherLabel } =
    formMessages;

  const { formId, dropdownOptions } = formParameters;

  const displaySpecifyOtherField =
    methods.watch(`${formId}.dropdownSelection`) === "Other";

  useEffect(() => {
    if (!displaySpecifyOtherField) {
      methods.setValue(`${formId}.otherValue`, undefined);
    }
  }, [displaySpecifyOtherField]);

  const formContentProps = {
    formId,
    formName,
    formInstructions,
    dropdownLabel,
    dropdownOptions,
    displaySpecifyOtherField,
    otherLabel,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
