import { Controller, get, useFormContext, useWatch } from "react-hook-form";
import {
  Box,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import FormInput from "components/common/form/FormInput";
import { t, Trans } from "@lingui/macro";
import FormSelect from "components/common/form/FormSelect";
import AddressForm from "components/common/form/AddressForm";
import { OptionalApiIdFormFields } from "components/tasks/taskHelpers";
import { useEffect, useState } from "react";
import { COUNTRIES, COUNTRY_UNITED_STATES } from "tasks/apiConstants";
import { PHONE_NUMBER } from "components/common/form/fieldConstants";
import {
  onKeyDownEnforceNumeric,
  onKeyDownEnforcePhone,
} from "util/InputUtils";
import {
  DatePickerInput,
  isStartDateBeforeEndDate,
  validateFormDate,
} from "components/common/form/DatePickerInput";

export interface InstitutionFormFields extends OptionalApiIdFormFields {
  educationType: string;
  degreeType?: string;
  graduated: string;
  gradDate?: string;
  institution: string;
  country: string;
  street: string;
  city: string;
  state: string;
  nameWhileAttending: string;
  fieldOfStudy?: string;
  schoolPhone?: string;
  startDate?: string;
  endDate?: string;
  gpa?: string;
  seatNumber?: string;
}

interface InstitutionFormProps {
  institutionId: string;
  eduTypes: string[];
  eduDegrees: {
    [eduType: string]: string[];
  };
  showRemoveButton?: boolean;
  onRemoveClick?: () => void;
}

export const EMPTY_INSTITUTION: InstitutionFormFields = {
  educationType: "",
  degreeType: "",
  graduated: "",
  gradDate: "",
  institution: "",
  country: COUNTRY_UNITED_STATES,
  street: "",
  city: "",
  state: "",
  nameWhileAttending: "",
  fieldOfStudy: "",
  schoolPhone: "",
  startDate: "",
  endDate: "",
  gpa: "",
  seatNumber: "",
};

export default function InstitutionForm({
  institutionId,
  showRemoveButton = false,
  eduTypes,
  eduDegrees,
  onRemoveClick,
}: InstitutionFormProps): JSX.Element {
  const {
    watch,
    formState: { isDirty, isSubmitted, isSubmitting, isValidating, errors },
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const [usePhoneNumberMask, setUsePhoneNumberMask] = useState<boolean>(false);

  const selectedEduType = watch(`${institutionId}.educationType`);
  const degreeOptions = eduDegrees[selectedEduType] || eduDegrees.Default;

  const selectedCountry = watch(`${institutionId}.country`);

  const [startMonth, startDay, startYear, startDate] = watch([
    `${institutionId}.startMonth`,
    `${institutionId}.startDay`,
    `${institutionId}.startYear`,
    `${institutionId}.startDate`,
  ]);

  const [endMonth, endDay, endYear, endDate] = watch([
    `${institutionId}.endMonth`,
    `${institutionId}.endDay`,
    `${institutionId}.endYear`,
    `${institutionId}.endDate`,
  ]);

  //set degreeType after selectedEduType changes and causes re-render
  useEffect(() => {
    isDirty &&
      setValue(
        `${institutionId}.degreeType`,
        degreeOptions?.length === 1 ? degreeOptions[0] : "",
        { shouldDirty: true, shouldTouch: true, shouldValidate: isSubmitted },
      );
  }, [selectedEduType]);

  const graduated = watch(`${institutionId}.graduated`);

  const validateStartAndEndDate = () => {
    const noHistory = getValues("noHistory");
    if (noHistory && noHistory === true) {
      return true;
    }
    const startDate = getValues(`${institutionId}.startDate`);
    const endDate = getValues(`${institutionId}.endDate`);
    if (startDate && endDate && !isStartDateBeforeEndDate(startDate, endDate)) {
      return "End date must be after Start date";
    }

    return true;
  };

  const isUnitedStates = selectedCountry === COUNTRIES[0];

  const institutionSchoolPhonePath = `${institutionId}.schoolPhone`;

  const institutionSchoolPhone = watch(institutionSchoolPhonePath);

  useEffect(() => {
    setUsePhoneNumberMask(
      isUnitedStates && institutionSchoolPhone?.replace(/\D/g, "")?.length < 11,
    );
  }, [institutionSchoolPhone]);

  const validateGradDate = () => {
    const graduated = getValues(`${institutionId}.graduated`);
    const noHistory = getValues("noHistory");
    if (
      !graduated ||
      graduated === "false" ||
      (noHistory && noHistory === true)
    ) {
      return true;
    }

    const gradDatePath = `${institutionId}.gradDate`;
    const gradDate = getValues(gradDatePath);

    return validateFormDate(gradDate);
  };

  // TODO (JDL:2022-06-23) Use the <FormRadioGroup/> instead of <RadioGroup/>
  return (
    <Box className="arrayItemForm">
      {showRemoveButton && (
        <CloseButton
          className="closeButton"
          size="md"
          onClick={onRemoveClick}
        />
      )}
      <FormSelect
        fieldId={`${institutionId}.educationType`}
        label={t`Education Type`}
        placeHolder={t`Select an Education Type`}
        options={eduTypes}
      />
      <FormSelect
        fieldId={`${institutionId}.degreeType`}
        label={t`Degree Type`}
        placeHolder={
          selectedEduType
            ? t`Select a Degree Type`
            : t`Select an Education Type above`
        }
        options={degreeOptions}
        disabled={degreeOptions.length === 1 || !selectedEduType}
      />
      <FormControl
        isInvalid={get(errors, `${institutionId}.graduated`)}
        py="0.5rem">
        <FormLabel
          id={`${institutionId}.graduatedLabel`}
          className="requiredField">
          <Trans>Were you awarded a degree/diploma/certificate/GED?</Trans>
        </FormLabel>
        <Controller
          control={control}
          name={`${institutionId}.graduated`}
          rules={{ required: { value: true, message: t`This is required` } }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <RadioGroup
              aria-label={`${institutionId}.graduatedLabel`}
              name={`${institutionId}.graduated`}
              defaultValue={value}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}>
              <Radio px={1} size="lg" value={"true"}>
                Yes
              </Radio>
              <Radio px={1} size="lg" value={"false"}>
                No
              </Radio>
            </RadioGroup>
          )}
        />
        <FormErrorMessage>
          {get(errors, `${institutionId}.graduated.message`)}
        </FormErrorMessage>
      </FormControl>
      {graduated === "true" && (
        <DatePickerInput
          label={t`Graduation/Award Date`}
          parentId={institutionId}
          fieldIds={{
            year: "gradYear",
            month: "gradMonth",
            day: "gradDay",
            date: "gradDate",
          }}
          validateDate={validateGradDate}
        />
      )}
      <FormInput
        fieldId={`${institutionId}.institution`}
        label={t`Institution`}
        maxLength={255}
      />
      <AddressForm
        addressId={`${institutionId}`}
        showPostalCode={false}
        required={{ street: false }}
      />
      <FormInput
        fieldId={`${institutionId}.nameWhileAttending`}
        label={t`Name while attending`}
        // defaultValue={defaultValues.nameWhileAttending}
        maxLength={255}
      />
      <FormInput
        fieldId={`${institutionId}.fieldOfStudy`}
        label={t`Field of Study`}
        required={false}
        maxLength={255}
      />
      <FormInput
        fieldId={institutionSchoolPhonePath}
        label={t`School Phone Number`}
        type={"tel"}
        onKeyDown={
          usePhoneNumberMask ? onKeyDownEnforceNumeric : onKeyDownEnforcePhone
        }
        inputMask={usePhoneNumberMask ? PHONE_NUMBER.PARENS_MASK : undefined}
        placeholder={
          usePhoneNumberMask ? PHONE_NUMBER.PARENS_PLACEHOLDER : undefined
        }
        validationPattern={
          usePhoneNumberMask ? PHONE_NUMBER.PARENS_PATTERN : undefined
        }
        validationPatternMessage={PHONE_NUMBER.PATTERN_MESSAGE}
        required={false}
        autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
      />
      <DatePickerInput
        label={t`Start Date`}
        parentId={institutionId}
        fieldIds={{
          year: "startYear",
          month: "startMonth",
          day: "startDay",
          date: "startDate",
        }}
        required={false}
        validateDate={validateStartAndEndDate}
      />
      <DatePickerInput
        label={t`End Date`}
        parentId={institutionId}
        fieldIds={{
          year: "endYear",
          month: "endMonth",
          day: "endDay",
          date: "endDate",
        }}
        required={false}
        validateDate={validateStartAndEndDate}
        requiredMinDate={true}
      />
      <FormInput
        fieldId={`${institutionId}.gpa`}
        label={t`GPA`}
        required={false}
        maxLength={100}
      />
      <FormInput
        fieldId={`${institutionId}.seatNumber`}
        label={`Seat Number`}
        required={false}
        maxLength={100}
      />
    </Box>
  );
}
