import { Heading, Text } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import {
  SpecialFormSchema,
  useGetSpecialForm,
  useStoreTaskMutation,
} from "tasks/taskQueries";
import {
  ApplicantEntrySpecialFormTaskId,
  getSpecialFormId,
} from "tasks/tasksApi";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import SpecialFormForm, {
  SpecialFormFields,
} from "components/tasks/special-form/SpecialFormForm";
import { TaskComponentProps } from "components/tasks/taskHelpers";

interface SpecialFormTaskProps extends TaskComponentProps {
  taskId: ApplicantEntrySpecialFormTaskId;
}

export default function SpecialFormTask({
  taskId,
  goToStatusCheck,
}: SpecialFormTaskProps): JSX.Element {
  const specialFormId = getSpecialFormId(taskId);

  const { data, isFetching, isRefetching } = useGetSpecialForm({
    specialFormId,
    includeTaskDef: true,
    goToStatusCheck,
  });

  const schema = data?.schema as SpecialFormSchema;

  const { mutate: mutateTaskData, isLoading: isStoring } = useStoreTaskMutation(
    { taskId, onSuccess: goToStatusCheck },
  );
  const storeSpecialFormData: SubmitHandler<SpecialFormFields> = async ({
    schema,
    uiSchema,
    formData,
  }: SpecialFormFields) => {
    const schemaFormFields = Object.keys(schema.properties);
    type RequestData = {
      [key: string]: any;
    };
    const submitData: RequestData = {};
    schemaFormFields.forEach((schemaField) => {
      const userDataProperties = uiSchema[schemaField].additionalProperties;
      const userData = formData[schemaField];
      if (userData) {
        const formFieldType = userDataProperties.formFieldType;
        switch (formFieldType) {
          case "radio":
            submitData[userDataProperties.fieldId] = userData;

            //The check below is needed for ga-statewide
            //because of how its data was setup
            if (taskId == ApplicantEntrySpecialFormTaskId.GA_STATEWIDE) {
              userDataProperties.formFields.forEach((formField: Array<any>) => {
                submitData[formField[1]] = userData === formField[0];
              });
            }
            break;
          case "compound": //compound is always signature?
            submitData[userDataProperties.formField] = { SIGNATURE: userData };
            break;
          default:
            submitData[userDataProperties.formField] = userData;
        }
      }
    });

    mutateTaskData({
      formFields: submitData,
    });
  };
  return isFetching || isRefetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {data?.taskName}
      </Heading>
      <Text pb="4">{data?.taskDescription}</Text>
      <SpecialFormForm
        defaultValues={{
          specialFormId: specialFormId,
          schema: schema.formSchema,
          uiSchema: schema.uiSchema,
          formData: schema.formData,
        }}
        onSubmit={storeSpecialFormData}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
