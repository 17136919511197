import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
  watchAndEvaluateBoolean,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { CreditFreezeFormFields } from "./CreditFreezeTask";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import FormRadioGroup from "components/common/form/FormRadioGroup";

interface CreditFreezeFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: CreditFreezeFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
}

export interface CreditFreezeSubFormFields extends InterviewFormFields {
  freezeWasLifted?: boolean;
  comment?: string;
}

export type CreditFreezeApiSubForm = InterviewApiSubForm<
  CreditFreezeSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
}: FormContentProps) => {
  const methods = useFormContext();
  const { control, getValues } = methods;
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;

  const freezeWasLifted = watchAndEvaluateBoolean(
    methods,
    `${formId}.freezeWasLifted`,
  );

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <FormRadioGroup
        fieldId={`${formId}.freezeWasLifted`}
        groupLabel={t`Freeze was lifted.`}
        radios={[
          [t`Yes`, "true"],
          [t`No`, "false"],
        ]}
        control={control}
      />
      <FormInput
        label={t`Comment`}
        fieldId={`${formId}.comment`}
        required={!freezeWasLifted}
      />
    </FormControl>
  );
};

export default function CreditFreezeForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: CreditFreezeFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<CreditFreezeFormFields>({ defaultValues });

  const { formName, formInstructions } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    formId,
    formName,
    formInstructions,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
