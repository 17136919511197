import { Button } from "@chakra-ui/react";
import useAuth from "auth/useAuth";
import { useDownloadFile } from "tasks/useDownloadFile";
import { useState } from "react";
import { AxiosResponse } from "axios";

interface FileDownloadButtonProps {
  label: string;
  getFile: (token: string) => Promise<AxiosResponse<Blob>>;
  onError: () => void;
  preDownload?: () => void;
  postDownload?: () => void;
}

export default function FileDownloadButton({
  label,
  onError,
  getFile,
  preDownload,
  postDownload,
}: FileDownloadButtonProps): JSX.Element {
  const { token } = useAuth();
  const [isFetching, setLoading] = useState<boolean>(false);

  const { download } = useDownloadFile({
    apiDefinition: () => getFile(token),
    preDownloading: () => {
      setLoading(true);
      !!preDownload && preDownload();
    },
    postDownloading: () => {
      !!postDownload && postDownload();
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      onError();
    },
  });

  return (
    <>
      <Button
        className="notranslate"
        isLoading={isFetching}
        mb={4}
        colorScheme="orange"
        onClick={download}
        minH={"40px"}
        height={"auto"}
        whiteSpace={"normal"}>
        {label}
      </Button>
    </>
  );
}
