import { WidgetProps } from "@rjsf/utils";
import useOtesModal from "error/useOtesModal";
import { FormLabel } from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import FileDownloadButton from "components/common/file/FileDownloadButton";
import { getPreliminarySpecialForm } from "tasks/tasksApi";

export default function SpecialFormPreliminaryDownloadButton(
  props: WidgetProps,
) {
  const { triggerOtesModal } = useOtesModal();
  const specialFormId = props?.uiSchema?.additionalProperties.specialFormId;

  return (
    <>
      <FormLabel fontSize="xl" fontWeight="bold">
        <Trans>Open File</Trans>
      </FormLabel>
      <FileDownloadButton
        label={"Download Preliminary Form"}
        getFile={(token: string) =>
          getPreliminarySpecialForm(token, specialFormId)
        }
        onError={() => {
          triggerOtesModal({
            bodyText: t`There was an error downloading the file, please try again.`,
          });
        }}
      />
      <br />
    </>
  );
}
