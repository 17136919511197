import { memo, useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  HStack,
  Table,
  Td,
  Text,
  Tr,
  useTheme,
} from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import { dynamicActivate, languages as availableLanguages } from "App";
import { AxiosError } from "axios";
import { handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { ResponseCodes } from "tasks/apiConstants";
import { t } from "@lingui/macro";
import {
  ApplicantEntryTaskId,
  clearAllSavedTasks,
  clearSavedTask,
  resetTask,
  resetTasks,
} from "tasks/tasksApi";
import { GenericApiResponse, unsetCommPrefs, unsetTos } from "auth/authApi";
import useOtesModal from "error/useOtesModal";
import useAuth from "auth/useAuth";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import { i18n } from "@lingui/core";

const DebuggingActions = () => {
  const theme = useTheme();
  const { orange, white } = theme.colors.brand;
  const { token } = useAuth();
  const [isPerformingDebug, setPerformingDebug] = useState<boolean>(false);
  const selectedTaskRef = useRef<HTMLSelectElement>(null);
  const { triggerOtesModal } = useOtesModal();
  const currentLanguage = i18n.locale;

  const handleDebugActionError = (response: AxiosError) => {
    handleOtesErrorFromAxiosError(response);

    if (
      ResponseCodes.Error.Action.TASK_NOT_FOUND ===
      response?.response?.data?.code
    ) {
      triggerOtesModal({
        bodyText: t`Selected task is not present for this candidate, please select a different one.`,
      });
    } else {
      triggerOtesModal({
        bodyText: t`Something went wrong, please try again.`,
      });
    }

    setPerformingDebug(false);
  };

  const performDebugAction = (
    debugAction: () => Promise<GenericApiResponse | string>,
  ) => {
    setPerformingDebug(true);
    debugAction()
      .then(() => location.reload())
      .catch(handleDebugActionError);
  };

  const clearSaveTaskOnClick = () => {
    const taskId = selectedTaskRef.current?.value ?? "";
    if (taskId?.trim()) {
      performDebugAction(() => clearSavedTask(token, taskId));
    }
  };

  const clearAllSavedTasksOnClick = () =>
    performDebugAction(() => clearAllSavedTasks(token));

  const resetTaskOnClick = () => {
    const taskId = selectedTaskRef.current?.value ?? "";
    if (taskId?.trim()) {
      performDebugAction(() => resetTask(token, taskId));
    }
  };

  const resetTasksOnClick = () => performDebugAction(() => resetTasks(token));

  const unsetCommPrefOnClick = () =>
    performDebugAction(() => unsetCommPrefs(token));

  const unsetTOSOnClick = () => performDebugAction(() => unsetTos(token));

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    dynamicActivate(event.target.value);

  const getAppEntryOptions = (): ApplicantEntryTaskId[] => {
    const appEntryOptions: ApplicantEntryTaskId[] = [];

    for (const key in taskHeadings) {
      appEntryOptions.push(key as ApplicantEntryTaskId);
    }

    return appEntryOptions;
  };

  interface DebuggingActionsRowProps {
    title?: string;
    label: string;
    value: React.ReactNode;
  }

  const DebuggingActionsRow = memo(
    ({ title, label, value }: DebuggingActionsRowProps) => {
      return (
        <Tr title={title}>
          <Td px={0} pb={0} pt={0.5} border={0}>
            {label}
          </Td>
          <Td px={0} pb={0} pt={0.5} border={0}>
            {value}
          </Td>
        </Tr>
      );
    },
  );
  DebuggingActionsRow.displayName = "DebuggingActionsRow";

  interface DebuggingActionButtonProps extends ButtonProps {
    buttonName: string;
  }

  const DebuggingActionButton = memo(
    ({ buttonName, ...other }: DebuggingActionButtonProps) => {
      return (
        <Button
          size={"xs"}
          width={"100%"}
          backgroundColor={orange}
          color={white}
          {...other}>
          {buttonName}
        </Button>
      );
    },
  );
  DebuggingActionButton.displayName = "DebuggingActionButton";

  return (
    <>
      {isPerformingDebug && (
        <SpinnerOverlay text={"Performing debug action..."} />
      )}
      <Text align="center" width="100%" borderTop="double" my={3} pt={3}>
        Debugging Actions
      </Text>
      <Table width="100%" fontSize="12px">
        <DebuggingActionsRow
          title="Clear will remove saved data. Reset will revert task to incomplete."
          label="Reset App Entry Task"
          value={
            <HStack spacing={1}>
              <select
                ref={selectedTaskRef}
                style={{
                  fontSize: 12,
                  width: 110,
                  border: "solid black 1px",
                }}>
                {getAppEntryOptions().map((value, index) => {
                  return (
                    <option key={index} value={value as string}>
                      {taskHeadings[value].long}
                    </option>
                  );
                })}
              </select>
              <DebuggingActionButton
                buttonName="Clear"
                pl="3px"
                pr="3px"
                onClick={clearSaveTaskOnClick}
              />
              <DebuggingActionButton
                buttonName="Reset"
                pl="3px"
                pr="3px"
                onClick={resetTaskOnClick}
              />
            </HStack>
          }
        />
        <DebuggingActionsRow
          label="Clear All Saved Data"
          value={
            <DebuggingActionButton
              buttonName="Clear All"
              title="Clear data from all tasks"
              onClick={clearAllSavedTasksOnClick}
            />
          }
        />
        <DebuggingActionsRow
          label="Reset All App Entry"
          value={
            <DebuggingActionButton
              buttonName="Reset All"
              title="Make all tasks incomplete"
              onClick={resetTasksOnClick}
            />
          }
        />
        <DebuggingActionsRow
          label="Unset Comm Pref"
          value={
            <DebuggingActionButton
              buttonName="Unset"
              title="Re-trigger Communication Preference screen"
              onClick={unsetCommPrefOnClick}
            />
          }
        />
        <DebuggingActionsRow
          label="Unset Terms of Service"
          value={
            <DebuggingActionButton
              buttonName="Unset"
              title="Unset Terms of Service"
              onClick={unsetTOSOnClick}
            />
          }
        />
        <DebuggingActionsRow
          label="Change Language"
          value={
            <select
              onChange={handleLanguageChange}
              style={{
                border: "solid black 1px",
              }}>
              <option value={currentLanguage}>{currentLanguage}</option>
              {Object.keys(availableLanguages).map((value, index) => {
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          }
        />
      </Table>
    </>
  );
};

export default memo(DebuggingActions);
