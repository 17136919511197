import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import {
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { SubmitHandler } from "react-hook-form";
import { TaskComponentProps } from "components/tasks/taskHelpers";
import EducationDatesForm from "./EducationDatesForm";
import { useStoreTaskMutation } from "tasks/taskQueries";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";

export type EducationDatesApiForm = {
  formFields: EducationDatesApiFormFields;
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
};

export interface EducationDatesFormFields {
  fromDate?: string;
  toDate?: string;
  current?: boolean;
  doSave?: boolean;
}

interface EducationDatesApiFormFields extends InterviewFormFields {
  from?: string;
  to?: string;
  current?: boolean;
}

interface EducationDatesTaskProps extends TaskComponentProps {
  applicationUid: string;
  taskId: string;
  form: EducationDatesApiForm;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  isSavable?: boolean;
}

export default function EducationDatesTask({
  applicationUid,
  taskId,
  form,
  onSubmit,
  isSavable = true,
  goToStatusCheck,
}: EducationDatesTaskProps): JSX.Element {
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const formFields = {
    from: form.formFields?.from || "",
    to: form.formFields?.to || "",
    current: form.formFields?.current,
  };

  const formMessages = {
    formName: form.formMessages?.formName || "",
    formInstructions:
      form.formMessages?.formInstructions ||
      "Please provide the dates of attendance.",
  };

  const formParameters = {
    formId: form.formParameters?.formId || "",
    formType: form.formParameters?.formType || "",
  };

  return isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <EducationDatesForm
      defaultValues={{
        [formParameters.formId]: {
          fromDate: formFields.from,
          toDate: formFields.to,
          current: formFields.current,
        },
      }}
      formMessages={formMessages}
      formParameters={formParameters}
      isSavable={isSavable}
      onSubmit={onSubmit}
      goToStatusCheck={goToStatusCheck}
    />
  );
}
