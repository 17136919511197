import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { DateOfBirthFormFields } from "./DateOfBirthTask";
import { t } from "@lingui/macro";
import { ReactNode } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "../FormInterviewTask";
import {
  DatePickerInput,
  validateDateOfBirth,
} from "components/common/form/DatePickerInput";

interface DateOfBirthFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: DateOfBirthFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  methods: any;
  formName?: string;
  formId?: string;
}

export interface DateOfBirthSubFormFields extends InterviewFormFields {
  dateOfBirthMonth?: string;
  dateOfBirthDay?: string;
  dateOfBirthYear?: string;
}

export type DateOfBirthApiSubForm = InterviewApiSubForm<
  DateOfBirthSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <Box>
    <FormProvider {...methods}>
      <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  </Box>
);

const FormContent = ({ methods, formName, formId }: FormContentProps) => {
  const theme = useTheme();
  const { orange } = theme.colors.brand;

  const validateDate = (dateOfBirthString: string) => {
    return validateDateOfBirth(dateOfBirthString);
  };

  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <DatePickerInput
        label={t`Date Of Birth`}
        fieldIds={{
          month: `${formId}.dateOfBirthMonth`,
          day: `${formId}.dateOfBirthDay`,
          year: `${formId}.dateOfBirthYear`,
          date: `${formId}.dateOfBirthDate`,
        }}
        {...{
          validateDate: (value) => validateDate(value),
        }}
      />
    </FormControl>
  );
};

export default function DateOfBirthForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: DateOfBirthFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<DateOfBirthFormFields>({ defaultValues });

  const { formName } = formMessages;

  const { formId } = formParameters;

  const formContentProps = {
    methods,
    formId,
    formName,
  };

  const formWrapperProps = {
    methods,
    formId,
    onSubmit,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
