import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import {
  genericOnSave,
  TaskComponentProps,
} from "components/tasks/taskHelpers";
import TaskButtons from "components/common/basic/TaskButtons";
import { ContactInformationFormFields } from "components/tasks/interviews/contact-information/ContactInformationTask";
import FormInput from "components/common/form/FormInput";
import { t } from "@lingui/macro";
import parse from "html-react-parser";
import { ReactNode, useEffect } from "react";
import {
  InterviewApiSubForm,
  InterviewFormFields,
  InterviewFormMessages,
  InterviewFormParameters,
} from "components/tasks/interviews/interviewApiTypes";
import { InterviewSubmitProps } from "components/tasks/interviews/FormInterviewTask";
import FormCheckbox from "components/common/form/FormCheckbox";
import {
  EMAIL_ADDRESS,
  PHONE_NUMBER,
} from "components/common/form/fieldConstants";
import { onKeyDownEnforceNumeric } from "util/InputUtils";

interface ContactInformationFormProps extends TaskComponentProps {
  formMessages: InterviewFormMessages;
  formParameters: InterviewFormParameters;
  defaultValues?: ContactInformationFormFields;
  hasParentForm?: boolean;
  isSavable?: boolean;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
}

interface FormWrapperProps {
  methods: any;
  formId?: string;
  onSubmit?: SubmitHandler<InterviewSubmitProps>;
  children: ReactNode;
}

interface FormContentProps {
  formName?: string;
  formInstructions?: string;
  formId?: string;
  phoneUnavailable?: boolean;
  emailUnavailable?: boolean;
}

export interface ContactInformationSubFormFields extends InterviewFormFields {
  phone?: string;
  phoneUnavailable?: boolean;
  email?: string;
  emailUnavailable?: boolean;
}

export type ContactInformationApiSubForm = InterviewApiSubForm<
  ContactInformationSubFormFields,
  InterviewFormMessages,
  InterviewFormParameters
>;

const FormWrapper = ({
  methods,
  formId,
  onSubmit,
  children,
}: FormWrapperProps) => (
  <FormProvider {...methods}>
    <form id={formId} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
      {children}
    </form>
  </FormProvider>
);

const FormContent = ({
  formName,
  formInstructions,
  formId,
  phoneUnavailable,
  emailUnavailable,
}: FormContentProps) => {
  const theme = useTheme();
  const { orange, dimGray } = theme.colors.brand;
  return (
    <FormControl as="fieldset">
      <FormLabel as="legend" fontSize="xl" fontWeight="bold" color={orange}>
        {formName}
      </FormLabel>
      <Text color={dimGray}>{formInstructions && parse(formInstructions)}</Text>
      <Box width="100%">
        <FormInput
          type="tel"
          label={t`Phone`}
          fieldId={`${formId}.phone`}
          disabled={phoneUnavailable}
          required={!phoneUnavailable}
          onKeyDown={onKeyDownEnforceNumeric}
          inputMask={PHONE_NUMBER.PARENS_MASK}
          placeholder={PHONE_NUMBER.PARENS_PLACEHOLDER}
          validationPattern={PHONE_NUMBER.PARENS_PATTERN}
          validationPatternMessage={PHONE_NUMBER.PATTERN_MESSAGE}
          autoComplete={PHONE_NUMBER.AUTO_COMPLETE}
        />
        <FormCheckbox
          fieldId={`${formId}.phoneUnavailable`}
          label={t`Unavailable`}
        />
        <FormInput
          type="email"
          label={t`Email`}
          fieldId={`${formId}.email`}
          disabled={emailUnavailable}
          required={!emailUnavailable}
          placeholder={EMAIL_ADDRESS.PLACEHOLDER}
          validationPattern={EMAIL_ADDRESS.PATTERN}
          validationPatternMessage={EMAIL_ADDRESS.PATTERN_MESSAGE}
        />
        <FormCheckbox
          fieldId={`${formId}.emailUnavailable`}
          label={t`Unavailable`}
        />
      </Box>
    </FormControl>
  );
};

export default function ContactInformationForm({
  defaultValues = {},
  formMessages,
  formParameters,
  hasParentForm = false,
  isSavable,
  onSubmit,
  goToStatusCheck,
}: ContactInformationFormProps): JSX.Element {
  const methods: any = hasParentForm
    ? useFormContext()
    : useForm<ContactInformationFormFields>({ defaultValues });

  const { formName, formInstructions } = formMessages;

  const { formId } = formParameters;

  const phoneUnavailable = methods.watch(`${formId}.phoneUnavailable`);

  const emailUnavailable = methods.watch(`${formId}.emailUnavailable`);

  useEffect(() => {
    const { isDirty, isSubmitted } = methods.formState;
    if (isDirty) {
      if (phoneUnavailable) {
        methods.setValue(`${formId}.phone`, undefined);
        methods.clearErrors(`${formId}.phone`);
      } else {
        if (isSubmitted) {
          methods.setError(`${formId}.phone`, {
            type: "required",
            message: t`This is required`,
          });
        }
      }
    }
  }, [phoneUnavailable]);

  useEffect(() => {
    const { isDirty, isSubmitted } = methods.formState;
    if (isDirty) {
      if (emailUnavailable) {
        methods.setValue(`${formId}.email`, undefined);
        methods.clearErrors(`${formId}.email`);
      } else {
        if (isSubmitted) {
          methods.setError(`${formId}.email`, {
            type: "required",
            message: t`This is required`,
          });
        }
      }
    }
  }, [emailUnavailable]);

  const formContentProps = {
    formId,
    formName,
    formInstructions,
    phoneUnavailable,
    emailUnavailable,
  };

  const formWrapperProps = {
    methods,
    onSubmit,
    formId,
  };

  return (
    <VStack w="100%" p={1}>
      {!hasParentForm ? (
        <FormWrapper {...formWrapperProps}>
          <FormContent {...formContentProps} />
          <TaskButtons
            onBack={goToStatusCheck}
            onSave={genericOnSave(methods, onSubmit, isSavable)}
            form={formId}
          />
        </FormWrapper>
      ) : (
        <FormContent {...formContentProps} />
      )}
    </VStack>
  );
}
