import { TaskComponentProps } from "components/tasks/taskHelpers";
import { useGetTaskdef, useStoreTaskMutation } from "tasks/taskQueries";
import {
  ApiFieldProperties,
  ApiFormFields,
  ApiFormParameters,
  SingleFormTaskDef,
  ApplicantEntryTaskId,
} from "tasks/tasksApi";
import TaskSpinnerOverlay from "components/common/basic/TaskSpinnerOverlay";
import { Heading, Text } from "@chakra-ui/react";
import taskHeadings from "tasks/taskHeadings";
import AkasForm, {
  AkasFormFields,
  KnownNameFields,
  NameFields,
  OtherNameFields,
} from "components/tasks/akas/AkasForm";
import { Trans } from "@lingui/macro";
import { SubmitHandler } from "react-hook-form";

interface AkasTaskdefNameFields {
  FIRST_NAME: ApiFieldProperties;
  MIDDLE_NAME?: ApiFieldProperties;
  NO_MIDDLE_NAME?: ApiFieldProperties;
  LAST_NAME: ApiFieldProperties;
}

interface KnownNamesTaskdefFields extends AkasTaskdefNameFields {
  PERSON_AKA_ID: ApiFieldProperties;
}

interface OtherNamesUsedTaskdefFields extends AkasTaskdefNameFields {
  DATE_CHANGED: ApiFieldProperties;
}

interface AkasTaskdefFields extends ApiFormFields {
  KNOWN_NAMES: KnownNamesTaskdefFields[];
  OTHER_NAMES_USED: OtherNamesUsedTaskdefFields[];
  NO_ADDITIONAL_NAMES: ApiFieldProperties;
}

interface NameApiSaveFields {
  FIRST_NAME: string;
  MIDDLE_NAME?: string;
  NO_MIDDLE_NAME: boolean;
  LAST_NAME: string;
}

interface KnownNameApiSaveFields extends NameApiSaveFields {
  PERSON_AKA_ID: string;
}

interface OtherNameApiSaveFields extends NameApiSaveFields {
  DATE_CHANGED: string;
}

export function isKnownNamesTaskDefFields(
  fields: KnownNamesTaskdefFields | OtherNamesUsedTaskdefFields,
): fields is KnownNamesTaskdefFields {
  return !!(fields as KnownNamesTaskdefFields).PERSON_AKA_ID;
}

export default function AkasTask({
  goToStatusCheck,
}: TaskComponentProps): JSX.Element {
  const taskId = ApplicantEntryTaskId.APP_ENTRY_AKAS;

  const { data, isFetching } = useGetTaskdef<
    SingleFormTaskDef<AkasTaskdefFields>
  >({ taskId, goToStatusCheck });

  function mapApiNameToFormName(apiName: AkasTaskdefNameFields): NameFields {
    return {
      firstName: apiName?.FIRST_NAME?.existingValue || "",
      noMiddleName: apiName?.NO_MIDDLE_NAME?.existingValue === "true",
      middleName: apiName?.MIDDLE_NAME?.existingValue || "",
      lastName: apiName?.LAST_NAME?.existingValue || "",
    };
  }

  function mapUiFormNameToApiSaveName(formName: NameFields): NameApiSaveFields {
    return {
      FIRST_NAME: formName.firstName,
      NO_MIDDLE_NAME: formName.noMiddleName,
      ...(!formName.noMiddleName && {
        MIDDLE_NAME: formName.middleName,
      }),
      LAST_NAME: formName.lastName,
    };
  }

  function mapUiKnownNameToApiSaveKnownName(
    formName: KnownNameFields,
  ): KnownNameApiSaveFields {
    return {
      ...mapUiFormNameToApiSaveName(formName),
      PERSON_AKA_ID: formName.hiddenPersonAkaId,
    };
  }

  function mapUiOtherNameToApiSaveOtherName(
    formName: OtherNameFields,
  ): OtherNameApiSaveFields {
    return {
      ...mapUiFormNameToApiSaveName(formName),
      // DATE_CHANGED: toIsoDate(
      //   formName.lastUsedYear,
      //   formName.lastUsedMonth,
      //   formName.lastUsedDay,
      // ),
      DATE_CHANGED: formName.lastUsedDate,
    };
  }

  function mapApiKnownNameToFormKnownName(
    apiName: KnownNamesTaskdefFields,
  ): KnownNameFields {
    return {
      ...mapApiNameToFormName(apiName),
      hiddenPersonAkaId: apiName.PERSON_AKA_ID.existingValue || "",
    };
  }

  function mapApiOtherNameToFormOtherName(
    apiName: OtherNamesUsedTaskdefFields,
  ): OtherNameFields {
    const splitLastUsedDate = apiName?.DATE_CHANGED?.existingValue
      ? apiName?.DATE_CHANGED?.existingValue?.split("-")
      : ["", "", ""];
    return {
      ...mapApiNameToFormName(apiName),
      lastUsedYear: splitLastUsedDate[0] || "",
      lastUsedMonth: splitLastUsedDate[1] || "",
      lastUsedDay: splitLastUsedDate[2] || "",
      lastUsedDate: apiName?.DATE_CHANGED?.existingValue || "",
    };
  }

  //create a mutate function for this specific taskId
  const {
    mutate: mutateTaskData,
    isLoading: isStoring,
    variables,
  } = useStoreTaskMutation({
    taskId,
    onSuccess: goToStatusCheck,
  });

  const storeAkas: SubmitHandler<AkasFormFields> = async ({
    knownNames,
    otherNames,
    noAdditionalNames,
    doSave,
  }: AkasFormFields) => {
    mutateTaskData({
      formFields: {
        KNOWN_NAMES: knownNames.map((knownName) =>
          mapUiKnownNameToApiSaveKnownName(knownName),
        ),
        NO_ADDITIONAL_NAMES: noAdditionalNames,
        ...(!noAdditionalNames && {
          OTHER_NAMES_USED: otherNames.map((otherName) =>
            mapUiOtherNameToApiSaveOtherName(otherName),
          ),
        }),
      },
      saveOnly: doSave,
    });
  };

  return isFetching || isStoring ? (
    <TaskSpinnerOverlay isStoring={isStoring} saveOnly={variables?.saveOnly} />
  ) : (
    <>
      <Heading p={1} as="h1">
        {taskHeadings[taskId].long}
      </Heading>
      <Text>
        <Trans>Please add any additional names you have used.</Trans>
      </Text>
      <AkasForm
        defaultValues={{
          knownNames:
            data?.forms[0]?.formFields?.KNOWN_NAMES.map((name) =>
              mapApiKnownNameToFormKnownName(name),
            ) || [],
          otherNames:
            data?.forms[0]?.formFields?.OTHER_NAMES_USED.map((name) =>
              mapApiOtherNameToFormOtherName(name),
            ) || [],
          noAdditionalNames:
            data?.forms[0]?.formFields?.NO_ADDITIONAL_NAMES?.existingValue ===
            "true",
        }}
        isSavable={data?.forms[0]?.formParameters?.isSavable || false}
        onSubmit={storeAkas}
        goToStatusCheck={goToStatusCheck}
      />
    </>
  );
}
